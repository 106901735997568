import { ApiUtility } from "../../Utilities/ApiUtility.axios";
import { Voucher_Type } from "./VoucherServices";

// v1/API/Acc_Draft/
class DraftService {
  route = "v1/API/Acc_Draft";

  // post & eidt api
  postVoucherDraft = (
    companyId: string | undefined,
    voucherType: Voucher_Type,
    params: any,
    id?: string
  ) =>
    ApiUtility.postForm(
      `${this.route}/${companyId}${
        id ? "/" + id : ""
      }?voucherType=${voucherType}`,
      params
    );

  // delete Draft
  deleteDraft = (companyId: string, draftId: string) =>
    ApiUtility.delete(`${this.route}/${companyId}/${draftId}`);

  // get by id Draft
  // draftId;
  getByDraftId = (companyId: string, draftId: string) =>
    ApiUtility.get(`${this.route}/${companyId}/${draftId}`);

  // GetDraftList
  getVoucherDraftList = (
    companyId: any,
    voucherType: Voucher_Type | any,
    start: number,
    length: number,
    from?: string,
    to?: string,
    sortCol?: string,
    sortDir?: string,
    partyId?: string,
    // reconciled?: ReconcileTypes | any,
    search?: string
    // status?: VoucherFilterStatus,
    // itemId?: string
  ) =>
    ApiUtility.get(`${this.route}/${companyId}/GetDraftList`, {
      voucherType,
      start,
      length,
      from,
      to,
      sortCol,
      sortDir,
      partyId,
      //   reconciled,
      search,
      //   status,
      // itemId,
    });
}
export default new DraftService();
