import {
  DeploymentUnitOutlined,
  DownOutlined,
  EyeOutlined,
  LoadingOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Dropdown,
  Empty,
  Form,
  Input,
  InputNumber,
  MenuProps,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Tag,
  Tooltip,
  Typography,
  Upload,
} from "antd";
import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import noteContext from "../../Context/StyleContext";
import { dayjs } from "../../../Utilities/dayjs";
import TextArea from "antd/es/input/TextArea";
import LedgerService, {
  SearchLedgerByGroupsTypes,
  TypeOfAddress,
} from "../../Services/LedgerService";
import { useClient } from "../../Context/ClientContext";
import { getIndianStates } from "../../Services/ClientService";
import VoucherServices, {
  AddressEnum,
  DiscountType,
  Party_Tax,
  RefType,
  Voucher_Type,
} from "../../Services/VoucherServices";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ConfirmationModal } from "../../Common/ConfirmationModal";
import { Utils } from "../../../Utilities/Utils";
import {
  percentCal,
  percentToRs,
  removeElementAtIndex,
} from "../../Common/CAPComponents";
import { handleRoundoff, returnNetAmount } from "./AddSalesInvoice";
import { notificationContext } from "../../Common/PageRoute";
import deleteImg from "../../../images/deletebutton.svg";
import ViewSalesInvoice from "./ViewSalesInvoice";
import { AddLedger } from "../Master/Ledger/AddLedger";
import SalesService, { CAPItemTypes } from "../../Services/SalesService";
import { number } from "echarts";
import { LocalChunkSize } from "papaparse";
import { CompanyGSTTypes } from "../../../Types/CommonConstants";
import {
  SalesDropdownItems,
  PurchaseDropdownItems,
} from "./SalesandPurchaseDropdownList";
import EntryLevelKeys from "../../Common/EntryLevelKeys";
import { Discount_Policy } from "../../Services/SettingService";
import DraftService from "../../Services/DraftService";
import TermsAndConditionModal from "./Terms&ConditionModal";
import DocumentPreview from "../../Common/DocumentPreview";

interface IInvoiceRef {
  voucherId: string;
  voucherNumber: string;
}
const AddSalesCreditNotesNew = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { creditNoteId, draftId } = useParams();
  const { currentClient, companySettings } = useClient();
  const { openNotification } = useContext(notificationContext);
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingAllPage, setLoadingAllPage] = useState<boolean>(false);
  const [form] = Form.useForm();
  const { windowWidth } = useContext(noteContext);
  const [openModel, setOpenModel] = useState<{ type: string; param: any }>({
    type: "",
    param: {},
  });
  const inputRef: any = useRef(null);
  const [searchCustomer, setSearchCustomer] = useState<{
    search: string;
    length: number;
  }>({
    search: " ",
    length: 15,
  });
  const [customerOptions, setCustomerOptions] = useState<any[]>([]);
  const [customerAddress, setCustomerAddress] = useState<any>();
  const [currentCustomerState, setCurrentCustomerState] = useState<number>();
  const [currentCustomerId, setCurrentCustomerId] = useState<string>("");
  const [currentCreditNoteId, setCurrentCreditNoteId] = useState<string>("");
  const [effectiveLedger, setEffectiveLedger] = useState<any[]>([]);
  const [showFreightInsurance, setShowFreightInsurance] =
    useState<boolean>(true);
  const [isGSTEnabled, setGstEnabled] = useState<boolean>(true);
  const [invoiceOption, setInvoiceOption] = useState<IInvoiceRef[]>([]);
  const [itemsOption, setItemsOption] = useState<any[]>([]);
  const [searchItem, setSearchItem] = useState<string>("");
  const [freightAndInsuranceOptions, setFreightAndInsuranceOptions] =
    useState<any>([]);
  const [confirmation, setConfirmation] = useState<boolean>(false);
  const [deleteItemList, setDeleteItemList] = useState<any>();
  const [deleteLedgerList, setDeleteLedgerList] = useState<any>();
  const [itemLength, setItemLength] = useState<number>(0);
  const [modelDetails, setModelDetails] = useState<any>();
  const [totalTcs, setTotalTcs] = useState<number>(0);
  const [totalTds, setTotalTds] = useState<number>(0);
  const [tdsOrTcs, setTdsOrTcs] = useState<number>(0);
  const [ledgerLength, setLedgerLength] = useState<number>(0);
  const [ledgerconfirmation, setLedgerConfirmation] = useState<boolean>(false);
  const [saveButtonLoading, setSaveButtonLoading] = useState<boolean>(false);
  const [saveNewLoading, setSaveNewLoading] = useState<boolean>(false);
  const [currentCreditNoteDetails, setCurrentCreditNoteDetails] =
    useState<any>();
  const [transactionId, setTransactionId] = useState<{
    partyId: string;
    effectiveId: string;
  }>({ partyId: "", effectiveId: "" });
  // const [partyReference, setPartyReference] = useState<any[]>([]);
  const [oldPartyRecepitDetails, setOldPartyRecepitDetails] = useState<any[]>(
    []
  );
  const [oldPartyInvoiceRef, setOldPartyInvoiceRef] = useState<any>({});
  const [onlyInvoiceRef, setOnlyInvoiceRef] = useState<any>({});
  const [invoiceTotalDueAmount, setInvoiceTotalDueAmount] =
    useState<number>(0.0);
  const [inputFieldDisable, setInputFieldDisable] = useState<boolean>(true);
  const [editInvoice, setEditInvoice] = useState<{
    voucherId: string;
    voucherNumber: string;
  }>({ voucherId: "", voucherNumber: "" });

  const [editParty, setEditParty] = useState<{
    id: string;
    balance: number;
  }>({
    id: "",
    balance: 0,
  });
  const [selectedParty, setSelectedParty] = useState<string>("");
  const [editAgiCallback, setEditAgiCallback] = useState<boolean>(true);
  const [invoiceApiCallback, setInvoiceApiCallback] = useState<boolean>(false);
  const [termsAndConditionField, setTermsAndConditionField] =
    React.useState<any>();
  const [footerTotal, setFooterTotal] = useState<{
    qty: number;
    unitPrice: number;
    taxableAmount: number;
    discount: number;
    gst: number;
    amount: number;
  }>({
    qty: 0,
    unitPrice: 0,
    taxableAmount: 0,
    discount: 0,
    gst: 0,
    amount: 0,
  });
  const [footerLedger, setFooterLedger] = useState<{
    ledgerAmount: number;
    ledgerGstAmount: number;
    ledgerTotalAmount: number;
  }>({
    ledgerAmount: 0,
    ledgerGstAmount: 0,
    ledgerTotalAmount: 0,
  });
  const [itemDetails, setItemDetails] = useState<any[]>([]);
  const [ledgerDeatils, setLdgerDeatils] = useState<any[]>([]);
  const [trigger, setTrigger] = useState<boolean>(false);
  const [dataFiled, setDateFiled] = useState<any>();
  const [editDate, setEditDate] = useState<any>();
  const [cashDiscountRs, setCashDiscountRs] = useState<number>(0.0);
  const [updateTimeData, setUpdateTimeData] = React.useState<{
    contactDetails: boolean;
    shippingAddress: boolean;
    friInsuranceOtherCharge: boolean;
    // receipt: boolean;
    cashDiscount: boolean;
    tradeDiscount: boolean;
    isGSTVoucher: boolean;
    showItemDescription: boolean;
    roundOff: boolean;
  }>({
    contactDetails: false,
    shippingAddress: false,
    friInsuranceOtherCharge: false,
    // receipt: false,
    cashDiscount: false,
    tradeDiscount: false,
    isGSTVoucher: false,
    showItemDescription: false,
    roundOff: false,
  });
  const [roundOffAmount, setRoundoffAmount] = useState<number>(0.0);
  const [deleteUploadId, setDeleteUploadId] = React.useState<string[]>([]);
  const [previewImage, setPreviewImage] = React.useState<{
    isBaseConvert: boolean;
    path: any;
  }>({ isBaseConvert: false, path: "" });
  // const [editAmount, setEditAmount] = React.useState<{
  //   balance: number;
  //   id: string;
  // }>({
  //   balance: 0.0,
  //   id: "",
  // });

  let splitPathInVchType: Voucher_Type;
  if (location.pathname.split("/")[4] === "sales") {
    splitPathInVchType = Voucher_Type.CreditNote;
  } else {
    splitPathInVchType = Voucher_Type.DebitNote;
  }
  const gstOption = companySettings?.setting?.gstDetails?.gst;
  const isStateSame = currentCustomerState === currentClient?.state!;

  console.log(
    "currentClient?.registeredAddress?.state",
    currentClient?.registeredAddress, 
    location
  );

  const amountInNumber = (val: any, key: any) => {
    return Utils.getNumber(val?.amount?.[key]);
  };

  const price = (curr: any) => amountInNumber(curr, "price");
  const qty = (curr: any) => amountInNumber(curr, "qty");
  const discount = (curr: any) => amountInNumber(curr, "discount");
  const taxRate = (curr: any) => Utils.getNumber(curr?.amount?.tax?.value);
  const netAmount = (curr: any) => price(curr) * qty(curr);
  const discountRupee = (curr: any) =>
    percentCal(netAmount(curr), discount(curr));
  const amountWithoutTax = (curr: any) => netAmount(curr) - discountRupee(curr);
  const itemTaxAmt = (curr: any) => {
    // console.log("itemTaxAmt1`", taxRate(curr) / 100, amountWithoutTax(curr));
    return amountWithoutTax(curr) * (taxRate(curr) / 100);
  };
  // console.log("itemTaxAmt", itemTaxAmt);

  const amountWithTax = (curr: any) =>
    amountWithoutTax(curr) + itemTaxAmt(curr);
  // const amountWithOutTax = (curr: any) => amountWithoutTax(curr);

  const amount = (curr: any) => amountInNumber(curr, "amount");
  const gstrate = (curr: any) => Utils.getNumber(curr?.gstRate?.title);
  const gstamount = (curr: any) => percentCal(curr?.amount, gstrate(curr));
  const ledgertotalamout = (curr: any) =>
    Utils.getNumber(curr?.amount) + gstamount(curr);

  // Date Api
  useEffect(() => {
    const getDate = async () => {
      if (currentClient?.id && !creditNoteId) {
        await VoucherServices.getMaxVoucherDate(
          currentClient?.id!,
          splitPathInVchType
        ).then((res) => {
          const givenDate = new Date(res?.result);
          const currentTime = new Date();

          // Set the time part of givenDate to match currentTime
          givenDate?.setHours(
            currentTime.getHours(),
            currentTime.getMinutes(),
            currentTime.getSeconds(),
            currentTime.getMilliseconds()
          );
          form.setFieldValue("date", dayjs(givenDate));

          setDateFiled(res.result);
        });
      }
    };
    getDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient?.id]);

  // initial value for credit or debit note
  useEffect(() => {
    if (currentClient?.id && !creditNoteId) {
      setLoading(true);
      VoucherServices.getVoucherMaxNo(
        currentClient?.id!,
        splitPathInVchType,
        dataFiled
      )
        .then((res) => {
          form.setFieldValue("number", res?.result);
        })
        .catch((err: any) => {
          openNotification("error", err.message);
          console.error(err.message);
        });

      setLoading(false);
      if (location.state?.partyId) CustomerSelect(location.state?.partyId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient?.id, dataFiled, splitPathInVchType]);

  // ----- Serch customer Api ------
  useEffect(() => {
    setLoadingAllPage(true);
    const customerList = async () => {
      await LedgerService.getLedgersByGroupName(
        currentClient?.id!,
        SearchLedgerByGroupsTypes.PartiesOnly
      )
        .then((res: any) => {
          if (res) {
            setCustomerOptions(res);
            setLoadingAllPage(false);

            // console.log(customerOptions, "getledger");
          }
        })
        .catch((ex: any) => {
          console.error(ex);
        });
      setLoadingAllPage(false);
    };
    customerList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient?.id, trigger]);

  // Customer select
  const CustomerSelect = async (value: string, partyChange?: boolean) => {
    setLoadingAllPage(true);
    setEditAgiCallback(false);
    console.log("x1,,,,,,,,,,", creditNoteId, value, partyChange, editInvoice);
    setCurrentCustomerId(value);
    setSelectedParty(value);
    if (value) {
      // all time party onchange
      if (partyChange) {
        // form.setFieldValue("purchaseOrderRef", "");
        setInvoiceOption([]);
        setInvoiceApiCallback(false);
        form.setFieldsValue({
          items: [],
          freightInsLedgers: [],
        });
        setCurrentCreditNoteId("");

        setFooterLedger({
          ledgerAmount: 0,
          ledgerGstAmount: 0,
          ledgerTotalAmount: 0,
        });
        setFooterTotal({
          qty: 0,
          unitPrice: 0,
          taxableAmount: 0,
          discount: 0,
          gst: 0,
          amount: 0,
        });
        setLoadingAllPage(false);
      }
      // All Sales Invoice list Api set Invoice Ref. No (Filter Customer)
      await VoucherServices.getVoucherListByPartyId(
        currentClient?.id!,
        splitPathInVchType,
        value
      ).then((res) => {
        console.log("res", res, res.result.length);

        if (res.result.length > 0) {
          // debugger;
          //  Sales Invoice to direct credit note create
          if (
            (location.state as any)?.invoice &&
            currentCreditNoteId.length === 0
          ) {
            setCurrentCreditNoteId((location.state as any)?.invoice?.id);

            // form.setFieldValue("purchaseOrderRef", {
            //   label: (location.state as any)?.invoice?.name,
            //   value: (location.state as any)?.invoice?.id,
            // });
            setInvoiceOption(res?.result);
          } else if (editInvoice?.voucherId && editParty.id === value) {
            // console.log(
            //   "alll",
            //   res?.result,
            //   editInvoice,
            //   { ...editInvoice, ...res?.result },
            //   res?.result?.find(
            //     (x: any) => x.voucherId === editInvoice.voucherId
            //   )
            // );

            res?.result?.find((x: any) => x.voucherId === editInvoice.voucherId)
              ? setInvoiceOption(res?.result)
              : setInvoiceOption([editInvoice, ...res?.result]);
          } else {
            setInvoiceOption(res?.result);
          }
          setLoadingAllPage(false);
        } else if (creditNoteId && editParty.id === value) {
          console.log("elsecondition", editInvoice);
          setInvoiceOption([editInvoice]);
        }
        // Invoice Nahi ho uus party ki
        else if (!creditNoteId) {
          // console.log("else CustomerSelect");
          form.setFieldValue("purchaseOrderRef", "");
          form.setFieldsValue({
            items: [],
            freightInsLedgers: [],
          });
          setCurrentCreditNoteId("");

          setInvoiceOption((invoiceOption) => [
            ...invoiceOption,
            ...res?.result,
          ]);
          setFooterLedger({
            ledgerAmount: 0,
            ledgerGstAmount: 0,
            ledgerTotalAmount: 0,
          });
          setFooterTotal({
            qty: 0,
            unitPrice: 0,
            taxableAmount: 0,
            discount: 0,
            gst: 0,
            amount: 0,
          });
          setLoadingAllPage(false);
        }
        // length 0 ho or creditNoteId(update case)
      });

      setLoadingAllPage(false);

      let findCutomerDetails = customerOptions.find(
        (Ledgerlist: any) => Ledgerlist?.id === value
      );
      setCustomerAddress(findCutomerDetails?.addresses);
      // customerAddSelect(
      //   TypeOfAddress.RegisteredAddress,
      //   AddressEnum.BillingAddress,
      //   findCutomerDetails?.addresses
      // );
    }
    setLoadingAllPage(false);
  };

  // onChange in address type
  const customerAddSelect: any = (
    typeOfAddress: TypeOfAddress,
    addressEnum: AddressEnum,
    addressTotal: any[]
  ) => {
    // console.log("customerAddress", addressTotal, addressTotal.length > 0);

    let findAddress: any;
    if (addressTotal?.length > 0) {
      findAddress = addressTotal?.find(
        (type: any) => type?.type === typeOfAddress
      );
      form.setFieldValue(["shippingAddress", "address"], "");
      form.setFieldValue(["shippingAddress", "type"], "");
    } else if (customerAddress?.length > 0) {
      findAddress = customerAddress?.find(
        (type: any) => type?.type === typeOfAddress
      );
    }
    console.log("Hello findAddress", findAddress);

    if (findAddress?.address) {
      // console.log("findAddress.....", findAddress);
      // const objectofaddress1: any[] = [];
      let objectofaddress1: string = "";
      Object.keys(findAddress?.address).forEach((key: any) => {
        console.log("key...........", key);
        switch (key) {
          case "building":
            if (findAddress?.address[key])
              objectofaddress1 += findAddress?.address[key] + ", ";
            break;
          case "street":
            if (findAddress?.address[key])
              objectofaddress1 += findAddress?.address[key] + ", " + "\n";
            else if (findAddress?.address[key] === "building")
              objectofaddress1 += "\n";
            break;
          case "landmark":
            if (findAddress?.address[key])
              objectofaddress1 += findAddress?.address[key] + ", ";
            break;
          case "city":
            if (findAddress?.address[key])
              objectofaddress1 += findAddress?.address[key] + ", " + "\n";
            else if (findAddress?.address[key] === "landmark")
              objectofaddress1 += "\n";
            break;
          case "district":
            if (findAddress?.address[key])
              objectofaddress1 += findAddress?.address[key] + ", ";
            break;
          case "pincode":
            if (findAddress?.address[key])
              objectofaddress1 += findAddress?.address[key] + ", " + "\n";
            else if (findAddress?.address[key] === "district")
              objectofaddress1 += "\n";
            break;
          case "state":
            objectofaddress1 +=
              getIndianStates(findAddress?.address[key]) + ", ";
            if (addressEnum === AddressEnum.BillingAddress) {
              setCurrentCustomerState(findAddress?.address[key]);
              form.setFieldValue("supplyState", findAddress?.address[key]);
              form.validateFields(["supplyState"]);
            }
            break;
          case "country":
            if (findAddress?.address[key])
              objectofaddress1 += findAddress?.address[key];
            break;
          default:
            if (findAddress?.address[key])
              objectofaddress1 += findAddress?.address[key] + ", ";
        }
      });

      if (addressEnum === AddressEnum.BillingAddress) {
        form.setFieldValue(["billingAddress", "address"], objectofaddress1);
        form.setFieldValue(["billingAddress", "type"], typeOfAddress);
      } else {
        form.setFieldValue(["shippingAddress", "address"], objectofaddress1);
        form.setFieldValue(["shippingAddress", "type"], typeOfAddress);
      }
    } else {
      if (addressEnum === AddressEnum.BillingAddress) {
        form.setFieldValue(["billingAddress", "address"], "");
        form.setFieldValue(["billingAddress", "type"], typeOfAddress);
      } else {
        form.setFieldValue(["shippingAddress", "address"], "");
        form.setFieldValue(["shippingAddress", "type"], typeOfAddress);
      }
    }
  };

  //  get api call invoice set data in credit note fildes
  useEffect(() => {
    const getInvoiceData = async (currentCreditNoteId: string) => {
      // console.log(
      //   "currentCreditNoteId",
      //   currentCreditNoteId.length,
      //   currentCreditNoteId
      // );
      if (currentCreditNoteId.length > 0 || invoiceApiCallback) {
        setLoading(true);
        await VoucherServices.getVoucharById(
          currentClient?.id!,
          currentCreditNoteId,
          splitPathInVchType === Voucher_Type.CreditNote
            ? Voucher_Type.SalesVoucher
            : Voucher_Type.PurchaseVoucher
        )
          .then((res: any) => {
            if (res?.result) {
              // use item max length on Blur
              const Items = res.result?.items?.map((itm: any) => {
                return {
                  ...itm,
                  amount: {
                    ...itm?.amount,
                    tax: {
                      key: itm?.amount?.tax?.id,
                      label: itm?.amount?.tax?.name,
                      value: itm?.amount?.tax?.rate,
                    },
                  },
                };
              });
              if (!invoiceApiCallback) {
                setInputFieldDisable(true);
                // console.log(
                //   "result............",
                //   res.result?.partyLedger[0]?.ref?.length
                // );
                setEditAgiCallback(false);
                setCashDiscountRs(
                  res.result?.amountSummary?.cashDiscount?.discountAmount ?? 0
                );

                setFooterTotal({
                  qty: res.result?.amountSummary?.totalQty,
                  unitPrice: res.result?.amountSummary?.totalPrice,
                  taxableAmount:
                    res.result?.amountSummary?.taxableAmount +
                    res.result?.amountSummary?.totalDis,
                  discount: res.result?.amountSummary?.totalDis,
                  gst: res.result?.amountSummary?.totalItemGSTAmt,
                  amount:
                    Math.abs(res.result?.effectiveLedger[0]?.amount) +
                    res.result?.amountSummary?.totalItemGSTAmt,
                });
                setFooterLedger({
                  ledgerAmount: res.result?.amountSummary?.cifExpenses,
                  ledgerGstAmount: res.result?.amountSummary?.totalCIFGSTAmt,
                  ledgerTotalAmount:
                    res.result?.amountSummary?.cifExpenses +
                    res.result?.amountSummary?.totalCIFGSTAmt,
                });
                // setBalance(res.result.amountSummary?.receivedAmount);
                // setInvoiceTotal(
                //   Math.abs(res.result?.effectiveLedger[0]?.amount) +
                //     res.result?.amountSummary?.totalItemGSTAmt +
                //     res.result?.amountSummary?.cifExpenses +
                //     res.result?.amountSummary?.totalCIFGSTAmt
                // );
                // setIsInCash(res.result.paymentType === 1 ? true : false);

                setItemLength(res.result?.items?.length);
                setCurrentCustomerState(res.result?.supplyState);
                // setGstEnabled(res?.result?.isGstEnabled);

                setTransactionId({
                  partyId: res.result?.partyLedger[0]?.transactionId,
                  effectiveId: res.result?.effectiveLedger[0]?.transactionId,
                });

                const filterFreightInsLedger =
                  res.result?.freightInsLedgers?.filter(
                    (x: any) => x.partyOrTax !== 7 && x.partyOrTax !== 6
                  );

                setShowFreightInsurance(filterFreightInsLedger?.length > 0);

                const freightInsLedgers = filterFreightInsLedger?.map(
                  (exp: any) => {
                    return {
                      ...exp,
                      id: {
                        label: exp?.ledgerName,
                        value: exp?.id,
                      },
                      amount: Math.abs(exp?.amount),
                      transactionId: exp?.transactionId,
                      gstRate: {
                        value: exp?.gstRate?.id,
                        title: exp?.gstRate?.rate,
                        label: exp?.gstRate?.name,
                      },
                    };
                  }
                );

                setUpdateTimeData({
                  contactDetails:
                    res.result?.contactName || res.result?.contactNo
                      ? true
                      : false,
                  shippingAddress:
                    res.result?.shippingAddress?.address ||
                    res.result?.shippingAddress?.type
                      ? true
                      : false,
                  friInsuranceOtherCharge: filterFreightInsLedger?.length > 0,
                  // receipt: bankDtsGet?.length > 0,
                  cashDiscount:
                    res.result?.amountSummary?.cashDiscount?.discountAmount > 0,
                  tradeDiscount: res.result?.amountSummary?.totalDis > 0,
                  isGSTVoucher: res.result?.isGSTVoucher,
                  showItemDescription: res.result?.showItemDescription,
                  roundOff:
                    res.result?.amountSummary?.roundOff?.roundOffApplicable ||
                    res.result?.amountSummary?.roundOff?.roundOffAmount !== 0,
                });

                // console.log("freightInsLedgers", freightInsLedgers);

                setLedgerLength(freightInsLedgers?.length);
                setRoundoffAmount(
                  res.result?.amountSummary?.roundOff?.roundOffAmount ?? 0
                );
                form.setFieldsValue({
                  items: Items?.length > 0 && [...Items],
                  // number: res.result?.,
                  roundOff: res.result?.amountSummary?.roundOff?.roundOffAmount,
                  roundoffCheckbox:
                    res.result?.amountSummary?.roundOff?.roundOffApplicable,
                  cashDiscount:
                    res.result?.amountSummary?.cashDiscount?.discountRate > 0
                      ? res.result?.amountSummary?.cashDiscount?.discountRate
                      : Number(
                          res.result?.amountSummary?.cashDiscount
                            ?.discountAmount ?? 0
                        ), 
                  cashDiscountDrop:
                    Number(
                      res.result?.amountSummary?.cashDiscount?.discountRate
                    ) > 0
                      ? DiscountType.PercentageDiscount
                      : DiscountType.RupeeDiscount,
                  // date: dayjs(),
                  freightInsLedgers:
                    freightInsLedgers?.length > 0
                      ? [...freightInsLedgers]
                      : undefined,
                  showFreightInsLedgers:
                    freightInsLedgers?.length > 0 ? true : false,
                  effectiveLedger: {
                    value: res.result?.effectiveLedger[0]?.id,
                    label: res.result?.effectiveLedger[0]?.ledgerName,
                  },
                  phoneNo: res.result?.phoneNo,
                  partyLedger: {
                    value: res.result?.partyLedger[0]?.id,
                    label: res.result?.partyLedger[0]?.ledgerName,
                  },
                  purchaseOrderRef:{
                    value: res.result?.id,
                    label: res.result?.number,
                  },
                  // id: val?.partyLedger?.value,
                  // PartyOrTax: Party_Tax.party,
                  // amount:
                  //   Utils.getNumber(invoiceTotal) +
                  //   Utils.getNumber(footerLedger.ledgerTotalAmount),
                  // number: res.result.number,
                  billingAddress: res.result?.billingAddress,
                  shippingAddress: res.result?.shippingAddress,
                  supplyState: res.result.supplyState,
                  receivedAmount: res.result.amountSummary?.receivedAmount,
                  paymentType: res.result.paymentType === 1 ? true : false,
                  // date: dayjs(
                  //   dayjs(res.result.date).format("DD/MM/YYYY"),
                  //   "DD/MM/YYYY"
                  // ),
                  // dueDate: dayjs(
                  //   dayjs(res.result.dueDate).format("DD/MM/YYYY"),
                  //   "DD/MM/YYYY"
                  // ),
                });
                setLdgerDeatils(freightInsLedgers);
              }
              setItemDetails(Items);
              // console.log("data", res.result?.partyLedger[0]?.ref[0]);
              setInvoiceTotalDueAmount(res.result?.amountSummary?.dueAmount);
              setOnlyInvoiceRef(res.result?.partyLedger[0]?.ref[0]);
              // setNewPartyRecepitDetails(
              //   res.result?.partyLedger[0]?.ref[0] !== null
              //     ? res.result?.partyLedger[0]?.ref
              //     : []
              // );

              setLoading(false);
            } else setLoading(false);
          })
          .catch((err: any) => {
            setLoading(false);
            console.error("Error..", err.message);
            openNotification("error", err.message);
          });
        setLoading(false);
      }
    };

    getInvoiceData(currentCreditNoteId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCreditNoteId, invoiceApiCallback]);

  // -------- Edit Sales credit Get API ---------
  useEffect(() => {
    if ((creditNoteId || draftId) && editAgiCallback) {
      const editData = async (id: any) => {
        try {
          setLoading(true);

          const res = draftId
            ? await DraftService.getByDraftId(currentClient?.id!, draftId)
            : await VoucherServices.getVoucharById(
                currentClient?.id!,
                id,
                splitPathInVchType === Voucher_Type.CreditNote
                  ? Voucher_Type.CreditNote
                  : Voucher_Type.DebitNote
              );

          if (res?.result) {
            console.log(
              "res.result?.purchaseOrderRef?.id",
              res.result,
              res.result?.amountSummary?.totalInvoiceAmount
            );
            setTermsAndConditionField(res?.result?.termsNConditions);
            console.log("ressssssss", res?.result);
            setEditInvoice({
              voucherId: res.result?.purchaseOrderRef?.id,
              voucherNumber: res.result?.purchaseOrderRef?.name,
            });
            setEditParty({
              id: res.result?.partyLedger[0]?.id,
              balance: res.result?.amountSummary?.totalInvoiceAmount,
            });
            setSelectedParty(res.result?.partyLedger[0]?.id);
            setCurrentCreditNoteId(res.result?.purchaseOrderRef?.id);
            setInputFieldDisable(false);

            // setInvoiceOption((invoiceOption) => [
            //   ...invoiceOption,
            //   {
            //     voucherId: res.result?.purchaseOrderRef?.id,
            //     voucherNumber: res.result?.purchaseOrderRef?.name,
            //   },
            // ]);

            setOldPartyInvoiceRef(res.result?.partyLedger[0]?.ref[0]);
            // setOldPartyRecepitDetails(
            //   res.result?.partyLedger[0]?.ref.map((v: any, index: number) => {
            //     return {
            //       key: index,
            //       date: dayjs(v?.date),
            //       value: v?.value,
            //       id: {
            //         title: v?.id,
            //         label: v?.name,
            //         value: v?.parentRef,
            //       },
            //       dueAmount: v?.dueAmount,
            //     };
            //   })
            // );
            setRoundoffAmount(
              res.result?.amountSummary?.roundOff?.roundOffAmount ??
                settingControl.autoRoundOff
            );
            setCashDiscountRs(
              res.result?.amountSummary?.cashDiscount?.discountAmount ?? 0
            );
            setFooterTotal({
              qty: res.result?.amountSummary?.totalQty,
              unitPrice: res.result?.amountSummary?.totalPrice,
              taxableAmount:
                res.result?.amountSummary?.taxableAmount +
                res.result?.amountSummary?.totalDis,
              discount: res.result?.amountSummary?.totalDis,
              gst: res.result?.amountSummary?.totalItemGSTAmt,
              amount:
                Math.abs(res.result?.effectiveLedger[0]?.amount) +
                res.result?.amountSummary?.totalItemGSTAmt,
            });

            setFooterLedger({
              ledgerAmount: res.result?.amountSummary?.cifExpenses,
              ledgerGstAmount: res.result?.amountSummary?.totalCIFGSTAmt,
              ledgerTotalAmount:
                res.result?.amountSummary?.cifExpenses +
                res.result?.amountSummary?.totalCIFGSTAmt,
            });

            // setBalance(res.result.amountSummary?.receivedAmount);
            // setInvoiceTotal(
            //   Math.abs(res.result?.effectiveLedger[0]?.amount) +
            //     res.result?.amountSummary?.totalItemGSTAmt +
            //     res.result?.amountSummary?.cifExpenses +
            //     res.result?.amountSummary?.totalCIFGSTAmt
            // );
            // setIsInCash(res.result.paymentType === 1 ? true : false);
            setItemLength(res.result?.items?.length);
            setCurrentCustomerState(res.result?.supplyState);
            // setGstEnabled(res?.result?.isGstEnabled);
            setTransactionId({
              partyId: res.result?.partyLedger[0]?.transactionId,
              effectiveId: res.result?.effectiveLedger[0]?.transactionId,
            });

            const Items = res.result?.items?.map((itm: any) => {
              return {
                ...itm,
                amount: {
                  ...itm?.amount,
                  tax: {
                    key: itm?.amount?.tax?.id,
                    label: itm?.amount?.tax?.name,
                    value: itm?.amount?.tax?.rate,
                  },
                },
              };
            });

            const filterFreightInsLedger =
              res.result?.freightInsLedgers?.filter(
                (x: any) => x.partyOrTax !== 7 && x.partyOrTax !== 6
              );
            setShowFreightInsurance(filterFreightInsLedger?.length > 0);

            const freightInsLedgers = filterFreightInsLedger?.map(
              (exp: any) => {
                return {
                  ...exp,
                  id: {
                    label: exp?.ledgerName,
                    value: exp?.id,
                  },
                  amount: Math.abs(exp?.amount),
                  transactionId: exp?.transactionId,
                  gstRate: {
                    value: exp?.gstRate?.id,
                    title: exp?.gstRate?.rate,
                    label: exp?.gstRate?.name,
                  },
                };
              }
            );

            // console.log("freightInsLedgers", freightInsLedgers);

            setLedgerLength(freightInsLedgers?.length);
            setEditDate(res.result?.date);
            setRoundoffAmount(
              res.result?.amountSummary?.roundOff?.roundOffAmount ?? 0
            );

            form.setFieldsValue({
              items: [
                ...Items,
                { amount: { price: 0.0, qty: 0, grossAmount: 0 } },
              ],
              roundOff: res.result?.amountSummary?.roundOff?.roundOffAmount,
              roundoffCheckbox:
                res.result?.amountSummary?.roundOff?.roundOffApplicable,
              cashDiscount:
                res.result?.amountSummary?.cashDiscount?.discountRate > 0
                  ? res.result?.amountSummary?.cashDiscount?.discountRate
                  : Number(
                      res.result?.amountSummary?.cashDiscount?.discountAmount ?? 0
                    ),
              cashDiscountDrop:
                Number(res.result?.amountSummary?.cashDiscount?.discountRate) >
                0
                  ? DiscountType.PercentageDiscount
                  : DiscountType.RupeeDiscount,
              // roundoffCheckbox: res.result?.amountSummary?.roundOff?.roundOffAmount !== 0,
              showFreightInsLedgers:
                freightInsLedgers?.length > 0 ? true : false,
              freightInsLedgers:
                freightInsLedgers?.length > 0
                  ? [
                      ...freightInsLedgers,
                      [
                        {
                          id: null,
                          gstRate: 0,
                          gstAmount: 0,
                          totalAmount: 0,
                          amount: 0,
                        },
                      ],
                    ]
                  : [
                      {
                        id: null,
                        gstRate: 0,
                        gstAmount: 0,
                        totalAmount: 0,
                        amount: 0,
                      },
                    ],
              purchaseOrderRef: {
                value: res.result?.purchaseOrderRef?.id,
                label: res.result?.purchaseOrderRef?.name,
              },
              effectiveLedger: {
                value: res.result?.effectiveLedger[0]?.id,
                label: res.result?.effectiveLedger[0]?.ledgerName,
              },
              phoneNo: res.result?.phoneNo,
              partyLedger: {
                value: res.result?.partyLedger[0]?.id,
                label: res.result?.partyLedger[0]?.ledgerName,
              },
              // id: val?.partyLedger?.value,
              // PartyOrTax: Party_Tax.party,
              // amount:
              //   Utils.getNumber(invoiceTotal) +
              //   Utils.getNumber(footerLedger.ledgerTotalAmount),
              number: res.result?.number,
              billingAddress: res.result?.billingAddress,
              narration: res?.result?.narration,

              shippingAddress: res.result?.shippingAddress,
              supplyState: res.result?.supplyState,
              receivedAmount: res.result?.amountSummary?.receivedAmount,
              paymentType: res.result?.paymentType === 1 ? true : false,
              date: dayjs(res.result?.date),
            });
            // Call api in invoice details
            setInvoiceApiCallback(true);
            setLoading(false);
          } else {
            openNotification("error", res.message);
            setLoading(false);
          }
          // .catch((err: any) => {
          //   setLoading(false);
          //   console.error("Error..", err.message);
          //   openNotification("error", err.message);
          // });
          setLoading(false);
        } catch (err) {
          setLoading(false);
          console.error("Error..", err);
          openNotification("error", err);
        }
      };

      editData(creditNoteId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creditNoteId, editAgiCallback]);

  React.useEffect(() => {
    if (editInvoice.voucherId && editParty) {
      CustomerSelect(editParty.id, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editParty, editInvoice]);

  // select in Items
  const ItemSelect: any = async (value: string, index: number) => {
    setItemLength(index + 1);
    const selectedItem = itemsOption?.find((Item: any) => Item?.id === value);

    const IsService = selectedItem.isService;
    if (selectedItem) {
      form.setFieldValue(["items", index], {
        name: selectedItem.name,
        isService: IsService,
        isTaxable: selectedItem.isTaxable,
        id: selectedItem.id,
        description: selectedItem.description,
        // account: {
        //   label: selectedItem.account?.name,
        //   value: selectedItem.account?.id,
        // },
        amount: {
          qty: selectedItem.quantity,
          price: selectedItem.isTaxable
            ? Utils.getFormattedNumber(
                (selectedItem.price / (100 + selectedItem.taxRate.rate)) * 100
              )
            : selectedItem.price,
          tax: {
            value: selectedItem.taxRate.rate,
            label: selectedItem.taxRate.name,
            key: selectedItem.taxRate.id,
          },
        },
      });
      handleItemsNetAmmount(index);
    }

    const items = form.getFieldValue("items");
    const currentItems = items.filter((itm: any) => itm?.id !== undefined);

    if (value) {
      const data = itemsOption?.map((o: any) => {
        if (currentItems.some((item: any) => item?.id === o.id)) {
          return {
            ...o,
            disabled: true,
          };
        } else {
          return {
            ...o,
            disabled: false,
          };
        }
      });
      // console.log("data", data);
      setItemsOption(data);
    }
  };

  // Include Other Expenses (Indirect Income expenses) select
  const freightInsLedgersSelect: any = async (value: any, index: number) => {
    // console.log("value11.............", value, index);
    const currentSelectLedger = freightAndInsuranceOptions.find(
      (x: any) => x.id === value
    );

    // console.log("currentSelectLedger.............", currentSelectLedger);
    if (currentSelectLedger) {
      const currentData = form.getFieldValue(["freightInsLedgers", index]);

      form.setFieldValue(["freightInsLedgers", index], {
        id: currentSelectLedger?.id,
        amount: currentData?.amount,
        gstRate: {
          value: currentSelectLedger?.gstRate?.id,
          label: currentSelectLedger?.gstRate?.name,
          title: currentSelectLedger?.gstRate?.rate,
        },
        gstAmount: Utils.getFormattedNumber(gstamount(currentData)),
        totalAmount: Utils.getFormattedNumber(ledgertotalamout(currentData)),
      });
    }
    setLedgerLength(index + 1);
    handleFreightAndInsLedgerTotal();
    // console.log("currentSelectLedger", currentSelectLedger);
  };

  //-------- All Item List-----------
  useEffect(() => {
    const getItems = async () => {
      if (editInvoice.voucherId)
        await SalesService.searchItem(
          currentClient?.id!,
          searchItem,
          splitPathInVchType === Voucher_Type.CreditNote
            ? CAPItemTypes.Sales
            : CAPItemTypes.Purchase
        ).then((res) => {
          setItemsOption(res);
        });
    };
    getItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient?.id, searchItem, editInvoice]);

  //------ Get a Include Other Expenses(Direct Income) Ledger
  useEffect(() => {
    const getFreightAndInsurance = async () => {
      if (editInvoice.voucherId) {
        await LedgerService.getLedgersByGroupName(
          currentClient?.id!,
          splitPathInVchType === Voucher_Type.CreditNote
            ? SearchLedgerByGroupsTypes.IndirectIncomeOnly
            : SearchLedgerByGroupsTypes.IndirectExpenses
        )
          .then((res: any) => {
            // console.log(res, "indirExpLedgers");
            if (res) {
              setFreightAndInsuranceOptions(res);
            }
          })
          .catch((ex: any) => {
            console.error(ex);
          });
      }
    };
    getFreightAndInsurance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient?.id, editInvoice?.voucherId, splitPathInVchType]);

  //function for all the summary of the calculation
  const handleAllTheTotalValues = () => {
    const ItemList = form.getFieldValue(["items"]);

    // console.log("ItemList", ItemList);
    //------- Total Row Calculations -------
    const Total = ItemList.reduce(
      (
        acc: {
          TotalQty: number;
          TotalUnitPrice: number;
          ItemSubTotal: number;
          TotalDiscount: number;
          Totalgst: number;
          TotalAmount: number;
        },
        curr: any
      ) => {
        acc.TotalQty += qty(curr);
        acc.TotalUnitPrice += price(curr);
        acc.ItemSubTotal += price(curr) * qty(curr);
        acc.TotalDiscount += amountInNumber(curr, "discountRupee");
        acc.Totalgst += amountInNumber(curr, "itemTaxAmt");
        acc.TotalAmount += amountInNumber(curr, "totalAmount");
        return acc;
      },
      {
        TotalQty: 0,
        TotalUnitPrice: 0,
        ItemSubTotal: 0,
        TotalDiscount: 0,
        Totalgst: 0,
        TotalAmount: 0,
      }
    );

    // console.log("Total", Total);

    setFooterTotal({
      qty: Total.TotalQty,
      unitPrice: Total.TotalUnitPrice,
      taxableAmount: Total.ItemSubTotal,
      discount: Total.TotalDiscount,
      gst: Total.Totalgst,
      amount: Total.TotalAmount,
    });

    // ----- Calculate item's total amount with discount -----
    const AmountAfterDisc = ItemList.reduce((acc: number, curr: any) => {
      return (acc += amountWithoutTax(curr));
    }, 0);

    //----- Calculate TDS amount -----
    const TdsRate = form.getFieldValue("tdsRate");
    const TotalTds = percentCal(AmountAfterDisc, TdsRate);
    setTotalTds(TotalTds);

    //----- Calculate TCS amount -----
    const TcsRate = form.getFieldValue("tcsRate");
    const TotalTcs = percentCal(footerTotal.amount, TcsRate);

    setTotalTcs(TotalTcs);

    //----- Calculate invoice total (WITH GST AND DISCOUNT AND TDS) -----
    const InvoiceTotal = ItemList.reduce((acc: number, curr: any) => {
      return (acc +=
        amountInNumber(curr, "totalAmount") +
        (tdsOrTcs === 0
          ? percentCal(amountWithoutTax(curr), TdsRate)
          : tdsOrTcs === 1
          ? percentCal(footerTotal.amount, TcsRate)
          : 0));
    }, 0);
    // setInvoiceTotal(InvoiceTotal);

    // cash discount
    const discount = form.getFieldValue("cashDiscount");
    const discountType = form.getFieldValue("cashDiscountDrop");

    const totalSum = Total.TotalAmount + footerLedger.ledgerTotalAmount;
    let cashDiscountAmount: number = 0.0;
    // console.log("discount", totalSum, discount, discountType, typeof discount);
    if (discount > 0) {
      if (discountType === DiscountType.PercentageDiscount) {
        cashDiscountAmount = percentCal(totalSum, discount);
        setCashDiscountRs(cashDiscountAmount);
      } else {
        cashDiscountAmount = discount;
        setCashDiscountRs(cashDiscountAmount);
      }
    } else {
      setCashDiscountRs(0.0);
    }

    // roundOff
    const totalAmount =
      Total.TotalAmount + footerLedger.ledgerTotalAmount - cashDiscountAmount;

    const roundOffAmount = handleRoundoff(
      totalAmount,
      form.getFieldValue("roundoffCheckbox"),
      settingControl.roundOffType
    );

    form.setFieldValue("roundOff", roundOffAmount);
    setRoundoffAmount(roundOffAmount);
  };

  // on change in items files amount chnage
  const handleItemsNetAmmount = (index: number) => {
    // console.log(index);
    const ItemList = form.getFieldValue(["items"]);
    const currentIndexData = ItemList[index];
    // console.log("items........", itemTaxAmt(currentIndexData));
    if (currentIndexData) {
      form.setFieldValue(["items", index], {
        ...currentIndexData,
        amount: {
          ...currentIndexData.amount,
          discountRupee: Utils.getFormattedNumber(
            discountRupee(currentIndexData)
          ),
          itemTaxAmt: Utils.getFormattedNumber(itemTaxAmt(currentIndexData)),
          totalAmount: Utils.getFormattedNumber(
            amountWithTax(currentIndexData)
          ),
        },
      });
    }

    // console.log("checkinvoicedat", ItemList);
    handleAllTheTotalValues();
    // addTotalTaxAmountChange(currentIndexData);
  };

  const onDiscountAmountChange = (index: number) => {
    const ItemList = form.getFieldValue(["items"]);
    const currentIndexData = ItemList[index];

    if (currentIndexData) {
      // Calculate the new values based on the discount change
      const newDiscountAmount = percentToRs(
        amountInNumber(currentIndexData, "discountRupee"),
        netAmount(currentIndexData)
      );

      const newTaxRs = percentCal(
        netAmount(currentIndexData) -
          amountInNumber(currentIndexData, "discountRupee"),
        taxRate(currentIndexData)
      );

      const newTotalAmount =
        netAmount(currentIndexData) -
        amountInNumber(currentIndexData, "discountRupee") +
        newTaxRs;

      // Update the form field with the new calculated values
      form.setFieldValue(["items", index], {
        ...currentIndexData,
        amount: {
          ...currentIndexData.amount,
          discount: Utils.getFormattedNumber(newDiscountAmount),
          itemTaxAmt: Utils.getFormattedNumber(newTaxRs),
          totalAmount: Utils.getFormattedNumber(newTotalAmount),
        },
      });

      // Call the function to handle the total values if needed
      handleAllTheTotalValues();
    }
  };

  const onItemsTotalAmountChange = async (index: number) => {
    // console.log("index...........", index);

    const ItemList = form.getFieldValue(["items"]);
    const currentIndexData = ItemList[index];

    // Calculate the denominator for various calculations
    const denominator =
      100 -
      discount(currentIndexData) +
      percentCal(100 - discount(currentIndexData), taxRate(currentIndexData));

    // Calculate the price based on the given formula
    const calculatedPrice =
      (amountInNumber(currentIndexData, "totalAmount") * 100) /
      (denominator * qty(currentIndexData));

    // Calculate the discountRupee based on the given formula
    const calculatedDiscountRupee =
      (amountInNumber(currentIndexData, "totalAmount") *
        discount(currentIndexData)) /
      denominator;

    // Calculate the itemTaxAmt based on the given formula
    const calculatedTaxRs =
      (amountInNumber(currentIndexData, "totalAmount") *
        percentCal(
          100 - discount(currentIndexData),
          taxRate(currentIndexData)
        )) /
      denominator;

    if (denominator > 0 && currentIndexData?.amount?.qty > 0) {
      // Update the form field values with the calculated values
      form.setFieldValue(["items", index], {
        ...currentIndexData,
        amount: {
          ...currentIndexData?.amount,
          price: Utils.getFormattedNumber(calculatedPrice),
          discountRupee: Utils.getFormattedNumber(calculatedDiscountRupee),
          itemTaxAmt: Utils.getFormattedNumber(calculatedTaxRs),
        },
      });
    } else {
      // Denominator is zero or negative, set all fields to 0
      form.setFieldValue(["items", index], {
        ...currentIndexData,
        amount: {
          ...currentIndexData?.amount,
          price: 0,
          discountRupee: 0,
          itemTaxAmt: 0,
          totalAmount: 0,
        },
      });
    }
    handleAllTheTotalValues();
  };

  // Freight And InsLedger total in fotter
  const handleFreightAndInsLedgerTotal = () => {
    const LedgerAllList = form.getFieldValue(["freightInsLedgers"]);

    //----- Total Row Calculations---------
    const AmountLedger = LedgerAllList.reduce((acc: number, curr: any) => {
      return (acc += Utils.getNumber(curr?.amount));
    }, 0);
    // console.log("AmountAfterDisc...........", AmountLedger);

    const Total = LedgerAllList.reduce(
      (
        acc: {
          amount: number;
          gstAmount: number;
          totalAmount: number;
        },
        curr: any
      ) => {
        acc.amount += Utils.getNumber(curr?.amount);
        acc.gstAmount += Utils.getNumber(curr?.gstAmount);
        acc.totalAmount += Utils.getNumber(curr?.totalAmount);
        return acc;
      },
      {
        amount: 0,
        gstAmount: 0,
        totalAmount: 0,
      }
    );
    // console.log("totalvalueoftheledger", Total);

    setFooterLedger({
      ledgerAmount: Total.amount,
      ledgerGstAmount: Total.gstAmount,
      ledgerTotalAmount: Total.totalAmount,
    });

    // ----- Calculate items total unit Price -----
    const TotalLedgerAmount = LedgerAllList.reduce((acc: number, curr: any) => {
      return (acc += amountInNumber(curr, "totalAmount"));
    }, 0);
    // setLedgerAmount(TotalLedgerAmount);

    // cash discount
    const discount = form.getFieldValue("cashDiscount");
    const discountType = form.getFieldValue("cashDiscountDrop");

    const totalSum = footerTotal.amount + Total.totalAmount;
    let cashDiscountAmount: number = 0.0;
    // console.log("discount", totalSum, discount, discountType, typeof discount);
    if (discount > 0) {
      if (discountType === DiscountType.PercentageDiscount) {
        cashDiscountAmount = percentCal(totalSum, discount);
        setCashDiscountRs(cashDiscountAmount);
      } else {
        cashDiscountAmount = discount;
        setCashDiscountRs(cashDiscountAmount);
      }
    } else {
      setCashDiscountRs(0.0);
    }

    // roundOff
    const totalAmount =
      footerTotal.amount + Total.totalAmount - cashDiscountAmount;

    const roundOffAmount = handleRoundoff(
      totalAmount,
      form.getFieldValue("roundoffCheckbox"),
      settingControl.roundOffType
    );

    form.setFieldValue("roundOff", roundOffAmount);
    setRoundoffAmount(roundOffAmount);

    // console.log("ledgeralllist", LedgerAllList);
  };

  // on chnage in Freight And InsLedger unit price & gst
  const handleLedgerNetAmount = (index: number) => {
    const Ledgerlist = form.getFieldValue(["freightInsLedgers"]);
    const currentLedgerIndexData = Ledgerlist[index];
    // console.log("currentLedgerIndexData", currentLedgerIndexData);

    if (currentLedgerIndexData) {
      form.setFieldValue(["freightInsLedgers", index], {
        ...currentLedgerIndexData,
        gstAmount: Utils.getFormattedNumber(gstamount(currentLedgerIndexData)),
        // totalAmount: Utils.getFormattedNumber(currentLedgerIndexData?.amount),
        totalAmount: Utils.getFormattedNumber(
          ledgertotalamout(currentLedgerIndexData)
        ),
      });
    }
    handleFreightAndInsLedgerTotal();
  };

  const onLedgerTotalAmountChange = async (index: number) => {
    const LedgerList = form.getFieldValue(["freightInsLedgers"]);
    const currentIndexData = LedgerList[index];
    // console.log(currentIndexData);

    //Calculate the denominator for various calculations
    const denominators = gstrate(currentIndexData);
    // console.log("denominators", denominators);

    const gstAmount =
      (Utils.getNumber(currentIndexData.totalAmount) * denominators) / 100;
    // console.log("gstAmount", gstAmount);

    const ledgernetAmount =
      Utils.getNumber(currentIndexData.totalAmount) - gstAmount;
    // console.log("ledgernetAmount...", ledgernetAmount);

    if (ledgernetAmount > 0) {
      form.setFieldValue(["freightInsLedgers", index], {
        ...currentIndexData,
        amount: Utils.getFormattedNumber(ledgernetAmount),
        gstAmount: Utils.getFormattedNumber(gstAmount),
        // totalAmount: Utils.getFormattedNumber(
        //   ledgertotalamout(currentIndexData)
        // ),
      });
    } else {
      // ledgernetAmount is zero or negative, set all fields to 0
      form.setFieldValue(["freightInsLedgers", index], {
        ...currentIndexData,
        amount: Utils.getFormattedNumber(ledgernetAmount),
        gstAmount: Utils.getFormattedNumber(gstAmount),
      });
    }
    handleFreightAndInsLedgerTotal();
  };

  const handleDeleteRowButton = () => {
    handleItemsNetAmmount(0);
    setConfirmation(false);
  };

  const handleDeleteRowFreightAndInsurance = () => {
    setLedgerConfirmation(false);
    handleLedgerNetAmount(0);
  };

  const hangleCashDisCount = () => {
    const discount = form.getFieldValue("cashDiscount");
    const discountType = form.getFieldValue("cashDiscountDrop");
    const totalSum = footerTotal.amount + footerLedger.ledgerTotalAmount;
    // console.log("discount", totalSum, discount, discountType, typeof discount);
    if (discount > 0) {
      if (discountType === DiscountType.PercentageDiscount) {
        const percentDis = percentCal(totalSum, discount);
        setCashDiscountRs(percentDis);
        const roundOffAmount = handleRoundoff(
          totalSum - percentDis,
          form.getFieldValue("roundoffCheckbox"),
          settingControl.roundOffType
        );

        form.setFieldValue("roundOff", roundOffAmount);
        setRoundoffAmount(roundOffAmount);
        // console.log("")
        // console.log("footerTotal.taxableAmount", percentCal(totalSum, discount));
      } else {
        setCashDiscountRs(discount);
        const roundOffAmount = handleRoundoff(
          totalSum - discount,
          form.getFieldValue("roundoffCheckbox"),
          settingControl.roundOffType
        );

        form.setFieldValue("roundOff", roundOffAmount);
        setRoundoffAmount(roundOffAmount);
        // console.log("RupeeDiscount", totalSum - discount);
      }
    } else {
      setCashDiscountRs(0.0);

      const roundOffAmount = handleRoundoff(
        totalSum,
        form.getFieldValue("roundoffCheckbox"),
        settingControl.roundOffType
      );

      form.setFieldValue("roundOff", roundOffAmount);
      setRoundoffAmount(roundOffAmount);
    }
  };

  // -------- Add Return note Post API ---------
  const addReturnNote = async (isSaveAndNew?: boolean, isDraft?: boolean) => {
    // console.log(
    //   "form.validateFields()",
    //   form.validateFields().then(async(val: any) => {
    //   console.log("post Val....", val);
    //     return await val;
    //   })
    // );

    try {
      const val = isDraft ? form.getFieldsValue() : await form.validateFields();
      // console.log("post Val....", val);
      setSaveButtonLoading(true);

      // isSaveAndNew ? setSaveNewLoading(true) : setSaveButtonLoading(true);

      const freightInsLedgers = val?.freightInsLedgers?.map((exp: any) => {
        return {
          ...exp,
          id: exp?.id?.value,
          gstRate: {
            id: exp?.gstRate?.value,
            rate: exp?.gstRate?.title,
            name: exp?.gstRate?.label,
          },
          // value: gst?.id,
          // title: gst?.rate,
          // label: gst?.name,
          // gstAmount: exp?.gstAmount,
          // amount: exp?.amount,
          // totalAmount: exp?.totalAmount,
        };
      });

      const partyLedger = new Array({
        id: val?.partyLedger?.value,
        PartyOrTax: Party_Tax.party,
        amount:
          Utils.getNumber(footerTotal.amount) +
          Utils.getNumber(footerLedger.ledgerTotalAmount) -
          Math.round(cashDiscountRs * 100) / 100 +
          roundOffAmount,
        transactionId:
          transactionId.partyId === "" ? null : transactionId.partyId,
        ref: new Array({
          id: oldPartyInvoiceRef?.id,
          parentRef: onlyInvoiceRef?.id,
          debitNature: onlyInvoiceRef?.debitNature,
          refType: RefType.AgnstRef,
          name: onlyInvoiceRef?.name,
          dueAmount: onlyInvoiceRef?.dueAmount,
          value: Utils.getFormattedNumber(
            Utils.getNumber(footerTotal.amount) +
              Utils.getNumber(footerLedger.ledgerTotalAmount) -
              Math.round(cashDiscountRs * 100) / 100 +
              roundOffAmount
          ),
        }),
      });

      const effectiveLedger = new Array({
        id: val?.effectiveLedger?.value,
        PartyOrTax: Party_Tax.other,
        amount: Utils.getFormattedNumber(
          Utils.getNumber(footerTotal.taxableAmount) - footerTotal.discount
        ),
        transactionId:
          transactionId.effectiveId === "" ? null : transactionId.effectiveId,
      });

      const Items =
        val?.items.length > 0 &&
        val?.items?.map((itm: any) => {
          return {
            ...itm,
            amount: {
              ...itm?.amount,
              grossAmount:
                Utils.getNumber(itm?.amount?.price) * itm?.amount?.qty,
              tax: {
                id: itm?.amount?.tax?.key,
                rate: itm?.amount?.tax?.value,
                name: itm?.amount?.tax?.label,
              },
            },
          };
        });

      let givenDate;
      if (creditNoteId && dayjs(val.date).isSame(dayjs(editDate), "day")) {
        givenDate = editDate;
      } else {
        givenDate = new Date(val.date);
        const currentTime = new Date();

        // Set the time part of givenDate to match currentTime
        givenDate?.setHours(
          currentTime.getHours(),
          currentTime.getMinutes(),
          currentTime.getSeconds(),
          currentTime.getMilliseconds()
        );
      }

      const invoice = {
        ...val,
        isGSTVoucher:
          companySettings?.setting?.gstDetails?.scheme ===
            CompanyGSTTypes.Regular || updateTimeData.isGSTVoucher,
        showItemDescription:
          settingControl?.showItemDescription ||
          updateTimeData?.showItemDescription,
        draftId: draftId,
        termsNConditions: termsAndConditionField,
        items: Items?.length > 0 ? Items : [],
        purchaseOrderRef: { id: val?.purchaseOrderRef?.value },
        date: dayjs(givenDate).toISOString(),
        // dueDate: dayjs(val?.dueDate).toString(),
        freightInsLedgers,
        partyLedger,
        effectiveLedger,
        // despatchDetail: invoiceDespatchDetails,
        // exportDetail: invoiceExportDetails,
        // statutoryLedgers,
        // shippingAddress: val?.shippingAddress.type
        //   ? val.shippingAddress
        //   : { address: val?.shippingAddress?.addess },
        isStateSame: isStateSame,
        amountSummary: {
          // roundOffAmount: roundOffAmount,
          roundOff: {
            roundOffApplicable: val?.roundoffCheckbox,
            roundOffAmount: roundOffAmount,
          },
          cashDiscount: {
            discountRate:
              val.cashDiscountDrop === DiscountType.PercentageDiscount
                ? val.cashDiscount ?? 0
                : 0,
            discountAmount: cashDiscountRs,
          },
          totalQty: Utils.getFormattedNumber(footerTotal?.qty),
          taxableAmount: Utils.getFormattedNumber(
            Utils.getNumber(footerTotal?.taxableAmount) - footerTotal?.discount
          ),
          // befor gst
          totalDis: Utils.getFormattedNumber(footerTotal?.discount),
          freightAmount: 0,
          cessAmount: 0,
          igstAmt: !isStateSame
            ? Utils.getFormattedNumber(
                footerTotal?.gst + footerLedger.ledgerGstAmount
              )
            : 0,
          cgstAmt: isStateSame
            ? Utils.getFormattedNumber(
                (footerTotal?.gst + footerLedger.ledgerGstAmount) / 2
              )
            : 0,
          sgstAmt: isStateSame
            ? Utils.getFormattedNumber(
                (footerTotal?.gst + footerLedger.ledgerGstAmount) / 2
              )
            : 0,
          tcsRate: 0,
          tcsAmt: 0,
          totalInvoiceAmount:
            Utils.getNumber(footerTotal.amount) +
            Utils.getNumber(footerLedger.ledgerTotalAmount) +
            roundOffAmount -
            cashDiscountRs,
          receivedAmount: 0,
          voucherAmountWithoutTCS:
            Utils.getNumber(footerTotal.amount) +
            Utils.getNumber(footerLedger.ledgerTotalAmount),

          //- balance,
        },
        deleteUploadId: deleteUploadId,
        // totalUnitPrice: Utils.getFormattedNumber(footerTotal.unitPrice),
      };

      // setOpenModel({
      //   type: "addCreditNoteReferenceModel",
      //   param: {
      //     open: true,
      //   },
      // });
      // setCurrentCreditNoteDetails(invoice);
      // console.log("invoice", invoice);

      const res = isDraft
        ? await DraftService.postVoucherDraft(
            currentClient?.id!,
            splitPathInVchType === Voucher_Type.CreditNote
              ? Voucher_Type.CreditNote
              : Voucher_Type.DebitNote,
            invoice,
            draftId
          )
        : await VoucherServices.postVoucher(
            currentClient?.id!,
            splitPathInVchType === Voucher_Type.CreditNote
              ? Voucher_Type.CreditNote
              : Voucher_Type.DebitNote,
            invoice,
            creditNoteId
          );

      if (res?.status) {
        sessionStorage.removeItem(`${currentClient?.id} Sales`);
        sessionStorage.removeItem("NotificationState");
        if (isDraft) {
          // ${isDraft && "Draft" && draftId}
          openNotification(
            "success",
            `Payment Draft ${res.result?.name} ${
              draftId ? "Edit Successfully" : "Add Successfully"
            }`
          );
        } else {
          openNotification(
            "success",
            `${
              splitPathInVchType === Voucher_Type.CreditNote
                ? "Credit"
                : "Debit"
            }  ${res.result?.name} ${isDraft && "Draft"} ${
              creditNoteId ? "Edit Successfully" : "Add Successfully"
            }`
          );
          navigate(-1);
        }
        // setInvoiceTotal(0.0);
        // setItemLength(0);
        setSaveButtonLoading(false);
      } else if (res?.message) {
        setSaveButtonLoading(false);
        openNotification("error", res?.message);
      }

      //  .catch((ex) => {
      //    setSaveButtonLoading(false);
      //    openNotification("error", ex);
      //  });

      setSaveNewLoading(false);
      // isSaveAndNew && seInitialRender(!initialRender);
      // } else {
      //   setSaveNewLoading(false);
      //   openNotification("error", "Selling price must be grated than 0");
      // }

      // console.log("Validated Values:", validatedValues);
    } catch (error) {
      setSaveButtonLoading(false);
      console.log("Validation failed:", error);
    }
  };
  EntryLevelKeys({
    save: () => addReturnNote(),
    cancel: () => navigate(-1),
  });
  const [addLedgerResponse, setAddLedgerResponse] = useState<any>(null);

  useEffect(() => {
    if (addLedgerResponse) {
      const exists = customerOptions.some(
        (option) => option.id === addLedgerResponse.id
      );
      if (exists) {
        form.setFieldValue("partyLedger", {
          ...form.getFieldValue("partyLedger"),
          key: addLedgerResponse?.id,
          value: addLedgerResponse?.id,
          label: addLedgerResponse?.ledgerName,
        });
        CustomerSelect(addLedgerResponse.id, true);
      }
      setAddLedgerResponse(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerOptions]);

  const settingControl = {
    title:
      splitPathInVchType === Voucher_Type.CreditNote
        ? companySettings?.setting?.crNote.title
        : companySettings?.setting?.drNote?.title,
    // showContactDetail:
    //   splitPathInVchType === Voucher_Type.CreditNote
    //     ? companySettings?.setting?.crNote?.showContactDetail
    //     : companySettings?.setting?.drNote?.showContactDetail,
    // discountPolicy:
    //   splitPathInVchType === Voucher_Type.CreditNote
    //     ? companySettings?.setting?.crNote?.discountPolicy
    //     : companySettings?.setting?.drNote?.discountPolicy,
    // showReceipt:
    //   splitPathInVchType === Voucher_Type.CreditNote
    //     ? companySettings?.setting?.crNote?.showReceipt
    //     : companySettings?.setting?.drNote?.showReceipt,
    // showOtherCharges:
    //   splitPathInVchType === Voucher_Type.CreditNote
    //     ? companySettings?.setting?.crNote?.showOtherCharges
    //     : companySettings?.setting?.drNote?.showOtherCharges,
    showShippingAddress:
      splitPathInVchType === Voucher_Type.CreditNote
        ? companySettings?.setting?.crNote?.showShippingAddress
        : companySettings?.setting?.drNote?.showShippingAddress,
    showItemDescription:
      splitPathInVchType === Voucher_Type.CreditNote
        ? companySettings?.setting?.crNote?.showItemDescription
        : companySettings?.setting?.drNote?.showItemDescription,
    autoRoundOff:
      splitPathInVchType === Voucher_Type.CreditNote
        ? companySettings?.setting?.crNote?.autoRoundOff
        : companySettings?.setting?.drNote?.autoRoundOff,
    roundOffType:
      splitPathInVchType === Voucher_Type.CreditNote
        ? companySettings?.setting?.crNote?.roundOffType
        : companySettings?.setting?.drNote?.roundOffType,
  };
  return (
    <Fragment>
      <Card
        // size="small"
        // className="TableCard"
        style={{ boxShadow: "0px 0px 10px 0px #96A1C84D", marginTop: 7 }}
        title={
          <>
            {windowWidth <= 1024 ? (
              ""
            ) : (
              <Col xl={4} lg={7} md={7} className="fs-20 fw-200 themeColor">
                <span>
                  {splitPathInVchType === Voucher_Type.CreditNote
                    ? companySettings?.setting?.crNote?.title
                    : companySettings?.setting?.drNote?.title}{" "}
                </span>
                <Dropdown
                  menu={{
                    items:
                      splitPathInVchType === Voucher_Type?.CreditNote
                        ? SalesDropdownItems()
                        : PurchaseDropdownItems(),
                    selectable: true,
                    defaultSelectedKeys: [
                      location?.pathname?.split("/")[5] === "addCreditNote" ||
                      "editCreditNote"
                        ? "1"
                        : "-1",
                    ],
                  }}
                  trigger={["click"]}
                  autoFocus={true}
                >
                  <Typography.Link>
                    <Space>
                      <DownOutlined
                      // style={{
                      //   fontSize: "12px",
                      //   color: "black",
                      //   padding: "0px 0px 10px 10px",
                      // }}
                      />
                    </Space>
                  </Typography.Link>
                </Dropdown>
              </Col>
            )}
          </>
        }
      >
        <Spin indicator={antIcon} spinning={loadingAllPage}>
          <Form
            form={form}
            autoComplete="off"
            name="Credit Note"
            initialValues={{ roundoffCheckbox: settingControl.autoRoundOff }}
          >
            {/* {windowWidth <= 1024 ? (
              <h3 className="fs-35 fw-500  textCenter">
                Credit Note
              </h3>
            ) : (
              ""
            )} */}
            <>
              {/* 
            <Row justify="space-between">
              <Col xl={17} lg={24} md={24}>
                <Row gutter={24} className="ps-14" justify="space-between"> */}
              {/* <Col lg={14} md={11} xs={23} sm={23} className="p-10"> */}
              {/* <Row gutter={[17, 2]}> */}
              {/*
                      <Col lg={12}>
                         <Row justify="space-between">
                          <Col className=" fw-500">Party</Col>
                          {selectedParty && (
                            <Col className="fw-500">
                              <Tooltip title="View Party">
                                <EyeOutlined
                                  onClick={() => {
                                    setCurrentCustomerId(selectedParty);
                                    setOpenModel({
                                      type: "addLedger",
                                      param: {
                                        open: true,
                                      },
                                    });
                                  }}
                                />
                              </Tooltip>
                            </Col>
                          )}
                        </Row> */}
              {/* <Form.Item
                          name="partyLedger"
                          className="m-0 selectPlaceholder selectedItem errormsg"
                          rules={[
                            { required: true, message: "Select a customer" },
                          ]}
                        >
                          <Select
                            showSearch
                            allowClear
                            labelInValue
                            optionFilterProp="children"
                            placeholder="Party Name"
                            filterOption={(input, option) =>
                              (option?.label?.toString() ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            className="selectedItem"
                            autoFocus={true}
                            ref={inputRef}
                            onSearch={(value: string) =>
                              setSearchCustomer({ search: value, length: 15 })
                            }
                            // onClick={() =>
                            //   setSearchCustomer({ ...searchCustomer, search: "" })
                            // }
                            onChange={(e: any) =>
                              CustomerSelect(e?.value, true)
                            }
                            options={customerOptions?.map((value: any) => ({
                              value: value?.id,
                              label: value?.ledgerName.concat(
                                value?.aliasName
                                  ? `${" (" + value?.aliasName + ")"}`
                                  : ""
                              ),
                            }))}
                            dropdownRender={(customerOptions) => (
                              <>
                                {customerOptions}
                                <Divider style={{ margin: "5px 0px" }} />
                                <Button
                                  style={{ width: "100%" }}
                                  icon={<PlusOutlined />}
                                  type="link"
                                  onClick={() => {
                                    setCurrentCustomerId("");
                                    setOpenModel({
                                      type: "addLedger",
                                      param: {
                                        open: true,
                                      },
                                    });
                                  }}
                                >
                                  New Customer
                                </Button>
                              </>
                            )}
                          />
                        </Form.Item>
                      </Col>
                         */}
              {/* <Col lg={12}>
                      <span className=" fw-500">Phone No.</span>
                      <Form.Item
                        name="phoneNo"
                        className="inputBoxMb"
                        rules={[
                          {
                            min: 10,
                            message: "phone no. must be 10 digits",
                          },
                        ]}
                      >
                        <Input
                          placeholder="9876543210"
                          onKeyPress={(e) =>
                            !/[0-9]/.test(e.key) && e.preventDefault()
                          }
                        />
                      </Form.Item>
                    </Col> */}

              {/* 
                      <Col lg={12}>
                        <Row justify="space-between">
                          <Col className=" fw-500">
                            {splitPathInVchType === Voucher_Type.CreditNote
                              ? "Invoice Ref. No."
                              : "Bill Ref. No."}
                          </Col>
                          {currentCreditNoteId && (
                            <Col className="fw-500">
                              <Tooltip title="View Invoice">
                                <EyeOutlined
                                  onClick={() => {
                                    setOpenModel({
                                      type: "viewSalesInvoice",
                                      param: {
                                        open: true,
                                      },
                                    });
                                  }}
                                />
                              </Tooltip>
                            </Col>
                          )}
                        </Row> */}

              {/* <Form.Item
                          name="purchaseOrderRef"
                          className="mb-3 errormsg"
                          rules={[
                            {
                              required: true,
                              message: `Please select Ref. No.`,
                            },
                          ]}
                        >
                          <Select
                            labelInValue
                            showSearch
                            allowClear={true}
                            filterOption={(input, option) =>
                              (option?.label?.toString() ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            placeholder="Select No"
                            popupMatchSelectWidth={false}
                            placement="bottomLeft"
                            optionFilterProp="children"
                            onSelect={(val) => {
                              console.log(
                                "if value,,,,,,,,,",
                                editInvoice,
                                val.value
                              );
                              if (editInvoice.voucherId === val.value) {
                                debugger;

                                setEditAgiCallback(true);
                                setInvoiceApiCallback(true);
                              } else {
                                // console.log("if value,,,,,,,,,");
                                setInvoiceApiCallback(false);
                              }
                              setCurrentCreditNoteId(val?.value);
                            }}
                            options={
                              invoiceOption &&
                              invoiceOption?.map((val: any) => ({
                                value: val?.voucherId,
                                label: val?.voucherNumber,
                              }))
                            }
                          />
                        </Form.Item>
                      </Col>
                         */}
              {/* <Col lg={12}> */}
              {/* <span className=" fw-500">
                          Billing Address
                        </span>
                        <Form.Item
                          name={["billingAddress", "type"]}
                          className="inputBoxMb"
                          rules={[
                            {
                              required: true,
                              message: "Address type is requied",
                            },
                          ]}
                          style={{ paddingTop: "4px" }}
                        >
                          <Select
                            showSearch
                            allowClear
                            optionFilterProp="children"
                            placeholder="Select type"
                            filterOption={false}
                            className="selectedItem"
                            options={[
                              {
                                label: "Registered Address",
                                value: TypeOfAddress.RegisteredAddress,
                              },
                              {
                                label: "Business Address",
                                value: TypeOfAddress.BusinessAddress,
                              },
                              {
                                label: "Branch Address",
                                value: TypeOfAddress.BranchAddress,
                              },
                              {
                                label: "Unit Address",
                                value: TypeOfAddress.UnitAddress,
                              },
                              {
                                label: "Godown Address",
                                value: TypeOfAddress.GodownAddress,
                              },
                            ]}
                            onChange={(value: any) =>
                              customerAddSelect(
                                value,
                                AddressEnum.BillingAddress,
                                []
                              )
                            }
                          />
                        </Form.Item> */}
              {/* <Form.Item
                          name={["billingAddress", "address"]}
                          className="inputBoxMb"
                          // rules={[
                          //   {
                          //     required: true,
                          //     message: "Billing address is required",
                          //   },
                          // ]}
                          style={{ paddingTop: "4px" }}
                        >
                          <TextArea
                            placeholder="Billing address"
                            rows={3}
                            // style={{ borderTop: "none" }}
                          />
                        </Form.Item>
                      </Col> */}
              {/* 
                      <Col lg={12}>
                        <span className=" fw-500">
                          Shipping Address
                        </span>
                        <Form.Item
                          style={{ marginTop: "4px" }}
                          name={["shippingAddress", "type"]}
                          className="inputBoxMb"
                        >
                          <Select
                            showSearch
                            allowClear
                            optionFilterProp="children"
                            placeholder="Select type"
                            filterOption={false}
                            className="selectedItem"
                            options={[
                              {
                                label: "Registered Address",
                                value: TypeOfAddress.RegisteredAddress,
                              },
                              {
                                label: "Business Address",
                                value: TypeOfAddress.BusinessAddress,
                              },
                              {
                                label: "Branch Address",
                                value: TypeOfAddress.BranchAddress,
                              },
                              {
                                label: "Unit Address",
                                value: TypeOfAddress.UnitAddress,
                              },
                              {
                                label: "Godown Address",
                                value: TypeOfAddress.GodownAddress,
                              },
                            ]}
                            onChange={(value: any) =>
                              customerAddSelect(
                                value,
                                AddressEnum.ShippingAddress,
                                []
                              )
                            }
                          />
                        </Form.Item> */}
              {/* <Form.Item
                          name={["shippingAddress", "address"]}
                          className="inputBoxMb"
                          style={{ paddingTop: "4px" }}
                        >
                          <TextArea rows={3} placeholder="Shipping address" />
                        </Form.Item> 
                      </Col>
                          */}
              {/* </Row> */}
              {/* </Col> */}

              {/* <Col lg={9} md={4} xs={23} sm={23}>
                    <Row gutter={[17, 8]} style={{ marginTop: "9px" }}>
                      <Col lg={12}> */}
              {/* <span className=" fw-500">
                          {splitPathInVchType === Voucher_Type.CreditNote
                            ? "Sales Ledger"
                            : "Purchase Ledger"}
                        </span>
                        <Form.Item
                          name="effectiveLedger"
                          className="mb-3 errormsg"
                          rules={[
                            {
                              required: true,
                              message: `Please select ${
                                splitPathInVchType === Voucher_Type.CreditNote
                                  ? "Sales Ledger"
                                  : "Purchase Ledger"
                              }`,
                            },
                          ]}
                        >
                          <Select
                            disabled
                            labelInValue
                            showSearch
                            allowClear={false}
                            placeholder="Select ledger"
                            popupMatchSelectWidth={false}
                            placement="bottomLeft"
                            optionFilterProp="children"
                            options={effectiveLedger?.map((x: any) => ({
                              value: x?.id,
                              label: x?.ledgerName,
                            }))}
                          />
                        </Form.Item> 
                      </Col>
                        */}
              {/*
                      <Col lg={12}>
                         <span className=" fw-500">
                          {splitPathInVchType === Voucher_Type.CreditNote
                            ? "Credit Note No."
                            : "Debit Note No."}
                        </span>
                        <Form.Item
                          name="number"
                          className="mb-3"
                          rules={[
                            {
                              required: true,
                              message: "Please enter No.",
                            },
                          ]}
                        >
                          <Input disabled />
                        </Form.Item> 
                      </Col>
                        */}
              {/*
                      <Col lg={12}>
                         <span className=" fw-500">Date</span>
                        <Form.Item
                          name="date"
                          className="m-0"
                          rules={[
                            {
                              required: true,
                              message: "Select Date",
                            },
                          ]}
                        >
                          <DatePicker
                            format="DD/MM/YYYY"
                            // disabledDate={(current) =>
                            //   current && current > dayjs().endOf("day")
                            // }
                            style={{ width: "100%" }}
                            allowClear={false}
                            onChange={(val) => {
                              setDateFiled(dayjs(val).toISOString());
                            }}
                          />
                        </Form.Item> 
                      </Col>
                        */}
              {/* <Col lg={12}>
                        <span className=" fw-500">
                          Include Other Expenses
                        </span>
                        <Form.Item
                          name="showFreightInsLedgers"
                          className="mb-3 errormsg"
                          valuePropName="checked"
                        >
                          <Checkbox
                            disabled
                            onChange={(e: any) => {
                              setShowFreightInsurance(e.target.checked);

                              setFooterLedger({
                                ledgerAmount: 0,
                                ledgerGstAmount: 0,
                                ledgerTotalAmount: 0,
                              });

                              form.setFieldValue(
                                ["freightInsLedgers"],
                                [
                                  {
                                    gstRate: 0,
                                    gstAmount: 0,
                                    totalAmount: 0,
                                    amount: 0,
                                  },
                                ]
                              );
                            }}
                          />
                        </Form.Item>
                      </Col> */}

              {/* <Col lg={12}>
                      <span className=" fw-500">
                        Include Other Expenses
                      </span>
                      <Button
                        icon={<PlusOutlined />}
                        type="primary"
                        onClick={() =>
                          setOpenModel({
                            type: "addDespatchDetail",
                            param: { open: true },
                          })
                        }
                      >
                        Add
                      </Button>
                    </Col> */}
              {/* </Row> */}
              {/* </Col> */}
              {/* </Row>
              </Col>
            </Row> */}
            </>

            <Row gutter={10}>
              <Col xl={12} lg={10} sm={24}>
                <Row gutter={10}>
                  {/* Select Party */}
                  <Col sm={12} xs={24}>
                    <Row justify="space-between">
                      <Col className=" fw-500">
                        <span>Party</span>
                      </Col>
                      {selectedParty && (
                        <Col className="fw-500">
                          <Tooltip title="View Party">
                            <EyeOutlined
                              onClick={() => {
                                setCurrentCustomerId(selectedParty);
                                setOpenModel({
                                  type: "addLedger",
                                  param: {
                                    open: true,
                                  },
                                });
                              }}
                            />
                          </Tooltip>
                        </Col>
                      )}
                    </Row>
                    <Form.Item
                      name="partyLedger"
                      className="m-0 selectPlaceholder selectedItem errormsg"
                      rules={[{ required: true, message: "Select a customer" }]}
                    >
                      <Select
                        showSearch
                        allowClear
                        labelInValue
                        optionFilterProp="children"
                        placeholder="Party Name"
                        filterOption={(input, option) =>
                          (option?.label?.toString() ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        className="selectedItem "
                        autoFocus={true}
                        ref={inputRef}
                        onSearch={(value: string) =>
                          setSearchCustomer({ search: value, length: 15 })
                        }
                        // onClick={() =>
                        //   setSearchCustomer({ ...searchCustomer, search: "" })
                        // }
                        onChange={(e: any) => CustomerSelect(e?.value, true)}
                        options={customerOptions?.map((value: any) => ({
                          value: value?.id,
                          label: value?.ledgerName.concat(
                            value?.aliasName
                              ? `${" (" + value?.aliasName + ")"}`
                              : ""
                          ),
                        }))}
                        dropdownRender={(customerOptions) => (
                          <>
                            {customerOptions}
                            <Divider style={{ margin: "5px 0px" }} />
                            <Button
                              style={{ width: "100%" }}
                              icon={<PlusOutlined />}
                              type="link"
                              onClick={() => {
                                setCurrentCustomerId("");
                                setOpenModel({
                                  type: "addLedger",
                                  param: {
                                    open: true,
                                  },
                                });
                              }}
                            >
                              New Customer
                            </Button>
                          </>
                        )}
                      />
                    </Form.Item>
                  </Col>
                  {/* Invoice Ref No. */}
                  <Col sm={12} xs={24}>
                    <Row justify="space-between">
                      <Col className=" fw-500">
                        <span>
                          {splitPathInVchType === Voucher_Type.CreditNote
                            ? "Invoice Ref. No."
                            : "Bill Ref. No."}
                        </span>
                      </Col>
                      {currentCreditNoteId && (
                        <Col className="fw-500">
                          <Tooltip title="View Invoice">
                            <EyeOutlined
                              onClick={() => {
                                setOpenModel({
                                  type: "viewSalesInvoice",
                                  param: {
                                    open: true,
                                  },
                                });
                              }}
                            />
                          </Tooltip>
                        </Col>
                      )}
                    </Row>

                    <Form.Item
                      name="purchaseOrderRef"
                      className="mb-3 errormsg"
                      rules={[
                        {
                          required: true,
                          message: `Please select Ref. No.`,
                        },
                      ]}
                    >
                      <Select
                        labelInValue
                        showSearch
                        allowClear={true}
                        filterOption={(input, option) =>
                          (option?.label?.toString() ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        placeholder="Select No"
                        popupMatchSelectWidth={false}
                        placement="bottomLeft"
                        optionFilterProp="children"
                        onSelect={(val) => {
                          console.log(
                            "if value,,,,,,,,,",
                            editInvoice,
                            val.value
                          );
                          if (editInvoice.voucherId === val.value) {
                            // debugger;

                            setEditAgiCallback(true);
                            setInvoiceApiCallback(true);
                            // editData;
                          } else {
                            // console.log("if value,,,,,,,,,");
                            setInvoiceApiCallback(false);
                          }
                          setCurrentCreditNoteId(val?.value);
                        }}
                        options={
                          invoiceOption &&
                          invoiceOption?.map((val: any) => ({
                            value: val?.voucherId,
                            label: val?.voucherNumber,
                          }))
                        }
                      />
                    </Form.Item>
                  </Col>
                  {/* Billding Address */}
                  <Col sm={12} xs={24}>
                    <span className=" fw-500">Billing Address</span>
                    <Form.Item
                      name={["billingAddress", "type"]}
                      className="inputBoxMb"
                      rules={[
                        {
                          required: true,
                          message: "Address type is requied",
                        },
                      ]}
                      style={{ paddingTop: "4px" }}
                    >
                      <Select
                        showSearch
                        allowClear
                        optionFilterProp="children"
                        placeholder="Select type"
                        filterOption={false}
                        className="selectedItem"
                        options={[
                          {
                            label: "Registered Address",
                            value: TypeOfAddress.RegisteredAddress,
                          },
                          {
                            label: "Business Address",
                            value: TypeOfAddress.BusinessAddress,
                          },
                          {
                            label: "Branch Address",
                            value: TypeOfAddress.BranchAddress,
                          },
                          {
                            label: "Unit Address",
                            value: TypeOfAddress.UnitAddress,
                          },
                          {
                            label: "Godown Address",
                            value: TypeOfAddress.GodownAddress,
                          },
                        ]}
                        onChange={(value: any) =>
                          customerAddSelect(
                            value,
                            AddressEnum.BillingAddress,
                            []
                          )
                        }
                      />
                    </Form.Item>
                  </Col>
                  {/* Shipping Address */}
                  {(settingControl.showShippingAddress ||
                    updateTimeData.shippingAddress) && (
                    <Col sm={12} xs={24}>
                      <span className=" fw-500">Shipping Address</span>
                      <Form.Item
                        style={{ marginTop: "4px" }}
                        name={["shippingAddress", "type"]}
                        className="inputBoxMb"
                      >
                        <Select
                          showSearch
                          allowClear
                          optionFilterProp="children"
                          placeholder="Select type"
                          filterOption={false}
                          className="selectedItem"
                          options={[
                            {
                              label: "Registered Address",
                              value: TypeOfAddress.RegisteredAddress,
                            },
                            {
                              label: "Business Address",
                              value: TypeOfAddress.BusinessAddress,
                            },
                            {
                              label: "Branch Address",
                              value: TypeOfAddress.BranchAddress,
                            },
                            {
                              label: "Unit Address",
                              value: TypeOfAddress.UnitAddress,
                            },
                            {
                              label: "Godown Address",
                              value: TypeOfAddress.GodownAddress,
                            },
                          ]}
                          onChange={(value: any) =>
                            customerAddSelect(
                              value,
                              AddressEnum.ShippingAddress,
                              []
                            )
                          }
                        />
                      </Form.Item>
                    </Col>
                  )}
                  {/* BillingAddress TaxtArea*/}
                  <Col sm={12} xs={24}>
                    <Form.Item
                      name={["billingAddress", "address"]}
                      className="inputBoxMb"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Billing address is required",
                      //   },
                      // ]}
                      style={{ paddingTop: "4px" }}
                    >
                      <TextArea
                        placeholder="Billing address"
                        rows={3}
                        // style={{ borderTop: "none" }}
                      />
                    </Form.Item>
                  </Col>
                  {(settingControl.showShippingAddress ||
                    updateTimeData.shippingAddress) && (
                    <Col sm={12} xs={24}>
                      <Form.Item
                        name={["shippingAddress", "address"]}
                        className="inputBoxMb"
                        style={{ paddingTop: "4px" }}
                      >
                        <TextArea rows={3} placeholder="Shipping address" />
                      </Form.Item>
                    </Col>
                  )}
                </Row>
              </Col>

              <Col xl={12} lg={14} sm={24}>
                <Row gutter={10}>
                  {/* Sales Ledger */}
                  <Col md={8} sm={12} xs={24}>
                    <span className=" fw-500">
                      {splitPathInVchType === Voucher_Type.CreditNote
                        ? "Sales Ledger"
                        : "Purchase Ledger"}
                    </span>
                    <Form.Item
                      name="effectiveLedger"
                      className="mb-3 errormsg"
                      rules={[
                        {
                          required: true,
                          message: `Please select ${
                            splitPathInVchType === Voucher_Type.CreditNote
                              ? "Sales Ledger"
                              : "Purchase Ledger"
                          }`,
                        },
                      ]}
                    >
                      <Select
                        disabled
                        labelInValue
                        showSearch
                        allowClear={false}
                        placeholder="Select ledger"
                        popupMatchSelectWidth={false}
                        placement="bottomLeft"
                        optionFilterProp="children"
                        options={effectiveLedger?.map((x: any) => ({
                          value: x?.id,
                          label: x?.ledgerName,
                        }))}
                      />
                    </Form.Item>
                  </Col>
                  {/* Note No. */}
                  <Col md={8} sm={12} xs={24}>
                    <span className=" fw-500">
                      {splitPathInVchType === Voucher_Type.CreditNote
                        ? "Credit Note No."
                        : "Debit Note No."}
                    </span>
                    <Form.Item
                      name="number"
                      className="mb-3"
                      rules={[
                        {
                          required: true,
                          message: "Please enter No.",
                        },
                      ]}
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  {/* Date */}
                  <Col md={8} sm={12} xs={24}>
                    <span className=" fw-500">Date</span>
                    <Form.Item
                      name="date"
                      className="m-0"
                      rules={[
                        {
                          required: true,
                          message: "Select Date",
                        },
                      ]}
                    >
                      <DatePicker
                        format="DD/MM/YYYY"
                        // disabledDate={(current) =>
                        //   current && current > dayjs().endOf("day")
                        // }
                        style={{ width: "100%" }}
                        allowClear={false}
                        onChange={(val) => {
                          setDateFiled(dayjs(val).toISOString());
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>

            {/*Items Table */}
            <Spin indicator={antIcon} spinning={loading}>
              {/* {invoiceOption.length > 0 ? ( */}
              <div className="tableWrapper" style={{ overflowY: "auto" }}>
                {/* dashedBorderTop */}
                <Row
                  style={{
                    marginTop: "10px",
                    width: "100%",
                    backgroundColor: "whitesmoke",
                    padding: "5px 10px",
                    fontSize: "15px",
                    fontWeight: "bolder",
                  }}
                >
                  <Col span={12}>
                    <span>Item Detail</span>
                  </Col>
                  {/* {(settingControl.discountPolicy ===
                    Discount_Policy.TradenCashDiscount ||
                    settingControl.discountPolicy ===
                      Discount_Policy.OnlyTradeDiscount ||
                    updateTimeData.tradeDiscount) && (
                    <Col span={12}>
                      <Row
                        gutter={6}
                        style={{ display: "flex", justifyContent: "end" }}
                      >
                        <Col style={{ paddingTop: "4px" }}>Discount (%)</Col>
                        <Col>
                          <Form.Item
                            className="discountInput inputBoxMb"
                            // name={["amount", "dics"]}
                            name="discount"
                          >
                            <InputNumber
                              placeholder="0.0"
                              controls={false}
                              min={0.0}
                              max={100}
                              // max={
                              //   form.getFieldValue("discountDrop") ===
                              //   DiscountType.PercentageDiscount
                              //     ? 100
                              //     : Infinity
                              // }
                              style={{
                                width: "100%",
                                // margin: "0px 30px 0px 10px",
                                // width: 60,
                              }}
                              onChange={(e: any) => {
                                // console.log(e);
                                // handleItemsAllDiscount(e);
                              }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  )} */}
                </Row>

                <table
                  className="AddPageTabelDesign "
                  style={{ fontSize: windowWidth <= 1024 ? "10.3px" : "" }}
                >
                  <thead>
                    <tr>
                      <th
                        colSpan={2}
                        rowSpan={2}
                        className="textCenter"
                        style={{ width: "5%" }}
                      >
                        S no.
                      </th>
                      <th
                        rowSpan={2}
                        style={{
                          width: "17%",
                          borderLeft: "1px solid rgb(240, 245, 255)",
                        }}
                      >
                        Item(s)
                      </th>
                      {/* <th
                          rowSpan={2}
                          style={{ width: "22%" }}
                          // style={{ width: windowWidth <= 1024 ? "18%" : "" }}
                        >
                          Description
                        </th> */}

                      <th rowSpan={2} style={{ width: "7%" }}>
                        Qty
                      </th>
                      {/* <th rowSpan={2} style={{ width: "6%" }}>
                      Unit
                    </th> */}
                      <th rowSpan={2} style={{ width: "9%" }}>
                        Unit Price
                      </th>
                      {(footerTotal.discount > 0 ||
                        updateTimeData.tradeDiscount) && (
                        <>
                          <th colSpan={2} style={{ width: "12%" }}>
                            Discount
                          </th>
                        </>
                      )}
                      {isGSTEnabled && (
                        <th colSpan={2} style={{ width: "14%" }}>
                          GST
                        </th>
                      )}
                      <th colSpan={2} rowSpan={2} style={{ width: "14%" }}>
                        Gross Amount
                      </th>
                    </tr>
                    <tr>
                      {(footerTotal.discount > 0 ||
                        updateTimeData.tradeDiscount) && (
                        <>
                          <th style={{ width: "6%" }}>%</th>
                          <th style={{ width: "6%" }}>₹</th>
                        </>
                      )}
                      {isGSTEnabled && <th style={{ width: "6%" }}>%</th>}
                      {isGSTEnabled && <th style={{ width: "6%" }}>₹</th>}
                    </tr>
                  </thead>
                  <tbody>
                    <Form.List name="items">
                      {(fields, { add, remove, move }) => {
                        return (
                          <>
                            {confirmation && (
                              <ConfirmationModal
                                open={confirmation}
                                text={`Do you really want to delete this item "${form.getFieldValue(
                                  ["items", deleteItemList, "name"]
                                )}" ?`}
                                onNo={() => setConfirmation(false)}
                                onYes={() => {
                                  remove(deleteItemList);
                                  handleDeleteRowButton();
                                  setItemLength(fields.length - 1);
                                  setModelDetails({
                                    ...modelDetails,
                                    items: [
                                      ...removeElementAtIndex(
                                        modelDetails.items,
                                        deleteItemList
                                      ),
                                    ],
                                  });
                                  setItemDetails([
                                    ...removeElementAtIndex(
                                      itemDetails,
                                      deleteItemList
                                    ),
                                  ]);
                                }}
                              />
                            )}
                            {fields.map(
                              ({ key, name, ...restField }, index) => {
                                return (
                                  <tr key={key}>
                                    <td>{index + 1}.</td>
                                    <td>
                                      <Form.Item
                                        name={[name, "isService"]}
                                        className="m-0"
                                        {...restField}
                                      >
                                        {form.getFieldValue([
                                          "items",
                                          index,
                                          "isService",
                                        ]) ? (
                                          <Tooltip
                                            title="Service"
                                            placement="left"
                                            color="#1d39c4"
                                          >
                                            <Avatar
                                              style={{
                                                cursor: "pointer",
                                                verticalAlign: "middle",
                                                color: "#1d39c4",
                                                backgroundColor: "#f0f5ff",
                                                borderColor: "#adc6ff",
                                                fontWeight: "bold",
                                              }}
                                              size="small"
                                            >
                                              S
                                            </Avatar>
                                          </Tooltip>
                                        ) : (
                                          <Tooltip
                                            title="Goods"
                                            placement="left"
                                            color="#389e0d"
                                          >
                                            <Avatar
                                              style={{
                                                cursor: "pointer",
                                                verticalAlign: "middle",
                                                color: "#389e0d",
                                                backgroundColor: "#f6ffed",
                                                borderColor: "#b7eb8f",
                                                fontWeight: "bold",
                                              }}
                                              size="small"
                                            >
                                              G
                                            </Avatar>
                                          </Tooltip>
                                        )}
                                      </Form.Item>
                                    </td>

                                    <td>
                                      <Form.Item
                                        name={[name, "name"]}
                                        className="m-0"
                                        {...restField}
                                      >
                                        <Select
                                          // variant="filled"
                                          showSearch
                                          bordered={false}
                                          placeholder="Search for items"
                                          optionFilterProp="children"
                                          filterOption={false}
                                          popupMatchSelectWidth={false}
                                          disabled={inputFieldDisable}
                                          listItemHeight={1}
                                          style={{
                                            width: "100%",
                                            backgroundColor: "#FFFFFF",
                                          }}
                                          dropdownStyle={{ width: "250px" }}
                                          placement="bottomRight"
                                          notFoundContent={
                                            <Empty
                                              image={
                                                Empty.PRESENTED_IMAGE_SIMPLE
                                              }
                                              description={
                                                <span>
                                                  Sales item not found.
                                                </span>
                                              }
                                            />
                                          }
                                          // prefixCls={<EyeOutlined />}
                                          onSearch={(value: string) =>
                                            setSearchItem(value)
                                          }
                                          onClick={() => {
                                            setSearchItem("");
                                            // setNewItem({
                                            //   data: {},
                                            //   isSaved: false,
                                            // });
                                          }}
                                          onChange={(value: any) => {
                                            if (fields.length === index + 1)
                                              add();
                                            ItemSelect(value, index);
                                          }}
                                          options={itemsOption?.map(
                                            (itm: any) => ({
                                              value: itm?.id,
                                              label: (
                                                <div>
                                                  <Row
                                                    justify={"space-between"}
                                                  >
                                                    <Col>
                                                      <p
                                                        style={{
                                                          fontWeight: "bold",
                                                          color: "dimgray",
                                                        }}
                                                      >
                                                        {itm?.name}
                                                      </p>
                                                    </Col>
                                                  </Row>
                                                  <div>
                                                    {itm?.hsnCode && (
                                                      <span>
                                                        <span
                                                          style={{
                                                            fontWeight: "500",
                                                            color: "dimgray",
                                                          }}
                                                        >
                                                          HSN Code -
                                                        </span>
                                                        {itm?.hsnCode}
                                                      </span>
                                                    )}
                                                    {itm?.sacCode && (
                                                      <span>
                                                        <span
                                                          style={{
                                                            fontWeight: "500",
                                                            color: "dimgray",
                                                          }}
                                                        >
                                                          SAC Code -
                                                        </span>
                                                        {itm?.sacCode}
                                                      </span>
                                                    )}
                                                    {itm?.description && (
                                                      <span>
                                                        <span
                                                          style={{
                                                            fontWeight: "500",
                                                            color: "dimgray",
                                                          }}
                                                        >
                                                          {" "}
                                                          Des. -
                                                        </span>
                                                        {itm?.description}
                                                      </span>
                                                    )}
                                                  </div>
                                                </div>
                                              ),
                                            })
                                          )}
                                        />
                                      </Form.Item>
                                      {(settingControl.showItemDescription ||
                                        updateTimeData.showItemDescription) && (
                                        <Form.Item
                                          className="m-0"
                                          {...restField}
                                          name={[name, "description"]}
                                        >
                                          <Input variant="filled" />
                                        </Form.Item>
                                      )}
                                    </td>
                                    {/* <td>
                                        <Form.Item
                                          className="m-0"
                                          {...restField}
                                          name={[name, "description"]}
                                        >
                                          <Input />
                                        </Form.Item>
                                      </td> */}

                                    <td>
                                      <Form.Item
                                        className="m-0"
                                        {...restField}
                                        name={[name, "amount", "qty"]}
                                      >
                                        <InputNumber
                                          variant="filled"
                                          min={0}
                                          step={0.01}
                                          style={{
                                            width: "100%",
                                            backgroundColor: "#FFFFFF",
                                          }}
                                          max={
                                            Math.round(
                                              itemDetails[index]?.amount?.qty *
                                                100
                                            ) / 100
                                          }
                                          controls={false}
                                          className="inputNumberTextEnd"
                                          onChange={() => {
                                            handleItemsNetAmmount(index);
                                          }}
                                          // formatter={(value: any) =>
                                          //   Utils.getInputNumberFormat(value)
                                          // }
                                          // onKeyPress={(e) =>
                                          //   handelTwoDigitAfterDecimal(e)
                                          // }
                                          // formatter={(value) =>
                                          //   `${value}`.replace(/^(.*\.\d{2}).*$/, "$1")
                                          // }
                                          // parser={(value: any) =>
                                          //   isNaN(parseFloat(value)) ? '' : parseFloat(value).toFixed(2)
                                          // }
                                        />
                                      </Form.Item>

                                      <Form.Item
                                        name={[name, "amount", "unit"]}
                                        className="m-0"
                                        {...restField}
                                      >
                                        <Select
                                          bordered={false}
                                          disabled
                                          labelInValue
                                          showSearch
                                          popupMatchSelectWidth={false}
                                          // dropdownStyle={{ width: "250px" }}
                                          // style={{ width: "140px" }}
                                          style={{
                                            minWidth: "100%",
                                            maxWidth: "240px",
                                            backgroundColor: "#FFFFFF",

                                            // backgroundColor: "#FFFFFF",
                                          }}
                                          placement="bottomRight"
                                          // filterOption={(input, option) =>
                                          //   (option?.label ?? "")
                                          //     .toLowerCase()
                                          //     .includes(input.toLowerCase())
                                          // }
                                          // options={account}
                                        />
                                      </Form.Item>
                                    </td>
                                    {/* <td>
                                  <Form.Item
                                    className="m-0"
                                    {...restField}
                                    name={[name, "amount", "unit"]}
                                  >
                                    <Select
                                      popupMatchSelectWidth={false}
                                      placement="bottomRight"
                                    />
                                  </Form.Item>
                                </td> */}
                                    <td style={{ verticalAlign: "top" }}>
                                      <Form.Item
                                        className="m-0"
                                        {...restField}
                                        name={[name, "amount", "price"]}
                                      >
                                        <InputNumber
                                          variant="filled"
                                          min={0}
                                          step={0.01}
                                          style={{
                                            width: "100%",
                                            backgroundColor: "#FFFFFF",
                                          }}
                                          controls={false}
                                          className="inputNumberTextEnd"
                                          max={
                                            Math.round(
                                              itemDetails[index]?.amount
                                                ?.price * 100
                                            ) / 100
                                          }
                                          // formatter={(value: any) =>
                                          //   Utils.getInputNumberFormat(value)
                                          // }
                                          // onKeyPress={(e) =>
                                          //   handelTwoDigitAfterDecimal(e)
                                          // }
                                          onChange={() =>
                                            handleItemsNetAmmount(index)
                                          }
                                        />
                                      </Form.Item>
                                    </td>
                                    {(footerTotal.discount > 0 ||
                                      updateTimeData.tradeDiscount) && (
                                      <>
                                        <td style={{ verticalAlign: "top" }}>
                                          <Form.Item
                                            className="m-0"
                                            {...restField}
                                            name={[name, "amount", "discount"]}
                                          >
                                            <InputNumber
                                              // variant="filled"
                                              bordered={false}
                                              disabled
                                              className="inputNumberTextEnd"
                                              controls={false}
                                              style={{
                                                backgroundColor: "#FFFFFF",
                                                width: "100%",
                                              }}
                                              min={0}
                                              max={100}
                                              // formatter={(value: any) =>
                                              //   Utils.getInputNumberFormat(value)
                                              // }
                                              // onKeyPress={(e) =>
                                              //   handelTwoDigitAfterDecimal(e)
                                              // }
                                              onChange={() =>
                                                handleItemsNetAmmount(index)
                                              }
                                            />
                                          </Form.Item>
                                        </td>
                                        <td style={{ verticalAlign: "top" }}>
                                          <Form.Item
                                            className="m-0"
                                            {...restField}
                                            name={[
                                              name,
                                              "amount",
                                              "discountRupee",
                                            ]}
                                          >
                                            <InputNumber
                                              // variant="filled"
                                              disabled
                                              bordered={false}
                                              controls={false}
                                              min={0}
                                              step={0.01}
                                              className="inputNumberTextEnd"
                                              max={returnNetAmount(
                                                index,
                                                form.getFieldValue(["items"])
                                              )}
                                              style={{
                                                width: "100%",
                                                backgroundColor: "#FFFFFF",
                                              }}
                                              // formatter={(value: any) =>
                                              //   Utils.getInputNumberFormat(value)
                                              // }
                                              // onKeyPress={(e) =>
                                              //   handelTwoDigitAfterDecimal(e)
                                              // }
                                              onChange={() => {
                                                onDiscountAmountChange(index);
                                              }}
                                            />
                                          </Form.Item>
                                        </td>
                                      </>
                                    )}
                                    {isGSTEnabled && (
                                      <td style={{ verticalAlign: "top" }}>
                                        <Form.Item
                                          name={[name, "amount", "tax"]}
                                          className="m-0"
                                          {...restField}
                                        >
                                          <Select
                                            bordered={false}
                                            style={{
                                              backgroundColor: "#FFFFFF",
                                              width: "100%",
                                            }}
                                            // variant="filled"
                                            disabled
                                            placeholder="%"
                                            popupMatchSelectWidth={false}
                                            placement="bottomRight"
                                            options={gstOption?.map(
                                              (gst: any) => ({
                                                key: gst.id,
                                                value: gst.rate,
                                                label: gst.name,
                                              })
                                            )}
                                          />
                                        </Form.Item>
                                      </td>
                                    )}
                                    {isGSTEnabled && (
                                      <td style={{ verticalAlign: "top" }}>
                                        <Tooltip
                                          title={form.getFieldValue([
                                            "items",
                                            index,
                                            "amount",
                                            "itemTaxAmt",
                                          ])}
                                          placement="top"
                                        >
                                          <Form.Item
                                            className="m-0"
                                            {...restField}
                                            name={[
                                              name,
                                              "amount",
                                              "itemTaxAmt",
                                            ]}
                                          >
                                            <InputNumber
                                              disabled
                                              bordered={false}
                                              step={0.01}
                                              controls={false}
                                              style={{
                                                width: "100%",
                                                backgroundColor: "#FFFFFF",
                                              }}
                                              className="inputNumberTextEnd"
                                            />
                                          </Form.Item>
                                        </Tooltip>
                                      </td>
                                    )}
                                    <td style={{ verticalAlign: "top" }}>
                                      <Form.Item
                                        className="m-0"
                                        {...restField}
                                        name={[name, "amount", "totalAmount"]}
                                      >
                                        <InputNumber
                                          // variant="filled"
                                          disabled
                                          min={0}
                                          step={0.01}
                                          style={{
                                            width: "100%",
                                            backgroundColor: "#FFFFFF",
                                          }}
                                          controls={false}
                                          bordered={false}
                                          className="inputNumberTextEnd"
                                          max={
                                            Math.round(
                                              (itemDetails[index]?.amount?.net +
                                                itemDetails[index]?.amount
                                                  ?.itemTaxAmt) *
                                                100
                                            ) / 100
                                          }
                                          // formatter={(value: any) =>
                                          //   Utils.getInputNumberFormat(value)
                                          // }
                                          // onKeyPress={(e) =>
                                          //   handelTwoDigitAfterDecimal(e)
                                          // }
                                          onChange={() => {
                                            onItemsTotalAmountChange(index);
                                          }}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td
                                      style={{
                                        width: 25,
                                        verticalAlign: "top",
                                      }}
                                    >
                                      {/* {fields.length !== 1 && ( */}
                                      <img
                                        className="cursor mx-1"
                                        onClick={() => {
                                          setDeleteItemList(name);
                                          setConfirmation(true);
                                          // console.log(
                                          //   "fields.length",
                                          //   fields.length,
                                          //   index
                                          // );
                                        }}
                                        src={deleteImg}
                                        alt="Delete"
                                      />
                                      {/* )} */}
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                          </>
                        );
                      }}
                    </Form.List>
                    {itemLength > 0 ? (
                      <>
                        <tr>
                          <td colSpan={3} className="textEnd">
                            <td
                              colSpan={3}
                              className="textEnd"
                              style={{ border: "None" }}
                            >
                              <div
                                style={
                                  {
                                    // display: "flex",
                                    // justifyContent: "space-between",
                                    // alignContent:"space-between"
                                    // verticalAlign: "inherit",
                                  }
                                }
                              >
                                {updateTimeData.friInsuranceOtherCharge && (
                                  <Button
                                    type="primary"
                                    icon={<PlusOutlined />}
                                    size="small"
                                    onClick={() => {
                                      setShowFreightInsurance(true);
                                      // setSelectedOtherExpenses([]);
                                      setFooterLedger({
                                        ledgerAmount: 0,
                                        ledgerGstAmount: 0,
                                        ledgerTotalAmount: 0,
                                      });
                                      form.setFieldValue(
                                        ["freightInsLedgers"],
                                        [
                                          {
                                            gstRate: 0,
                                            gstAmount: 0,
                                            totalAmount: 0,
                                            amount: 0,
                                          },
                                        ]
                                      );
                                    }}
                                    disabled={showFreightInsurance}
                                  >
                                    Other Expenses
                                  </Button>
                                )}
                                <div
                                  style={{ fontWeight: "bold", padding: "3px" }}
                                >
                                  Sub Total
                                </div>
                              </div>
                            </td>
                          </td>
                          <td colSpan={1} className="textEnd pe-14">
                            {Utils.getFormattedNumber(footerTotal.qty)}
                          </td>
                          {/* <td colSpan={1} className="textEnd pe-12">
                        ₹ {Utils.getFormattedNumber(footerTotal.taxableAmount)}
                      </td> */}
                          <td></td>
                          {(footerTotal.discount > 0 ||
                            updateTimeData.tradeDiscount) && (
                            <>
                              <td></td>
                              <td className="textEnd pe-12">
                                ₹{" "}
                                {Utils.getFormattedNumber(footerTotal.discount)}
                              </td>
                            </>
                          )}
                          {isGSTEnabled && (
                            <>
                              <td></td>
                              <td className="textEnd pe-12">
                                ₹ {Utils.getFormattedNumber(footerTotal.gst)}
                              </td>
                            </>
                          )}
                          <td className="textEnd pe-12">
                            ₹ {Utils.getFormattedNumber(footerTotal.amount)}
                          </td>
                          <td></td>
                        </tr>
                        {/* 
                          <tr>
                            <td colSpan={12}>
                              <Divider className="my-5 grayBg" />
                            </td>
                          </tr> */}
                      </>
                    ) : (
                      // <tfoot>
                      <tr>
                        <td
                          colSpan={13}
                          style={{
                            textAlign: "center",
                            width: "100%",
                            paddingTop: 10,
                          }}
                        >
                          No Items
                        </td>
                      </tr>
                      // </tfoot>
                    )}
                    {/* Show Include Other Expenses */}
                    {showFreightInsurance && (
                      <>
                        <Form.List name="freightInsLedgers">
                          {(fields, { add, remove, move }) => {
                            return (
                              <>
                                {ledgerconfirmation && (
                                  <ConfirmationModal
                                    open={ledgerconfirmation}
                                    text={
                                      "Do you really want to delete this item"
                                    }
                                    onNo={() => setLedgerConfirmation(false)}
                                    onYes={() => {
                                      remove(deleteLedgerList);
                                      setLedgerLength(fields.length - 2);
                                      handleDeleteRowFreightAndInsurance();
                                    }}
                                  />
                                )}
                                {fields.map(
                                  ({ key, name, ...restField }, index) => {
                                    return (
                                      <tr key={key}>
                                        <td>{index + 1}</td>
                                        <td></td>

                                        <td>
                                          <Form.Item
                                            name={[name, "id"]}
                                            className="m-0"
                                            {...restField}
                                          >
                                            <Select
                                              style={{
                                                backgroundColor: "#FFFFFF",
                                                width: "100%",
                                              }}
                                              bordered={false}
                                              // variant="filled"
                                              labelInValue
                                              disabled={inputFieldDisable}
                                              placeholder="Search for Other Expenses"
                                              showSearch
                                              optionFilterProp="children"
                                              filterOption={false}
                                              // onSearch={}
                                              onChange={(e: any) => {
                                                if (fields.length === index + 1)
                                                  add();
                                                freightInsLedgersSelect(
                                                  e,
                                                  index
                                                );
                                              }}
                                              options={freightAndInsuranceOptions?.map(
                                                (value: any) => ({
                                                  value: value?.id,
                                                  label: value?.ledgerName,
                                                })
                                              )}
                                            />
                                          </Form.Item>
                                        </td>
                                        <td></td>

                                        <td>
                                          <Form.Item
                                            name={[name, "amount"]}
                                            className="m-0"
                                            {...restField}
                                            // initialValue={0.0}
                                          >
                                            <InputNumber
                                              variant="filled"
                                              placeholder="rate"
                                              min={0}
                                              step={0.01}
                                              max={
                                                Math.round(
                                                  ledgerDeatils[index]?.amount *
                                                    100
                                                ) / 100
                                              }
                                              style={{
                                                width: "100%",

                                                // marginLeft: "10px",
                                                backgroundColor: "#FFFFFF",
                                              }}
                                              controls={false}
                                              className="inputNumberTextEnd"
                                              onChange={() => {
                                                handleLedgerNetAmount(index);
                                              }}
                                            />
                                          </Form.Item>
                                        </td>
                                        {(footerTotal.discount > 0 ||
                                          updateTimeData.tradeDiscount) && (
                                          <>
                                            <td></td>
                                            <td></td>
                                          </>
                                        )}
                                        <td>
                                          <Form.Item
                                            name={[name, "gstRate"]}
                                            className="m-0"
                                            {...restField}
                                          >
                                            <Select
                                              // variant="filled"
                                              disabled={inputFieldDisable}
                                              labelInValue
                                              style={{
                                                width: "100%",
                                                backgroundColor: "#FFFFFF",
                                              }}
                                              bordered={false}
                                              placeholder={"%"}
                                              popupMatchSelectWidth={false}
                                              onChange={() => {
                                                handleLedgerNetAmount(index);
                                              }}
                                              options={gstOption?.map(
                                                (gst: any) => ({
                                                  // key: gst.id,
                                                  // value: gst.rate,
                                                  // label: gst.name,
                                                  value: gst?.id,
                                                  title: gst?.rate,
                                                  label: gst?.name,
                                                })
                                              )}
                                            />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <Form.Item
                                            className="m-0 inputNumberTextEnd"
                                            name={[name, "gstAmount"]}
                                            {...restField}
                                          >
                                            <InputNumber
                                              // variant="filled"
                                              step={0.01}
                                              disabled
                                              style={{
                                                width: "100%",
                                                backgroundColor: "#FFFFFF",
                                              }}
                                              onChange={() => {
                                                handleLedgerNetAmount(index);
                                              }}
                                              bordered={false}
                                            />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <div>
                                            <Form.Item
                                              name={[name, "totalAmount"]}
                                              className="m-0"
                                              {...restField}
                                              initialValue={0.0}
                                            >
                                              <InputNumber
                                                // disabled
                                                variant="filled"
                                                style={{
                                                  width: "100%",
                                                  backgroundColor: "#FFFFFF",
                                                }}
                                                className="inputNumberTextEnd"
                                                controls={false}
                                                step={0.01}
                                                onChange={() => {
                                                  onLedgerTotalAmountChange(
                                                    index
                                                  );
                                                }}
                                              />
                                            </Form.Item>
                                          </div>
                                        </td>
                                        <td>
                                          <img
                                            className="cursor mx-1"
                                            onClick={() => {
                                              setDeleteLedgerList(name);
                                              setLedgerConfirmation(true);
                                            }}
                                            src={deleteImg}
                                            alt="Delete"
                                          />
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                              </>
                            );
                          }}
                        </Form.List>
                        {ledgerLength > 0 && (
                          <tr>
                            <td colSpan={3} className="textEnd pe-14">
                              Sub Total
                            </td>
                            <td></td>
                            <td className="textEnd pe-12">
                              ₹{" "}
                              {Utils.getFormattedNumber(
                                footerLedger.ledgerAmount
                              )}
                            </td>
                            {(footerTotal.discount > 0 ||
                              updateTimeData.tradeDiscount) && (
                              <>
                                <td></td>
                                <td></td>
                              </>
                            )}
                            {isGSTEnabled && (
                              <>
                                <td></td>
                                <td className="textEnd pe-12">
                                  ₹{" "}
                                  {Utils.getFormattedNumber(
                                    footerLedger.ledgerGstAmount
                                  )}
                                </td>
                              </>
                            )}
                            <td className="textEnd pe-12">
                              ₹{" "}
                              {Utils.getFormattedNumber(
                                footerLedger.ledgerTotalAmount
                              )}
                            </td>
                            <td></td>
                          </tr>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
              {/* ) : (
                <div style={{ textAlign: "center" }}>No Invoice Found</div>
              )} */}

              <Row className="p-10">
                <Col xl={6} lg={8} md={24} xs={24} className="pt-10">
                  <Form.Item name="narration" className="m-0">
                    <TextArea placeholder="Write Narration" rows={4} />
                  </Form.Item>

                  <div style={{ marginTop: "10px" }}>
                    <Button
                      style={{ backgroundColor: "ButtonFace" }}
                      onClick={() => {
                        setOpenModel({
                          type: "termsAndConditionsModal",
                          param: { open: true },
                        });
                      }}
                    >
                      View and edit Terms and Conditions
                    </Button>
                  </div>

                  <Form.Item
                    name="attachments"
                    valuePropName="fileList"
                    getValueFromEvent={(e) => {
                      return e.fileList;
                    }}
                    // className="m-0"
                    style={{ paddingTop: "10px", margin: "0px" }}
                  >
                    <Upload
                      multiple
                      // accept={".png, .jpg, .jpeg, .pdf"}
                      // accept={".png, .jpg, .jpeg"}
                      beforeUpload={() => {
                        return false;
                      }}
                      onRemove={(e: any) => {
                        if (creditNoteId && e?.id) {
                          setDeleteUploadId([...deleteUploadId, e?.id]);
                        }
                      }}
                      onPreview={(e: any) => {
                        console.log("preview", e);
                        if (creditNoteId && e?.path) {
                          setPreviewImage({
                            isBaseConvert: false,
                            path: e,
                          });
                        } else if (e.type?.split("/")[0] === "image") {
                          setPreviewImage({
                            isBaseConvert: true,
                            path: e,
                          });
                        }
                      }}
                    >
                      <Button
                        icon={<UploadOutlined />}
                        style={{ backgroundColor: "ButtonFace" }}
                      >
                        Upload
                      </Button>
                    </Upload>
                  </Form.Item>
                </Col>

                <Col xl={10} xs={0}></Col>

                <Col xl={8} lg={24} md={24} xs={24}>
                  <Row gutter={[0, 8]}>
                    <Col lg={9} span={9}>
                      <span>
                        {splitPathInVchType === Voucher_Type.CreditNote
                          ? "Selling Price"
                          : "Purchase price"}
                      </span>
                    </Col>
                    <Col lg={15} span={15} className="textEnd">
                      <span>
                        ₹ {Utils.getFormattedNumber(footerTotal.taxableAmount)}
                      </span>
                    </Col>
                    {(footerTotal.discount > 0 ||
                      updateTimeData.tradeDiscount) && (
                      <>
                        <Col lg={9} span={9}>
                          <span>Discount</span>
                        </Col>
                        <Col lg={15} span={15} className="textEnd">
                          <span>
                            ₹ {Utils.getFormattedNumber(footerTotal.discount)}
                          </span>
                        </Col>
                      </>
                    )}
                    {showFreightInsurance && (
                      <>
                        <Col lg={9} span={9}>
                          <span>Other Expenses</span>
                        </Col>
                        <Col lg={15} span={15} className="textEnd">
                          <span>
                            ₹{" "}
                            {Utils.getFormattedNumber(
                              footerLedger.ledgerAmount
                            )}
                          </span>
                        </Col>
                      </>
                    )}
                    {(companySettings?.setting?.gstDetails?.scheme ===
                      CompanyGSTTypes.Regular ||
                      updateTimeData.isGSTVoucher) && (
                      <>
                        {isStateSame ? (
                          <>
                            <Col lg={9} span={9}>
                              <span>CGST</span>
                            </Col>
                            <Col lg={15} span={15} className="textEnd">
                              <span>
                                ₹{" "}
                                {Utils.getFormattedNumber(
                                  (footerTotal.gst +
                                    footerLedger.ledgerGstAmount) /
                                    2
                                )}
                              </span>
                            </Col>
                            <Col lg={9} span={9}>
                              <span>SGST</span>
                            </Col>
                            <Col lg={15} span={15} className="textEnd">
                              <span>
                                ₹{" "}
                                {Utils.getFormattedNumber(
                                  (footerTotal.gst +
                                    footerLedger.ledgerGstAmount) /
                                    2
                                )}
                              </span>
                            </Col>
                          </>
                        ) : currentCustomerState ? (
                          <>
                            <Col lg={9} span={9}>
                              <span>IGST</span>
                            </Col>
                            <Col lg={15} span={15} className="textEnd">
                              <span>
                                ₹{" "}
                                {Utils.getFormattedNumber(
                                  footerTotal.gst + footerLedger.ledgerGstAmount
                                )}
                              </span>
                            </Col>
                          </>
                        ) : (
                          <></>
                        )}

                        <Col lg={15} span={15}>
                          <Row>
                            <Col lg={14} span={14}>
                              <Radio.Group
                                disabled
                                onChange={(e) => {
                                  setTdsOrTcs(e.target.value);
                                  handleAllTheTotalValues();
                                }}
                                value={tdsOrTcs}
                              >
                                <Radio value={0}>TDS</Radio>
                                <Radio value={1}>TCS</Radio>
                              </Radio.Group>
                            </Col>
                            <Col lg={10} span={10}>
                              {tdsOrTcs === 1 ? (
                                <Form.Item name="tcsRate" className="m-0">
                                  <Select
                                    className="variantFilledSelect"
                                    disabled
                                    size="small"
                                    dropdownRender={(menu) => (
                                      <>
                                        {menu}
                                        <div
                                          className=" ps-6"
                                          // onClick={() => setTcsModelOpen(true)}
                                        >
                                          TCS Details
                                        </div>
                                      </>
                                    )}
                                    onSelect={() => handleAllTheTotalValues()}
                                    options={[
                                      { value: 0, label: "None" },
                                      { value: 0.1, label: "0.1 %" },
                                    ]}
                                  />
                                </Form.Item>
                              ) : tdsOrTcs === 0 ? (
                                <Form.Item name="tdsRate" className="m-0">
                                  <Select
                                    disabled
                                    size="small"
                                    dropdownRender={(menu) => (
                                      <>
                                        {menu}
                                        <div
                                          className=" ps-6"
                                          // onClick={() => setTdsModelOpen(true)}
                                        >
                                          TDS Details
                                        </div>
                                      </>
                                    )}
                                    onChange={() => handleAllTheTotalValues()}
                                    // options={TdsOptions?.map((itm: any) => ({
                                    //   value: itm?.value,
                                    //   label: itm?.label,
                                    // }))}
                                  />
                                </Form.Item>
                              ) : (
                                <></>
                              )}
                            </Col>
                          </Row>
                        </Col>
                        {tdsOrTcs === 1 ? (
                          <Col lg={9} span={9} className="textEnd">
                            <span>₹ {Utils.getFormattedNumber(totalTcs)}</span>
                          </Col>
                        ) : tdsOrTcs === 0 ? (
                          <Col lg={9} span={9} className="textEnd">
                            <span>₹ {Utils.getFormattedNumber(totalTds)}</span>
                          </Col>
                        ) : (
                          <></>
                        )}
                      </>
                    )}

                    {(cashDiscountRs !== 0 || updateTimeData.cashDiscount) && (
                      <>
                        <Col lg={6} span={6}>
                          <span>Cash Discount</span>
                        </Col>

                        <Col lg={4} span={4}>
                          <Form.Item
                            // style={{flex:'end'}}
                            name="cashDiscountDrop"
                            className="inputBoxMb"
                          >
                            <Select
                              size="small"
                              options={[
                                {
                                  value: DiscountType.PercentageDiscount,
                                  label: "%",
                                },
                                {
                                  value: DiscountType.RupeeDiscount,
                                  label: "₹",
                                },
                              ]}
                              onChange={() => hangleCashDisCount()}
                            />
                          </Form.Item>
                        </Col>

                        <Col lg={5} span={5}>
                          <Form.Item
                            className="discountInput inputBoxMb textEnd"
                            name="cashDiscount"
                          >
                            <InputNumber
                              step={0.01}
                              controls={false}
                              className="inputNumberTextEnd"
                              size="small"
                              placeholder="0.0"
                              min={0.0}
                              max={
                                form.getFieldValue("cashDiscountDrop") ===
                                DiscountType.PercentageDiscount
                                  ? 100
                                  : Utils.getNumber(
                                      footerTotal.amount +
                                        footerLedger.ledgerTotalAmount
                                    )
                              }
                              style={{
                                width: "100%",
                                // borderBottom: "1px solid black"
                                // margin: "0px 30px 0px 10px",
                              }}
                              onChange={() => hangleCashDisCount()}
                            />
                          </Form.Item>
                        </Col>

                        <Col lg={9} span={9} className="textEnd">
                          <span>
                            ₹ {Utils.getFormattedNumber(cashDiscountRs)}
                          </span>
                        </Col>

                        {(cashDiscountRs !== 0 ||
                          updateTimeData.cashDiscount) && (
                          <>
                            <Col lg={6} span={6}>
                              <span>Total</span>
                            </Col>

                            <Col lg={18} span={18} className="textEnd">
                              <span>
                                ₹{" "}
                                {Utils.getFormattedNumber(
                                  footerTotal.amount +
                                    footerLedger.ledgerTotalAmount -
                                    cashDiscountRs
                                )}
                              </span>
                            </Col>
                          </>
                        )}
                      </>
                    )}
                  </Row>
                  <Divider className="my-5 grayBg" />
                  <Row gutter={[12, 2]}>
                    {(settingControl.autoRoundOff ||
                      updateTimeData.roundOff) && (
                      <>
                        <Col lg={5} span={5}>
                          <span>Round off</span>
                        </Col>

                        <Col lg={4} span={4}>
                          <Form.Item
                            valuePropName="checked"
                            name="roundoffCheckbox"
                            className="inputBoxMb"
                          >
                            <Checkbox
                              onChange={(e: any) => {
                                const roundOffAmount = handleRoundoff(
                                  Utils.getNumber(footerTotal.amount) +
                                    Utils.getNumber(
                                      footerLedger.ledgerTotalAmount
                                    ),
                                  e.target.checked,
                                  settingControl.roundOffType
                                );
                                // console.log("roundOffAmount", roundOffAmount);

                                form.setFieldValue("roundOff", roundOffAmount);
                                setRoundoffAmount(roundOffAmount);
                              }}
                            />
                          </Form.Item>
                        </Col>

                        <Col
                          lg={15}
                          span={15}
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Form.Item
                            className="discountInput inputBoxMb textEnd"
                            name="roundOff"
                          >
                            <InputNumber
                              step={0.01}
                              className="inputNumberTextEnd"
                              size="small"
                              controls={false}
                              placeholder="0.0"
                              // bordered={false}
                              // controls={false}
                              style={{
                                width: "100%",
                              }}
                              onChange={(e: any) => {
                                setRoundoffAmount(e);
                              }}
                            />
                          </Form.Item>
                        </Col>
                      </>
                    )}

                    <Col lg={11} style={{ fontWeight: 600, fontSize: "16px" }}>
                      <span>
                        {splitPathInVchType === Voucher_Type.CreditNote
                          ? "Total Invoice Value"
                          : "Total Bill Value"}
                      </span>
                    </Col>
                    {/* <Col lg={8} className=" fw-500">
                GBP
              </Col> */}
                    <Col
                      lg={13}
                      style={{ fontWeight: 600, fontSize: "16px" }}
                      className="textEnd"
                    >
                      <span>
                        ₹{" "}
                        {Utils.getFormattedNumber(
                          Utils.getNumber(footerTotal.amount) +
                            Utils.getNumber(footerLedger.ledgerTotalAmount) +
                            roundOffAmount -
                            Math.round(cashDiscountRs * 100) / 100
                        )}
                      </span>
                    </Col>
                  </Row>
                  {/* <Divider
                style={{
                  borderBottom: "2px solid rgb(203, 203, 203)",
                }}
                className="my-5"
              /> */}

                  <Row justify="end" className="mt-10">
                    <Col>
                      <Button
                        onClick={() => {
                          // splitPathInVchType === Voucher_Type.CreditNote
                          //   ? navigate(
                          //       `/accounts/clients/${currentClient?.id}/sales/creditNotes`
                          //     )
                          //   : navigate(
                          //       `/accounts/clients/${currentClient?.id}/purchase/debitNote`
                          //     );
                          if (
                            Utils.getNumber(footerTotal.amount) +
                              Utils.getNumber(footerLedger.ledgerTotalAmount) >
                              0 &&
                            !creditNoteId
                          ) {
                            addReturnNote(false, true);
                          }
                          navigate(-1);
                        }}
                      >
                        Cancel
                      </Button>
                    </Col>
                    <Col className="ps-14">
                      <Form.Item>
                        <Button
                          loading={saveButtonLoading}
                          //   totalPaymentIn >
                          //   currentBalance +
                          //     (editPayment.id === openLedgerDrawer.id
                          //       ? editPayment.balance
                          //       : 0) &&
                          // splitPathInVchType === Voucher_Type.Payment
                          //   ? true
                          //   : false

                          // disabled={
                          // invoiceTotalDueAmount +
                          //   (editInvoice.voucherId === currentCreditNoteId
                          //     ? editParty.balance
                          //     : 0) >=
                          // Utils.getNumber(footerTotal.amount) +
                          //   Utils.getNumber(footerLedger.ledgerTotalAmount)
                          //   ? false
                          //   : true
                          // }
                          type="primary"
                          onClick={() => addReturnNote()}
                        >
                          {creditNoteId ? "Update" : "Save"}
                        </Button>
                      </Form.Item>
                    </Col>

                    {/* <Col className="ps-14">
                  <Form.Item>
                    <Button
                      // loading={saveNewLoading}
                      onClick={() => {
                        addSalesInvoice(true);
                      }}
                      type="primary"
                    >
                      Save & New
                    </Button>
                  </Form.Item>
                </Col> */}
                  </Row>
                </Col>
              </Row>
              {/* <Row justify="end" className="mt-10">
              <Col>
                {invoiceTotalDueAmount +
                  +(editInvoice.voucherId === currentCreditNoteId
                    ? editParty.balance
                    : 0) >=
                Utils.getNumber(footerTotal.amount) +
                  Utils.getNumber(footerLedger.ledgerTotalAmount) ? (
                  <></>
                ) : (
                  <Tag bordered={true} color="red" className="m-5">
                    Credit note can't be created as Invoice pending amount is{" "}
                    {invoiceTotalDueAmount +
                      (editInvoice.voucherId === currentCreditNoteId
                        ? editParty.balance
                        : 0)}{" "}
                    only.
                  </Tag>
                )}
              </Col>
            </Row> */}
            </Spin>
          </Form>
        </Spin>
      </Card>
      {openModel.type === "addLedger" && (
        <AddLedger
          openDrawer={openModel?.param.open}
          id={currentCustomerId}
          setOpenDrawer={() => setOpenModel({ type: "", param: "" })}
          setTrigger={() => setTrigger(!trigger)}
          setAddLedgerResponse={setAddLedgerResponse}
        />
      )}
      {/* {openModel.type === "addCreditNoteReferenceModel" && (
        <AddSalesCreditNotesReferenceModelNew
          open={true}
          onCancel={() => setOpenModel({ type: "", param: "" })}
          invoiceId={creditNoteId}
          voucherType={splitPathInVchType}
          currentCreditNoteDetails={currentCreditNoteDetails}
          newPartyRecepitDetails={newPartyRecepitDetails}
          oldPartyRecepitDetails={oldPartyRecepitDetails}
        />
      )} */}
      {openModel.type === "viewSalesInvoice" && (
        <ViewSalesInvoice
          open={true}
          invVoucharType={
            splitPathInVchType === Voucher_Type.CreditNote
              ? Voucher_Type.SalesVoucher
              : Voucher_Type.CreditNote
          }
          onCancel={() => setOpenModel({ type: "", param: "" })}
          invoiceId={currentCreditNoteId}
        />
      )}
      {previewImage.path && (
        <DocumentPreview
          open={true}
          onCancel={() =>
            setPreviewImage({
              path: "",
              isBaseConvert: false,
              // uploadType: "",
            })
          }
          documentDetails={previewImage}
        />
      )}
      {openModel.type === "termsAndConditionsModal" && (
        <TermsAndConditionModal
          open={openModel.param.open}
          onCancel={() => setOpenModel({ type: "", param: { open: false } })}
          setTermsAndConditionField={setTermsAndConditionField}
          termsAndConditionField={termsAndConditionField}
          voucherType={
            splitPathInVchType === Voucher_Type.CreditNote
              ? Voucher_Type.CreditNote
              : Voucher_Type.DebitNote
          }
        />
      )}
    </Fragment>
  );
};

export default AddSalesCreditNotesNew;
