import {
  Button,
  Col,
  DatePicker,
  Divider,
  InputNumber,
  Row,
  Skeleton,
  Switch,
  Table,
  Tooltip,
  notification,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { Select } from "antd";
import type { SelectProps, TableColumnsType } from "antd";
import AdjustBankBalanceModel, {
  AdjustBankType,
} from "./Banks/AdjustBankBalanceModel";
import LedgerService, {
  SearchLedgerByGroupsTypes,
} from "../../Services/LedgerService";
import { useClient } from "../../Context/ClientContext";
import VoucherServices, {
  Party_Tax,
  ReconcileTypes,
  VoucherFilterStatus,
  Voucher_Type,
} from "../../Services/VoucherServices";
import { dayjs } from "../../../Utilities/dayjs";
import { useTheme } from "../../Context/ThemeContext";
import {
  VoucherDetailsData,
  voucherType,
} from "../Reports/VoucherDetailsByLedger";
import { DeleteTwoTone, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { AddLedger } from "../Master/Ledger/AddLedger";
import UploadCSVfile from "./UploadCSVfile";
import ViewPaymentIn from "../Sales/ViewPaymentIn";
import ViewSalesInvoice from "../Sales/ViewSalesInvoice";
import ViewCreditNote from "../Sales/ViewCreditNote";
import ViewJournal from "../Journals/ViewJournal";
import { Utils } from "../../../Utilities/Utils";
import { ConfirmationModal } from "../../Common/ConfirmationModal";
import { notificationContext } from "../../Common/PageRoute";
import { useKeyboardShortcuts } from "../../Common/KeyboardShortcuts ";

const BankReconciliation: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const options: SelectProps["options"] = [];
  const { currentClient, companySettings } = useClient();
  const { themeData } = useTheme();
  const { openNotification } = useContext(notificationContext);
  const [openModel, setOpenModel] = React.useState<{
    type: string;
    param: any;
  }>({
    type: "",
    param: {},
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [bank, setBank] = React.useState<{
    id: string;
    name: string;
    partyOrTax: Party_Tax;
  }>({
    id: "",
    name: "",
    partyOrTax: Party_Tax.other,
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ledgerList, setLedgerList] = React?.useState<any>();
  const [ledgerId, setLeadgerId] = React?.useState<string>("");
  const [loading, setLoading] = React?.useState(false);
  const [voucherList, setVoucherList] = React?.useState<any>([]);
  const [pageSize, setPageSize] = React.useState<number>(50);
  const [page, setPage] = React.useState(1);
  const [searchCustomer, setSearchCustomer] = React.useState<string>("");
  const [showSaveButton, setShowSaveButton] = React.useState<boolean>(false);
  const [saveDisabled, setSaveDisabled] = React.useState<boolean>(true);
  const [openingBalance, setOpeningBalance] = React.useState<number>(0);
  const [displayDate, setDisplayDate] = React.useState<any | undefined>();

  const [filterDate, setFilterDate] = React.useState<any>({
    from: "",
    to: "",
  });
  const [trigger, setTrigger] = React?.useState(false);
  const [type, setType] = React?.useState<any>();
  const [refresh, setRefresh] = React?.useState(false);
  const [filter, setfilter] = React.useState<any>({
    sortCol: "",
    sortDir: "",
  });
  const [id, setId] = React?.useState<any>();
  const navigate = useNavigate();

  const [selectedOption, setSelectedOption] = React?.useState<number>(2);
  const [inputValue, setInputValue] = React?.useState<number>(0);
  // const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [paymentId, setPaymentId] = React.useState<string>("");
  // const [open, setOpen] = React.useState<string>("");
  // const [creditModalOpen, setCreditModalOpen] = React.useState<boolean>(false);
  // const [journalModal, setJournalModal] = React.useState<boolean>(false);
  const [deleteVoucher, setDeleteVoucher] = React.useState<{
    id: string;
    isRev: boolean;
    number: string;
  }>({
    id: "",
    isRev: false,
    number: "",
  });
  const [invoiceStatus, setInvoiceStatus] = React.useState<VoucherFilterStatus>(
    VoucherFilterStatus.Active
  );
  // const [confirmation, setConfirmation] = React.useState<boolean>(false);

  const handleSelectChange = (e: any) => {
    setBank({
      id: e.value,
      name: e.lable,
      partyOrTax: e.title,
    });
    setLeadgerId(e.value);
  };

  const handleChange = (val: any) => {
    setSelectedOption(val);
  };

  React?.useEffect(() => {
    getLedgersByGroupName();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  useEffect(() => {
    GetLastReconcileHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ledgerId]);

  const GetLastReconcileHistory = async () => {
    try {
      console.log("helo");
      if (ledgerId && currentClient?.id) {
        const reconcileHistoryResponse =
          await VoucherServices.GetLastReconcileHistory(
            currentClient?.id,
            ledgerId
          );
        if (reconcileHistoryResponse.status) {
          setDisplayDate(reconcileHistoryResponse?.result?.closingBalDate);
          setOpeningBalance(reconcileHistoryResponse?.result?.closingBalAmount);
        } else {
          setOpeningBalance(0);
          setDisplayDate(dayjs(""));
        }
      }
    } catch (error) {
      console.log("error", error);
    } finally {
    }
  };

  const getLedgersByGroupName = async () => {
    let res = await LedgerService?.getLedgersByGroupName(
      currentClient?.id,
      SearchLedgerByGroupsTypes?.BankOnly
    );
    if (res) {
      setLedgerList(res);
    }
  };

  const changeReconcileStatus = (voucherId: string, isReconciled: boolean) => {
    const updatedVouchers = voucherList.map((voucher: any) => {
      if (voucher.id === voucherId) {
        return {
          ...voucher,
          isReconciled: isReconciled
            ? ReconcileTypes.Reconciled
            : ReconcileTypes.Nonreconciled,
        };
      }
      return voucher;
    });
    setVoucherList(updatedVouchers);
  };

  const postReconcileVoucher = async () => {
    // debugger;
    try {
      console.log("voucherList", voucherList);

      setLoading(true);
      console.log(
        "filterDate?.to",
        filterDate?.to,
        new Date(filterDate?.to),
        new Date()
      );

      const reconcileData = {
        ledgerId: ledgerId,
        openingBalDate:
          displayDate > (companySettings?.period?.from || 0)
            ? dayjs(displayDate).add(1, "day").startOf("day").toISOString()
            : dayjs(companySettings?.period?.from).toISOString(),
        closingBalDate: dayjs(filterDate?.to || new Date()).toISOString(),
        openingBalAmount: openingBalance,
        debitTotalAmount: totalReconciledDebitAmount,
        creditTotalAmount: totalReconciledCreditAmount,
        voucherList: voucherList
          .filter((v: any) => v.isReconciled === ReconcileTypes.Reconciled)
          .map((v: any) => ({
            name: v.number,
            id: v.id,
          })),
      };
      let res = await VoucherServices?.postReconcileVoucher(
        currentClient?.id,
        reconcileData
      );
      if (res?.status) {
        notification.success({
          message: "",
          description: "saved successfully.",
          placement: "topRight",
        });
        setInputValue(0);
        setRefresh((x: boolean) => !x);
      } else {
        notification.error({
          message: "",
          description: res?.message || "An error occurred while saving.",
          placement: "topRight",
        });
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  const columns: TableColumnsType<VoucherDetailsData> = [
    {
      title: "Date",
      dataIndex: "date",
      ellipsis: true,
      //   width: width <= 1440 ? "10%" : "9%",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
          }}
          title={false}
        >
          {record?.date}
        </Skeleton>
      ),
    },
    {
      title: "Account Name",
      dataIndex: "ledgerName",
      width: "25%",
      align: "left",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.ledgerName}
        </Skeleton>
      ),
    },
    {
      title: "Ref.No",
      dataIndex: "number",
      ellipsis: true,
      //   width: width <= 1440 ? "9%" : "8%",
      align: "left",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          <span
            style={{
              color: "#395cd2",
              cursor: "pointer",
            }}
            onClick={() => {
              if (record?.voucherType === Voucher_Type.Receipt) {
                setOpenModel({
                  type: "viewPayment",
                  param: "",
                });
                setPaymentId(record?.id);
                setType(record?.voucherType);
              } else if (record?.voucherType === Voucher_Type.Payment) {
                setOpenModel({
                  type: "viewPayment",
                  param: "",
                });
                setPaymentId(record?.id);
                setType(record?.voucherType);
              } else if (record?.voucherType === Voucher_Type.SalesVoucher) {
                setOpenModel({
                  type: "viewInvoice",
                  param: "",
                });
                setPaymentId(record?.id);
                setType(record?.voucherType);
              } else if (record?.voucherType === Voucher_Type.PurchaseVoucher) {
                setOpenModel({
                  type: "viewInvoice",
                  param: "",
                });
                setPaymentId(record?.id);
                setType(record?.voucherType);
              } else if (record?.voucherType === Voucher_Type.CreditNote) {
                setOpenModel({
                  type: "returnNote",
                  param: "",
                });
                setPaymentId(record?.id);
                setType(record?.voucherType);
              } else if (record?.voucherType === Voucher_Type.DebitNote) {
                setOpenModel({
                  type: "returnNote",
                  param: "",
                });
                setPaymentId(record?.id);
                setType(record?.voucherType);
              } else if (record?.voucherType === Voucher_Type.Journal) {
                setOpenModel({
                  type: "journal",
                  param: "",
                });
                setPaymentId(record?.id);
                setType(record?.voucherType);
              } else if (record?.voucherType === Voucher_Type.Contra) {
                setPaymentId(record?.id);
                setOpenModel({
                  type: "adjustBankBalance",
                  param: { disable: true },
                });
              }
            }}
          >
            {record?.number}
          </span>
        </Skeleton>
      ),
    },
    {
      title: "Voucher",
      dataIndex: "voucherType",
      ellipsis: true,
      //   width: width <= 1440 ? "10%" : "9%",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
          }}
          title={false}
        >
          {voucherType[record?.voucherType]}
        </Skeleton>
      ),
    },
    {
      title: "Debit",
      dataIndex: "amount",
      ellipsis: true,
      //   width: width <= 1440 ? "9%" : "8%",
      align: "right",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
            style: { display: "flex", justifyContent: "end" },
          }}
          title={false}
        >
          {Utils.getFormattedNumber(record?.amount > 0 ? record?.amount : 0)}
        </Skeleton>
      ),
    },
    {
      title: "Credit",
      dataIndex: "amount",
      ellipsis: true,
      //   width: width <= 1440 ? "9%" : "8%",
      align: "right",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
            style: { display: "flex", justifyContent: "end" },
          }}
          title={false}
        >
          {Utils.getFormattedNumber(
            Math.abs(record?.amount < 0 ? record?.amount : 0)
          )}
        </Skeleton>
      ),
    },
    {
      title: "Is Reconciled",
      dataIndex: "isReconciled",
      ellipsis: true,
      //   width: width <= 1440 ? "9%" : "8%",
      align: "right",
      render: (checked, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
            style: { display: "flex", justifyContent: "end" },
          }}
          title={false}
        >
          {
            <>
              <Switch
                // checked={checked === ReconcileTypes.Reconciled}
                size="small"
                defaultChecked={checked === ReconcileTypes.Reconciled}
                onChange={(isChecked) => {
                  changeReconcileStatus(record?.id, isChecked);
                  // console.log("isChecked", isChecked, voucherList);
                }}
              />
            </>
          }
        </Skeleton>
      ),
    },
    {
      title: "Action",
      dataIndex: "id",
      ellipsis: true,
      align: "center",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
            style: { display: "flex", justifyContent: "end" },
          }}
          title={false}
        >
          <div
            style={{ display: "flex", justifyContent: "space-evenly", gap: 1 }}
          >
            <Tooltip title="Edit" color="#444653" placement="top">
              <EditOutlined
                twoToneColor="#444653"
                onClick={() => {
                  record?.voucherType === Voucher_Type?.Receipt
                    ? navigate(
                        `/accounts/clients/${currentClient?.id}/sales/editReceipt/${record?.id}`
                      )
                    : record?.voucherType === Voucher_Type.Payment
                    ? navigate(
                        `/accounts/clients/${currentClient?.id}/purchase/editPaymentOut/${record.id}`
                      )
                    : record?.voucherType === Voucher_Type.SalesVoucher
                    ? navigate(
                        `/accounts/clients/${currentClient?.id}/sales/editInvoice/${record?.id}`,
                        {
                          state: { copy: false },
                        }
                      )
                    : record?.voucherType === Voucher_Type.PurchaseVoucher
                    ? navigate(
                        `/accounts/clients/${currentClient?.id}/sales/editInvoice/${record?.id}`,
                        {
                          state: { copy: false },
                        }
                      )
                    : record?.voucherType === Voucher_Type.CreditNote
                    ? navigate(
                        `/accounts/clients/${currentClient?.id}/sales/editCreditNote/${record?.id}`
                      )
                    : record?.voucherType === Voucher_Type.DebitNote
                    ? navigate(
                        `/accounts/clients/${currentClient?.id}/sales/editCreditNote/${record?.id}`
                      )
                    : record?.voucherType === Voucher_Type.Journal
                    ? navigate(
                        `/accounts/clients/${currentClient?.id}/journals/${record?.id}`
                      )
                    : setId(record?.id);
                  setType(AdjustBankType.BankReconciliation);
                  setOpenModel({
                    type: "adjustBankBalance",
                    param: {
                      id: "",
                      type: "",
                    },
                  });
                  // Navigate
                  // setId(record?.id);
                  // setOpenModel({
                  //   type: "adjustBankBalance",
                  //   param: {
                  //     id: "",
                  //     type: "",
                  //   },
                  // });
                }}
              />
            </Tooltip>
            {/* {invoiceStatus !== VoucherFilterStatus.Deleted && (
              <Tooltip color="red" title="Delete" placement="top">
                <DeleteTwoTone
                  className="ca-delete-btn cursor"
                  twoToneColor="#ff879d"
                  onClick={() => {
                    setOpenModel({
                      type: "delete",
                      param: "",
                    });
                    setPaymentId(record?.id);
                    setDeleteVoucher({
                      ...deleteVoucher,
                      number: record?.number,
                    });
                  }}
                />
              </Tooltip>
            )} */}
          </div>
        </Skeleton>
      ),
    },
  ];

  React.useEffect(() => {
    if (currentClient?.id) {
      let reconcileType;
      // Use ternary conditional operator to determine reconcileType based on selectedOption
      reconcileType =
        selectedOption === 1
          ? ReconcileTypes.Reconciled
          : selectedOption === 2
          ? ReconcileTypes?.Nonreconciled
          : ReconcileTypes.Both;

      getList(
        currentClient.id,
        Voucher_Type.All,
        (page - 1) * pageSize,
        pageSize,
        filterDate.from,
        dayjs(filterDate.to).format("DD/MM/YYYY"),
        filter.sortCol,
        filter.sortDir,
        ledgerId,
        reconcileType,
        searchCustomer,
        VoucherFilterStatus.Active
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentClient?.id,
    page,
    pageSize,
    filter.sortCol,
    filter.sortDir,
    searchCustomer,
    filterDate.from,
    filterDate.to,
    ledgerId,
    refresh,
    selectedOption, // Include selectedOption in the dependency array
  ]);

  // deleteVoucher api
  React.useEffect(() => {
    if (deleteVoucher?.id) {
      const DeleteCreditNote = async () => {
        setLoading(true);
        await VoucherServices.deleteVoucher(
          currentClient?.id!,
          deleteVoucher.id
        ).then((res) => {
          setLoading(false);
          if (res?.result) {
            setRefresh(!refresh);
            openNotification(
              "success",
              `Entry deleted successfully ${deleteVoucher.number}`
            );
          }
        });
      };
      DeleteCreditNote();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteVoucher?.id]);

  const getList = async (
    clientId: string,
    Voucher_Type: Voucher_Type,
    start: number,
    length: number,
    fromDate?: string,
    toDate?: string,
    sortCol?: string,
    sortDir?: string,
    partyId?: string,
    IsReconcile?: ReconcileTypes,
    search?: string,
    status?: VoucherFilterStatus
  ) => {
    setLoading(true);
    await VoucherServices.getVoucharList(
      clientId,
      Voucher_Type,
      start,
      length,
      fromDate,
      toDate,
      sortCol,
      sortDir,
      partyId,
      IsReconcile,
      search,
      status
    )
      .then((res: any) => {
        console.log("ress", res);
        if (res?.result?.voucherItems?.length > 0) {
          if (ledgerId) {
            setShowSaveButton(true);
          }
          setVoucherList(
            res?.result?.voucherItems
              ?.map((el: any, index: number) => {
                var arr: any = [];
                //debugger;
                switch (el.voucherType) {
                  case 10:
                    if (
                      el?.eLedger?.find((item: any) => item.id === ledgerId)
                    ) {
                      var tmpLedList = el?.eLedger?.filter(
                        (itm: any) => itm.id === ledgerId
                      );
                      arr.push({
                        ...tmpLedList[0],
                        amount: tmpLedList[0].amount,
                        ledgerName: el?.pLedger[0]?.ledgerName,
                        voucherType: el?.voucherType,
                        id: el?.vId,
                        number: el?.number,
                        isReconciled: el?.isReconciled,
                        date: dayjs(el?.date).format("DD/MM/YYYY"),
                      });
                    } else {
                      // eslint-disable-next-line @typescript-eslint/no-redeclare
                      tmpLedList = el?.pLedger?.filter(
                        (itm: any) => itm.id === ledgerId
                      );
                      arr.push({
                        ...tmpLedList[0],
                        amount: tmpLedList[0].amount,
                        ledgerName: el?.eLedger[0]?.ledgerName,
                        voucherType: el?.voucherType,
                        id: el?.vId,
                        number: el?.number,
                        isReconciled: el?.isReconciled,

                        date: dayjs(el?.date).format("DD/MM/YYYY"),
                      });
                    }
                    break;
                  case 1:
                  case 2:
                  case 3:
                  case 4:
                    if (el?.pLedger?.find((itm: any) => itm?.id === ledgerId)) {
                      arr.push({
                        ...el?.eLedger[0],
                        amount: el?.pLedger[0].amount, //-itm.amount,
                        voucherType: el?.voucherType,
                        id: el?.vId,
                        number: el?.number,
                        isReconciled: el?.isReconciled,
                        date: dayjs(el?.date).format("DD/MM/YYYY"),
                      });
                    } else {
                      if (
                        el?.dLedger?.find((item: any) => item.id === ledgerId)
                      ) {
                        // eslint-disable-next-line @typescript-eslint/no-redeclare
                        var tmpLedList = el?.dLedger?.filter(
                          (itm: any) => itm.id === ledgerId
                        );
                        arr.push({
                          ...tmpLedList[0],
                          amount: tmpLedList[0].amount,
                          ledgerName: el?.pLedger[0]?.ledgerName,
                          voucherType: el?.voucherType,
                          id: el?.vId,
                          number: el?.number,
                          isReconciled: el?.isReconciled,

                          date: dayjs(el?.date).format("DD/MM/YYYY"),
                        });
                      }
                      if (
                        el?.eLedger?.find((item: any) => item.id === ledgerId)
                      ) {
                        // eslint-disable-next-line @typescript-eslint/no-redeclare
                        var tmpLedList = el?.eLedger?.filter(
                          (itm: any) => itm.id === ledgerId
                        );
                        arr.push({
                          ...tmpLedList[0],
                          amount: tmpLedList[0].amount,
                          ledgerName: el?.pLedger[0]?.ledgerName,
                          voucherType: el?.voucherType,
                          id: el?.vId,
                          number: el?.number,
                          isReconciled: el?.isReconciled,

                          date: dayjs(el?.date).format("DD/MM/YYYY"),
                        });
                      }
                      if (
                        el?.sLedger?.find((item: any) => item.id === ledgerId)
                      ) {
                        // eslint-disable-next-line @typescript-eslint/no-redeclare
                        var tmpLedList = el?.sLedger?.filter(
                          (itm: any) => itm.id === ledgerId
                        );
                        arr.push({
                          ...tmpLedList[0],
                          amount: tmpLedList[0].amount,
                          ledgerName: el?.pLedger[0]?.ledgerName,
                          voucherType: el?.voucherType,
                          id: el?.vId,
                          number: el?.number,
                          isReconciled: el?.isReconciled,

                          date: dayjs(el?.date).format("DD/MM/YYYY"),
                        });
                      }
                    }
                    break;
                  case 5:
                  case 6:
                    if (el?.eLedger?.find((itm: any) => itm?.id === ledgerId)) {
                      arr.push({
                        ...el?.pLedger[0],
                        amount: el?.eLedger[0].amount, //-itm.amount,
                        voucherType: el?.voucherType,
                        id: el?.vId,
                        number: el?.number,
                        isReconciled: el?.isReconciled,
                        date: dayjs(el?.date).format("DD/MM/YYYY"),
                      });
                    } else {
                      if (
                        el?.dLedger?.find((item: any) => item.id === ledgerId)
                      ) {
                        // eslint-disable-next-line @typescript-eslint/no-redeclare
                        var tmpLedList = el?.dLedger?.filter(
                          (itm: any) => itm.id === ledgerId
                        );
                        arr.push({
                          ...tmpLedList[0],
                          amount: tmpLedList[0].amount,
                          ledgerName: el?.eLedger[0]?.ledgerName,
                          voucherType: el?.voucherType,
                          isReconciled: el?.isReconciled,
                          id: el?.vId,
                          number: el?.number,
                          date: dayjs(el?.date).format("DD/MM/YYYY"),
                        });
                      }
                      if (
                        el?.pLedger?.find((item: any) => item.id === ledgerId)
                      ) {
                        // eslint-disable-next-line @typescript-eslint/no-redeclare
                        var tmpLedList = el?.pLedger?.filter(
                          (itm: any) => itm.id === ledgerId
                        );
                        arr.push({
                          ...tmpLedList[0],
                          amount: tmpLedList[0].amount,
                          ledgerName: el?.eLedger[0]?.ledgerName,
                          voucherType: el?.voucherType,
                          isReconciled: el?.isReconciled,

                          id: el?.vId,
                          number: el?.number,
                          date: dayjs(el?.date).format("DD/MM/YYYY"),
                        });
                      }
                      if (
                        el?.sLedger?.find((item: any) => item.id === ledgerId)
                      ) {
                        // eslint-disable-next-line @typescript-eslint/no-redeclare
                        var tmpLedList = el?.sLedger?.filter(
                          (itm: any) => itm.id === ledgerId
                        );
                        arr.push({
                          ...tmpLedList[0],
                          amount: tmpLedList[0].amount,
                          ledgerName: el?.eLedger[0]?.ledgerName,
                          voucherType: el?.voucherType,
                          id: el?.vId,
                          isReconciled: el?.isReconciled,

                          number: el?.number,
                          date: dayjs(el?.date).format("DD/MM/YYYY"),
                        });
                      }
                    }
                    break;
                  case 7:
                    if (el?.eLedger?.find((itm: any) => itm?.id === ledgerId)) {
                      arr.push({
                        ...el?.pLedger[0],
                        amount: el?.eLedger[0].amount, //-itm.amount,
                        voucherType: el?.voucherType,
                        id: el?.vId,
                        number: el?.number,
                        isReconciled: el?.isReconciled,
                        date: dayjs(el?.date).format("DD/MM/YYYY"),
                      });
                    } else {
                      if (
                        el?.dLedger?.find((item: any) => item.id === ledgerId)
                      ) {
                        // eslint-disable-next-line @typescript-eslint/no-redeclare
                        var tmpLedList = el?.dLedger?.filter(
                          (itm: any) => itm.id === ledgerId
                        );
                        arr.push({
                          ...tmpLedList[0],
                          amount: tmpLedList[0].amount,
                          ledgerName: el?.eLedger[0]?.ledgerName,
                          voucherType: el?.voucherType,
                          id: el?.vId,
                          number: el?.number,
                          isReconciled: el?.isReconciled,
                          date: dayjs(el?.date).format("DD/MM/YYYY"),
                        });
                      }
                      if (
                        el?.pLedger?.find((item: any) => item.id === ledgerId)
                      ) {
                        // eslint-disable-next-line @typescript-eslint/no-redeclare
                        var tmpLedList = el?.pLedger?.filter(
                          (itm: any) => itm.id === ledgerId
                        );
                        arr.push({
                          ...tmpLedList[0],
                          amount: tmpLedList[0].amount,
                          ledgerName: el?.eLedger[0]?.ledgerName,
                          voucherType: el?.voucherType,
                          id: el?.vId,
                          isReconciled: el?.isReconciled,
                          number: el?.number,
                          date: dayjs(el?.date).format("DD/MM/YYYY"),
                        });
                      }
                      if (
                        el?.sLedger?.find((item: any) => item.id === ledgerId)
                      ) {
                        // eslint-disable-next-line @typescript-eslint/no-redeclare
                        var tmpLedList = el?.sLedger?.filter(
                          (itm: any) => itm.id === ledgerId
                        );
                        arr.push({
                          ...tmpLedList[0],
                          amount: tmpLedList[0].amount,
                          ledgerName: el?.eLedger[0]?.ledgerName,
                          voucherType: el?.voucherType,
                          id: el?.vId,
                          isReconciled: el?.isReconciled,
                          number: el?.number,
                          date: dayjs(el?.date).format("DD/MM/YYYY"),
                        });
                      }
                    }
                    break;
                }
                return arr;
              })
              .flat()
          );
        } else {
          setShowSaveButton(false);
          setVoucherList([]);
        }
        setLoading(false);

        //   setDueAmount(res?.result.totalDueAmount);
        //setNetAmount(        );
        //setNetAmount(res?.result.totalAmount);
        //   setTotalRecords(res?.totalRecords);
        //   setLoading(false);
        // setVoucherDetailsData(res);
      })
      .catch((ex: any) => {
        //   setLoading(false);
        //   setVoucherDetailsData([]);
      });
  };

  const tmpTotal: any = voucherList
    ?.filter((el: any) => el.isReconciled === ReconcileTypes.Reconciled)
    .reduce((acc: number, rcc: any) => acc + rcc?.amount, 0);

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const totalAfterSubtraction = tmpTotal - inputValue;

  // ----- Short-Cut for Open Model -----F
  useKeyboardShortcuts("N", ["shiftKey"], () => {
    setOpenModel({
      type: "CSVmodal",
      param: {
        id: "",
        type: "",
      },
    });
  });

  const [totalDebitAmount, setTotalDebitAmount] = useState(0);
  const [totalCreditAmount, setTotalCreditAmount] = useState(0);
  const [totalBalance, setTotalBalance] = useState(0);
  const [totalReconciledDebitAmount, setTotalReconciledDebitAmount] =
    useState(0);
  const [totalReconciledCreditAmount, setTotalReconciledCreditAmount] =
    useState(0);
  const [totalReconciledBalance, setTotalReconciledBalance] = useState(0);

  useEffect(() => {
    const calculateTotals = () => {
      const totalDebitAmount = Math.abs(
        voucherList.reduce(
          (acc: any, rcc: any) => acc + (rcc?.amount > 0 ? rcc?.amount : 0),
          0
        )
      );
      const totalCreditAmount = Math.abs(
        voucherList.reduce(
          (acc: any, rcc: any) => acc + (rcc?.amount < 0 ? rcc?.amount : 0),
          0
        )
      );
      const totalBalance = totalDebitAmount - totalCreditAmount;

      const reconciledVouchers = voucherList.filter(
        (el: any) => el.isReconciled === ReconcileTypes.Reconciled
      );

      const totalReconciledDebitAmount = Math.abs(
        reconciledVouchers.reduce(
          (acc: any, rcc: any) => acc + (rcc?.amount > 0 ? rcc?.amount : 0),
          0
        )
      );
      const totalReconciledCreditAmount = Math.abs(
        reconciledVouchers.reduce(
          (acc: any, rcc: any) => acc + (rcc?.amount < 0 ? rcc?.amount : 0),
          0
        )
      );
      const totalReconciledBalance =
        totalReconciledDebitAmount - totalReconciledCreditAmount;

      setTotalDebitAmount(totalDebitAmount);
      setTotalCreditAmount(totalCreditAmount);
      setTotalBalance(totalBalance);
      setTotalReconciledDebitAmount(totalReconciledDebitAmount);
      setTotalReconciledCreditAmount(totalReconciledCreditAmount);
      setTotalReconciledBalance(totalReconciledBalance);
    };
    calculateTotals();
  }, [voucherList]);

  useEffect(() => {
    if (ledgerId) {
      if (totalReconciledBalance === inputValue && inputValue !== 0) {
        setSaveDisabled(false);
      } else {
        setSaveDisabled(true);
      }
    }
  }, [totalReconciledBalance, inputValue, ledgerId]);

  return (
    <>
      <div
        style={{
          // borderRadius: "8px",
          marginTop: 7,
          // backgroundColor: "white",
          padding: "10px 0px 0px 0px",
          // boxShadow: "0px 0px 10px 0px #96A1C84D",
        }}
      ></div>

      <div
        style={{
          marginTop: "8px",
          // padding: "20px 0px 0px 0px",
          backgroundColor: "white",
          // minHeight: window.innerHeight - 200,
          boxShadow: "0px 0px 10px 0px #96A1C84D",
          borderRadius: "8px",
        }}
      >
        <div style={{ padding: "8px 18px 0px 18px" }}>
          {ledgerId && (
            <p style={{ marginBottom: "10px", fontWeight: "bolder" }}>
              {displayDate > (companySettings?.period?.from || 0)
                ? "Last reconciled on " +
                  dayjs(displayDate).format("DD/MM/YYYY")
                : "Reconcilation will start from financial year dating " +
                  dayjs(companySettings?.period?.from).format("DD/MM/YYYY")}
            </p>
          )}
          <Row>
            <Col xs={24} md={12} lg={15}>
              <Row>
                <Col>
                  <Select
                    labelInValue
                    // defaultValue={ledgerList?.value}
                    style={{ width: "150px" }}
                    showSearch
                    placeholder="Select..."
                    optionFilterProp="children"
                    options={ledgerList?.map((itm: any) => ({
                      label: itm?.ledgerName,
                      value: itm?.id,
                      title: itm?.partyOrTax,
                    }))}
                    onChange={(e) => handleSelectChange(e)}
                    // onSearch={onSearch}
                    filterOption={filterOption}
                    dropdownRender={(customerOptions) => (
                      <>
                        {customerOptions}
                        <Divider style={{ margin: "5px 0px" }} />
                        <Button
                          style={{ width: "100%" }}
                          icon={<PlusOutlined />}
                          type="link"
                          onClick={() => {
                            setOpenModel({
                              type: "addLedger",
                              param: {
                                open: true,
                              },
                            });
                            // setEditParty({ id: "" });
                          }}
                        >
                          New Ledger
                        </Button>
                      </>
                    )}
                  />
                </Col>
                <Col>
                  <span style={{ marginLeft: "10px" }}>As on : </span>
                  <DatePicker
                    format="DD/MM/YYYY"
                    // defaultValue={}
                    onChange={(e) => {
                      if (e === null) {
                        setFilterDate({});
                      } else {
                        setFilterDate({
                          ...filterDate,
                          to: dayjs(e),
                        });
                      }
                    }}
                  />
                </Col>

                <span style={{ padding: "8px 5px 4px 8px" }}>
                  Balance as per Bank :
                </span>
                <Col span={3}>
                  <InputNumber
                    style={{ width: "100%" }}
                    controls={false}
                    value={inputValue}
                    onChange={(e: any) => setInputValue(e)}
                  />
                </Col>
                <span style={{ margin: "8px 5px 4px 8px" }}>
                  Difference Amount :
                </span>
                <Col>
                  {totalAfterSubtraction === 0 ? (
                    <>
                      <p style={{ color: "green", paddingTop: "8px" }}>
                        {Math.round(totalAfterSubtraction * 100) / 100}
                      </p>
                    </>
                  ) : (
                    <>
                      <p style={{ color: "red", paddingTop: "8px" }}>
                        {Math.round(totalAfterSubtraction * 100) / 100}
                      </p>
                    </>
                  )}
                </Col>
              </Row>
            </Col>
            <Col xs={24} md={9} lg={9}>
              <Row
                justify={"space-between"}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <span style={{ padding: "8px 5px 4px 0px" }}>Filter by</span>
                <Col style={{ marginRight: "10px" }}>
                  <Select
                    placeholder="Select..."
                    defaultValue={2}
                    value={selectedOption}
                    style={{ width: 160 }}
                    onChange={handleChange}
                    options={[
                      { value: 0, label: "Both" },
                      { value: 1, label: "Reconciled" },
                      { value: 2, label: "Non Reconciled" },
                    ]}
                  />
                </Col>
                <Col style={{ marginRight: "10px" }}>
                  <Button
                    type="primary"
                    onClick={() => {
                      setId(undefined);
                      setType(AdjustBankType.BankReconciliation);
                      setOpenModel({
                        type: "adjustBankBalance",
                        param: {
                          id: "",
                          type: "",
                        },
                      });
                    }}
                  >
                    Add Adjustment Entry
                  </Button>
                </Col>
                {/* <Col span={2}>
                  <Button
                    type="primary"
                    onClick={() => {
                      setOpenModel({
                        type: "CSVmodal",
                        param: {
                          id: "",
                          type: "",
                        },
                      });
                    }}
                  >
                    <PlusOutlined />
                  </Button>
                </Col> */}
              </Row>
            </Col>
          </Row>
          <Row justify={"space-between"}>
            <Col span={20}>
              <Row style={{ marginTop: "15px" }}>
                <Col
                  span={5}
                  style={{
                    border: "1px solid #e0e0de",
                    boxShadow: "1px 1px 2px #a0a0ae",
                    padding: "8px",
                    borderRadius: "5px",
                    textAlign: "center",
                  }}
                >
                  <p
                    style={{
                      fontSize: "15px",
                      padding: "5px",
                      marginBottom: "10px",
                    }}
                  >
                    Opening Balance
                  </p>
                  <span style={{ fontSize: "18px" }}>
                    ₹{Utils.getFormattedNumber(openingBalance)}
                  </span>
                </Col>
                <Col
                  span={1}
                  style={{
                    textAlign: "center",
                    paddingTop: "4px",
                    fontSize: "33px",
                  }}
                >
                  -
                </Col>{" "}
                <Col
                  span={5}
                  style={{
                    border: "1px solid #e0e0de",
                    boxShadow: "1px 1px 2px #a0a0ae",
                    padding: "8px",
                    borderRadius: "5px",
                    textAlign: "center",
                  }}
                >
                  <p
                    style={{
                      fontSize: "15px",
                      padding: "5px",
                      marginBottom: "10px",
                    }}
                  >
                    Expenses/Payments
                  </p>
                  <span style={{ fontSize: "18px" }}>
                    ₹
                    {Utils.getFormattedNumber(
                      Math.abs(totalReconciledCreditAmount)
                    )}
                  </span>
                </Col>
                <Col
                  span={1}
                  style={{
                    textAlign: "center",
                    paddingTop: "4px",
                    fontSize: "33px",
                  }}
                >
                  +
                </Col>
                <Col
                  span={5}
                  style={{
                    border: "1px solid #e0e0de",
                    boxShadow: "1px 1px 2px #a0a0ae",
                    padding: "8px",
                    borderRadius: "5px",
                    textAlign: "center",
                  }}
                >
                  <p
                    style={{
                      fontSize: "15px",
                      padding: "5px",
                      marginBottom: "10px",
                    }}
                  >
                    Incomes/Receipts
                  </p>
                  <span style={{ fontSize: "18px" }}>
                    ₹
                    {Utils.getFormattedNumber(
                      Math.abs(totalReconciledDebitAmount)
                    )}
                  </span>
                </Col>{" "}
                <Col
                  span={1}
                  style={{
                    textAlign: "center",
                    paddingTop: "4px",
                    fontSize: "33px",
                  }}
                >
                  =
                </Col>
                <Col
                  span={5}
                  style={{
                    border: "1px solid #e0e0de",
                    boxShadow: "1px 1px 2px #a0a0ae",
                    padding: "8px",
                    borderRadius: "5px",
                    textAlign: "center",
                  }}
                >
                  <p
                    style={{
                      fontSize: "15px",
                      padding: "5px",
                      marginBottom: "10px",
                    }}
                  >
                    Closing Balance
                  </p>
                  <span style={{ fontSize: "18px" }}>
                    ₹{Utils.getFormattedNumber(totalReconciledBalance)}
                  </span>
                </Col>
              </Row>
            </Col>
            <Col style={{ padding: "20px 10px" }}>
              {showSaveButton && (
                <Button
                  type="primary"
                  onClick={() => postReconcileVoucher()}
                  disabled={saveDisabled}
                >
                  Save
                </Button>
              )}
            </Col>
          </Row>
          <div style={{ marginTop: "10px" }}>
            <Table
              className={`Tabel-style table-${
                themeData?.componentSize ?? "middle"
              }`}
              size="small"
              columns={columns}
              dataSource={
                loading
                  ? (Array.from({ length: 15 }, (_, index) => ({
                      key: `loading-${index}`,
                    })) as VoucherDetailsData[])
                  : voucherList
              }
              pagination={false}
              summary={() => {
                return (
                  <Table.Summary fixed>
                    <Table.Summary.Row
                      style={{ backgroundColor: "#f5f8ff", fontWeight: "bold" }}
                    >
                      <Table.Summary.Cell
                        index={1}
                        className="fw600"
                      ></Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={2}
                        className="fw600"
                      ></Table.Summary.Cell>
                      <Table.Summary.Cell index={3} className="fw600">
                        Total Amount
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={4}
                        className="fw600"
                      ></Table.Summary.Cell>
                      <Table.Summary.Cell index={5} align="right">
                        ₹ {Utils.getFormattedNumber(totalDebitAmount)}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={6}
                        className="fw600"
                        align="right"
                      >
                        ₹ {Utils.getFormattedNumber(totalCreditAmount)}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={7} align="right">
                        Balance
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={8} align="right">
                        ₹{Utils.getFormattedNumber(Math.abs(totalBalance))}{" "}
                        {totalBalance > 0
                          ? "Dr."
                          : totalBalance < 0
                          ? "Cr."
                          : null}
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                    {/* <Table.Summary.Row
                      style={{
                        backgroundColor: "#fff9f9",
                        fontWeight: "bold",
                        borderTop: "10px solid black !important",
                      }}
                    >
                      <Table.Summary.Cell
                        index={1}
                        className="fw600"
                      ></Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={2}
                        className="fw600"
                      ></Table.Summary.Cell>
                      <Table.Summary.Cell index={3} className="fw600">
                        Reconciled Amount
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={4} className="fw600">
                        {" "}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={5} align="right">
                        ₹ {Utils.getFormattedNumber(totalReconciledDebitAmount)}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={6}
                        className="fw600"
                        align="right"
                      >
                        ₹{" "}
                        {Utils.getFormattedNumber(totalReconciledCreditAmount)}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={7} align="right">
                        Balance
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={8} align="right">
                        ₹
                        {Utils.getFormattedNumber(
                          Math.abs(totalReconciledBalance)
                        )}{" "}
                        {totalReconciledBalance > 0
                          ? "Dr."
                          : totalReconciledBalance < 0
                          ? "Cr."
                          : null}
                      </Table.Summary.Cell>
                    </Table.Summary.Row> */}
                  </Table.Summary>
                );
              }}
            />
          </div>
        </div>
      </div>

      {/* {openModel.type === "adjustBankBalance" && (
        <AdjustBankBalanceModel
          open={true}
          onCancel={() => setOpenModel({ type: "", param: "" })}
          // bankAccountTrigger={""}
          // triggerBankTransactions={""}
          type={type}
          bank={bank}
          id={id}
          allBankAccount={ledgerList}
          ledgerName={ledgerId}
          setRefresh={setRefresh}
        />
      )} */}
      {openModel.type === "addLedger" && (
        <AddLedger
          openDrawer={openModel?.param.open}
          id={""}
          setOpenDrawer={() => setOpenModel({ type: "", param: "" })}
          setTrigger={() => setTrigger(!trigger)}
        />
      )}
      {openModel.type === "CSVmodal" && (
        <UploadCSVfile
          open={true}
          onCancel={() => setOpenModel({ type: "", param: "" })}
        />
      )}
      {openModel.type === "viewPayment" && (
        <ViewPaymentIn
          open={true}
          onCancel={() =>
            setOpenModel({
              type: "",
              param: "",
            })
          }
          paymentId={paymentId}
          type={type}
        />
      )}
      {openModel.type === "viewInvoice" && (
        <ViewSalesInvoice
          open={true}
          invVoucharType={type}
          onCancel={() =>
            setOpenModel({
              type: "",
              param: "",
            })
          }
          invoiceId={paymentId}
        />
      )}
      {openModel.type === "returnNote" && (
        <ViewCreditNote
          open={true}
          onCancel={() =>
            setOpenModel({
              type: "",
              param: "",
            })
          }
          creditNoteId={paymentId}
          voucharType={type}
        />
      )}
      {openModel.type === "journal" && (
        <ViewJournal
          open={true}
          onCancel={() =>
            setOpenModel({
              type: "",
              param: "",
            })
          }
          journalId={paymentId}
          type={type}
        />
      )}
      {openModel.type === "delete" && (
        <ConfirmationModal
          open={true}
          onNo={() =>
            setOpenModel({
              type: "",
              param: "",
            })
          }
          onYes={() => {
            setDeleteVoucher({
              ...deleteVoucher,
              id: paymentId,
              isRev:
                invoiceStatus === VoucherFilterStatus.Deleted ? true : false,
            });
            setOpenModel({
              type: "",
              param: "",
            });
          }}
          text={
            invoiceStatus === VoucherFilterStatus.Deleted
              ? `Are you sure you want to revive "${deleteVoucher.number}" ?`
              : `Are you sure you want to deleteVoucher "${deleteVoucher.number}" ?`
          }
        />
      )}
      {openModel.type === "adjustBankBalance" && (
        <AdjustBankBalanceModel
          open={true}
          onCancel={() => setOpenModel({ type: "", param: "" })}
          // bankAccountTrigger={() => setBankTrigger(!bankTrigger)}
          // triggerBankTransactions={() =>
          //   setTriggerBankTransactions(!triggerBankTransactions)
          // }
          type={type}
          bank={bank}
          id={paymentId}
          allBankAccount={ledgerList}
          // ledgerName={lederId}
          setRefresh={setRefresh}
          disable={openModel?.param?.disable}
          // item={{ id: bank.id, name: bankAccountDetails?.name }}
        />
      )}
    </>
  );
};

export default BankReconciliation;
function async(id: string | undefined) {
  throw new Error("Function not implemented.");
}
