import {
  Button,
  Card,
  Col,
  DatePicker,
  Row,
  Select,
  Spin,
  Table,
  TableProps,
  Tag,
} from "antd";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useClient } from "../../../Context/ClientContext";
import { useTheme } from "../../../Context/ThemeContext";
import { dayjs } from "../../../../Utilities/dayjs";
import { ImportOutlined, LoadingOutlined, SaveFilled } from "@ant-design/icons";
import LedgerService, {
  SearchLedgerByGroupsTypes,
} from "../../../Services/LedgerService";
import AdjustBankBalanceModel from "../Banks/AdjustBankBalanceModel";
import { Party_Tax, Voucher_Type } from "../../../Services/VoucherServices";
import BankTransactions, {
  BankImportEntriesStatus,
  EntryStatus,
} from "../../../Services/BankTransactions";
import ImportBankTransactionModal from "../Banks/ImportBankTransactionModal";
import { notificationContext } from "../../../Common/PageRoute";
import { Utils } from "../../../../Utilities/Utils";
import MatchVoucher from "./MatchVoucher";
const { RangePicker } = DatePicker;

export const bankEntriesStatus = ["Uncategorized", "Saved"];

interface DataType {
  date: string | any;
  reference: string;
  moneyOut: number;
  moneyIn: number;
  valid?: boolean;
  error?: any;
  status?: BankImportEntriesStatus;
}
// interface BankTransactionsDetailsProps {
//   setRefresh: (refresh: boolean) => void;
// }
const BankTransactionsDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { themeData } = useTheme();
  const { currentClient, companySettings } = useClient();
  const [searchVoucherModal, setSearchVoucherModal] = React?.useState<{
    type: string;
    param: any;
  }>({ type: "", param: {} });
  const { openNotification } = React.useContext(notificationContext);
  const [csvData, setCsvdata] = React.useState<any>();
  const [showBtn, setShowBtn] = React?.useState<boolean>();
  const [showSaveBtn, setShowSaveBtn] = React?.useState<boolean>(false);
  const [importDataList, setImportDataList] = React.useState<any[]>([]);
  const [ledgerList, setLedgerList] = React?.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [refresh, setRefresh] = React.useState<boolean>(false);
  const [showTable, setShowTable] = React.useState<boolean>(false);
  const [importList, setImportList] = React.useState<any>();
  const [contraModal, setContraModal] = React?.useState<{
    type: string;
    param: any;
  }>({ type: "", param: {} });
  const [bank, setBank] = React.useState<{
    id: string;
    name: string;
    partyOrTax: Party_Tax;
  }>({
    id: "",
    name: "",
    partyOrTax: Party_Tax.other,
  });
  const [btnLoading, setBtnLoading] = React.useState<boolean>(false);
  // const [filterDate, setFilterDate] = React.useState<any>({
  //   from: dayjs().startOf("month").format("DD/MM/YYYY"),
  //   to: dayjs().endOf("month").format("DD/MM/YYYY"),
  // });

  const [filterDate, setFilterDate] = React.useState<any>(
    JSON.parse(localStorage.getItem("LockPeriod")!)
      ? {
          from: dayjs(
            JSON.parse(localStorage.getItem("LockPeriod")!).from,
            "DD/MM/YYYY"
          ),
          to: dayjs(
            JSON.parse(localStorage.getItem("LockPeriod")!).to,
            "DD/MM/YYYY"
          ),
        }
      : {
          from: dayjs(companySettings?.period?.from).format("DD/MM/YYYY"),
          to: dayjs(companySettings?.period?.to).format("DD/MM/YYYY"),
        }
  );

  // const [filterDate, setFilterDate] = React.useState<any>({
  //   from: dayjs().startOf("month"),
  //   to: dayjs().endOf("month"),
  // });
  const [data, setData] = React.useState<DataType[]>([]);
  const [width, setWidth] = React.useState<any>(window.innerWidth);
  const [trigger, setTrigger] = React.useState<boolean>(true);
  const [modelApiCall, setModelApiCall] = React.useState<boolean>(false);
  const [bankEntriesId, setBankEntriesId] = React.useState<string>(
    location?.pathname?.split("/")[6]
  );
  const [dateChangeTrigger, setDateChangeTrigger] =
    React.useState<boolean>(false);
  const [fileName, setFileName] = React.useState<string>("");
  React.useEffect(() => {
    console.log("location", location?.pathname?.split("/")[6]);

    setImportDataList([]);
    // }else{
    setBankEntriesId(location?.pathname?.split("/")[6]);

    if (location.state?.ledgerId && ledgerList.length > 0) {
      // console.log("useEffected");
      const x = ledgerList.find((x: any) => x.id === location?.state.ledgerId);
      // debugger;
      setBank({
        id: x.id,
        name: x.ledgerName,
        partyOrTax: x.partyOrTax,
      });
    }
  }, [location, ledgerList]);

  console.log(
    // "TransactionsDetails",
    "TransactionsDetails ....",
    location
  );

  const [bankTransactionModal, setBankTransactionModal] = React?.useState<{
    type: string;
    param: any;
  }>({ type: "", param: {} });

  React.useEffect(() => {
    if (currentClient?.id) {
      const getLedgersByGroupName = async () => {
        let res = await LedgerService?.getLedgersByGroupName(
          currentClient?.id,
          SearchLedgerByGroupsTypes?.BankOnly
        );
        if (res) {
          console.log("res", res);
          setLedgerList(res);
        }
      };
      getLedgersByGroupName();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setData(
      location.state?.parsedData?.map((rec: any) => {
        const dateField = rec.Date; // Adjust according to your actual field name
        const moneyOut = rec.MoneyOut;
        const moneyIn = rec.MoneyIn;
        const isValidDate = dayjs(
          dateField,
          ["DD/MM/YYYY", "DD-MM-YYYY"],
          true
        ).isValid();

        //  moneyOut === "" ||
        //   !isNaN(Number(moneyOut)) ||

        const isValidMoneyIn = Utils.getFormattedNumber(moneyIn) === "0.00";
        // &&
        // Utils.getNumber(Utils.getFormattedNumber(moneyIn)) > 0;

        const isValidMoneyOut = Utils.getFormattedNumber(moneyOut) === "0.00";
        // &&
        // Utils.getNumber(Utils.getFormattedNumber(moneyOut)) > 0;

        const bothAmount =
          Utils.getNumber(Utils.getFormattedNumber(moneyIn)) > 0 &&
          Utils.getNumber(Utils.getFormattedNumber(moneyOut)) > 0;

        console.log(
          "isValidMoneyIn",
          // typeof moneyIn,
          // showAmountError
          isValidMoneyIn,
          Utils.getNumber(Utils.getFormattedNumber(moneyOut)),
          isValidMoneyOut
          // typeof moneyOut
        );

        const showAmountError =
          (isValidMoneyIn && isValidMoneyOut) || bothAmount;

        const dateRangeError = !dayjs(rec.Date, "DD/MM/YYYY").isBetween(
          dayjs(filterDate.from, "DD/MM/YYYY"),
          dayjs(filterDate.to, "DD/MM/YYYY"),
          "day",
          "[]"
        );

        return {
          date: dayjs(rec.Date, ["DD/MM/YYYY", "DD-MM-YYYY"]).format(
            "DD/MM/YYYY"
          ),
          reference: rec.Reference,
          moneyOut: rec.MoneyOut,
          moneyIn: rec.MoneyIn,
          valid: isValidDate && !showAmountError && !dateRangeError,
          error:
            !isValidDate && showAmountError ? (
              <>
                <div
                  style={{
                    color: "red",
                    fontWeight: "bold",
                  }}
                >
                  Invalid Date &
                </div>
                <div
                  style={{
                    color: "red",
                    fontWeight: "bold",
                  }}
                >
                  Invalid Amount
                </div>
              </>
            ) : !isValidDate && !showAmountError ? (
              <>
                <div
                  style={{
                    color: "red",
                    fontWeight: "bold",
                  }}
                >
                  Invalid Date
                </div>
              </>
            ) : isValidDate && showAmountError ? (
              <>
                <div
                  style={{
                    color: "red",
                    fontWeight: "bold",
                  }}
                >
                  Invalid Amount
                </div>
              </>
            ) : dateRangeError ? (
              <div
                style={{
                  color: "red",
                  fontWeight: "bold",
                }}
              >
                Not a Date Range
              </div>
            ) : (
              <>
                <div
                  style={{
                    color: "green",
                    fontWeight: "bold",
                  }}
                >
                  No Error
                </div>
              </>
            ),
        };
      })
    );
    setShowTable(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.state?.parsedData, dateChangeTrigger]);

  useEffect(() => {
    // debugger
    let testdata = data?.filter((d: any) => d.valid === false);
    console.log("testdata", testdata);

    setShowSaveBtn(testdata?.length > 0 ? false : true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const columnss: TableProps<any>["columns"] = [
    {
      title: "Date",
      dataIndex: "date",
      key: "importDate",
    },
    {
      title: "Money Out",
      dataIndex: "moneyOut",
      key: "moneyOut",
    },
    {
      title: "Money In",
      dataIndex: "moneyIn",
      key: "moneyIn",
    },
    {
      title: "Reference",
      dataIndex: "reference",
      key: "reference",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "",
      render: (record: any) => {
        return (
          <>
            <>
              {record === 0 ? (
                <>
                  <Tag color="red">{bankEntriesStatus[record]}</Tag>
                </>
              ) : (
                <>
                  <Tag color="green">{bankEntriesStatus[record]}</Tag>
                </>
              )}
            </>
          </>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "status",
      key: "status",
      width: "14%",
      render: (_: any, record: any, index: number) => (
        <>
          <Select
            disabled={record.status === 1}
            placeholder={"Select..."}
            style={{ width: "150px" }}
            value={null}
            onChange={(value: any) => {
              console.log("index", index);

              //   handleChange(value);
              setCsvdata(record || bank?.id);

              if (value === "advanceReceipt") {
                navigate(
                  `/accounts/clients/${currentClient?.id}/sales/addReceipt`,
                  {
                    state: {
                      BankTransactionData: record,
                      ledgerId: bank?.id,
                      bankImportIndex: index,
                      bankEntriesId: bankEntriesId,
                      // partyOrTax: ledgerId?.partyOrTax,
                    },
                  }
                );
              } else if (value === "advancePayment") {
                navigate(
                  `/accounts/clients/${currentClient?.id}/purchase/addPaymentOut`,
                  {
                    state: {
                      BankTransactionData: record,
                      ledgerId: bank?.id,
                      bankImportIndex: index,
                      bankEntriesId: bankEntriesId,
                      // partyOrTax: ledgerId?.partyOrTax,
                    },
                  }
                );
              } else if (value === "contra") {
                setContraModal({
                  type: "Contra",
                  param: {
                    open: true,
                    ledgerId: bank?.id,
                    bankImportIndex: index,
                    bankEntriesId: bankEntriesId,
                  },
                });
                setCsvdata(record);
                // console.log("record", record);
              } else if (value === "newSales") {
                setCsvdata(record);
                navigate(
                  `/accounts/clients/${currentClient?.id}/sales/addInvoice`,
                  {
                    state: {
                      BankTransactionData: record,
                      ledgerId: bank?.id,
                      bankImportIndex: index,
                      bankEntriesId: bankEntriesId,
                    },
                  }
                );
              } else if (value === "newPurchase") {
                setCsvdata(record);
                navigate(
                  `/accounts/clients/${currentClient?.id}/purchase/addPurchaseBill`,
                  {
                    state: {
                      BankTransactionData: record,
                      ledgerId: bank?.id,
                      bankImportIndex: index,
                      bankEntriesId: bankEntriesId,
                    },
                  }
                );
              } else if (value === "matchVoucher") {
                setCsvdata(record);
                setSearchVoucherModal({
                  type: "SearchVoucher",
                  param: {
                    open: true,
                    ledgerId: bank?.id,
                    bankImportIndex: index,
                    bankEntriesId: bankEntriesId,
                  },
                });
              }
            }}
            options={
              record?.moneyOut === 0
                ? [
                    { value: "advanceReceipt", label: "Receipt Voucher" },
                    {
                      value: "matchVoucher",
                      label: "Match Voucher",
                    },
                    { value: "contra", label: "Contra" },
                    { value: "newSales", label: "New Sales" },
                  ]
                : [
                    {
                      value: "advancePayment",
                      label: "Payment Voucher",
                    },
                    {
                      value: "matchVoucher",
                      label: "Match Voucher",
                    },
                    { value: "contra", label: "Contra" },
                    { value: "newPurchase", label: "New Purchase" },
                  ]
            }
          />
        </>
      ),
    },
  ];

  const columns: any[] = [
    {
      title: "Date",
      dataIndex: "date",
      render: (val: any) => val,
    },
    {
      title: "Reference",
      dataIndex: "reference",
      render: (val: any) => val,
    },
    {
      title: "Money Out",
      dataIndex: "moneyOut",
      align: "right",
      render: (val: any) => <>{Utils?.getFormattedNumber(val)}</>,
    },
    {
      title: "Money In",
      dataIndex: "moneyIn",
      align: "right",
      render: (val: any) => <>{Utils?.getFormattedNumber(val)}</>,
    },
    {
      title: "Errors",
      dataIndex: "error",
      align: "center",
      render: (val: any) => val,
    },
  ];

  // console.log("Data: ", bankEntriesId);

  // console.log("data....", location?.state, importDataList);

  React.useEffect(() => {
    if (
      (location?.state?.bankImportIndex === 0 ||
        location?.state?.bankImportIndex) &&
      location.state?.ledgerId &&
      importDataList?.length > 0 &&
      trigger
    ) {
      saveImportData(
        location.state?.ledgerId,
        location?.state?.bankImportIndex
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, importDataList]);

  const saveImportData = async (
    bankledgerId: string,
    bankImportIndex?: number,
    contraVoucherRef?: any
  ) => {
    console.log("data");

    // const parsedData: rawDataType[] = location?.state?.CSVData || [];
    // const validEntries = filterValidEntries();
    if (data?.length > 0 || importDataList?.length > 0) {
      if (bankledgerId) {
        let entries: DataType[];
        if (bankImportIndex === 0 || bankImportIndex) {
          entries = importDataList.map((entry: any, index: number) => ({
            date: dayjs(entry?.date, "DD/MM/YYYY"),
            reference: entry?.reference,
            moneyOut: entry?.moneyOut,
            moneyIn: entry.moneyIn,
            status:
              index === bankImportIndex || entry?.status
                ? BankImportEntriesStatus.ActionTaken
                : BankImportEntriesStatus.ActionPending,
            voucherRef:
              index === bankImportIndex
                ? contraVoucherRef ?? location.state?.voucherRef
                : entry.voucherRef,
          }));
        } else {
          entries = data.map((entry: any, index: number) => {
            const dateRangeError = !dayjs(entry.date, "DD/MM/YYYY").isBetween(
              dayjs(filterDate.from, "DD/MM/YYYY"),
              dayjs(filterDate.to, "DD/MM/YYYY"),
              "day",
              "[]"
            );
            return {
              date: dayjs(entry?.date, "DD/MM/YYYY"),
              reference: entry?.reference,
              moneyOut: Utils.getNumber(entry?.moneyOut),
              moneyIn: Utils.getNumber(entry.moneyIn),
              valid: !dateRangeError,
              error: dateRangeError ? (
                <div
                  style={{
                    color: "red",
                    fontWeight: "bold",
                  }}
                >
                  Not a Date Range
                </div>
              ) : (
                <>
                  <div
                    style={{
                      color: "green",
                      fontWeight: "bold",
                    }}
                  >
                    No Error
                  </div>
                </>
              ),
            };
          });
        }

        console.log("date", entries);
        if (entries?.find((d: any) => d.valid === false)) {
          setLoading(false);
          openNotification("error", "Please Import Valid Date Range");
          // setData(entries);
        } else {
          const param = {
            importDate: dayjs(csvData?.Date),
            startDate: dayjs(filterDate?.from, "DD/MM/YYYY"),
            endDate: dayjs(filterDate?.to, "DD/MM/YYYY"),
            ledgerId: bank?.id,
            fileName: fileName === "" ? location?.state?.fileName : fileName,
            entries: entries,
            status: EntryStatus?.Active,
            id: bankEntriesId,
          };

          try {
            let res = await BankTransactions?.postImportData(
              currentClient?.id,
              bankledgerId,
              param
            );
            if (res?.result) {
              // console.log("Res....", res);
              if (contraVoucherRef) {
                setModelApiCall((x) => !x);
              } else if (bankImportIndex === 0 || bankImportIndex) {
                setTrigger(false);
              }
              // save first time navigate
              else {
                navigate(`${res?.result}`, {
                  state: {
                    ledgerId: bank?.id,
                  },
                });
              }
              // setRefresh(false);
              setBankEntriesId(res?.result);
              openNotification("success", "Saved Successfully");
              setShowTable(true);
            } else {
              openNotification("error", res?.message);
              setBtnLoading(false);
            }
          } catch (error: any) {
            openNotification("error", error?.message);
            setBtnLoading(false);
            // setRefresh(false);
          }
        }
      }
    } else {
      setBtnLoading(false);
      openNotification("error", "Please Import Valid Data");
    }
    setBtnLoading(false);
  };

  React.useEffect(() => {
    if (bankEntriesId && bank?.id) {
      // debugger
      const getImportList = async () => {
        setLoading(true);
        let res = await BankTransactions?.getImportList(
          currentClient?.id,
          bank?.id,
          bankEntriesId
        );
        if (res) {
          // debugger
          setImportList(res?.result);
          setShowTable(true);
          setFilterDate({
            from: dayjs(res.result?.startDate),
            to: dayjs(res.result?.endDate),
          });
          setFileName(res.result.fileName);
          setImportDataList(
            res?.result?.entries?.map((b: any) => {
              return {
                date: dayjs(b?.date).format("DD/MM/YYYY"),
                moneyOut: b?.moneyOut,
                moneyIn: b?.moneyIn,
                reference: b?.reference,
                status: b?.status,
                voucherRef: b?.voucherRef,
              };
            })
          );
          setLoading(false);
        } else {
          setLoading(false);
        }
      };
      getImportList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankEntriesId, bank?.id, trigger, modelApiCall, refresh]);

  useEffect(() => {
    console.log("filterDate", filterDate);
  }, [filterDate]);
  // console.log("import", importDataList);
  console.log("iooo", location?.state?.bankImpEntryId);

  const getQuarterPreset: any = (startMonth: number) => {
    const fromDate = dayjs(companySettings?.period?.from);
    return [
      fromDate.month(fromDate.month() + startMonth).startOf("month"),
      fromDate.month(fromDate.month() + startMonth + 2).endOf("month"),
    ];
  };
  const thisYear = () => {
    const fromDate = dayjs(companySettings?.period?.from);
    return [
      fromDate.month(fromDate.month()).startOf("month"),
      fromDate.month(fromDate.month() + 11).endOf("month"),
    ];
  };
  const lastYear = () => {
    const fromDate = dayjs(companySettings?.period?.from);
    return [
      fromDate.month(fromDate.month() - 12).startOf("month"),
      fromDate.month(fromDate.month() - 1).endOf("month"),
    ];
  };

  const getLast15Days = () => {
    const now = dayjs();
    const startDate = now.subtract(15, "day").startOf("day");
    const endDate = now.endOf("day");
    return [startDate, endDate];
  };
  const getLast7Days = () => {
    const now = dayjs();
    const startDate = now.subtract(7, "day").startOf("day");
    const endDate = now.endOf("day");
    return [startDate, endDate];
  };

  const getThisMonth = () => {
    const now = dayjs();
    return [now.startOf("month"), now.endOf("month")];
  };
  const getLastMonth = () => {
    const now = dayjs();
    const lastMonth = now.subtract(1, "month");
    return [lastMonth.startOf("month"), lastMonth.endOf("month")];
  };
  return (
    <>
      <Spin indicator={<LoadingOutlined />} spinning={loading}>
        <Card className="TableCard">
          <Row justify={"space-between"}>
            <Col>
              <Row>
                <Col>
                  <h3
                    style={{
                      margin: "5px 0px 15px 0px",
                      color: "rgb(22, 119, 255)",
                    }}
                  >
                    Bank Transactions Details
                  </h3>
                </Col>
              </Row>
            </Col>

            <Col>
              <Row gutter={14}>
                <Col>
                  {/* <span style={{ marginRight: "10px", fontWeight: "bold" }}>
                    Period :false}
                  </span> */}
                  <RangePicker
                    format="DD/MM/YYYY"
                    defaultValue={[
                      dayjs(filterDate?.from, "DD/MM/YYYY"),
                      dayjs(filterDate?.to, "DD/MM/YYYY"),
                    ]}
                    value={[filterDate?.from, filterDate?.to]}
                    allowClear={false}
                    disabled={showTable}
                    style={{
                      width:
                        width <= 464
                          ? "210px"
                          : width == 375
                          ? "200px"
                          : "250px",
                      border: "1px solid #f1f3ff",
                    }}
                    onChange={(e: any) => {
                      if (e === null) {
                        setFilterDate({});
                      } else {
                        setDateChangeTrigger((x) => !x);
                        setFilterDate({
                          ...filterDate,
                          from: dayjs(e[0]),
                          to: dayjs(e[1]),
                        });
                        localStorage.setItem(
                          "LockPeriod",
                          JSON.stringify({
                            ...filterDate,
                            from: dayjs(e[0]).format("DD/MM/YYYY"),
                            to: dayjs(e[1]).format("DD/MM/YYYY"),
                          })
                        );
                        openNotification("success", `Period locked.`);
                      }
                    }}
                    presets={[
                      {
                        label: "Today",
                        value: [dayjs().add(0, "d"), dayjs()],
                      },
                      {
                        label: "Last 7 Days",
                        value: getLast7Days(),
                      },
                      {
                        label: "Last 15 Days",
                        value: getLast15Days(),
                      },
                      {
                        label: "This Month",
                        value: getThisMonth(),
                      },
                      {
                        label: "Last Month",
                        value: getLastMonth(),
                      },
                      {
                        label: "Quarter 1",
                        value: getQuarterPreset(0),
                      },
                      {
                        label: "Quarter 2",
                        value: getQuarterPreset(3),
                      },
                      {
                        label: "Quarter 3",
                        value: getQuarterPreset(6),
                      },
                      {
                        label: "Quarter 4",
                        value: getQuarterPreset(9),
                      },
                      {
                        label: "This Year",
                        value: thisYear(),
                      },
                      {
                        label: "Last Year",
                        value: lastYear(),
                      },
                    ]}
                  />
                </Col>
                <Col>
                  {/* <span style={{ fontWeight: "bold" }}>Select Ledger : </span> */}
                  <Select
                    disabled={showTable}
                    labelInValue
                    placeholder="Select.."
                    // defaultValue={bank?.id}
                    value={bank?.id}
                    onChange={(x: any) => {
                      setBank({
                        id: x?.value,
                        partyOrTax: x?.title,
                        name: x?.label,
                      });
                    }}
                    style={{ width: "200px" }}
                    options={ledgerList?.map((itm: any) => ({
                      label: itm?.ledgerName,
                      value: itm?.id,
                      title: itm?.partyOrTax,
                    }))}
                  />
                </Col>

                <Col>
                  <Button
                    icon={<ImportOutlined />}
                    type="primary"
                    onClick={() => {
                      setBankTransactionModal({
                        type: "BankTransaction",
                        param: {
                          open: true,
                        },
                      });
                      // setShowTable(false);
                    }}
                  >
                    Import File
                  </Button>
                </Col>

                <Col>
                  <Button
                    icon={<SaveFilled />}
                    onClick={() => {
                      saveImportData(bank?.id);
                      location?.state?.setRefresh(true);
                    }}
                    type="primary"
                    disabled={!showSaveBtn || showTable}
                    // disabled={data?.length < 0}
                    // disabled={true}
                    loading={btnLoading}
                  >
                    Save Data
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <div style={{ maxHeight: "85vh", overflowY: "scroll" }}>
            {showTable === true ? (
              <>
                <Table
                  className={`Tabel-style table-${
                    themeData?.componentSize ?? "middle"
                  }`}
                  columns={columnss}
                  dataSource={importDataList}
                  pagination={false}
                />
              </>
            ) : (
              <>
                <Table
                  className={`Tabel-style table-${
                    themeData?.componentSize ?? "middle"
                  }`}
                  dataSource={data}
                  columns={columns}
                  pagination={false}
                />
              </>
            )}
          </div>
        </Card>
      </Spin>

      {contraModal.type === "Contra" && (
        <AdjustBankBalanceModel
          open={true}
          onCancel={() => setContraModal({ type: "", param: "" })}
          type={Voucher_Type?.Contra}
          bank={bank}
          bankDetails={csvData}
          bankImportIndex={contraModal.param?.bankImportIndex}
          bankEntriesId={contraModal.param?.bankEntriesId}
          saveBankImport={(data: any) =>
            saveImportData(
              location.state?.ledgerId,
              contraModal.param?.bankImportIndex,
              data
            )
          }
        />
      )}
      {bankTransactionModal.type === "BankTransaction" && (
        <ImportBankTransactionModal
          ledgerId={bank?.id}
          open={true}
          onCancel={() => setBankTransactionModal({ type: "", param: "" })}
          partyOrTax={bank}
        />
      )}
      {searchVoucherModal.type === "SearchVoucher" && (
        <MatchVoucher
          open={true}
          onCancel={() => setSearchVoucherModal({ type: "", param: "" })}
          bank={bank}
          csvData={csvData}
          importList={importList}
          bankImportIndex={searchVoucherModal.param?.bankImportIndex}
          bankEntriesId={searchVoucherModal.param?.bankEntriesId}
          setRefresh={setRefresh}
        />
      )}
    </>
  );
};

export default BankTransactionsDetails;
