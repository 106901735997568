import { Button, Divider, Form, Modal, Select, Space } from "antd";
import React from "react";
import SunEditor from "suneditor-react";
import TermsAndConditionsService from "../../Services/TermsAndConditionsService";
import { Voucher_Type } from "../../Services/VoucherServices";
import { useClient } from "../../Context/ClientContext";
import { PlusOutlined } from "@ant-design/icons";
import AddTermsAndCondition from "../Settings/AddTermsAndCondition";
import { voucherType } from "../Reports/VoucherDetailsByLedger";
import { useLocation } from "react-router-dom";

const TermsAndConditionModal: React.FC<{
  open: boolean;
  onCancel: any;
  setTermsAndConditionField: any;
  termsAndConditionField: any;
  voucherType: any;
}> = (props) => {
  const [form] = Form.useForm();
  const location = useLocation();
  const { currentClient, companySettings } = useClient();
  const [conditionList, setConditionList] = React.useState<any[]>([]);
  const [isModelOpen, setIsModelOpen] = React.useState<boolean>(false);
  const [refresh, setRefresh] = React.useState<Boolean>();

  const getTemplateId = () => {
    if (
      props?.termsAndConditionField !== undefined &&
      props?.termsAndConditionField !== null
    ) {
      return props?.termsAndConditionField?.templateId;
    }
    switch (props?.voucherType) {
      case 1:
        return companySettings?.setting?.invoice?.tnCTemplateId;
      case 2:
        return companySettings?.setting?.crNote?.tnCTemplateId;
      case 11:
        return companySettings?.setting?.estimate?.tnCTemplateId;
      case 9:
        return companySettings?.setting?.sOrder?.tnCTemplateId;
      case 8:
        return companySettings?.setting?.pOrder?.tnCTemplateId;
      case 3:
        return companySettings?.setting?.pInvoice?.tnCTemplateId;
      case 4:
        return companySettings?.setting?.drNote?.tnCTemplateId;
      default:
        return undefined;
    }
  };

  const [id, setId] = React.useState<any>(getTemplateId());
  const [termsAndConditionsByIdList, setTermsAndConditionsByIdList] =
    React.useState<any>();
  const [editorContent, setEditorContent] = React?.useState(
    termsAndConditionsByIdList || ""
  );

  console.log("DESCR", props?.termsAndConditionField?.tnCText);

  console.log("id", id);
  var selVouchertype =
    props?.voucherType === Voucher_Type.SalesOrder ||
    props?.voucherType === Voucher_Type.SalesVoucher ||
    props?.voucherType === Voucher_Type.Quotation
      ? Voucher_Type.SalesVoucher
      : props?.voucherType === Voucher_Type.PurchaseOrder ||
        props?.voucherType === Voucher_Type.PurchaseVoucher
      ? Voucher_Type.PurchaseVoucher
      : props?.voucherType;

  React.useEffect(() => {
    const getTnC = async () => {
      let res: any = await TermsAndConditionsService?.getTermsAndConditionList(
        currentClient?.id,
        selVouchertype,
        0,
        0,
        ""
      );
      if (res) {
        console.log("res", res);
        setConditionList(res?.result);
        setTermsAndConditionsByIdList(res?.result?.termNConditions);
      }
    };
    getTnC();
  }, [refresh]);

  console.log(
    "location",
    location?.pathname ===
      `/accounts/clients/${currentClient?.id}/settings/customization`
  );

  React.useEffect(() => {
    if (id && currentClient?.id) {
      getTnCbyId();
    }
  }, [id, currentClient?.id, location?.pathname]);

  const getTnCbyId = async () => {
    try {
      if (
        location?.pathname ===
        `/accounts/clients/${currentClient?.id}/settings/customization`
      ) {
        debugger;
        const res = await TermsAndConditionsService?.getTermsAndConditionbyId(
          currentClient?.id,
          id ?? props?.termsAndConditionField?.templateId,
          selVouchertype
        );
        if (res?.result) {
          console.log("res", res);
          setTermsAndConditionsByIdList(res.result.termNConditions);
        }
      } else if (id !== props?.termsAndConditionField?.templateId) {
        debugger;
        const res = await TermsAndConditionsService?.getTermsAndConditionbyId(
          currentClient?.id,
          id ?? props?.termsAndConditionField?.templateId,
          selVouchertype
        );
        if (res?.result) {
          console.log("res", res);
          setTermsAndConditionsByIdList(res.result.termNConditions);
        } else {
          setTermsAndConditionsByIdList(props?.termsAndConditionField?.tnCText);
        }
      } else {
        setTermsAndConditionsByIdList(props?.termsAndConditionField?.tnCText);
      }
    } catch (error) {
      console.error("Error fetching terms and conditions:", error);
    }
  };

  // React.useEffect(() => {
  //   if (props?.termsAndConditionField?.tnCText) {
  //     setTermsAndConditionsByIdList(props?.termsAndConditionField?.tnCText);
  //   }
  // }, [conditionList]);

  return (
    <>
      <div>
        <Modal
          width={700}
          open={props.open}
          onCancel={() => props.onCancel({})}
          title="Select Term & Conditions"
          footer={
            <Space>
              <Button onClick={() => props.onCancel({})}>Cancel</Button>
              <Button
                type="primary"
                onClick={() => {
                  props?.setTermsAndConditionField({
                    tnCText: editorContent,
                    templateId: id,
                  });

                  props.onCancel();
                }}
              >
                Save
              </Button>
            </Space>
          }
        >
          <span>Select your terms & conditions</span>
          <Form form={form}>
            <Form.Item className="inputBoxMb" name="termNConditions">
              <Select
                placeholder="Select...."
                defaultValue={id}
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={conditionList?.map((value) => ({
                  value: value?.id,
                  label: value?.templateName,
                }))}
                onChange={(val: any) => setId(val)}
                dropdownRender={(itemsOption) => (
                  <>
                    {itemsOption}
                    <Divider
                      style={{
                        margin: "5px 0px",
                      }}
                    />
                    <Button
                      style={{ width: "100%" }}
                      icon={<PlusOutlined />}
                      type="link"
                      onClick={() => setIsModelOpen(true)}
                    >
                      New Item
                    </Button>
                  </>
                )}
              />
            </Form.Item>
            <span>Edit your conditions</span>
            <Form.Item name="editorContent">
              <SunEditor
                setContents={termsAndConditionsByIdList}
                setOptions={{
                  buttonList: [
                    ["undo", "redo"],
                    ["font", "fontSize"],
                    ["bold", "underline", "italic", "strike"],
                    ["fontColor", "hiliteColor"],
                    ["align", "list", "lineHeight"],
                    ["outdent", "indent"],
                    ["removeFormat"],
                  ],
                  defaultTag: "div",
                  showPathLabel: false,
                }}
                height="150px"
                onChange={(content) => setEditorContent(content)}
              />
            </Form.Item>
          </Form>
        </Modal>
      </div>
      {isModelOpen && (
        <AddTermsAndCondition
          onCancel={() => setIsModelOpen(false)}
          isModelOpen={isModelOpen}
          id={""}
          // voucher={}
          setRefresh={setRefresh}
        />
      )}
    </>
  );
};

export default TermsAndConditionModal;
