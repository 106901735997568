import { ApiUtility } from "../../Utilities/ApiUtility.axios";

export enum ItemInvStatus {
  All,
  Sales,
  Purchase,
  AdjustItem,
}

export enum InventoryType {
  Add = 0,
  Update = 1,
  Delete = 2,
  Active = 3,
}

export enum ItemType {
  All,
  SalesItems,
  PurchaseItems,
  SalesGoods,
  SalesService,
  PurchaseGoods,
  PurchaseService,
}

export enum UnitTypes {
  Length,
  Weight,
  Capacity,
  Countables,
}

class ItemsServices {
  route = "/v1/API/Acc_Item/";

  getItem = (clientId: string, id?: string) =>
    ApiUtility.get(`${this.route}${clientId}/Get${id ? `?id=${id}` : ""}`);

  getListItems = (
    clientId: string,
    start: number,
    length: any,
    sortCol?: string,
    sortDir?: string,
    search?: string,
    status?: any,
    type?: ItemType,
    gstRate?: number[],
    itemInventory?: boolean
  ) =>
    ApiUtility.getResult(`${this.route}${clientId}`, {
      start,
      length,
      sortCol,
      sortDir,
      search,
      type,
      status,
      gstRate,
      itemInventory,
    });

  getItemDetails = (clientId: string, itemId: string) =>
    ApiUtility.getResult(`${this.route}${clientId}/ItemIntDetails/${itemId}`);

  PostItems = (param: any, clientId: string, id?: string, config?: string) =>
    ApiUtility.postForm(
      `${this.route}${id ? clientId + "/" + id : clientId}`,
      param,
      // config
    );

  // "https://localhost:7142/V1/API/Acc_ItemInventory/6463611020350584c0d5c017/Get?itemId=6501a570643413cc49d1164c&start=0&length=15&status=0";

  // https: //localhost:7142/V1/API/Acc_ItemInventory/6463611020350584c0d5c017/Get?itemId=6465e265731bc731fb04ada8&start=0&length=15&type=1&type=2

  // "https://localhost:7142/V1/API/Acc_ItemInventory/6463611020350584c0d5c017/Get?itemId=651bc9472706aed188a399a7&start=0&length=15&from=01%2F09%2F2023&to=30%2F09%2F2023&type=1";

  getItemInventoryList = (
    clientId: string | undefined,
    itemId: string,
    start?: number,
    length?: number,
    from?: string,
    to?: string,
    sortCol?: string,
    sortDir?: string,
    type?: number[],
    partyId?: string[]
  ) =>
    ApiUtility.get(`/V1/API/Acc_ItemInventory/${clientId}/Get`, {
      itemId,
      start,
      length,
      from,
      to,
      sortCol,
      sortDir,
      type,
      partyId,
    });

  // https: //localhost:7142/V1/API/Acc_ItemInventory/64f96b34ef29cde5ee5e45fe?Type=0
  // /localhost:7142/V1/API/Acc_ItemInventory/64f96b34ef29cde5ee5e45fe/6509410c5f4e3d2158fbed7d?Type=0
  addItemInventory = (
    param: any,
    clientId: string | undefined,
    type?: InventoryType,
    id?: string
  ) =>
    ApiUtility.post(
      `/V1/API/Acc_ItemInventory/${
        id ? clientId + "/" + id : clientId
      }?Type=${type}`,
      param
    );

  // https://localhost:7142/V1/API/Acc_ItemInventory/64f96b34ef29cde5ee5e45fe/650941ff5f4e3d2158fbed80
  getItemInventory = (clientId: string | undefined, id: string) =>
    ApiUtility.get(`/V1/API/Acc_ItemInventory/${clientId}/${id}`);

  // Unit APi
  // https://localhost:7142/V1/API/Acc_Units/6463611020350584c0d5c017?start=0&length=150

  getUnitsList = (
    companyId: string | undefined,
    start: number,
    length: number,
    sortCol?: string,
    sortDir?: string,
    search?: string
  ) =>
    ApiUtility.get(`/V1/API/Acc_Units/${companyId}`, {
      start,
      length,
      sortCol,
      sortDir,
      search,
    });

  // https://localhost:7142/V1/API/Acc_Units/6463611020350584c0d5c017/Units
  // https: //localhost:7142/V1/API/Acc_Units/6463611020350584c0d5c017/Units/44
  postUnit = (param: any, clientId?: string, id?: number) =>
    ApiUtility.post(
      `/V1/API/Acc_Units/${
        id ? clientId + "/Units/" + id : clientId + "/Units"
      }`,
      param
    );

  getUnit = (clientId?: string, id?: number) =>
    ApiUtility.get(`/V1/API/Acc_Units/${clientId}`, id);
}
// eslint-disable-next-line new-parens
export default new ItemsServices();
