import { IndianStates } from "../../Types";
import { ApiUtility } from "../../Utilities/ApiUtility.axios";
import { GroupTypes } from "./ChartofAccountServicesNew";

export enum SearchLedgerByGroupsTypes {
  All,
  PartiesOnly,
  PartiesWithBank,
  BankAndCash,
  Sales,
  Purchase,
  DirectExpenses,
  IndirectExpenses,
  PaymentIn,
  PaymentOut,
  DutiesAndTaxes,
  DirectExpensesWithDutiesAndTaxes,
  IndirectExpensesWithDutiesAndTaxes,
  TaxforItems,
  IndirectIncomeOnly,
  FixedAssets,
  BankOnly,
  CashOnly,
  Depreciation,
  AssetnLiabilities,
  CreditorsOnly,
  DebtorsOnly,
  AllExpensesWithDutiesAndTaxes,
}

// Sundry creditors & Sundry debitors

export enum NewCompanyGstType_new {
  Unregistered,
  Regular,
  Composition,
  Unspecified,
  Consumer,
}
export enum TypeOfAddress {
  RegisteredAddress,
  BusinessAddress,
  BranchAddress,
  UnitAddress,
  GodownAddress,
}

// Dutes & Taxes enums
export enum Duty_Type {
  GST,
  TDS,
  TCS,
  Cess,
  Surcharge,
  Other,
}
export enum GSTTax_Type {
  IntegratedTax,
  CentralTax,
  StateTax,
  UnionTerritoryTax,
}
export enum TCS_Nature {
  Any,
}
export enum TDS_Nature {
  Any,
}
export enum Calc_Methods {
  TaxBasedOnItemRate,
  OnTotalSales,
  SurchargeOnTax,
  AdditionalDuty,
}
export enum LedgerStatus {
  all,
  active,
  deactive,
  deleted,
}

export enum ClientStatus {
  Active,
  Inactive,
  All,
}

class LedgerService {
  private route = "/v1/API/Acc_New_Ledgers";

  getResult(id: string, arg1: string, arg2: string, id1: any) {
    throw new Error("Method not implemented.");
  }
  // https: //localhost:7142/v1/API/Acc_New_Ledgers/65cdd05235241f48a55de738/GetLedgersByGroupTypes?ledgersByGroupSelType=3
  getLedgersByGroupName = (
    companyId: any,
    ledgersByGroupSelType: SearchLedgerByGroupsTypes,
    search?: string
  ) =>
    ApiUtility.getResult(`${this.route}/${companyId}/GetLedgersByGroupTypes`, {
      ledgersByGroupSelType,
      search,
    });

  // "https://localhost:7142/v1/API/Acc_New_Ledgers/65cdd05235241f48a55de738/GetList?start=0&length=15&groupSearch=a&ledgerParentId=661cfa1a60353ddd335781e3&status=1&GstTxt=G&StateTxt=0&ContPerson=a&ContPhone=a";
  //  ----- ladger Services(Api)  -----
  getLedgerList = (
    companyId: string | undefined,
    start?: number,
    length?: number,
    search?: string,
    groupSearch?: string,
    sortCol?: string,
    sortDir?: string,
    ledgerParentId?: string,
    status?: LedgerStatus,
    GstTxt?: string,
    StateTxt?: IndianStates,
    ContPerson?: string,
    ContPhone?: string,
    // filterSearch?: any
    // ledgerStatus: ledgerStatus
    OnlyViewValue?: boolean
  ) =>
    ApiUtility.getResult(`${this.route}/${companyId}/GetList`, {
      start,
      length,
      search,
      groupSearch,
      sortCol,
      sortDir,
      ledgerParentId,
      status,
      GstTxt,
      StateTxt,
      ContPerson,
      ContPhone,
      OnlyViewValue,
      // ledgerStatus,
    });

  // https: //localhost:7142/v1/API/Acc_New_Ledgers/65cdd05235241f48a55de738/65cdfde02b71e33b3f06f6ef
  getLedgerDetails = (companyId: string | undefined, ledgerId: string) =>
    ApiUtility.get(`${this.route}/${companyId}/${ledgerId}`);

  // https://localhost:7142/v1/API/Acc_New_Ledgers/6463611020350584c0d5c017
  // https: //localhost:7142/v1/API/Acc_New_Ledgers/65cdd05235241f48a55de738/65cdfde02b71e33b3f06f6ef

  postLedger = (companyId: string | undefined, params: any, id?: string) =>
    ApiUtility.postForm(`${this.route}/${companyId}${id ? "/" + id : ""}`, params);

  // https://localhost:7142/v1/API/Acc_New_Ledgers/65cdd05235241f48a55de738/MakeLedgerActiveDeactive/6603bb240221b834a09d1916
  // https: //localhost:7142/v1/API/Acc_New_Ledgers/65cdd05235241f48a55de738/MakeLedgerActiveDeactive/

  makeLedgerActiveInactive = (companyId: string, ledgerId: string) =>
    ApiUtility.post(
      `${this.route}/${companyId}/MakeLedgerActiveDeactive/${ledgerId}`,
      null
    );

  exportCsv = (
    companyId: string,
    search?: string,
    groupSearch?: string,
    sortCol?: string,
    sortDir?: string,
    ledgerParentId?: string,
    status?: LedgerStatus,
    GstTxt?: string,
    StateTxt?: IndianStates,
    ContPerson?: string,
    ContPhone?: string
    // filterSearch?: any
    // ledgerStatus: ledgerStatus
  ) =>
    ApiUtility.downloadFile(`${this.route}/${companyId}/Download/CSV`, {
      search,
      groupSearch,
      sortCol,
      sortDir,
      ledgerParentId,
      status,
      GstTxt,
      StateTxt,
      ContPerson,
      ContPhone,
      // ledgerStatus,
    });

  deleteLedgerMaster = (companyId: string, ledgerId: string) =>
    ApiUtility.delete(`${this.route}/${companyId}/${ledgerId}`);

  ValidateImportLedgers = (companyId: string | undefined, csvData: any) =>
    ApiUtility.post(
      `${this.route}/${companyId}/ValidateImportLedgers`,
      csvData
    );

  ImportLedgers = (companyId: string | undefined, csvData: any) =>
    ApiUtility.post(`${this.route}/${companyId}/ImportLedgers`, csvData);
}

export default new LedgerService();
