import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Select,
  Spin,
  Table,
  TableColumnsType,
} from "antd";
import React from "react";
import LedgerService, {
  SearchLedgerByGroupsTypes,
} from "../../../Services/LedgerService";
import { useClient } from "../../../Context/ClientContext";
import { dayjs } from "../../../../Utilities/dayjs";
import { useTheme } from "../../../Context/ThemeContext";

import { voucherType } from "../../Reports/VoucherDetailsByLedger";
import { Utils } from "../../../../Utilities/Utils";
import { LoadingOutlined } from "@ant-design/icons";
import BankTransactions from "../../../Services/BankTransactions";
import AdjustBankBalanceModel, {
  AdjustBankType,
} from "./AdjustBankBalanceModel";
import { Party_Tax } from "../../../Services/VoucherServices";

const MatchVoucher: React.FC<{
  open: any;
  onCancel: any;
  csvData: any;
  importList: any;
  bankImportIndex: number;
  bankEntriesId: any;
  setRefresh: any;
  bank?: any;
}> = (props) => {
  const [form] = Form.useForm();
  const { themeData } = useTheme();
  const { currentClient } = useClient();
  const [ledgerList, setLedgerList] = React?.useState<any>();
  const [lederId, setLedgerId] = React?.useState<any>();
  const [selectedVouchers, setSelectedVouchers] = React?.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [filterType, setFilterType] = React.useState<any>(true);
  const [selectedRecord, setSelectedRecord] = React.useState<any>(null);
  const [totalAmount, setTotalAmount] = React.useState();
  const [filterDate, setFilterDate] = React.useState<{
    date: string;
  }>({
    date: props.csvData?.date,
  });
  const [searchAdjustmentEntryModal, setSearchAdjustmentEntryModal] =
    React?.useState<{
      type: string;
      param: any;
    }>({ type: "", param: {} });
  const [trigger, setTrigger] = React.useState<boolean>(false);

  const [voucherData, setVoucherData] = React?.useState<any>();
  const moneyOutOrIn = props.csvData?.moneyOut || props.csvData?.moneyIn;

  React.useEffect(() => {
    if (props.importList) {
      setFilterDate({
        date: props.csvData?.date,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.csvData?.date]);

  console.log("props.saveBankImport(data);", props);

  const getLedgersByGroupName = async () => {
    let res = await LedgerService?.getLedgersByGroupName(
      currentClient?.id,
      SearchLedgerByGroupsTypes?.PaymentIn
    );
    if (res) {
      setLedgerList(res);
    }
  };
  React.useEffect(() => {
    getLedgersByGroupName();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lederId]);

  console.log("csvData", props.csvData);

  const getList = async () => {
    const filterAmount = props.csvData?.moneyIn
      ? props.csvData.moneyIn
      : -props.csvData?.moneyOut;

    setLoading(true);
    let res = await BankTransactions?.GetVoucherList(
      currentClient?.id,
      props.importList?.ledgerId,
      filterAmount,
      props.csvData?.reference,
      filterType,
      filterDate?.date
    );
    if (res) {
      setLoading(false);
      setVoucherData(
        res?.result?.map((v: any, index: number) => ({
          key: index,
          number: v?.number,
          date: dayjs(v?.date).format("DD/MM/YYYY"),
          dueAmount: Utils?.getFormattedNumber(v?.dueAmount),
          partyName: v?.partyLedger[0]?.ledgerName,
          partyId: v?.partyLedger[0]?.id,
          voucherId: v?.voucherId,
          effectiveId: v?.effectiveLedger[0]?.id,
          effectiveLedger: v?.effectiveLedger[0]?.ledgerName,
          type: v?.voucherType,
          invAmount: Utils?.getFormattedNumber(v?.invAmount),
          ledgerName:
            props.importList?.ledgerId === v?.partyLedger[0]?.id
              ? v?.effectiveLedger[0]?.ledgerName
              : props.importList?.ledgerId === v?.effectiveLedger[0]?.id
              ? v?.partyLedger[0]?.ledgerName
              : "",
        }))
      );
    } else {
      setLoading(false);
    }
  };

  // console.log("list");

  React.useEffect(() => {
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lederId, filterDate?.date, filterType, trigger]);

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      console.log("Selected Rows: ", selectedRows);
      // const moneyOutOrIn = csvData?.moneyOut || csvData?.moneyIn;
      const totalAmount = selectedRows.reduce(
        (acc, row) => acc + (Utils.getNumber(row?.dueAmount) || 0),
        0
      );
      setTotalAmount(totalAmount);
      form.resetFields();
      selectedRows.map((row: any, index: number) => {
        form.setFieldValue(["amount", selectedRowKeys[index]], row?.dueAmount);
      });
      setSelectedVouchers(selectedRows);
    },
  };

  // post Data
  const postData = () => {
    props.setRefresh(true);
    let requestData = {
      bankImpEntryId: props.bankEntriesId,
      EntryDetail: {
        date: dayjs(props.csvData?.date, "DD/MM/YYYY"),
        reference: props.csvData?.reference,
        moneyIn: props.csvData?.moneyIn,
        moneyOut: props.csvData?.moneyOut,
      },
      vouchersDetail: selectedVouchers.map((vch: any) => ({
        id: vch?.voucherId,
        name: vch.number,
        voucherType: vch?.type,
      })),
    };
    console.log("req", requestData);

    form.validateFields().then(async () => {
      await BankTransactions?.saveSearchVoucherDetails(
        currentClient?.id,
        props.importList?.ledgerId,
        requestData
      ).then((res: any) => {
        if (res?.status) {
          console.log("res", res);
          notification.success({
            message: "",
            description: "saved successfully.",
            placement: "topRight",
          });
          props.onCancel();
          props.setRefresh(false);
        } else {
          notification.error({
            message: "",
            description: res?.message || "An error occurred while saving.",
            placement: "topRight",
          });
        }
      });
    });
  };

  const columns: TableColumnsType<any> = [
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Voucher Number",
      dataIndex: "number",
    },

    {
      title: "Voucher Type",
      dataIndex: "type",
      render: (_, record) => <>{voucherType[record?.type]}</>,
    },

    {
      title: "Ledger Name",
      dataIndex: "ledgerName",
    },

    {
      title: "Invoice Amount",
      dataIndex: "invAmount",
      align: "right",
    },
    {
      title: "Due Amount",
      dataIndex: "dueAmount",
      align: "right",
    },

    {
      title: "Allocated Amount",
      dataIndex: "",
      width: "13%",
      render: (_: any, record: any, index: number) => (
        <>
          <Form form={form} style={{ margin: "0px", padding: "0px" }}>
            <Form.Item
              key={record?.key}
              name={["amount", index]}
              className="m-0"
              style={{ margin: "0px", padding: "0px" }}
            >
              <Input />
            </Form.Item>
          </Form>
        </>
      ),
    },
  ];

  return (
    <>
      <div>
        <Modal
          onCancel={() => props.onCancel()}
          width={1200}
          open={true}
          footer={
            <div style={{ marginTop: "0px" }}>
              <Button
                onClick={() => {
                  props.onCancel();
                }}
              >
                Cancel
              </Button>
              {totalAmount !== moneyOutOrIn ? (
                <>
                  <Button
                    disabled
                    type="primary"
                    onClick={postData}
                    className="modelSaveButtonMargin"
                  >
                    Save
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    type="primary"
                    onClick={postData}
                    className="modelSaveButtonMargin"
                  >
                    Save
                  </Button>
                </>
              )}
            </div>
          }
          title="Match Voucher"
        >
          <Spin
            spinning={loading}
            indicator={<LoadingOutlined />}
            style={{
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <div>
              <Row justify={"end"}>
                <Col>
                  <Button
                    type="primary"
                    onClick={() =>
                      setSearchAdjustmentEntryModal({
                        type: "SearchAdjustment",
                        param: {},
                      })
                    }
                  >
                    Add Adjustment Entry
                  </Button>
                </Col>
              </Row>
              <Row style={{ marginTop: "10px" }} justify={"space-between"}>
                <Col>
                  <Row gutter={9}>
                    <Col>
                      <Select
                        disabled
                        defaultValue={props.importList?.ledgerId}
                        placeholder="Select.."
                        onChange={(val: any) => setLedgerId(val)}
                        style={{ width: "200px" }}
                        options={ledgerList?.map((itm: any) => ({
                          label: itm?.ledgerName,
                          value: itm?.id,
                          title: itm?.partyOrTax,
                        }))}
                      />
                    </Col>
                    <Col>
                      <span style={{ marginRight: "10px" }}>FIlter Type :</span>

                      <Select
                        showSearch
                        placeholder="Select a person"
                        defaultValue={filterType}
                        optionFilterProp="label"
                        onChange={(val: any) => setFilterType(val)}
                        options={[
                          {
                            value: true,
                            label: "Exact Search",
                          },
                          {
                            value: false,
                            label: "Multiple Search",
                          },
                        ]}
                      />
                    </Col>
                  </Row>
                </Col>

                <Col span={15}>
                  <Row gutter={12} justify={"end"}>
                    <Col style={{ paddingTop: "5px" }}>
                      <span style={{ paddingRight: "10px" }}>
                        Entry Amount :
                      </span>
                      {props.csvData?.moneyOut ? (
                        <span style={{ fontWeight: "bold" }}>
                          {Utils?.getFormattedNumber(props.csvData?.moneyOut)}
                        </span>
                      ) : props.csvData?.moneyIn ? (
                        <span style={{ fontWeight: "bold" }}>
                          {Utils?.getFormattedNumber(props.csvData?.moneyIn)}
                        </span>
                      ) : (
                        <>0</>
                      )}
                    </Col>
                    <Col style={{ paddingTop: "5px" }}>
                      <span style={{ paddingRight: "10px" }}>
                        Allocated Amount :
                      </span>
                      {totalAmount === moneyOutOrIn ? (
                        <>
                          <span style={{ fontWeight: "bold", color: "green" }}>
                            {Utils?.getFormattedNumber(totalAmount)}
                          </span>
                        </>
                      ) : (
                        <>
                          <span style={{ fontWeight: "bold", color: "red" }}>
                            {Utils?.getFormattedNumber(totalAmount)}
                          </span>
                        </>
                      )}
                    </Col>
                    <Col>
                      <span style={{ paddingRight: "10px" }}>Date :</span>
                      <DatePicker
                        disabled
                        value={
                          filterDate.date
                            ? dayjs(filterDate.date, "DD/MM/YYYY")
                            : null
                        }
                        allowClear
                        format="DD/MM/YYYY"
                        style={{ width: "150px", border: "1px solid #f1f3ff" }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>

              <div style={{ marginTop: "20px" }}>
                <Table
                  rowSelection={{ ...rowSelection }}
                  className={`Tabel-style table-${
                    themeData?.componentSize ?? "middle"
                  }`}
                  columns={columns}
                  dataSource={voucherData}
                  size="small"
                />
              </div>
            </div>
          </Spin>
        </Modal>
      </div>
      {searchAdjustmentEntryModal.type === "SearchAdjustment" && (
        <AdjustBankBalanceModel
          open={true}
          onCancel={() =>
            setSearchAdjustmentEntryModal({ type: "", param: "" })
          }
          type={AdjustBankType.BankReconciliation}
          bank={props?.bank}
          bankDetails={props?.csvData}
          bankEntriesId={props?.bankEntriesId}
          bankImportIndex={props?.bankImportIndex}
          setRefresh={setTrigger}
        />
      )}
    </>
  );
};

export default MatchVoucher;
