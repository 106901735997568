import { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useClient } from "../../Context/ClientContext";
import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Dropdown,
  Empty,
  Form,
  Input,
  InputNumber,
  MenuProps,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Tooltip,
  Typography,
  Upload,
} from "antd";
import noteContext from "../../Context/StyleContext";
import { notificationContext } from "../../Common/PageRoute";
import { Utils } from "../../../Utilities/Utils";
import { percentCal, percentToRs } from "../../Common/CAPComponents";
import {
  DownOutlined,
  EditOutlined,
  EyeOutlined,
  LoadingOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import VoucherServices, {
  AddressEnum,
  DiscountType,
  Party_Tax,
  Voucher_Type,
} from "../../Services/VoucherServices";
import { dayjs } from "../../../Utilities/dayjs";
import LedgerService, {
  SearchLedgerByGroupsTypes,
  TypeOfAddress,
} from "../../Services/LedgerService";
import SalesService, { CAPItemTypes } from "../../Services/SalesService";
import TextArea from "antd/es/input/TextArea";
import { getIndianStates } from "../../Services/ClientService";
import { ConfirmationModal } from "../../Common/ConfirmationModal";
import { handleRoundoff, returnNetAmount } from "./AddSalesInvoice";
import deleteImg from "../../../images/deletebutton.svg";
import AddItems from "../Purchase/AddItems";
import { AddLedger } from "../Master/Ledger/AddLedger";
import QuotationServices from "../../Services/QuotationServices";
import { CompanyGSTTypes } from "../../../Types/CommonConstants";
import {
  SalesDropdownItems,
  PurchaseDropdownItems,
} from "./SalesandPurchaseDropdownList";
import EntryLevelKeys from "../../Common/EntryLevelKeys";
import { Discount_Policy } from "../../Services/SettingService";
import DraftService from "../../Services/DraftService";
import DocumentPreview from "../../Common/DocumentPreview";
import TermsAndConditionModal from "./Terms&ConditionModal";
import { useUser } from "../../../Context/UserContext";

const AddQuotation = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const inputRef: any = useRef(null);
  const { windowWidth } = useContext(noteContext);
  const { currentClient, companySettings } = useClient();
  const { getAllIndStates } = useUser();
  const stateOptions: any = getAllIndStates();
  const { id, salesOrderId, quotationId, partyId, draftId } = useParams();
  const { openNotification } = useContext(notificationContext);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [trigger, setTrigger] = useState(false);
  const [dueOn, setDueOn] = useState<number>(0);
  const [balance, setBalance] = useState<number>(0);
  const [totalTcs, setTotalTcs] = useState<number>(0);
  const [totalTds, setTotalTds] = useState<number>(0);
  const [tdsOrTcs, setTdsOrTcs] = useState<number>(0);
  // const [totalgst, setTotalgst] = useState<number>(0);
  // const [totalQty, setTotalQty] = useState<number>(0);
  const [newCustomer, setNewCustomer] = useState<any>();
  const [isOpenDueDate, setIsDueDate] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [itemLength, setItemLength] = useState<number>(0);
  const [estimateNo, setEstimateNo] = useState<string>("");
  const [searchItem, setSearchItem] = useState<string>("");
  const [isInCash, setIsInCash] = useState<boolean>(false);
  const [itemsOption, setItemsOption] = useState<any[]>([]);
  const [deleteItemList, setDeleteItemList] = useState<any>();
  // const [invoiceTotal, setInvoiceTotal] = useState<number>(0.0);
  // const [salesOrderNo, setSalesOrderNo] = useState<string>("");
  // const [salesInvoiceNo, setSalesInvoiceNo] = useState<any>("");
  const [editParty, setEditParty] = useState<{ id: string }>({ id: "" });
  const [selectedParty, setSelectedParty] = useState<string>("");

  const [isGSTEnabled, setGstEnabled] = useState<boolean>(true);
  const [confirmation, setConfirmation] = useState<boolean>(false);
  const [ledgerconfirmation, setLedgerConfirmation] = useState<boolean>(false);
  const [tcsModelOpen, setTcsModelOpen] = useState<boolean>(false);
  const [tdsModelOpen, setTdsModelOpen] = useState<boolean>(false);
  const [customerOptions, setCustomerOptions] = useState<any[]>([]);
  // const [addressList, setAddressList] = useState<any[]>([]);
  const [effectiveLedger, setEffectiveLedger] = useState<any[]>([]);
  const [currentCustomerState, setCurrentCustomerState] = useState<number>();
  const [saveButtonLoading, setSaveButtonLoading] = useState<boolean>(false);
  const [saveNewLoading, setSaveNewLoading] = useState<boolean>(false);
  const [itemId, setItemId] = useState<any>();
  const [loadItemAPI, setloadItemAPI] = useState<boolean>(false);
  const [termsAndConditionField, setTermsAndConditionField] = useState<any>();
  const [customerAddress, setCustomerAddress] = useState<any>();
  const [openModel, setOpenModel] = useState<{ type: string; param: any }>({
    type: "",
    param: {},
  });
  const [newItem, setNewItem] = useState<any>({
    data: {},
    isSaved: false,
  });
  const [itemFields, setItemFields] = useState<any>({
    field: 0,
    index: 0,
    add: null,
  });
  const [searchCustomer, setSearchCustomer] = useState<{
    search: string;
    length: number;
  }>({
    search: " ",
    length: 15,
  });

  const [footerTotal, setFooterTotal] = useState<{
    qty: number;
    unitPrice: number;
    taxableAmount: number;
    discount: number;
    gst: number;
    amount: number;
  }>({
    qty: 0,
    unitPrice: 0,
    taxableAmount: 0,
    discount: 0,
    gst: 0,
    amount: 0,
  });

  const [footerLedger, setFooterLedger] = useState<{
    ledgerAmount: number;
    ledgerGstAmount: number;
    ledgerTotalAmount: number;
  }>({
    ledgerAmount: 0,
    ledgerGstAmount: 0,
    ledgerTotalAmount: 0,
  });

  // const [directExpenses, setDirectExpenses] = useState<boolean>(false);
  const [ledgerLength, setLedgerLength] = useState<number>(0);
  const [freightAndInsuranceOptions, setFreightAndInsuranceOptions] =
    useState<any>([]);
  const [deleteLedgerList, setDeleteLedgerList] = useState<any>();
  const [ledgerAmount, setLedgerAmount] = useState<any>();
  const [showFreightInsurance, setShowFreightInsurance] =
    useState<boolean>(false);
  const [currentInvoiceDetails, setCurrentInvoiceDetails] = useState<any>();
  const [dateFiled, setDateFiled] = useState<any>();

  const [transactionId, setTransactionId] = useState<{
    partyId: string;
    effectiveId: string;
  }>({ partyId: "", effectiveId: "" });

  const [selectedOtherExpenses, setSelectedOtherExpenses] = useState<any[]>([]);
  const [editDate, setEditDate] = useState<any>();
  const [roundOffAmount, setRoundoffAmount] = useState<number>(0.0);
  const [cashDiscountRs, setCashDiscountRs] = useState<number>(0.0);
  const [updateTimeData, setUpdateTimeData] = useState<{
    contactDetails: boolean;
    shippingAddress: boolean;
    friInsuranceOtherCharge: boolean;
    receipt: boolean;
    cashDiscount: boolean;
    tradeDiscount: boolean;
    isGSTVoucher: boolean;
    showItemDescription: boolean;
    roundOff: boolean;
  }>({
    contactDetails: false,
    shippingAddress: false,
    friInsuranceOtherCharge: false,
    receipt: false,
    cashDiscount: false,
    tradeDiscount: false,
    isGSTVoucher: false,
    showItemDescription: false,
    roundOff: false,
  });
  const [isSameBillingAddress, setIsSameBillingAddress] =
    useState<boolean>(false);
  const [deleteUploadId, setDeleteUploadId] = useState<string[]>([]);
  const [previewImage, setPreviewImage] = useState<{
    isBaseConvert: boolean;
    path: any;
  }>({ isBaseConvert: false, path: "" });

  const gstOption = companySettings?.setting?.gstDetails?.gst;
  const isStateSame = currentCustomerState === currentClient?.state!;

  const amountInNumber = (val: any, key: any) => {
    return Utils.getNumber(val?.amount?.[key]);
  };
  const price = (curr: any) => amountInNumber(curr, "price");
  const qty = (curr: any) => amountInNumber(curr, "qty");
  const discount = (curr: any) => amountInNumber(curr, "discount");
  const taxRate = (curr: any) => Utils.getNumber(curr?.amount?.tax?.value);
  const netAmount = (curr: any) => price(curr) * qty(curr);
  const discountRupee = (curr: any, percentDiscountAll?: number) => {
    if (percentDiscountAll || percentDiscountAll === 0) {
      return percentCal(netAmount(curr), percentDiscountAll);
    } else return percentCal(netAmount(curr), discount(curr));
  };
  const amountWithoutTax = (curr: any, percentDiscountAll?: number) =>
    netAmount(curr) - discountRupee(curr, percentDiscountAll);

  const itemTaxAmt = (curr: any, percentDiscountAll?: number) =>
    percentCal(amountWithoutTax(curr, percentDiscountAll), taxRate(curr));

  const amountWithTax = (curr: any, percentDiscountAll?: number) =>
    amountWithoutTax(curr, percentDiscountAll) +
    itemTaxAmt(curr, percentDiscountAll);

  const amountWithOutTax = (curr: any) => amountWithoutTax(curr);

  const amount = (curr: any) => amountInNumber(curr, "amount");
  const gstrate = (curr: any) => Utils.getNumber(curr?.gstRate?.title);
  const gstamount = (curr: any) => percentCal(curr?.amount, gstrate(curr));
  const ledgertotalamout = (curr: any) =>
    Utils.getNumber(curr?.amount) + gstamount(curr);
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  // Date Api
  useEffect(() => {
    const getDate = async () => {
      if (currentClient?.id && !quotationId) {
        await VoucherServices.getMaxVoucherDate(
          currentClient?.id!,
          Voucher_Type.Quotation
        ).then((res) => {
          const givenDate = new Date(res?.result);
          const currentTime = new Date();

          // Set the time part of givenDate to match currentTime
          givenDate?.setHours(
            currentTime.getHours(),
            currentTime.getMinutes(),
            currentTime.getSeconds(),
            currentTime.getMilliseconds()
          );
          form.setFieldValue("date", dayjs(givenDate));

          setDateFiled(res.result);
        });
      }
    };
    getDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient?.id]);

  // initital value
  useEffect(() => {
    if (currentClient?.id && !salesOrderId && dateFiled) {
      setLoading(true);
      VoucherServices.getVoucherMaxNo(
        currentClient?.id!,
        Voucher_Type.Quotation,
        dateFiled
      )
        .then((res) => {
          form.setFieldValue("number", res?.result);
        })
        .catch((err: any) => {
          openNotification("error", err.message);
          console.error(err.message);
        });
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient?.id, dateFiled]);

  // ----- Serch customer Api ------
  useEffect(() => {
    LedgerService.getLedgersByGroupName(
      currentClient?.id!,
      SearchLedgerByGroupsTypes.PartiesOnly
    )
      .then((res: any) => {
        if (res) {
          setCustomerOptions(res);
          console.log(customerOptions, "getledger");
        }
      })
      .catch((ex: any) => {
        console.error(ex);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient?.id, trigger]);

  // ----- Serch effectiveLedger Api ------
  useEffect(() => {
    LedgerService.getLedgersByGroupName(
      currentClient?.id!,
      SearchLedgerByGroupsTypes.Sales
    )
      .then((res: any) => {
        if (res) {
          setEffectiveLedger(res);
        }
      })
      .catch((ex: any) => {
        console.error(ex);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient?.id, trigger]);

  // Edit get api
  useEffect(() => {
    if (id || draftId) {
      const editData = async (id: any) => {
        setLoading(true);
        try {
          const res = draftId
            ? await DraftService.getByDraftId(currentClient?.id!, draftId)
            : await QuotationServices.getQuotationId(
                currentClient?.id!,
                id,
                Voucher_Type.Quotation
              );

          if (res?.result) {
            console.log("true............", res.result?.partyLedger[0]?.ref);
            // CustomerSelect(res.result?.partyLedger[0]?.id);
            setTermsAndConditionField(res?.result?.termsNConditions);

            setEditParty({ id: res.result?.partyLedger[0]?.id });
            setIsSameBillingAddress(res?.result?.isShippingAddressSame);
            setFooterTotal({
              qty: res.result?.amountSummary?.totalQty,
              unitPrice: res.result?.amountSummary?.totalPrice,
              taxableAmount:
                res.result?.amountSummary?.taxableAmount +
                res.result?.amountSummary?.totalDis,
              discount: res.result?.amountSummary?.totalDis,
              gst: res.result?.amountSummary?.totalItemGSTAmt,
              amount:
                Math.abs(res.result?.effectiveLedger[0]?.amount) +
                res.result?.amountSummary?.totalItemGSTAmt,
            });
            setFooterLedger({
              ledgerAmount: res.result?.amountSummary?.cifExpenses,
              ledgerGstAmount: res.result?.amountSummary?.totalCIFGSTAmt,
              ledgerTotalAmount:
                res.result?.amountSummary?.cifExpenses +
                res.result?.amountSummary?.totalCIFGSTAmt,
            });
            setBalance(res.result.amountSummary?.receivedAmount);
            setRoundoffAmount(
              res.result?.amountSummary?.roundOff?.roundOffAmount ?? 0
            );
            setCashDiscountRs(
              res.result?.amountSummary?.cashDiscount?.discountAmount ?? 0
            );
            // setInvoiceTotal(
            //   Math.abs(res.result?.effectiveLedger[0]?.amount) +
            //     res.result?.amountSummary?.totalItemGSTAmt +
            //     res.result?.amountSummary?.cifExpenses +
            //     res.result?.amountSummary?.totalCIFGSTAmt
            // );
            setIsInCash(res.result.paymentType === 1 ? true : false);
            setItemLength(res.result.items.length);
            setCurrentCustomerState(res.result.supplyState);
            // setGstEnabled(res?.result?.isGstEnabled);
            const filterFreightInsLedger =
              res.result?.freightInsLedgers?.filter(
                (x: any) => x?.partyOrTax !== 7 && x?.partyOrTax !== 6
              );
            setShowFreightInsurance(filterFreightInsLedger?.length > 0);
            //  setTransactionId({
            //    partyId: res.result?.partyLedger[0]?.transactionId,
            //    effectiveId: res.result?.effectiveLedger[0]?.transactionId,
            //  });
            const Items = res.result?.items?.map((itm: any) => {
              return {
                ...itm,
                amount: {
                  ...itm?.amount,
                  tax: {
                    key: itm?.amount?.tax?.id,
                    label: itm?.amount?.tax?.name,
                    value: itm?.amount?.tax?.rate,
                  },
                },
              };
            });

            const freightInsLedgers: any[] = filterFreightInsLedger?.map(
              (exp: any) => {
                setSelectedOtherExpenses((select) => [...select, exp?.id]);
                return {
                  ...exp,
                  id: {
                    value: exp?.id,
                    label: exp?.ledgerName,
                  },
                  amount: Math.abs(exp?.amount),
                  transactionId: exp?.transactionId,
                  gstRate: {
                    value: exp?.gstRate?.id,
                    title: exp?.gstRate?.rate,
                    label: exp?.gstRate?.name,
                  },
                };
              }
            );

            setLedgerLength(freightInsLedgers?.length ?? 1);
            setEditDate(res.result?.date);

            setUpdateTimeData({
              contactDetails:
                res.result?.contactName || res.result?.contactNo ? true : false,
              shippingAddress:
                res.result?.shippingAddress?.address ||
                res.result?.shippingAddress?.type
                  ? true
                  : false,
              friInsuranceOtherCharge: freightInsLedgers?.length > 0,
              // receipt: bankDtsGet?.length > 0,
              receipt: false,
              cashDiscount:
                res.result?.amountSummary?.cashDiscount?.discountAmount > 0,
              tradeDiscount: res.result?.amountSummary?.totalDis > 0,
              isGSTVoucher: res.result?.isGSTVoucher,
              showItemDescription: res.result?.showItemDescription,
              roundOff:
                res.result?.amountSummary?.roundOff?.roundOffApplicable ||
                res.result?.amountSummary?.roundOff?.roundOffAmount !== 0,
            });

            form.setFieldsValue({
              attachments: res.result?.attachments,
              showFreightInsLedgers: freightInsLedgers?.length > 0,
              roundOff: res.result?.amountSummary?.roundOff?.roundOffAmount,
              roundoffCheckbox:
                res.result?.amountSummary?.roundOff?.roundOffApplicable,
              cashDiscount:
                res.result?.amountSummary?.cashDiscount?.discountRate ?? 0,
              cashDiscountDrop:
                Number(res.result?.amountSummary?.cashDiscount?.discountRate) >
                0
                  ? DiscountType.PercentageDiscount
                  : DiscountType.RupeeDiscount,
              items: [
                ...Items,
                { amount: { price: 0.0, qty: 0, grossAmount: 0 } },
              ],
              freightInsLedgers:
                freightInsLedgers?.length > 0
                  ? [
                      ...freightInsLedgers,
                      {
                        id: null,
                        gstRate: 0,
                        gstAmount: 0,
                        totalAmount: 0,
                        amount: 0,
                      },
                    ]
                  : [
                      {
                        id: null,
                        gstRate: 0,
                        gstAmount: 0,
                        totalAmount: 0,
                        amount: 0,
                      },
                    ],
              effectiveLedger: {
                value: res.result?.effectiveLedger[0]?.id,
                label: res.result?.effectiveLedger[0]?.ledgerName,
              },
              phoneNo: res.result?.phoneNo,
              partyLedger: {
                value: res.result?.partyLedger[0]?.id,
                label: res.result?.partyLedger[0]?.ledgerName,
              },
              number: res.result.number,
              billingAddress: res.result.billingAddress,
              shippingAddress: res.result.shippingAddress,
              supplyState: res.result.supplyState,
              narration: res?.result?.narration,
              receivedAmount: res.result.amountSummary?.receivedAmount,
              paymentType: res.result.paymentType === 1 ? true : false,
              date: dayjs(res.result.date),
              dueDate: dayjs(
                dayjs(res.result.dueDate).format("DD/MM/YYYY"),
                "DD/MM/YYYY"
              ),
            });
          } else console.error("error..");
          setLoading(false);
        } catch (err: any) {
          setLoading(false);
          console.error("Error..", err);
          openNotification("error", err);
        }
      };
      editData(id ?? draftId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, draftId]);

  //------ Get a Other Expenses(Direct Income) Ledger
  useEffect(() => {
    LedgerService.getLedgersByGroupName(
      currentClient?.id!,
      SearchLedgerByGroupsTypes.IndirectIncomeOnly
    )
      .then((res: any) => {
        // console.log(res, "indirExpLedgers");
        if (res) {
          setFreightAndInsuranceOptions(res);
        }
      })
      .catch((ex: any) => {
        console.error(ex);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient?.id, trigger]);

  // -------- Add Sales Estimate Post API ---------
  const save = async (isSaveAndNew?: boolean, isDraft?: boolean) => {
    try {
      const val = isDraft ? form.getFieldsValue() : await form.validateFields();

      if (!isDraft) {
        isSaveAndNew ? setSaveNewLoading(true) : setSaveButtonLoading(true);
      }

      const freightInsLedgers = val?.freightInsLedgers?.map((exp: any) => {
        return {
          ...exp,
          id: exp.id?.value,
          gstRate: {
            id: exp?.gstRate?.value,
            rate: exp?.gstRate?.title,
            name: exp?.gstRate?.label,
          },
        };
      });

      const partyLedger = new Array({
        id: val?.partyLedger?.value,
        PartyOrTax: Party_Tax.party,
        amount: Utils.getFormattedNumber(
          Utils.getNumber(footerTotal.amount) +
            Utils.getNumber(footerLedger.ledgerTotalAmount) -
            Math.round(cashDiscountRs * 100) / 100 +
            roundOffAmount
        ),
        transactionId:
          transactionId.partyId === "" ? null : transactionId.partyId,
      });

      const effectiveLedger = new Array({
        id: val?.effectiveLedger?.value,
        PartyOrTax: Party_Tax.other,
        amount: Utils.getFormattedNumber(
          Utils.getNumber(footerTotal.taxableAmount) - footerTotal.discount
        ),
        transactionId:
          transactionId.partyId === "" ? null : transactionId.partyId,
      });

      const Items = val?.items?.map((itm: any) => {
        return {
          ...itm,
          amount: {
            ...itm?.amount,
            grossAmount: Utils.getNumber(itm?.amount?.price) * itm?.amount?.qty,
            tax: {
              id: itm?.amount?.tax?.key,
              rate: itm?.amount?.tax?.value,
              name: itm?.amount?.tax?.label,
            },
          },
        };
      });

      let givenDate;
      if (quotationId && dayjs(val.date).isSame(dayjs(editDate), "day")) {
        givenDate = editDate;
      } else {
        givenDate = new Date(val.date);
        const currentTime = new Date();

        // Set the time part of givenDate to match currentTime
        givenDate?.setHours(
          currentTime.getHours(),
          currentTime.getMinutes(),
          currentTime.getSeconds(),
          currentTime.getMilliseconds()
        );
      }
      const invoice = {
        ...val,
        deleteUploadId: deleteUploadId,
        items: Items,
        isShippingAddressSame: isSameBillingAddress,
        date: dayjs(givenDate).toISOString(),
        freightInsLedgers,
        partyLedger,
        effectiveLedger,
        draftId: draftId,
        termsNConditions: termsAndConditionField,
        // despatchDetail: invoiceDespatchDetails,
        // exportDetail: invoiceExportDetails,
        shippingAddress: val?.shippingAddress.type
          ? val.shippingAddress
          : { address: val?.shippingAddress?.address },
        isStateSame: isStateSame,
        amountSummary: {
          totalQty: Utils.getFormattedNumber(footerTotal?.qty),
          taxableAmount: Utils.getFormattedNumber(
            Utils.getNumber(footerTotal?.taxableAmount) - footerTotal?.discount
          ),
          // befor gst
          totalDis: Utils.getFormattedNumber(footerTotal?.discount),
          freightAmount: 0,
          cessAmount: 0,
          igstAmt: !isStateSame
            ? Utils.getFormattedNumber(
                footerTotal?.gst + footerLedger.ledgerGstAmount
              )
            : 0,
          cgstAmt: isStateSame
            ? Utils.getFormattedNumber(
                (footerTotal?.gst + footerLedger.ledgerGstAmount) / 2
              )
            : 0,
          sgstAmt: isStateSame
            ? Utils.getFormattedNumber(
                (footerTotal?.gst + footerLedger.ledgerGstAmount) / 2
              )
            : 0,
          tcsRate: 0,
          tcsAmt: 0,
          totalInvoiceAmount:
            Utils.getNumber(footerTotal.amount) +
            Utils.getNumber(footerLedger.ledgerTotalAmount) +
            roundOffAmount -
            cashDiscountRs,
          receivedAmount: 0,
          voucherAmountWithoutTCS:
            Utils.getNumber(footerTotal.amount) +
            Utils.getNumber(footerLedger.ledgerTotalAmount) -
            balance,
          roundOff: {
            roundOffApplicable: val?.roundoffCheckbox,
            roundOffAmount: roundOffAmount,
          },
          cashDiscount: {
            discountRate:
              val.cashDiscountDrop === DiscountType.PercentageDiscount
                ? val.cashDiscount ?? 0
                : 0,
            discountAmount: cashDiscountRs,
          },
        },
      };

      if (footerTotal.discount <= footerTotal.taxableAmount) {
        if (
          Utils.getNumber(footerTotal.amount) +
            Utils.getNumber(footerLedger.ledgerTotalAmount) >
          0
        ) {
          setOpenModel({
            type: "addInvoiceReferenceModel",
            param: {
              open: true,
            },
          });
          setCurrentInvoiceDetails(invoice);
          const res = isDraft
            ? await DraftService.postVoucherDraft(
                currentClient?.id!,
                Voucher_Type.Quotation,
                invoice,
                draftId
              )
            : await QuotationServices.postQuotation(
                currentClient?.id!,
                Voucher_Type.Quotation,
                invoice,
                location?.state?.copy ? undefined : id
              );
          if (res?.status) {
            if (isDraft) {
              openNotification(
                "success",
                `Quotation Draft ${res.result?.name} ${
                  draftId ? "Edit Successfully" : "Add Successfully"
                }`
              );
            } else {
              openNotification(
                "success",
                `Estimate ${res.result?.name} ${
                  id ? "Edit Successfully" : "Add Successfully"
                }`
              );
              navigate(-1);
              // Voucher_Type.Quotation
              //   ? navigate(
              //       `/accounts/clients/${currentClient?.id}/sales/quotation`
              //     )
              //   : partyId
              //   ? navigate(
              //       `/accounts/clients/${currentClient?.id}/parties/${editParty?.id}`
              //     )
              //   : !isSaveAndNew
              //   ? navigate(
              //       `/accounts/clients/${currentClient?.id}/sales/invoices`
              //     )
              //   : setFooterTotal({
              //       qty: 0,
              //       unitPrice: 0,
              //       taxableAmount: 0,
              //       discount: 0,
              //       gst: 0,
              //       amount: 0,
              //     });
              form.resetFields();
              setItemLength(0);
              setSaveButtonLoading(false);
            }
          } else if (res?.message) {
            setSaveButtonLoading(false);
            openNotification("error", res?.message);
          }
          setSaveButtonLoading(false);
          setSaveNewLoading(false);
        } else {
          setSaveNewLoading(false);
          openNotification("error", "Selling price must be grated than 0");
          setSaveButtonLoading(false);
        }
      } else {
        openNotification("error", "discount should be less than selling price");
        setSaveButtonLoading(false);
      }
    } catch (error) {
      setSaveButtonLoading(false);
      console.log("Validation failed:", error);
    }
  };

  EntryLevelKeys({
    save: () => save(false),
    cancel: () => navigate(-1),
  });

  //-------- All Item List-----------
  useEffect(() => {
    SalesService.searchItem(
      currentClient?.id!,
      searchItem,
      CAPItemTypes.Sales
    ).then((res) => {
      setItemsOption(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient?.id, searchItem, loadItemAPI]);

  // ----- Add new customer ----------
  useEffect(() => {
    if (newCustomer) {
      form.setFieldValue("partyLedger", {
        value: newCustomer?.id,
        label: newCustomer?.businessName,
      });
      form.setFieldValue("billingAddress", newCustomer?.billingAddress);
      form.setFieldValue("shippingAddress", newCustomer?.shippingAddress);
      form.setFieldValue("supplyState", newCustomer?.state);
      const defaultPhone = newCustomer?.contactInformation?.find(
        (item: any) => item?.isDefault === true
      );
      form.setFieldValue("phoneNo", defaultPhone?.phone);
      setEditParty({ id: newCustomer?.id });
      setSelectedParty(newCustomer?.id);
      setCurrentCustomerState(newCustomer?.state);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newCustomer]);

  //------- Add new item ------
  useEffect(() => {
    if (newItem?.isSaved && newItem?.data?.salesItem?.isActive) {
      // Set the new item's data into the form field
      const newItemFields = {
        name: newItem.data?.name,
        isService: newItem.data?.isService,
        isTaxable: newItem.data?.isTaxable,
        id: newItem.data?.id,
        description: newItem.data?.salesItem?.description,
        account: {
          value: newItem.data?.salesItem?.account?.id,
          lable: newItem.data?.salesItem?.account?.name,
        },
        amount: {
          qty: 1,
          price: newItem.data?.isTaxable
            ? Utils.getFormattedNumber(
                (newItem.data?.salesItem?.price /
                  (100 + newItem.data?.taxRate.rate)) *
                  100
              )
            : newItem.data?.salesItem?.price,
          tax: {
            key: newItem.data?.taxRate?.id,
            label: newItem.data?.taxRate?.name,
            value: newItem.data?.taxRate?.rate,
          },
        },
      };

      // Update the current item at index itemFields.index
      setItemLength(itemFields.index + 1);
      form.setFieldValue(["items", itemFields.index], newItemFields);

      // If the current item index is at the end, add a new item with initial values
      if (itemFields?.field.length === itemFields?.index + 1) {
        form.setFieldValue(["items", itemFields.index + 1], {
          amount: { price: 0.0, qty: 0, totalAmount: 0 },
        });
      }

      // Prepare the new item data to be added to itemsOption
      const newItemData = {
        name: newItem.data?.name,
        isService: newItem.data?.isService,
        isTaxable: newItem.data?.isTaxable,
        id: newItem.data?.id,
        description: newItem.data?.salesItem?.description,
        account: {
          value: newItem.data?.salesItem?.account?.id,
          lable: newItem.data?.salesItem?.account?.name,
        },
        quantity: 1,
        price: newItem.data?.isTaxable
          ? Utils.getFormattedNumber(
              (newItem.data?.salesItem?.price /
                (100 + newItem.data?.taxRate.rate)) *
                100
            )
          : newItem.data?.salesItem?.price,
        tax: {
          key: newItem.data?.taxRate?.id,
          label: newItem.data?.taxRate?.name,
          value: newItem.data?.taxRate?.rate,
        },
      };

      // Add the new item data to itemsOption
      setItemsOption([...itemsOption, newItemData]);

      // Call the function to handle the total values
      handleItemsNetAmmount(itemFields.index);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newItem.data, newItem.isSaved]);

  const TdsOptions = [
    { label: "None", value: 0 },
    { label: "5 %", value: 5 },
    { label: "10 %", value: 10 },
    { label: "2 %", value: 2 },
    { label: "1 %", value: 1 },
  ];

  const handleDeleteRowButton = () => {
    handleItemsNetAmmount(0);
    setConfirmation(false);
  };

  const CustomerSelect: any = async (value: string) => {
    if (value) {
      let findCutomerDetails = customerOptions.find(
        (Ledgerlist: any) => Ledgerlist?.id === value
      );
      setCustomerAddress(findCutomerDetails?.addresses);
      setEditParty({ id: value });
      setSelectedParty(value);
      customerAddSelect(
        TypeOfAddress.RegisteredAddress,
        AddressEnum.BillingAddress,
        findCutomerDetails?.addresses
      );

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  };

  // onChange in address type
  const customerAddSelect: any = (
    typeOfAddress: TypeOfAddress,
    addressEnum: AddressEnum,
    addressTotal: any[]
  ) => {
    // console.log("customerAddress", addressTotal, addressTotal.length > 0);

    let findAddress: any;
    if (addressTotal?.length > 0) {
      findAddress = addressTotal?.find(
        (type: any) => type?.type === typeOfAddress
      );
    } else if (customerAddress?.length > 0) {
      findAddress = customerAddress?.find(
        (type: any) => type?.type === typeOfAddress
      );
    }
    // console.log("Hello findAddress", findAddress);

    if (findAddress) {
      // console.log("findAddress.....", findAddress);
      // const objectofaddress1: any[] = [];
      let objectofaddress1: string = "";
      Object.keys(findAddress?.address)?.forEach((key: any) => {
        if (key === "state") {
          objectofaddress1 += getIndianStates(findAddress?.address[key]) + " ";
          if (addressEnum === AddressEnum.BillingAddress) {
            setCurrentCustomerState(findAddress?.address[key]);
            form.setFieldValue("supplyState", findAddress?.address[key]);
            form.validateFields(["supplyState"]);
          }
          // objectofaddress1.push(getIndianStates(findAddress?.address[key]));
        } else if (findAddress?.address[key]) {
          objectofaddress1 += findAddress?.address[key] + " ";
          // console.log(findAddress.address[key]);
        }
      });

      if (addressEnum === AddressEnum.BillingAddress) {
        form.setFieldValue(["billingAddress", "address"], objectofaddress1);
        form.setFieldValue(["billingAddress", "type"], typeOfAddress);
        if (isSameBillingAddress) {
          form.setFieldValue(["shippingAddress", "address"], objectofaddress1);
          form.setFieldValue(["shippingAddress", "type"], typeOfAddress);
        }
      } else {
        form.setFieldValue(["shippingAddress", "address"], objectofaddress1);
        form.setFieldValue(["shippingAddress", "type"], typeOfAddress);
      }
    } else {
      if (addressEnum === AddressEnum.BillingAddress) {
        form.setFieldValue(["billingAddress", "address"], "");
        form.setFieldValue(["billingAddress", "type"], typeOfAddress);
        if (isSameBillingAddress) {
          form.setFieldValue(["shippingAddress", "address"], "");
          form.setFieldValue(["shippingAddress", "type"], typeOfAddress);
        }
      } else {
        form.setFieldValue(["shippingAddress", "address"], "");
        form.setFieldValue(["shippingAddress", "type"], typeOfAddress);
      }
    }
  };

  // select in Items
  const ItemSelect: any = async (value: string, index: number) => {
    setItemLength(index + 1);
    const selectedItem = itemsOption?.find((Item: any) => Item?.id === value);

    const IsService = selectedItem.isService;
    if (selectedItem) {
      form.setFieldValue(["items", index], {
        name: selectedItem.name,
        isService: IsService,
        isTaxable: selectedItem?.isTaxable,
        id: selectedItem?.id,
        description: selectedItem?.description,
        // account: {
        //   label: selectedItem.account?.name,
        //   value: selectedItem.account?.id,
        // },
        amount: {
          discount: form.getFieldValue("discount"),
          qty: selectedItem.quantity,
          unit: {
            value: selectedItem.unit?.id,
            label: selectedItem.unit?.name,
          },
          price: selectedItem?.isTaxable
            ? Utils.getFormattedNumber(
                (selectedItem?.price / (100 + selectedItem?.taxRate?.rate)) *
                  100
              )
            : selectedItem?.price,
          tax: {
            value: selectedItem?.taxRate?.rate,
            label: selectedItem?.taxRate?.name,
            key: selectedItem?.taxRate?.id,
          },
        },
      });
      // form.setFieldValue(["items", index], {

      //   name: selectedItem.name,
      //   isService: IsService,
      //   isTaxable: selectedItem.isTaxable,
      //   id: selectedItem.id,
      //   description: selectedItem.description,
      //   // account: {
      //   //   label: selectedItem.account?.name,
      //   //   value: selectedItem.account?.id,
      //   // },
      //   amount: {
      //     discount: form.getFieldValue("discount"),
      //     qty: selectedItem.quantity,
      //     unit: {
      //       value: selectedItem.unit?.id,
      //       label: selectedItem.unit?.name,
      //     },
      //     price: selectedItem.isTaxable
      //       ? Utils.getFormattedNumber(
      //           (selectedItem.price / (100 + selectedItem.taxRate.rate)) * 100
      //         )
      //       : selectedItem.price,
      //     tax: {
      //       value: selectedItem.taxRate.rate,
      //       label: selectedItem.taxRate.name,
      //       key: selectedItem.taxRate.id,
      //     },
      //   },
      // });
      handleItemsNetAmmount(index);
    }

    const items = form.getFieldValue("items");
    const currentItems = items.filter((itm: any) => itm?.id !== undefined);

    if (value) {
      const data = itemsOption?.map((o: any) => {
        if (currentItems.some((item: any) => item?.id === o.id)) {
          return {
            ...o,
            disabled: true,
          };
        } else {
          return {
            ...o,
            disabled: false,
          };
        }
      });
      console.log("data", data);
      setItemsOption(data);
    }
  };

  const handleAllTheTotalValues = () => {
    const ItemList = form.getFieldValue(["items"]);

    // console.log("ItemList", ItemList);
    //------- Total Row Calculations -------
    const Total = ItemList.reduce(
      (
        acc: {
          TotalQty: number;
          TotalUnitPrice: number;
          ItemSubTotal: number;
          TotalDiscount: number;
          Totalgst: number;
          TotalAmount: number;
        },
        curr: any
      ) => {
        acc.TotalQty += qty(curr);
        acc.TotalUnitPrice += price(curr);
        acc.ItemSubTotal += price(curr) * qty(curr);
        acc.TotalDiscount += amountInNumber(curr, "discountRupee");
        acc.Totalgst += amountInNumber(curr, "itemTaxAmt");
        acc.TotalAmount += amountInNumber(curr, "totalAmount");
        return acc;
      },
      {
        TotalQty: 0,
        TotalUnitPrice: 0,
        ItemSubTotal: 0,
        TotalDiscount: 0,
        Totalgst: 0,
        TotalAmount: 0,
      }
    );

    console.log("Total", Total);

    setFooterTotal({
      qty: Total.TotalQty,
      unitPrice: Total.TotalUnitPrice,
      taxableAmount: Total.ItemSubTotal,
      discount: Total.TotalDiscount,
      gst: Total.Totalgst,
      amount: Total.TotalAmount,
    });

    // ----- Calculate item's total amount with discount -----
    const AmountAfterDisc = ItemList.reduce((acc: number, curr: any) => {
      return (acc += amountWithoutTax(curr));
    }, 0);

    //----- Calculate TDS amount -----
    const TdsRate = form.getFieldValue("tdsRate");
    const TotalTds = percentCal(AmountAfterDisc, TdsRate);
    setTotalTds(TotalTds);

    //----- Calculate TCS amount -----
    const TcsRate = form.getFieldValue("tcsRate");
    const TotalTcs = percentCal(footerTotal.amount, TcsRate);

    setTotalTcs(TotalTcs);

    //----- Calculate invoice total (WITH GST AND DISCOUNT AND TDS) -----
    const InvoiceTotal = ItemList.reduce((acc: number, curr: any) => {
      return (acc +=
        amountInNumber(curr, "totalAmount") +
        (tdsOrTcs === 0
          ? percentCal(amountWithoutTax(curr), TdsRate)
          : tdsOrTcs === 1
          ? percentCal(footerTotal.amount, TcsRate)
          : 0));
    }, 0);
    // setInvoiceTotal(InvoiceTotal);

    // cash discount
    const discount = form.getFieldValue("cashDiscount");
    const discountType = form.getFieldValue("cashDiscountDrop");

    const totalSum = Total.TotalAmount + footerLedger.ledgerTotalAmount;
    let cashDiscountAmount: number = 0.0;
    // console.log("discount", totalSum, discount, discountType, typeof discount);
    if (discount > 0) {
      if (discountType === DiscountType.PercentageDiscount) {
        cashDiscountAmount = percentCal(totalSum, discount);
        setCashDiscountRs(cashDiscountAmount);
      } else {
        cashDiscountAmount = discount;
        setCashDiscountRs(cashDiscountAmount);
      }
    } else {
      setCashDiscountRs(0.0);
    }

    // roundOff
    const totalAmount =
      Total.TotalAmount + footerLedger.ledgerTotalAmount - cashDiscountAmount;

    const roundOffAmount = handleRoundoff(
      totalAmount,
      form.getFieldValue("roundoffCheckbox"),
      settingControl.roundOffType
    );

    form.setFieldValue("roundOff", roundOffAmount);
    setRoundoffAmount(roundOffAmount);
  };

  // on change in items files amount chnage
  const handleItemsNetAmmount = (index: number) => {
    // console.log(index);
    const ItemList = form.getFieldValue(["items"]);
    const currentIndexData = ItemList[index];
    console.log("items........", itemTaxAmt(currentIndexData));
    if (currentIndexData) {
      form.setFieldValue(["items", index], {
        ...currentIndexData,
        amount: {
          ...currentIndexData.amount,
          discountRupee: Utils.getFormattedNumber(
            discountRupee(currentIndexData)
          ),
          itemTaxAmt: Utils.getFormattedNumber(itemTaxAmt(currentIndexData)),
          totalAmount: Utils.getFormattedNumber(
            amountWithTax(currentIndexData)
          ),
        },
      });
    }

    // console.log("checkinvoicedat", ItemList);
    handleAllTheTotalValues();
    // addTotalTaxAmountChange(currentIndexData);
  };

  const handleItemsAllDiscount = (discountPercentage: number) => {
    // console.log(index);
    console.log("items........", "currentIndexData", discountPercentage);
    const ItemList = form.getFieldValue(["items"]);
    if (ItemList?.length > 0) {
      ItemList?.map((x: any, index: number) => {
        // ItemList;
        const currentIndexData = ItemList[index];

        form.setFieldValue(["items", index], {
          ...currentIndexData,
          amount: {
            ...currentIndexData.amount,
            discount: discountPercentage ?? 0,
            discountRupee: Utils.getFormattedNumber(
              discountRupee(currentIndexData, discountPercentage ?? 0)
            ),
            itemTaxAmt: Utils.getFormattedNumber(
              itemTaxAmt(currentIndexData, discountPercentage ?? 0)
            ),
            totalAmount: Utils.getFormattedNumber(
              amountWithTax(currentIndexData, discountPercentage ?? 0)
            ),
          },
        });
      });
    }
    // console.log("checkinvoicedat", ItemList);
    handleAllTheTotalValues();
    // addTotalTaxAmountChange(currentIndexData);
  };

  const onDiscountAmountChange = (index: number) => {
    const ItemList = form.getFieldValue(["items"]);
    const currentIndexData = ItemList[index];

    if (currentIndexData) {
      // Calculate the new values based on the discount change
      const newDiscountAmount = percentToRs(
        amountInNumber(currentIndexData, "discountRupee"),
        netAmount(currentIndexData)
      );

      const newTaxRs = percentCal(
        netAmount(currentIndexData) -
          amountInNumber(currentIndexData, "discountRupee"),
        taxRate(currentIndexData)
      );

      const newTotalAmount =
        netAmount(currentIndexData) -
        amountInNumber(currentIndexData, "discountRupee") +
        newTaxRs;

      // Update the form field with the new calculated values
      form.setFieldValue(["items", index], {
        ...currentIndexData,
        amount: {
          ...currentIndexData.amount,
          discount: Utils.getFormattedNumber(newDiscountAmount),
          itemTaxAmt: Utils.getFormattedNumber(newTaxRs),
          totalAmount: Utils.getFormattedNumber(newTotalAmount),
        },
      });

      // Call the function to handle the total values if needed
      handleAllTheTotalValues();
    }
  };

  const onItemsTotalAmountChange = async (index: number) => {
    console.log("index...........", index);

    const ItemList = form.getFieldValue(["items"]);
    const currentIndexData = ItemList[index];

    // Calculate the denominator for various calculations
    const denominator =
      100 -
      discount(currentIndexData) +
      percentCal(100 - discount(currentIndexData), taxRate(currentIndexData));

    // Calculate the price based on the given formula
    const calculatedPrice =
      (amountInNumber(currentIndexData, "totalAmount") * 100) /
      (denominator * qty(currentIndexData));

    // Calculate the discountRupee based on the given formula
    const calculatedDiscountRupee =
      (amountInNumber(currentIndexData, "totalAmount") *
        discount(currentIndexData)) /
      denominator;

    // Calculate the itemTaxAmt based on the given formula
    const calculatedTaxRs =
      (amountInNumber(currentIndexData, "totalAmount") *
        percentCal(
          100 - discount(currentIndexData),
          taxRate(currentIndexData)
        )) /
      denominator;

    if (denominator > 0 && currentIndexData?.amount?.qty > 0) {
      // Update the form field values with the calculated values
      form.setFieldValue(["items", index], {
        ...currentIndexData,
        amount: {
          ...currentIndexData?.amount,
          price: Utils.getFormattedNumber(calculatedPrice),
          discountRupee: Utils.getFormattedNumber(calculatedDiscountRupee),
          itemTaxAmt: Utils.getFormattedNumber(calculatedTaxRs),
        },
      });
    } else {
      // Denominator is zero or negative, set all fields to 0
      form.setFieldValue(["items", index], {
        ...currentIndexData,
        amount: {
          ...currentIndexData?.amount,
          price: 0,
          discountRupee: 0,
          itemTaxAmt: 0,
          totalAmount: 0,
        },
      });
    }
    handleAllTheTotalValues();
  };

  // Freight And InsLedger total in fotter
  const handleFreightAndInsLedgerTotal = () => {
    const LedgerAllList = form.getFieldValue(["freightInsLedgers"]);

    //----- Total Row Calculations---------
    const AmountLedger = LedgerAllList.reduce((acc: number, curr: any) => {
      return (acc += Utils.getNumber(curr?.amount));
    }, 0);
    console.log("AmountAfterDisc...........", AmountLedger);

    const Total = LedgerAllList.reduce(
      (
        acc: {
          amount: number;
          gstAmount: number;
          totalAmount: number;
        },
        curr: any
      ) => {
        acc.amount += Utils.getNumber(curr?.amount);
        acc.gstAmount += Utils.getNumber(curr?.gstAmount);
        acc.totalAmount += Utils.getNumber(curr?.totalAmount);
        return acc;
      },
      {
        amount: 0,
        gstAmount: 0,
        totalAmount: 0,
      }
    );
    // console.log("totalvalueoftheledger", Total);

    setFooterLedger({
      ledgerAmount: Total.amount,
      ledgerGstAmount: Total.gstAmount,
      ledgerTotalAmount: Total.totalAmount,
    });

    // ----- Calculate items total unit Price -----
    const TotalLedgerAmount = LedgerAllList.reduce((acc: number, curr: any) => {
      return (acc += amountInNumber(curr, "totalAmount"));
    }, 0);
    setLedgerAmount(TotalLedgerAmount);

    console.log("ledgeralllist", LedgerAllList);
  };

  // on chnage in Freight And InsLedger unit price & gst
  const handleLedgerNetAmount = (index: number) => {
    const Ledgerlist = form.getFieldValue(["freightInsLedgers"]);
    const currentLedgerIndexData = Ledgerlist[index];
    console.log("currentLedgerIndexData", currentLedgerIndexData);

    if (currentLedgerIndexData) {
      form.setFieldValue(["freightInsLedgers", index], {
        ...currentLedgerIndexData,
        gstAmount: Utils.getFormattedNumber(gstamount(currentLedgerIndexData)),
        // totalAmount: Utils.getFormattedNumber(currentLedgerIndexData?.amount),
        totalAmount: Utils.getFormattedNumber(
          ledgertotalamout(currentLedgerIndexData)
        ),
      });
      handleFreightAndInsLedgerTotal();
    }
    Ledgerlist?.length === 0 && setShowFreightInsurance(false);
  };

  const onLedgerTotalAmountChange = async (index: number) => {
    const LedgerList = form.getFieldValue(["freightInsLedgers"]);
    const currentIndexData = LedgerList[index];
    // console.log(currentIndexData);

    //Calculate the denominator for various calculations
    const denominators = gstrate(currentIndexData);
    console.log("denominators", denominators);

    const gstAmount =
      (Utils.getNumber(currentIndexData.totalAmount) * denominators) / 100;
    console.log("gstAmount", gstAmount);

    const ledgernetAmount =
      Utils.getNumber(currentIndexData.totalAmount) - gstAmount;
    console.log("ledgernetAmount...", ledgernetAmount);

    if (ledgernetAmount > 0) {
      form.setFieldValue(["freightInsLedgers", index], {
        ...currentIndexData,
        amount: Utils.getFormattedNumber(ledgernetAmount),
        gstAmount: Utils.getFormattedNumber(gstAmount),
        // totalAmount: Utils.getFormattedNumber(
        //   ledgertotalamout(currentIndexData)
        // ),
      });
    } else {
      // ledgernetAmount is zero or negative, set all fields to 0
      form.setFieldValue(["freightInsLedgers", index], {
        ...currentIndexData,
        amount: Utils.getFormattedNumber(ledgernetAmount),
        gstAmount: Utils.getFormattedNumber(gstAmount),
      });
    }
    handleFreightAndInsLedgerTotal();
  };

  // Other Expenses (Indirect Income expenses) select
  const freightInsLedgersSelect: any = async (value: any, index: number) => {
    // console.log("value11.............", value, index);
    const currentSelectLedger = freightAndInsuranceOptions.find(
      (x: any) => x.id === value
    );

    console.log("currentSelectLedger.............", currentSelectLedger);
    if (currentSelectLedger) {
      const currentData = form.getFieldValue(["freightInsLedgers", index]);

      form.setFieldValue(["freightInsLedgers", index], {
        id: {
          value: currentSelectLedger?.id,
          label: currentSelectLedger.ledgerName,
        },
        amount: currentData?.amount,
        gstRate: {
          value: currentSelectLedger?.gstRate?.id,
          label: currentSelectLedger?.gstRate?.name,
          title: currentSelectLedger?.gstRate?.rate,
        },
        gstAmount: Utils.getFormattedNumber(gstamount(currentData)),
        totalAmount: Utils.getFormattedNumber(ledgertotalamout(currentData)),
      });
    }
    setLedgerLength(index + 1);
    handleFreightAndInsLedgerTotal();
    setSelectedOtherExpenses((prevState) => {
      const updatedSelectedOptions = [...prevState];
      updatedSelectedOptions[index] = value;
      return updatedSelectedOptions;
    });
    // console.log("currentSelectLedger", currentSelectLedger);
  };
  const [addLedgerResponse, setAddLedgerResponse] = useState<any>(null);

  useEffect(() => {
    if (addLedgerResponse) {
      const exists = customerOptions.some(
        (option) => option.id === addLedgerResponse.id
      );
      if (exists) {
        form.setFieldValue("partyLedger", {
          ...form.getFieldValue("partyLedger"),
          key: addLedgerResponse?.id,
          value: addLedgerResponse?.id,
          label: addLedgerResponse?.ledgerName,
        });
        CustomerSelect(addLedgerResponse?.id);
        setAddLedgerResponse(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerOptions]);

  // ------- Set other expense Data After Adding ledger ------
  useEffect(() => {
    try {
      if (addLedgerResponse) {
        const exists = freightAndInsuranceOptions.some(
          (option: any) => option.id === addLedgerResponse.id
        );
        if (exists) {
          let freightInsLedgers = [
            ...(form.getFieldValue("freightInsLedgers") || []),
          ];
          freightInsLedgers.pop();
          freightInsLedgers.push({
            gstRate: 0,
            gstAmount: 0,
            totalAmount: 0,
            amount: 0,
            id: {
              label: addLedgerResponse?.ledgerName,
              value: addLedgerResponse.id,
              key: addLedgerResponse.id,
            },
          });
          freightInsLedgers.push({
            gstRate: 0,
            gstAmount: 0,
            totalAmount: 0,
            amount: 0,
            id: {},
          });
          const index = freightInsLedgers.findIndex(
            (ledgerArray) => ledgerArray?.id?.value === addLedgerResponse.id
          );
          form.setFieldValue("freightInsLedgers", freightInsLedgers);
          freightInsLedgersSelect(addLedgerResponse.id, index);
          setAddLedgerResponse(null);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [freightAndInsuranceOptions]);

  // //------- Set sales ledger Data After Adding ledger ------
  useEffect(() => {
    if (addLedgerResponse) {
      const exists = effectiveLedger.some(
        (option) => option.id === addLedgerResponse.id
      );
      if (exists) {
        form.setFieldValue("effectiveLedger", {
          ...form.getFieldValue("effectiveLedger"),
          key: addLedgerResponse?.id,
          value: addLedgerResponse?.id,
          label: addLedgerResponse?.ledgerName,
        });
        setAddLedgerResponse(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [effectiveLedger]);

  const hangleCashDisCount = () => {
    const discount = form.getFieldValue("cashDiscount");

    const discountType = form.getFieldValue("cashDiscountDrop");
    const totalSum = footerTotal.amount + footerLedger.ledgerTotalAmount;
    // console.log("discount", totalSum, discount, discountType, typeof discount);
    if (discount > 0) {
      if (discountType === DiscountType.PercentageDiscount) {
        const percentDis = percentCal(totalSum, discount);
        setCashDiscountRs(percentDis);

        const roundOffAmount = handleRoundoff(
          totalSum - percentDis,
          form.getFieldValue("roundoffCheckbox"),
          settingControl.roundOffType
        );

        form.setFieldValue("roundOff", roundOffAmount);
        setRoundoffAmount(roundOffAmount);
        // console.log("")
        // console.log("footerTotal.taxableAmount", percentCal(totalSum, discount));
      } else {
        setCashDiscountRs(discount);

        const roundOffAmount = handleRoundoff(
          totalSum - discount,
          form.getFieldValue("roundoffCheckbox"),
          settingControl.roundOffType
        );

        form.setFieldValue("roundOff", roundOffAmount);
        setRoundoffAmount(roundOffAmount);
        // console.log("RupeeDiscount", totalSum - discount);
      }
    } else {
      setCashDiscountRs(0.0);

      const roundOffAmount = handleRoundoff(
        totalSum,
        form.getFieldValue("roundoffCheckbox"),
        settingControl.roundOffType
      );

      form.setFieldValue("roundOff", roundOffAmount);
      setRoundoffAmount(roundOffAmount);
    }
  };

  // const handleRoundoff = () => {
  //   // debugger;
  //   const totalAmount =
  //     footerTotal.amount +
  //     footerLedger.ledgerTotalAmount -
  //     Math.round(cashDiscountRs * 100) / 100;
  //   // console.log(
  //   //   "form.getFieldValue()",
  //   //   footerTotal.amount,
  //   //   footerLedger.ledgerTotalAmount,
  //   //   totalAmount,
  //   //   // form.getFieldValue("roundoffCheckbox"),
  //   //   Math.round((cashDiscountRs * 100) / 100),
  //   //   form.getFieldValue("roundoffCheckbox"),
  //   //   totalAmount
  //   // );

  //   if (form.getFieldValue("roundoffCheckbox") && totalAmount > 0) {
  //     const onlyInteger = Math.floor(totalAmount);
  //     const onlyPointAmount = totalAmount - onlyInteger;
  //     console.log(
  //       "onlyPointAmount",
  //       // Number(onlyPointAmount),
  //       onlyInteger,
  //       onlyPointAmount,
  //       // Math.round((1 - onlyPointAmount) * 100) /
  //       -Math.round(onlyPointAmount * 100) / 100
  //     );
  //     if (Number(onlyPointAmount)) {
  //       if (onlyPointAmount > 0.49) {
  //         form.setFieldValue(
  //           "roundOff",
  //           Math.round((1 - onlyPointAmount) * 100) / 100
  //         );
  //         // setRoundoffAmount(Math.round((1 - onlyPointAmount) * 100) / 100);
  //       } else {
  //         form.setFieldValue(
  //           "roundOff",
  //           -Math.round(onlyPointAmount * 100) / 100
  //         );
  //         // setRoundoffAmount(-Math.round(onlyPointAmount * 100) / 100);
  //       }
  //     }
  //   } else {
  //     form.setFieldValue("roundOff", 0.0);
  //     // setRoundoffAmount(0.0);
  //   }
  // };

  const settingControl = {
    title: companySettings?.setting?.estimate?.title,
    showShippingAddress:
      companySettings?.setting?.estimate?.showShippingAddress,
    showPriceHistory: companySettings?.setting?.estimate?.showPriceHistory,
    showOtherCharges: companySettings?.setting?.estimate?.showOtherCharges,
    discountPolicy: companySettings?.setting?.estimate?.discountPolicy,
    roundOffType: companySettings?.setting?.estimate?.roundOffType,
    autoRoundOff: companySettings?.setting?.estimate?.autoRoundOff,
    showItemDescription:
      companySettings?.setting?.estimate?.showItemDescription,
  };

  return (
    <div>
      <Spin indicator={antIcon} spinning={loading}>
        <Card
          style={{ boxShadow: "0px 0px 10px 0px #96A1C84D", marginTop: 7 }}
          title={
            <Row
              justify={"space-between"}
              // style={{ backgroundColor: "whitesmoke", padding: 0 }}
            >
              <Col className="fs-20 fw-200 themeColor">
                {settingControl.title}

                <Dropdown
                  //overlay={itemsabc}
                  menu={{
                    items: SalesDropdownItems(),
                    selectable: true,
                    defaultSelectedKeys: [
                      location?.pathname?.split("/")[5] === "addInvoice" ||
                      "editInvoice"
                        ? "0"
                        : "-1",
                    ],
                  }}
                  trigger={["click"]}
                  autoFocus={true}
                >
                  <Typography.Link>
                    <Space>
                      <DownOutlined
                      // style={{
                      //   fontSize: "12px",
                      //   color: "black",
                      //   padding: "0px 0px 10px 10px",
                      // }}
                      />
                    </Space>
                  </Typography.Link>
                </Dropdown>
              </Col>
              {/* <Col span={12}>
              <h3>Sales Invoice</h3>
            </Col> */}
            </Row>
          }
        >
          <Form
            form={form}
            autoComplete="off"
            name="salesInvoice"
            initialValues={{
              tcsRate: 0,
              tdsRate: 0,
              // showFreightInsLedgers: showFreightInsurance,
              items: [
                {
                  amount: { price: 0.0, qty: 0, totalAmount: 0 },
                },
              ],
              freightInsLedgers: [
                {
                  gstRate: 0,
                  gstAmount: 0,
                  totalAmount: 0,
                  amount: 0,
                },
              ],
              bankDts: [{}],
              roundoffCheckbox: settingControl.autoRoundOff,
              cashDiscountDrop: DiscountType.PercentageDiscount,
            }}
          >
            <>
              {/* <Row justify="space-between"> */}
              {/* <Col xl={17} lg={24} md={24} className="dashedBoxStyle">
                <Row gutter={24} className="ps-14" justify="space-between"> */}
              {/* <Col
                    lg={14}
                    md={11}
                    xs={23}
                    sm={23}
                    className="themeLightBgColor p-10"
                  > */}
              {/* <Row gutter={[17, 2]}> */}
              {/*
                      <Col lg={12}>
                        <Row justify="space-between">
                          <Col className=" fw-500">Party</Col>
                          {selectedParty && (
                            <Col className="fw-500">
                              <Tooltip title="View Party">
                                <EyeOutlined
                                  onClick={() => {
                                    setEditParty({ id: selectedParty });
                                    setOpenModel({
                                      type: "addLedger",
                                      param: {
                                        open: true,
                                      },
                                    });
                                  }}
                                />
                              </Tooltip>
                            </Col>
                          )}
                        </Row>
                        <Form.Item
                          name="partyLedger"
                          className="m-0 selectPlaceholder selectedItem errormsg"
                          rules={[
                            { required: true, message: "Select a customer" },
                          ]}
                        >
                          <Select
                            showSearch
                            allowClear
                            labelInValue
                            optionFilterProp="children"
                            placeholder="Party Name"
                            filterOption={false}
                            className="selectedItem"
                            disabled={
                              salesOrderId || quotationId ? true : false
                            }
                            autoFocus={true}
                            ref={inputRef}
                            onSearch={(value: string) =>
                              setSearchCustomer({ search: value, length: 15 })
                            }
                            // onClick={() =>
                            //   setSearchCustomer({ ...searchCustomer, search: "" })
                            // }
                            onChange={(e: any) => {
                              form.setFieldValue(
                                ["shippingAddress", "address"],
                                ""
                              );
                              form.setFieldValue(
                                ["shippingAddress", "type"],
                                ""
                              );
                              CustomerSelect(e?.value);
                            }}
                            options={customerOptions?.map((value: any) => ({
                              value: value?.id,
                              label: value?.ledgerName.concat(
                                value?.aliasName
                                  ? `${" (" + value?.aliasName + ")"}`
                                  : ""
                              ),
                            }))}
                            dropdownRender={(customerOptions) => (
                              <>
                                {customerOptions}
                                <Divider style={{ margin: "5px 0px" }} />
                                <Button
                                  style={{ width: "100%" }}
                                  icon={<PlusOutlined />}
                                  type="link"
                                  onClick={() => {
                                    setEditParty({ id: "" });
                                    setOpenModel({
                                      type: "addLedger",
                                      param: {
                                        open: true,
                                      },
                                    });
                                  }}
                                >
                                  New Customer
                                </Button>
                              </>
                            )}
                          />
                        </Form.Item>
                      </Col>
                        */}
              {/* <Col lg={12}>
                      <span className=" fw-500">Phone No.</span>
                      <Form.Item
                        name="phoneNo"
                        className="inputBoxMb"
                        rules={[
                          {
                            min: 10,
                            message: "phone no. must be 10 digits",
                          },
                        ]}
                      >
                        <Input
                          placeholder="9876543210"
                          onKeyPress={(e) =>
                            !/[0-9]/.test(e.key) && e.preventDefault()
                          }
                        />
                      </Form.Item>
                    </Col> 
                      <Col lg={12}></Col>
                    */}
              {/*
                      <Col lg={12}>
                         <span className=" fw-500">
                          Billing Address
                        </span>
                        <Form.Item
                          name={["billingAddress", "type"]}
                          className="inputBoxMb"
                          // rules={[
                          //   {
                          //     required: true,
                          //     message: "Address type is requied",
                          //   },
                          // ]}
                        >
                          <Select
                            showSearch
                            allowClear
                            optionFilterProp="children"
                            placeholder="Select type"
                            filterOption={false}
                            className="selectedItem"
                            options={[
                              {
                                label: "Registered Address",
                                value: TypeOfAddress.RegisteredAddress,
                              },
                              {
                                label: "Business Address",
                                value: TypeOfAddress.BusinessAddress,
                              },
                              {
                                label: "Branch Address",
                                value: TypeOfAddress.BranchAddress,
                              },
                              {
                                label: "Unit Address",
                                value: TypeOfAddress.UnitAddress,
                              },
                              {
                                label: "Godown Address",
                                value: TypeOfAddress.GodownAddress,
                              },
                            ]}
                            onChange={(value: any) =>
                              customerAddSelect(
                                value,
                                AddressEnum.BillingAddress,
                                []
                              )
                            }
                          />
                        </Form.Item> */}

              {/* <Form.Item
                          name={["billingAddress", "address"]}
                          className="inputBoxMb"
                          // rules={[
                          //   {
                          //     required: true,
                          //     message: "Billing address is required",
                          //   },
                          // ]}
                          style={{ paddingTop: "4px" }}
                        >
                          <TextArea
                            placeholder="Billing address"
                            rows={3}
                            // style={{ borderTop: "none" }}
                          />
                        </Form.Item> 
                      </Col>
                        */}
              {/*
                      <Col lg={12}>
                        <span className=" fw-500">
                          Shipping Address
                        </span>
                        <Form.Item
                          name={["shippingAddress", "type"]}
                          className="inputBoxMb"
                        >
                          <Select
                            showSearch
                            allowClear
                            optionFilterProp="children"
                            placeholder="Select type"
                            filterOption={false}
                            className="selectedItem"
                            options={[
                              {
                                label: "Registered Address",
                                value: TypeOfAddress.RegisteredAddress,
                              },
                              {
                                label: "Business Address",
                                value: TypeOfAddress.BusinessAddress,
                              },
                              {
                                label: "Branch Address",
                                value: TypeOfAddress.BranchAddress,
                              },
                              {
                                label: "Unit Address",
                                value: TypeOfAddress.UnitAddress,
                              },
                              {
                                label: "Godown Address",
                                value: TypeOfAddress.GodownAddress,
                              },
                            ]}
                            onChange={(value: any) =>
                              customerAddSelect(
                                value,
                                AddressEnum.ShippingAddress,
                                []
                              )
                            }
                          />
                        </Form.Item> */}
              {/* <Form.Item
                          name={["shippingAddress", "address"]}
                          className="inputBoxMb"
                          style={{ paddingTop: "4px" }}
                        >
                          <TextArea rows={3} placeholder="Shipping address" />
                        </Form.Item>{" "}
                        <Form.Item
                          name={["shippingAddress", "address"]}
                          className="inputBoxMb"
                          style={{ paddingTop: "4px" }}
                        >
                          <TextArea rows={3} placeholder="Shipping address" />
                        </Form.Item>
                      </Col> */}
              {/* </Row> */}
              {/* </Col> */}
              {/* <Col lg={9} md={4} xs={23} sm={23}>
                    <Row gutter={[17, 8]}> */}
              {/* <Col lg={12}>
                      <span style={{ display: "flex", justifyContent: "end" }}>
                        <span
                          className="pe-10 fw-500"
                          style={{
                            color: isInCash ? "gray" : "rgb(11, 48, 142)",
                            paddingTop: "7px",
                          }}
                        >
                          Credit
                        </span>
                        <Form.Item
                          name="paymentType"
                          className="m-0"
                          valuePropName="checked"
                        >
                          <Switch
                            disabled
                            size="small"
                            className="unCheckSwitch"
                            onChange={(value) => setIsInCash(value)}
                          />
                        </Form.Item>
                        <span
                          className="ps-11 fw-500"
                          style={{
                            color: isInCash ? "rgb(11, 48, 142)" : "gray",
                            paddingTop: "7px",
                          }}
                        >
                          Cash
                        </span>
                      </span>
                    </Col> */}
              <Col lg={12}>
                {/* <span className=" fw-500">
                          Place Of Supply
                        </span>
                        <Form.Item
                          name="supplyState"
                          className="mb-3 errormsg"
                          rules={[
                            {
                              required: true,
                              message: "Place of supply is required",
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            allowClear={false}
                            placeholder="Select state"
                            popupMatchSelectWidth={false}
                            placement="bottomLeft"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            onSelect={(val) => setCurrentCustomerState(val)}
                            options={stateOptions?.map((x: any) => ({
                              value: x?.id,
                              label: x?.name,
                            }))}
                          />
                        </Form.Item> */}
              </Col>
              <Col lg={12}>
                {/* <span className=" fw-500">Quotation No.</span>
                        <Form.Item
                          name="number"
                          className="mb-3"
                          rules={[
                            {
                              required: true,
                              message: "Please enter Quotation No.",
                            },
                          ]}
                        >
                          <Input disabled />
                        </Form.Item> */}
              </Col>

              <Col lg={12}>
                {/* <span className=" fw-500">Date</span>
                        <Form.Item
                          name="date"
                          className="m-0"
                          // rules={[
                          //   {
                          //     required: true,
                          //     message: "Select Date",
                          //   },
                          // ]}
                        >
                          <DatePicker
                            format="DD/MM/YYYY"
                            // disabledDate={(current) =>
                            //   current && current > dayjs().endOf("day")
                            // }
                            style={{ width: "100%" }}
                            allowClear={false}
                            onChange={(val) => {
                              if (dueOn) {
                                let dueDate = dayjs(val, "DD/MM/YYYY").add(
                                  dueOn,
                                  "day"
                                );
                                form.setFieldValue("dueDate", dueDate);
                              }
                              setDateFiled(dayjs(val).toISOString());
                            }}
                          />
                        </Form.Item> */}
              </Col>
              {/* <Col lg={12}>
                        <span className=" fw-500">
                          Include Other Expenses
                        </span>
                        <Form.Item
                          name="showFreightInsLedgers"
                          className="mb-3 errormsg"
                          valuePropName="checked"
                        >
                          <Checkbox
                            // defaultChecked={showFreightInsurance}
                            checked={showFreightInsurance}
                            onChange={(e) => {
                              setSelectedOtherExpenses([]);
                              setShowFreightInsurance(e.target.checked);
                            }}
                          />
                        </Form.Item>
                      </Col> */}

              {/* <Col lg={12}>
                      <span className=" fw-500">
                        Other Expenses
                      </span>
                      <Button
                        icon={<PlusOutlined />}
                        type="primary"
                        onClick={() =>
                          setOpenModel({
                            type: "addDespatchDetail",
                            param: { open: true },
                          })
                        }
                      >
                        Add
                      </Button>
                    </Col> */}
              {/* </Row>
                  </Col> */}
              {/* </Row>
              </Col> */}
              {/* {windowWidth <= 1024 ? (
                ""
              ) : (
                <Col
                  xl={6}
                  lg={7}
                  md={7}
                  className="fs-35 fw-400  textEnd"
                >
                  {companySettings?.setting?.estimate?.title}
                  <Dropdown
                    menu={{
                      items: SalesDropdownItems(),
                      selectable: true,
                      defaultSelectedKeys: [
                        location?.pathname?.split("/")[5] === "addQuotation" ||
                        "editQuotation"
                          ? "3"
                          : "-1",
                      ],
                    }}
                    trigger={["click"]}
                    autoFocus={true}
                  >
                    <Typography.Link>
                      <Space>
                        <DownOutlined
                          style={{
                            fontSize: "12px",
                            color: "black",
                            padding: "0px 0px 10px 10px",
                          }}
                        />
                      </Space>
                    </Typography.Link>
                  </Dropdown>
                </Col>
              )} */}
              {/* </Row> */}
            </>

            <Row gutter={10}>
              <Col xl={12} lg={10} sm={24}>
                <Row gutter={10}>
                  {/* select party */}
                  <Col sm={12} xs={24}>
                    <Row justify="space-between">
                      <Col className=" fw-500">
                        <span>Party</span>
                      </Col>
                      {selectedParty && (
                        <Col className="fw-500">
                          <Tooltip title="View Party">
                            <EyeOutlined
                              onClick={() => {
                                setEditParty({ id: selectedParty });
                                setOpenModel({
                                  type: "addLedger",
                                  param: {
                                    open: true,
                                  },
                                });
                              }}
                            />
                          </Tooltip>
                        </Col>
                      )}
                    </Row>
                    <Form.Item
                      name="partyLedger"
                      className="m-0 selectPlaceholder selectedItem errormsg"
                      rules={[{ required: true, message: "Select a customer" }]}
                    >
                      <Select
                        showSearch
                        allowClear
                        labelInValue
                        optionFilterProp="children"
                        placeholder="Party Name"
                        filterOption={false}
                        className="selectedItem"
                        disabled={salesOrderId || quotationId ? true : false}
                        autoFocus={true}
                        ref={inputRef}
                        onSearch={(value: string) =>
                          setSearchCustomer({ search: value, length: 15 })
                        }
                        // onClick={() =>
                        //   setSearchCustomer({ ...searchCustomer, search: "" })
                        // }
                        onChange={(e: any) => {
                          form.setFieldValue(
                            ["shippingAddress", "address"],
                            ""
                          );
                          form.setFieldValue(["shippingAddress", "type"], "");
                          CustomerSelect(e?.value);
                        }}
                        options={customerOptions?.map((value: any) => ({
                          value: value?.id,
                          label: value?.ledgerName.concat(
                            value?.aliasName
                              ? `${" (" + value?.aliasName + ")"}`
                              : ""
                          ),
                        }))}
                        dropdownRender={(customerOptions) => (
                          <>
                            {customerOptions}
                            <Divider style={{ margin: "5px 0px" }} />
                            <Button
                              style={{ width: "100%" }}
                              icon={<PlusOutlined />}
                              type="link"
                              onClick={() => {
                                setEditParty({ id: "" });
                                setOpenModel({
                                  type: "addLedger",
                                  param: {
                                    open: true,
                                  },
                                });
                              }}
                            >
                              New Customer
                            </Button>
                          </>
                        )}
                      />
                    </Form.Item>
                  </Col>
                  {/* Sales Ledger */}
                  <Col sm={12} xs={24}>
                    <span className=" fw-500">Sales Ledger</span>
                    <Form.Item
                      name="effectiveLedger"
                      className="mb-3 errormsg"
                      rules={[
                        {
                          required: true,
                          message: `Please select effectiveLedger`,
                        },
                      ]}
                    >
                      <Select
                        labelInValue
                        showSearch
                        allowClear={false}
                        placeholder="Select ledger"
                        popupMatchSelectWidth={false}
                        placement="bottomLeft"
                        optionFilterProp="children"
                        options={effectiveLedger?.map((x: any) => ({
                          value: x?.id,
                          label: x?.ledgerName.concat(
                            x?.aliasName ? `${" (" + x?.aliasName + ")"}` : ""
                          ),
                        }))}
                        dropdownRender={(customerOptions) => (
                          <>
                            {customerOptions}
                            <Divider style={{ margin: "5px 0px" }} />
                            <Button
                              style={{ width: "100%" }}
                              icon={<PlusOutlined />}
                              type="link"
                              onClick={() => {
                                setEditParty({ id: "" });
                                setOpenModel({
                                  type: "addLedger",
                                  param: {
                                    open: true,
                                  },
                                });
                              }}
                            >
                              New Ledger
                            </Button>
                          </>
                        )}
                      />
                    </Form.Item>
                  </Col>
                  {/* Billing Address */}
                  <Col sm={12} xs={24}>
                    <span className="fw-500">Billing Address</span>

                    <Form.Item
                      name={["billingAddress", "type"]}
                      className="inputBoxMb"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Address type is requied",
                      //   },
                      // ]}
                    >
                      <Select
                        showSearch
                        allowClear
                        optionFilterProp="children"
                        placeholder="Select type"
                        filterOption={false}
                        className="selectedItem"
                        options={[
                          {
                            label: "Registered Address",
                            value: TypeOfAddress.RegisteredAddress,
                          },
                          {
                            label: "Business Address",
                            value: TypeOfAddress.BusinessAddress,
                          },
                          {
                            label: "Branch Address",
                            value: TypeOfAddress.BranchAddress,
                          },
                          {
                            label: "Unit Address",
                            value: TypeOfAddress.UnitAddress,
                          },
                          {
                            label: "Godown Address",
                            value: TypeOfAddress.GodownAddress,
                          },
                        ]}
                        onChange={(value: any) =>
                          customerAddSelect(
                            value,
                            AddressEnum.BillingAddress,
                            []
                          )
                        }
                      />
                    </Form.Item>
                  </Col>
                  {/* Shipping Address */}
                  {(settingControl.showShippingAddress ||
                    updateTimeData.shippingAddress) && (
                    <Col sm={12} xs={24}>
                      <Row>
                        <Col>
                          <span className=" fw-500">Shipping Address</span>
                        </Col>

                        <Col style={{ paddingLeft: "10px" }}>
                          <Checkbox
                            checked={isSameBillingAddress}
                            onChange={(e) => {
                              setIsSameBillingAddress(e.target.checked);
                              if (e.target.checked) {
                                form.setFieldValue(
                                  ["shippingAddress", "type"],
                                  form.getFieldValue(["billingAddress", "type"])
                                );

                                form.setFieldValue(
                                  ["shippingAddress", "address"],
                                  form.getFieldValue([
                                    "billingAddress",
                                    "address",
                                  ])
                                );
                              }
                            }}
                          />
                        </Col>
                      </Row>
                      <Form.Item
                        name={["shippingAddress", "type"]}
                        className="inputBoxMb"
                      >
                        <Select
                          showSearch
                          disabled={isSameBillingAddress && true}
                          allowClear
                          optionFilterProp="children"
                          placeholder="Select type"
                          filterOption={false}
                          className="selectedItem"
                          options={[
                            {
                              label: "Registered Address",
                              value: TypeOfAddress.RegisteredAddress,
                            },
                            {
                              label: "Business Address",
                              value: TypeOfAddress.BusinessAddress,
                            },
                            {
                              label: "Branch Address",
                              value: TypeOfAddress.BranchAddress,
                            },
                            {
                              label: "Unit Address",
                              value: TypeOfAddress.UnitAddress,
                            },
                            {
                              label: "Godown Address",
                              value: TypeOfAddress.GodownAddress,
                            },
                          ]}
                          onChange={(value: any) =>
                            customerAddSelect(
                              value,
                              AddressEnum.ShippingAddress,
                              []
                            )
                          }
                        />
                      </Form.Item>
                    </Col>
                  )}
                  {/* Billing Address TextArea */}
                  <Col sm={12} xs={24}>
                    <Form.Item
                      name={["billingAddress", "address"]}
                      className="inputBoxMb"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Billing address is required",
                      //   },
                      // ]}
                      style={{ paddingTop: "4px" }}
                    >
                      <TextArea
                        placeholder="Billing address"
                        rows={3}
                        onBlur={() => {
                          if (isSameBillingAddress) {
                            form.setFieldValue(
                              ["shippingAddress", "type"],
                              form.getFieldValue(["billingAddress", "type"])
                            );

                            form.setFieldValue(
                              ["shippingAddress", "address"],
                              form.getFieldValue(["billingAddress", "address"])
                            );
                          }
                        }}
                        // style={{ borderTop: "none" }}
                      />
                    </Form.Item>
                  </Col>
                  {/* Shipping Address TextArea */}
                  {(settingControl.showShippingAddress ||
                    updateTimeData.shippingAddress) && (
                    <Col sm={12} xs={24}>
                      <Form.Item
                        name={["shippingAddress", "address"]}
                        className="inputBoxMb"
                        style={{ paddingTop: "4px" }}
                      >
                        <TextArea
                          disabled={isSameBillingAddress && true}
                          rows={3}
                          placeholder="Shipping address"
                        />
                      </Form.Item>
                    </Col>
                  )}
                </Row>
              </Col>

              <Col xl={12} lg={14} sm={24}>
                <Row gutter={10}>
                  {/* Place Of Supply */}
                  <Col md={8} sm={12} xs={24}>
                    <span className=" fw-500">Place Of Supply</span>
                    <Form.Item
                      name="supplyState"
                      className="mb-3 errormsg"
                      rules={[
                        {
                          required: true,
                          message: "Place of supply is required",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        allowClear={false}
                        placeholder="Select State"
                        popupMatchSelectWidth={false}
                        placement="bottomLeft"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        onSelect={(val) => setCurrentCustomerState(val)}
                        options={Object.entries(stateOptions)?.map(
                          ([key, x]: any) => ({
                            value: x?.id,
                            label: x?.name,
                          })
                        )}
                      />
                    </Form.Item>
                  </Col>
                  {/* Quotation No. */}
                  <Col md={8} sm={12} xs={24}>
                    <span className=" fw-500">Quotation No.</span>
                    <Form.Item
                      name="number"
                      className="mb-3"
                      rules={[
                        {
                          required: true,
                          message: "Please enter Quotation No.",
                        },
                      ]}
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  {/* Date */}
                  <Col md={8} sm={12} xs={24}>
                    <span className=" fw-500">Date</span>
                    <Form.Item
                      name="date"
                      className="m-0"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Select Date",
                      //   },
                      // ]}
                    >
                      <DatePicker
                        format="DD/MM/YYYY"
                        // disabledDate={(current) =>
                        //   current && current > dayjs().endOf("day")
                        // }
                        style={{ width: "100%" }}
                        allowClear={false}
                        onChange={(val) => {
                          if (dueOn) {
                            let dueDate = dayjs(val, "DD/MM/YYYY").add(
                              dueOn,
                              "day"
                            );
                            form.setFieldValue("dueDate", dueDate);
                          }
                          setDateFiled(dayjs(val).toISOString());
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>

            {/* Item Table */}
            <div
              // className="tableWrapper"
              style={{ overflowY: "auto" }}
            >
              {/* dashedBorderTop */}
              <Row
                style={{
                  marginTop: "10px",
                  width: "100%",
                  backgroundColor: "whitesmoke",
                  padding: "5px 10px",
                  fontSize: "15px",
                  fontWeight: "bolder",
                }}
              >
                <Col span={12}>
                  <span>Item Detail</span>
                </Col>

                {(settingControl.discountPolicy ===
                  Discount_Policy.TradenCashDiscount ||
                  settingControl.discountPolicy ===
                    Discount_Policy.OnlyTradeDiscount ||
                  updateTimeData.tradeDiscount) && (
                  <Col span={12}>
                    <Row
                      gutter={6}
                      style={{ display: "flex", justifyContent: "end" }}
                    >
                      <Col style={{ paddingTop: "4px" }}>
                        <span>Discount (%)</span>
                      </Col>

                      <Col>
                        <Form.Item
                          className="discountInput inputBoxMb"
                          // name={["amount", "dics"]}
                          name="discount"
                        >
                          <InputNumber
                            placeholder="0.0"
                            controls={false}
                            min={0.0}
                            max={100}
                            // max={
                            //   form.getFieldValue("discountDrop") ===
                            //   DiscountType.PercentageDiscount
                            //     ? 100
                            //     : Infinity
                            // }
                            style={{
                              width: "100%",
                              // margin: "0px 30px 0px 10px",
                              // width: 60,
                            }}
                            onChange={(e: any) => {
                              // console.log(e);
                              handleItemsAllDiscount(e);
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                )}
              </Row>

              <table
                className="AddPageTabelDesign"
                // style={{ fontSize: windowWidth <= 1024 ? "10.3px" : "" }}
              >
                <thead>
                  <tr>
                    <th
                      colSpan={2}
                      rowSpan={2}
                      style={{ fontWeight: "500", width: "5%" }}
                    >
                      S No.
                    </th>
                    <th
                      rowSpan={2}
                      style={{
                        fontWeight: "500",
                        width: "17%",
                        // maxWidth: "14%",
                        borderLeft: "1px solid rgb(240, 245, 255)",
                      }}
                    >
                      Item(s)
                    </th>
                    {/* <th
                      rowSpan={2}
                      style={{
                        fontWeight: "500",
                        // width: windowWidth <= 1024 ? "18%" : "",
                        width: "22%",
                      }}
                    >
                      Description
                    </th> */}
                    {/* <th rowSpan={2} style={{ fontWeight: "500", width: "10%" }}>
                    Account
                  </th> */}
                    <th rowSpan={2} style={{ fontWeight: "500", width: "7%" }}>
                      Qty
                    </th>
                    {/* <th rowSpan={2} style={{ fontWeight: "500", width: "6%" }}>
                    Unit
                  </th> */}
                    <th rowSpan={2} style={{ fontWeight: "500", width: "9%" }}>
                      Unit Price
                    </th>
                    {(settingControl.discountPolicy ===
                      Discount_Policy.TradenCashDiscount ||
                      settingControl.discountPolicy ===
                        Discount_Policy.OnlyTradeDiscount ||
                      updateTimeData.tradeDiscount) && (
                      <th
                        colSpan={2}
                        style={{ fontWeight: "500", width: "12%" }}
                      >
                        Discount
                      </th>
                    )}
                    {(companySettings?.setting?.gstDetails?.scheme ===
                      CompanyGSTTypes.Regular ||
                      updateTimeData.isGSTVoucher) && (
                      <th
                        colSpan={2}
                        style={{ fontWeight: "500", width: "14%" }}
                      >
                        GST
                      </th>
                    )}
                    <th
                      colSpan={2}
                      rowSpan={2}
                      style={{ fontWeight: "500", width: "14%" }}
                    >
                      Gross Amount
                    </th>
                  </tr>
                  <tr>
                    {(settingControl.discountPolicy ===
                      Discount_Policy.TradenCashDiscount ||
                      settingControl.discountPolicy ===
                        Discount_Policy.OnlyTradeDiscount ||
                      updateTimeData.tradeDiscount) && (
                      <>
                        <th style={{ fontWeight: "500", width: "6%" }}>%</th>
                        <th style={{ fontWeight: "500", width: "6%" }}>₹</th>
                      </>
                    )}
                    {(companySettings?.setting?.gstDetails?.scheme ===
                      CompanyGSTTypes.Regular ||
                      updateTimeData.isGSTVoucher) && (
                      <>
                        <th style={{ fontWeight: "500", width: "6%" }}>%</th>
                        <th style={{ fontWeight: "500", width: "6%" }}>₹</th>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>
                  <Form.List name="items">
                    {(fields, { add, remove, move }) => {
                      return (
                        <>
                          {confirmation && (
                            <ConfirmationModal
                              open={confirmation}
                              text={`Do you really want to delete this item "${form.getFieldValue(
                                ["items", deleteItemList, "name"]
                              )}" ?`}
                              // text="Do you really want to delete this row?"
                              onNo={() => setConfirmation(false)}
                              onYes={() => {
                                remove(deleteItemList);
                                handleDeleteRowButton();
                                setItemLength(fields.length - 2);
                              }}
                            />
                          )}
                          {fields.map(({ key, name, ...restField }, index) => {
                            return (
                              <tr key={key}>
                                <td>
                                  {fields.length !== index + 1 && (
                                    <>
                                      <div className="ps-10">{index + 1}.</div>
                                    </>
                                  )}
                                </td>
                                <td>
                                  {fields.length !== index + 1 && (
                                    <Form.Item
                                      name={[name, "isService"]}
                                      className="m-0"
                                      {...restField}
                                    >
                                      {form.getFieldValue([
                                        "items",
                                        index,
                                        "isService",
                                      ]) ? (
                                        <Tooltip
                                          title="Service"
                                          placement="left"
                                          color="#1d39c4"
                                        >
                                          <Avatar
                                            style={{
                                              cursor: "pointer",
                                              verticalAlign: "middle",
                                              color: "#1d39c4",
                                              backgroundColor: "#f0f5ff",
                                              borderColor: "#adc6ff",
                                              fontWeight: "bold",
                                            }}
                                            size="small"
                                          >
                                            S
                                          </Avatar>
                                        </Tooltip>
                                      ) : (
                                        <Tooltip
                                          title="Goods"
                                          placement="left"
                                          color="#389e0d"
                                        >
                                          <Avatar
                                            style={{
                                              cursor: "pointer",
                                              verticalAlign: "middle",
                                              color: "#389e0d",
                                              backgroundColor: "#f6ffed",
                                              borderColor: "#b7eb8f",
                                              fontWeight: "bold",
                                            }}
                                            size="small"
                                          >
                                            G
                                          </Avatar>
                                        </Tooltip>
                                      )}
                                    </Form.Item>
                                  )}
                                </td>

                                <td>
                                  <Form.Item
                                    name={[name, "name"]}
                                    className="m-0"
                                    {...restField}
                                  >
                                    <Select
                                      variant="filled"
                                      className="variantFilledSelect"
                                      // allowClear={
                                      //   fields.length !== index + 1 && true
                                      // }
                                      // clearIcon={<span>{<EyeOutlined />}</span>}
                                      // onClear={() => remove(name)}
                                      // setConfirmation(true);
                                      showSearch
                                      placeholder="Search for items"
                                      optionFilterProp="children"
                                      filterOption={false}
                                      popupMatchSelectWidth={false}
                                      disabled={
                                        salesOrderId || quotationId
                                          ? true
                                          : false
                                      }
                                      listItemHeight={1}
                                      style={{
                                        minWidth: "100%",
                                        maxWidth: "240px",
                                        backgroundColor: "#FFFFFF",
                                      }}
                                      dropdownStyle={{ width: "250px" }}
                                      placement="bottomRight"
                                      notFoundContent={
                                        <Empty
                                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                                          description={
                                            <span>Sales item not found.</span>
                                          }
                                        />
                                      }
                                      // prefixCls={<EyeOutlined />}
                                      onSearch={(value: string) =>
                                        setSearchItem(value)
                                      }
                                      onClick={() => {
                                        setSearchItem("");
                                        setNewItem({
                                          data: {},
                                          isSaved: false,
                                        });
                                      }}
                                      onChange={(value: any) => {
                                        if (fields.length === index + 1) add();
                                        ItemSelect(value, index);
                                      }}
                                      options={itemsOption?.map((itm: any) => ({
                                        value: itm?.id,
                                        label: (
                                          <div>
                                            <Row justify={"space-between"}>
                                              <Col>
                                                <p
                                                  style={{
                                                    fontWeight: "bold",
                                                    color: "dimgray",
                                                  }}
                                                >
                                                  {itm?.name}
                                                </p>
                                              </Col>
                                              <Col>
                                                <EditOutlined
                                                  style={{
                                                    fontSize: "13px",
                                                  }}
                                                  onClick={() => {
                                                    setItemId(itm?.id);
                                                    setOpenModel({
                                                      type: "addItem",
                                                      param: {
                                                        open: true,
                                                      },
                                                    });
                                                  }}
                                                />
                                              </Col>
                                            </Row>
                                            <div>
                                              {itm?.hsnCode && (
                                                <span>
                                                  <span
                                                    style={{
                                                      fontWeight: "500",
                                                      color: "dimgray",
                                                    }}
                                                  >
                                                    HSN Code -
                                                  </span>
                                                  {itm?.hsnCode}
                                                </span>
                                              )}
                                              {itm?.sacCode && (
                                                <span>
                                                  <span
                                                    style={{
                                                      fontWeight: "500",
                                                      color: "dimgray",
                                                    }}
                                                  >
                                                    SAC Code -
                                                  </span>
                                                  {itm?.sacCode}
                                                </span>
                                              )}
                                              {itm?.description && (
                                                <span>
                                                  <span
                                                    style={{
                                                      fontWeight: "500",
                                                      color: "dimgray",
                                                    }}
                                                  >
                                                    {" "}
                                                    Des. -
                                                  </span>
                                                  {itm?.description}
                                                </span>
                                              )}
                                            </div>
                                          </div>
                                        ),
                                      }))}
                                      dropdownRender={(itemsOption) => (
                                        <>
                                          {itemsOption}
                                          <Divider
                                            style={{ margin: "5px 0px" }}
                                          />
                                          <Button
                                            style={{ width: "100%" }}
                                            icon={<PlusOutlined />}
                                            type="link"
                                            onClick={() => {
                                              setOpenModel({
                                                type: "addItem",
                                                param: {
                                                  open: true,
                                                },
                                              });
                                              setNewItem({
                                                data: {},
                                                isSaved: false,
                                              });
                                              setItemFields({
                                                field: fields,
                                                index: index,
                                              });
                                            }}
                                          >
                                            New Item
                                          </Button>
                                        </>
                                      )}
                                    />
                                  </Form.Item>
                                  {(settingControl.showItemDescription ||
                                    updateTimeData.showItemDescription) && (
                                    <Form.Item
                                      className="m-0"
                                      {...restField}
                                      name={[name, "description"]}
                                    >
                                      <Input variant="filled" />
                                    </Form.Item>
                                  )}
                                </td>
                                {/* <td>
                                <Form.Item
                                  className="m-0"
                                  {...restField}
                                  name={[name, "description"]}
                                >
                                  <Input />
                                </Form.Item>
                              </td> */}
                                {/* <td>
                                <Form.Item
                                  name={[name, "account"]}
                                  className="m-0"
                                  {...restField}
                                >
                                  <Select
                                    labelInValue
                                    showSearch
                                    placeholder="select"
                                    popupMatchSelectWidth={false}
                                    dropdownStyle={{ width: "250px" }}
                                    style={{ width: "140px" }}
                                    placement="bottomRight"
                                    filterOption={(input, option) =>
                                      (option?.label ?? "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                    }
                                    options={account}
                                  />
                                </Form.Item>
                              </td> */}
                                {/* Qty */}
                                <td>
                                  <Form.Item
                                    className="m-0"
                                    {...restField}
                                    name={[name, "amount", "qty"]}
                                    initialValue={0.0}
                                  >
                                    <InputNumber
                                      variant="filled"
                                      min={0}
                                      style={{
                                        width: "100%",
                                        backgroundColor: "#FFFFFF",
                                      }}
                                      step={0.01}
                                      controls={false}
                                      className="inputNumberTextEnd"
                                      onChange={() =>
                                        handleItemsNetAmmount(index)
                                      }
                                      // formatter={(value: any) =>
                                      //   Utils.getInputNumberFormat(value)
                                      // }
                                      // onKeyPress={(e) =>
                                      //   handelTwoDigitAfterDecimal(e)
                                      // }
                                      // formatter={(value) =>
                                      //   `${value}`.replace(/^(.*\.\d{2}).*$/, "$1")
                                      // }
                                      // parser={(value: any) =>
                                      //   isNaN(parseFloat(value)) ? '' : parseFloat(value).toFixed(2)
                                      // }
                                    />
                                  </Form.Item>

                                  <Form.Item
                                    name={[name, "amount", "unit"]}
                                    className="m-0"
                                    {...restField}
                                  >
                                    <Select
                                      bordered={false}
                                      // variant="filled"
                                      // className="inputNumberTextEnd"
                                      disabled
                                      labelInValue
                                      showSearch
                                      popupMatchSelectWidth={false}
                                      // dropdownStyle={{ width: "250px" }}
                                      // style={{ width: "140px" }}
                                      style={{
                                        minWidth: "100%",
                                        maxWidth: "240px",
                                        backgroundColor: "#FFFFFF",
                                      }}
                                      placement="bottomRight"
                                      // filterOption={(input, option) =>
                                      //   (option?.label ?? "")
                                      //     .toLowerCase()
                                      //     .includes(input.toLowerCase())
                                      // }
                                      // options={account}
                                    />
                                  </Form.Item>
                                </td>
                                {/* <td>
                                <Form.Item
                                  className="m-0"
                                  {...restField}
                                  name={[name, "amount", "unit"]}
                                >
                                  <Select
                                    popupMatchSelectWidth={false}
                                    placement="bottomRight"
                                  />
                                </Form.Item>
                              </td> */}
                                {/* Unit Price */}
                                <td style={{ verticalAlign: "top" }}>
                                  <Form.Item
                                    className="m-0"
                                    {...restField}
                                    name={[name, "amount", "price"]}
                                    initialValue={0.0}
                                  >
                                    <InputNumber
                                      variant="filled"
                                      min={0}
                                      step={0.01}
                                      style={{
                                        width: "100%",
                                        backgroundColor: "#FFFFFF",
                                      }}
                                      controls={false}
                                      className="inputNumberTextEnd"
                                      // formatter={(value: any) =>
                                      //   Utils.getInputNumberFormat(value)
                                      // }
                                      // onKeyPress={(e) =>
                                      //   handelTwoDigitAfterDecimal(e)
                                      // }
                                      onChange={() =>
                                        handleItemsNetAmmount(index)
                                      }
                                    />
                                  </Form.Item>
                                </td>

                                {(settingControl.discountPolicy ===
                                  Discount_Policy.TradenCashDiscount ||
                                  settingControl.discountPolicy ===
                                    Discount_Policy.OnlyTradeDiscount ||
                                  updateTimeData.tradeDiscount) && (
                                  <>
                                    <td style={{ verticalAlign: "top" }}>
                                      <Form.Item
                                        className="m-0"
                                        {...restField}
                                        name={[name, "amount", "discount"]}
                                      >
                                        <InputNumber
                                          variant="filled"
                                          style={{
                                            width: "100%",
                                            backgroundColor: "#FFFFFF",
                                          }}
                                          className="inputNumberTextEnd"
                                          controls={false}
                                          min={0}
                                          max={100}
                                          // formatter={(value: any) =>
                                          //   Utils.getInputNumberFormat(value)
                                          // }
                                          // onKeyPress={(e) =>
                                          //   handelTwoDigitAfterDecimal(e)
                                          // }
                                          onChange={() =>
                                            handleItemsNetAmmount(index)
                                          }
                                        />
                                      </Form.Item>
                                    </td>

                                    <td style={{ verticalAlign: "top" }}>
                                      <Form.Item
                                        className="m-0"
                                        {...restField}
                                        name={[name, "amount", "discountRupee"]}
                                        initialValue={0}
                                      >
                                        <InputNumber
                                          variant="filled"
                                          min={0}
                                          step={0.01}
                                          controls={false}
                                          style={{
                                            width: "100%",
                                            backgroundColor: "#FFFFFF",
                                          }}
                                          className="inputNumberTextEnd"
                                          max={returnNetAmount(
                                            index,
                                            form.getFieldValue(["items"])
                                          )}
                                          // formatter={(value: any) =>
                                          //   Utils.getInputNumberFormat(value)
                                          // }
                                          // onKeyPress={(e) =>
                                          //   handelTwoDigitAfterDecimal(e)
                                          // }F
                                          onChange={() => {
                                            onDiscountAmountChange(index);
                                          }}
                                        />
                                      </Form.Item>
                                    </td>
                                  </>
                                )}

                                {(companySettings?.setting?.gstDetails
                                  ?.scheme === CompanyGSTTypes.Regular ||
                                  updateTimeData.isGSTVoucher) && (
                                  <>
                                    <td style={{ verticalAlign: "top" }}>
                                      <Form.Item
                                        name={[name, "amount", "tax"]}
                                        className="m-0"
                                        {...restField}
                                      >
                                        <Select
                                          // variant="filled"
                                          // className="variantFilledSelect"
                                          bordered={false}
                                          style={{
                                            width: "100%",
                                            backgroundColor: "#FFFFFF",
                                          }}
                                          labelInValue
                                          disabled
                                          placeholder="%"
                                          popupMatchSelectWidth={false}
                                          placement="bottomRight"
                                          // onChange={() => {
                                          //   handleItemsNetAmmount(index);
                                          // }}
                                          options={gstOption?.map(
                                            (gst: any) => ({
                                              key: gst.id,
                                              value: gst.rate,
                                              label: gst.name,
                                            })
                                          )}
                                          /* {gstSlabs?.map((option: any) => (
                                      <Select.Option
                                        key={option?.id}
                                        value={option?.rate}
                                        title={option?.name}
                                      >
                                        {option?.rate + `%`}
                                      </Select.Option>
                                    ))} */
                                        />
                                      </Form.Item>
                                    </td>

                                    <td style={{ verticalAlign: "top" }}>
                                      <Tooltip
                                        title={form.getFieldValue([
                                          "items",
                                          index,
                                          "amount",
                                          "itemTaxAmt",
                                        ])}
                                        placement="top"
                                      >
                                        <Form.Item
                                          className="m-0"
                                          {...restField}
                                          name={[name, "amount", "itemTaxAmt"]}
                                        >
                                          <InputNumber
                                            // variant="filled"
                                            bordered={false}
                                            disabled
                                            style={{
                                              width: "100%",
                                              backgroundColor: "#FFFFFF",
                                            }}
                                            // step={0.01}
                                            // controls={false}
                                            className="inputNumberTextEnd"
                                            // formatter={(value: any) =>
                                            //   Utils.getInputNumberFormat(value)
                                            // }
                                            // onKeyPress={(e) =>
                                            //   handelTwoDigitAfterDecimal(e)
                                            // }
                                            // onChange={() => handleItemsNetAmmount(index)}
                                          />
                                        </Form.Item>
                                      </Tooltip>
                                    </td>
                                  </>
                                )}

                                {/* Total Amount */}
                                <td style={{ verticalAlign: "top" }}>
                                  <Form.Item
                                    className="m-0"
                                    {...restField}
                                    name={[name, "amount", "totalAmount"]}
                                    initialValue={0}
                                  >
                                    <InputNumber
                                      variant="filled"
                                      // min={0}
                                      step={0.01}
                                      style={{
                                        width: "100%",
                                        backgroundColor: "#FFFFFF",
                                      }}
                                      controls={false}
                                      className="inputNumberTextEnd"
                                      // formatter={(value: any) =>
                                      //   Utils.getInputNumberFormat(value)
                                      // }
                                      // onKeyPress={(e) =>
                                      //   handelTwoDigitAfterDecimal(e)
                                      // }
                                      onChange={(value) => {
                                        onItemsTotalAmountChange(index);
                                      }}
                                    />
                                  </Form.Item>
                                </td>

                                <td style={{ width: 25, verticalAlign: "top" }}>
                                  {fields.length !== index + 1 && (
                                    <img
                                      className="cursor mx-1"
                                      onClick={() => {
                                        setDeleteItemList(name);
                                        setConfirmation(true);
                                      }}
                                      src={deleteImg}
                                      alt="Delete"
                                    />
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </>
                      );
                    }}
                  </Form.List>

                  <tr>
                    <td colSpan={3} className="textEnd pe-14">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          // alignContent:"space-between"
                          // verticalAlign: "inherit",
                        }}
                      >
                        {(settingControl.showOtherCharges ||
                          updateTimeData.friInsuranceOtherCharge) && (
                          <Button
                            style={{ backgroundColor: "ButtonFace" }}
                            icon={<PlusOutlined />}
                            size="small"
                            onClick={() => {
                              setShowFreightInsurance(true);
                              setSelectedOtherExpenses([]);
                              setFooterLedger({
                                ledgerAmount: 0,
                                ledgerGstAmount: 0,
                                ledgerTotalAmount: 0,
                              });

                              form.setFieldValue(
                                ["freightInsLedgers"],
                                [
                                  {
                                    gstRate: 0,
                                    gstAmount: 0,
                                    totalAmount: 0,
                                    amount: 0,
                                  },
                                ]
                              );
                            }}
                            disabled={showFreightInsurance}
                          >
                            Other Expenses
                          </Button>
                        )}
                        <div style={{ fontWeight: "bold" }}>Sub Total</div>
                      </div>
                    </td>
                    <td
                      colSpan={1}
                      className="textEnd pe-14"
                      style={{ fontWeight: "bold" }}
                    >
                      {Utils.getFormattedNumber(footerTotal.qty)}
                    </td>
                    {/* <td colSpan={1} className="textEnd pe-12">
                      ₹ {Utils.getFormattedNumber(footerTotal.unitPrice)}
                    </td> */}
                    <td></td>
                    {(settingControl.discountPolicy ===
                      Discount_Policy.TradenCashDiscount ||
                      settingControl.discountPolicy ===
                        Discount_Policy.OnlyTradeDiscount ||
                      updateTimeData.tradeDiscount) && (
                      <>
                        <td></td>
                        <td
                          className="textEnd pe-12"
                          style={{ fontWeight: "bold" }}
                        >
                          ₹ {Utils.getFormattedNumber(footerTotal.discount)}
                        </td>
                      </>
                    )}

                    {(companySettings?.setting?.gstDetails?.scheme ===
                      CompanyGSTTypes.Regular ||
                      updateTimeData.isGSTVoucher) && (
                      <>
                        <td></td>
                        <td
                          className="textEnd pe-12"
                          style={{ fontWeight: "bold" }}
                        >
                          ₹ {Utils.getFormattedNumber(footerTotal.gst)}
                        </td>
                      </>
                    )}
                    <td
                      className="textEnd pe-12"
                      style={{ paddingRight: "35px", fontWeight: "bold" }}
                    >
                      ₹ {Utils.getFormattedNumber(footerTotal.amount)}
                    </td>
                    <td></td>
                    {/* {isGSTEnabled && (
                      <td colSpan={2} className="textEnd pe-12">
                        ₹ {Utils.getFormattedNumber(footerTotal.gst)}
                      </td>
                    )}
                    <td className="textEnd pe-12">
                      ₹ {Utils.getFormattedNumber(footerTotal.amount)}
                    </td> */}
                  </tr>

                  {/* Show Other Expenses */}
                  {/* showFreightInsurance */}
                  {showFreightInsurance && (
                    <>
                      <Form.List name="freightInsLedgers">
                        {(fields, { add, remove, move }) => {
                          return (
                            <>
                              {ledgerconfirmation && (
                                <ConfirmationModal
                                  open={ledgerconfirmation}
                                  text={
                                    "Do you really want to delete this item"
                                  }
                                  onNo={() => setLedgerConfirmation(false)}
                                  onYes={() => {
                                    setLedgerConfirmation(false);
                                    setLedgerLength(fields.length - 2);
                                    handleLedgerNetAmount(0);
                                    remove(deleteLedgerList);
                                    setSelectedOtherExpenses((prev) => {
                                      const newDisabledInputs = [...prev];
                                      newDisabledInputs.splice(
                                        deleteItemList,
                                        1
                                      );
                                      return newDisabledInputs;
                                    });
                                  }}
                                />
                              )}
                              {fields.map(
                                ({ key, name, ...restField }, index) => {
                                  return (
                                    <tr key={key}>
                                      <td>{index + 1}</td>
                                      <td></td>

                                      <td>
                                        <Form.Item
                                          name={[name, "id"]}
                                          className="m-0"
                                          {...restField}
                                        >
                                          <Select
                                            labelInValue
                                            bordered={false}
                                            // variant="filled"
                                            // style={{ width: "338px" }}
                                            placeholder="Search for Other Expenses"
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                              (option?.label?.toString() ?? "")
                                                .toLowerCase()
                                                .includes(input.toLowerCase())
                                            }
                                            // onSearch={}
                                            onChange={(e: any) => {
                                              console.log(
                                                "form",
                                                form.getFieldsValue()
                                              );

                                              if (fields.length === index + 1)
                                                add();
                                              freightInsLedgersSelect(
                                                e?.value,
                                                index
                                              );
                                            }}
                                            options={freightAndInsuranceOptions
                                              ?.filter(
                                                (option: any) =>
                                                  !selectedOtherExpenses.includes(
                                                    option.id
                                                  )
                                              )
                                              ?.map((value: any) => ({
                                                value: value?.id,
                                                label: value?.ledgerName.concat(
                                                  value?.aliasName
                                                    ? `${
                                                        " (" +
                                                        value?.aliasName +
                                                        ")"
                                                      }`
                                                    : ""
                                                ),
                                              }))}
                                            dropdownRender={(
                                              customerOptions
                                            ) => (
                                              <>
                                                {customerOptions}
                                                <Divider
                                                  style={{
                                                    margin: "5px 0px",
                                                  }}
                                                />
                                                <Button
                                                  style={{ width: "100%" }}
                                                  icon={<PlusOutlined />}
                                                  type="link"
                                                  onClick={() => {
                                                    setEditParty({ id: "" });
                                                    setOpenModel({
                                                      type: "addLedger",
                                                      param: {
                                                        open: true,
                                                      },
                                                    });
                                                  }}
                                                >
                                                  New Ledger
                                                </Button>
                                              </>
                                            )}
                                          />
                                        </Form.Item>
                                      </td>
                                      <td></td>

                                      <td>
                                        <Form.Item
                                          name={[name, "amount"]}
                                          className="m-0"
                                          {...restField}
                                          initialValue={0.0}
                                        >
                                          <InputNumber
                                            variant="filled"
                                            defaultValue={0.0}
                                            min={0}
                                            step={0.01}
                                            style={{
                                              width: "100%",
                                              backgroundColor: "#FFFFFF",
                                            }}
                                            controls={false}
                                            className="inputNumberTextEnd"
                                            onChange={() => {
                                              handleLedgerNetAmount(index);
                                            }}
                                          />
                                        </Form.Item>
                                      </td>
                                      {(settingControl.discountPolicy ===
                                        Discount_Policy.TradenCashDiscount ||
                                        settingControl.discountPolicy ===
                                          Discount_Policy.OnlyTradeDiscount ||
                                        updateTimeData.tradeDiscount) && (
                                        <>
                                          <td></td>
                                          <td></td>
                                        </>
                                      )}

                                      {(companySettings?.setting?.gstDetails
                                        ?.scheme === CompanyGSTTypes.Regular ||
                                        updateTimeData.isGSTVoucher) && (
                                        <>
                                          <td>
                                            <Form.Item
                                              name={[name, "gstRate"]}
                                              className="m-0"
                                              {...restField}
                                            >
                                              <Select
                                                // variant="filled"
                                                bordered={false}
                                                labelInValue
                                                style={{
                                                  width: "100%",
                                                  backgroundColor: "#FFFFFF",
                                                }}
                                                placeholder={"%"}
                                                popupMatchSelectWidth={false}
                                                onChange={() => {
                                                  handleLedgerNetAmount(index);
                                                }}
                                                filterOption={(input, option) =>
                                                  (
                                                    option?.label?.toString() ??
                                                    ""
                                                  )
                                                    .toLowerCase()
                                                    .includes(
                                                      input.toLowerCase()
                                                    )
                                                }
                                                options={gstOption?.map(
                                                  (gst: any) => ({
                                                    // key: gst.id,
                                                    // value: gst.rate,
                                                    // label: gst.name,
                                                    value: gst?.id,
                                                    title: gst?.rate,
                                                    label: gst?.name,
                                                  })
                                                )}
                                              />
                                            </Form.Item>
                                          </td>
                                          <td style={{ width: "6%" }}>
                                            <Form.Item
                                              className="m-0 inputNumberTextEnd"
                                              name={[name, "gstAmount"]}
                                              {...restField}
                                            >
                                              <InputNumber
                                                // variant="filled"
                                                disabled
                                                bordered={false}
                                                style={{
                                                  width: "100%",
                                                  backgroundColor: "#FFFFFF",
                                                }}
                                                onChange={() => {
                                                  handleLedgerNetAmount(index);
                                                }}
                                              />
                                            </Form.Item>
                                          </td>
                                        </>
                                      )}
                                      <td>
                                        <Form.Item
                                          name={[name, "totalAmount"]}
                                          className="m-0"
                                          {...restField}
                                          initialValue={0.0}
                                        >
                                          <InputNumber
                                            // disabled
                                            variant="filled"
                                            style={{
                                              width: "100%",
                                              backgroundColor: "#FFFFFF",
                                            }}
                                            className="inputNumberTextEnd"
                                            controls={false}
                                            step={0.01}
                                            onChange={() => {
                                              onLedgerTotalAmountChange(index);
                                            }}
                                          />
                                        </Form.Item>
                                      </td>
                                      <td>
                                        <img
                                          className="cursor mx-1"
                                          onClick={() => {
                                            setDeleteLedgerList(name);
                                            setLedgerConfirmation(true);
                                          }}
                                          src={deleteImg}
                                          alt="Delete"
                                        />
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            </>
                          );
                        }}
                      </Form.List>

                      {
                        <tr>
                          <td
                            colSpan={3}
                            className="textEnd pe-14"
                            style={{ fontWeight: "bold" }}
                          >
                            Sub Total
                          </td>
                          <td
                            colSpan={2}
                            className="textEnd pe-12"
                            style={{ fontWeight: "bold" }}
                          >
                            ₹{" "}
                            {Utils.getFormattedNumber(
                              footerLedger.ledgerAmount
                            )}
                          </td>
                          {(settingControl.discountPolicy ===
                            Discount_Policy.TradenCashDiscount ||
                            settingControl.discountPolicy ===
                              Discount_Policy.OnlyTradeDiscount ||
                            updateTimeData.tradeDiscount) && (
                            <>
                              <td></td>
                              <td></td>
                            </>
                          )}
                          {(companySettings?.setting?.gstDetails?.scheme ===
                            CompanyGSTTypes.Regular ||
                            updateTimeData.isGSTVoucher) && (
                            <>
                              <td></td>

                              <td
                                className="textEnd pe-12"
                                style={{ fontWeight: "bold" }}
                              >
                                ₹{" "}
                                {Utils.getFormattedNumber(
                                  footerLedger.ledgerGstAmount
                                )}
                              </td>
                            </>
                          )}
                          <td
                            className="textEnd pe-12"
                            // style={{ paddingRight: "35px" }}
                            style={{ fontWeight: "bold" }}
                          >
                            ₹{" "}
                            {Utils.getFormattedNumber(
                              footerLedger.ledgerTotalAmount
                            )}
                          </td>
                          <td></td>
                        </tr>
                      }
                    </>
                  )}
                </tbody>
              </table>
            </div>

            <Row className="pt-10">
              <Col xl={6} lg={8} md={24} xs={24}>
                <Form.Item name="narration" className="m-0">
                  <TextArea rows={4} placeholder="Write Narration" />
                </Form.Item>

                <div style={{ marginTop: "10px" }}>
                  <Button
                    style={{ backgroundColor: "ButtonFace" }}
                    onClick={() => {
                      setOpenModel({
                        type: "termsAndConditionsModal",
                        param: { open: true },
                      });
                    }}
                  >
                    View and edit Terms and Conditions
                  </Button>
                </div>

                <Form.Item
                  name="attachments"
                  valuePropName="fileList"
                  getValueFromEvent={(e) => {
                    return e.fileList;
                  }}
                  // className="m-0"
                  style={{ paddingTop: "10px", margin: "0px" }}
                >
                  <Upload
                    multiple
                    // accept={".png, .jpg, .jpeg, .pdf"}
                    // accept={".png, .jpg, .jpeg"}
                    beforeUpload={() => {
                      return false;
                    }}
                    onRemove={(e: any) => {
                      if ((id || draftId) && e?.id) {
                        setDeleteUploadId([...deleteUploadId, e?.id]);
                      }
                    }}
                    onPreview={(e: any) => {
                      console.log("preview", e);
                      if ((id || draftId) && e?.path) {
                        setPreviewImage({
                          isBaseConvert: false,
                          path: e,
                        });
                      } else if (e.type?.split("/")[0] === "image") {
                        setPreviewImage({
                          isBaseConvert: true,
                          path: e,
                        });
                      }
                    }}
                  >
                    <Button
                      icon={<UploadOutlined />}
                      style={{ backgroundColor: "ButtonFace" }}
                    >
                      Upload
                    </Button>
                  </Upload>
                </Form.Item>
              </Col>

              <Col xl={10} xs={0}></Col>
              {/* <Col lg={17} md={24}>
              <div style={{ overflowY: "auto" }}></div>
            </Col> */}
              <Col xl={8} lg={24} md={24} xs={24}>
                <Row gutter={[0, 8]}>
                  <Col lg={9} span={9}>
                    <span>Selling Price</span>
                  </Col>
                  <Col lg={15} span={15} className="textEnd">
                    <span>
                      ₹ {Utils.getFormattedNumber(footerTotal.taxableAmount)}
                    </span>
                  </Col>
                  {showFreightInsurance && (
                    <>
                      <Col lg={9} span={9}>
                        <span>Other Expenses</span>
                      </Col>
                      <Col lg={15} span={15} className="textEnd">
                        <span>
                          ₹{Utils.getFormattedNumber(footerLedger.ledgerAmount)}
                        </span>
                      </Col>
                    </>
                  )}
                  {(settingControl.discountPolicy ===
                    Discount_Policy.TradenCashDiscount ||
                    settingControl.discountPolicy ===
                      Discount_Policy.OnlyTradeDiscount ||
                    updateTimeData.tradeDiscount) && (
                    <>
                      <Col lg={9} span={9}>
                        <span>Discount</span>
                      </Col>
                      <Col lg={15} span={15} className="textEnd">
                        <span>
                          ₹ {Utils.getFormattedNumber(footerTotal.discount)}
                        </span>
                      </Col>
                    </>
                  )}
                  {isGSTEnabled && (
                    <>
                      {isStateSame ? (
                        <>
                          <Col lg={9} span={9}>
                            <span>CGST</span>
                          </Col>
                          <Col lg={15} span={15} className="textEnd">
                            <span>
                              ₹{" "}
                              {Utils.getFormattedNumber(
                                (footerTotal.gst +
                                  footerLedger.ledgerGstAmount) /
                                  2
                              )}
                            </span>
                          </Col>
                          <Col lg={9} span={9}>
                            <span>SGST</span>
                          </Col>
                          <Col lg={15} span={15} className="textEnd">
                            <span>
                              ₹{" "}
                              {Utils.getFormattedNumber(
                                (footerTotal.gst +
                                  footerLedger.ledgerGstAmount) /
                                  2
                              )}
                            </span>
                          </Col>
                        </>
                      ) : currentCustomerState ? (
                        <>
                          <Col lg={9} span={9}>
                            <span>IGST</span>
                          </Col>
                          <Col lg={15} span={15} className="textEnd">
                            <span>
                              ₹{" "}
                              {Utils.getFormattedNumber(
                                footerTotal.gst + footerLedger.ledgerGstAmount
                              )}
                            </span>
                          </Col>
                        </>
                      ) : (
                        <></>
                      )}

                      <Col lg={15}>
                        <Row>
                          <Col lg={14} span={14}>
                            <Radio.Group
                              disabled
                              onChange={(e) => {
                                setTdsOrTcs(e.target.value);
                                handleAllTheTotalValues();
                              }}
                              value={tdsOrTcs}
                            >
                              <Radio value={0}>TDS</Radio>
                              <Radio value={1}>TCS</Radio>
                            </Radio.Group>
                          </Col>
                          <Col lg={10} span={10}>
                            {tdsOrTcs === 1 ? (
                              <Form.Item name="tcsRate" className="m-0">
                                <Select
                                  disabled
                                  size="small"
                                  dropdownRender={(menu) => (
                                    <>
                                      {menu}
                                      <div
                                        className=" ps-6"
                                        onClick={() => setTcsModelOpen(true)}
                                      >
                                        TCS Details
                                      </div>
                                    </>
                                  )}
                                  onSelect={() => handleAllTheTotalValues()}
                                  options={[
                                    { value: 0, label: "None" },
                                    { value: 0.1, label: "0.1 %" },
                                  ]}
                                />
                              </Form.Item>
                            ) : tdsOrTcs === 0 ? (
                              <Form.Item name="tdsRate" className="m-0">
                                <Select
                                  disabled
                                  size="small"
                                  dropdownRender={(menu) => (
                                    <>
                                      {menu}
                                      <div
                                        className=" ps-6"
                                        onClick={() => setTdsModelOpen(true)}
                                      >
                                        TDS Details
                                      </div>
                                    </>
                                  )}
                                  onChange={() => handleAllTheTotalValues()}
                                  options={TdsOptions?.map((itm: any) => ({
                                    value: itm?.value,
                                    label: itm?.label,
                                  }))}
                                />
                              </Form.Item>
                            ) : (
                              <></>
                            )}
                          </Col>
                        </Row>
                      </Col>
                      {tdsOrTcs === 1 ? (
                        <Col lg={9} span={9} className="textEnd">
                          <span>₹ {Utils.getFormattedNumber(totalTcs)}</span>
                        </Col>
                      ) : tdsOrTcs === 0 ? (
                        <Col lg={9} span={9} className="textEnd">
                          <span>₹ {Utils.getFormattedNumber(totalTds)}</span>
                        </Col>
                      ) : (
                        <></>
                      )}
                    </>
                  )}

                  <>
                    <Col lg={6} span={6}>
                      <span>Sub Total</span>
                    </Col>

                    <Col lg={18} span={18} className="textEnd">
                      <span>
                        ₹{" "}
                        {Utils.getFormattedNumber(
                          footerTotal.amount + footerLedger.ledgerTotalAmount
                        )}
                      </span>
                      {/* {Utils.getFormattedNumber(cashDiscount)} */}
                    </Col>

                    {(settingControl.discountPolicy ===
                      Discount_Policy.OnlyCashDiscount ||
                      settingControl.discountPolicy ===
                        Discount_Policy.TradenCashDiscount) && (
                      <>
                        <Col lg={6} span={6}>
                          <span>Cash Discount</span>
                        </Col>

                        <Col lg={4} span={4}>
                          <Form.Item
                            // style={{flex:'end'}}
                            name="cashDiscountDrop"
                            className="inputBoxMb"
                          >
                            <Select
                              size="small"
                              options={[
                                {
                                  value: DiscountType.PercentageDiscount,
                                  label: "%",
                                },
                                {
                                  value: DiscountType.RupeeDiscount,
                                  label: "₹",
                                },
                              ]}
                              onChange={() => hangleCashDisCount()}
                            />
                          </Form.Item>
                        </Col>

                        <Col lg={5} span={5}>
                          <Form.Item
                            className="discountInput inputBoxMb textEnd"
                            name="cashDiscount"
                          >
                            <InputNumber
                              step={0.01}
                              controls={false}
                              className="inputNumberTextEnd"
                              size="small"
                              placeholder="0.0"
                              min={0.0}
                              max={
                                form.getFieldValue("cashDiscountDrop") ===
                                DiscountType.PercentageDiscount
                                  ? 100
                                  : footerTotal.amount +
                                    footerLedger.ledgerTotalAmount
                              }
                              style={{
                                width: "100%",
                                // borderBottom: "1px solid black"
                                // margin: "0px 30px 0px 10px",
                              }}
                              onChange={() => hangleCashDisCount()}
                            />
                          </Form.Item>
                        </Col>
                        <Col lg={9} span={9} className="textEnd">
                          <span>
                            ₹ {Utils.getFormattedNumber(cashDiscountRs)}
                          </span>
                        </Col>

                        {cashDiscountRs > 0 && (
                          <>
                            <Col lg={6} span={6}>
                              <span>Total</span>
                            </Col>

                            <Col lg={18} span={18} className="textEnd">
                              <span>
                                ₹{" "}
                                {Utils.getFormattedNumber(
                                  footerTotal.amount +
                                    footerLedger.ledgerTotalAmount -
                                    cashDiscountRs
                                )}
                              </span>
                            </Col>
                          </>
                        )}
                      </>
                    )}
                  </>
                </Row>
                <Divider className="my-5 grayBg" />
                <Row gutter={[12, 2]}>
                  {(settingControl.autoRoundOff || updateTimeData.roundOff) && (
                    <>
                      <Col lg={5} span={5}>
                        <span>Round off</span>
                      </Col>

                      <Col lg={4} span={4}>
                        <Form.Item
                          valuePropName="checked"
                          name="roundoffCheckbox"
                          className="inputBoxMb"
                        >
                          <Checkbox
                            onChange={() => {
                              const roundOffAmount = handleRoundoff(
                                footerTotal.amount +
                                  footerLedger.ledgerTotalAmount -
                                  Math.round(cashDiscountRs * 100) / 100,
                                form.getFieldValue("roundoffCheckbox"),
                                settingControl.roundOffType
                              );

                              form.setFieldValue("roundOff", roundOffAmount);
                              setRoundoffAmount(roundOffAmount);
                            }}
                          />
                        </Form.Item>
                      </Col>

                      <Col
                        lg={15}
                        span={15}
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Form.Item
                          className="discountInput inputBoxMb textEnd"
                          name="roundOff"
                        >
                          <InputNumber
                            step={0.01}
                            className="inputNumberTextEnd"
                            size="small"
                            controls={false}
                            placeholder="0.0"
                            // bordered={false}
                            // controls={false}
                            style={{
                              width: "100%",
                            }}
                            onChange={(e: any) => {
                              setRoundoffAmount(e);
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </>
                  )}

                  <Col
                    lg={11}
                    span={11}
                    style={{ fontWeight: 600, fontSize: "16px" }}
                  >
                    <span>Total Invoice Value</span>
                  </Col>
                  {/* <Col lg={8} className=" fw-500">
                GBP
              </Col> */}
                  <Col
                    lg={13}
                    span={13}
                    style={{ fontWeight: 600, fontSize: "16px" }}
                    className="textEnd"
                  >
                    <span>
                      ₹{" "}
                      {Utils.getFormattedNumber(
                        Utils.getNumber(footerTotal.amount) +
                          Utils.getNumber(footerLedger.ledgerTotalAmount) +
                          roundOffAmount -
                          Math.round(cashDiscountRs * 100) / 100
                      )}
                    </span>
                  </Col>
                  {!isInCash && itemLength > 0 && (
                    <>
                      {/* <Divider className="my-10 grayBg" /> */}
                      {/* <Col lg={12} className="pt-5">
                      <Checkbox
                        disabled
                        onChange={(e) => {
                          e.target.checked
                            ? form.setFieldValue("receivedAmount", footerTotal.amount)
                            : form.setFieldValue("receivedAmount", 0);
                          onAmountReceived();
                        }}
                      >
                        Received
                      </Checkbox>
                    </Col> */}
                      {/* <Col lg={12} className="textEnd">
                      <Form.Item className="m-0" name="receivedAmount">
                        <InputNumber
                          disabled
                          style={{ width: "100%" }}
                          size="small"
                          controls={false}
                          className="inputNumberTextEnd "
                          onChange={(e) => onAmountReceived()}
                        />
                      </Form.Item>
                    </Col> */}
                      {/* <Divider className="my-10 grayBg" /> */}
                      {/* <Col lg={9}>Balance</Col>
                    <Col lg={15} className="textEnd">
                      ₹{Utils.getFormattedNumber(invoiceTotal - balance)}
                    </Col> */}
                    </>
                  )}
                </Row>
                {/* <Divider
                style={{
                  borderBottom: "2px solid rgb(203, 203, 203)",
                }}
                className="my-5"
              /> */}

                <Row justify="end" className="mt-10">
                  <Col>
                    <Button
                      onClick={() => {
                        if (
                          Utils.getNumber(footerTotal.amount) +
                            Utils.getNumber(footerLedger.ledgerTotalAmount) +
                            roundOffAmount -
                            Math.round(cashDiscountRs * 100) / 100 >
                            0 &&
                          !id
                        ) {
                          save(false, true);
                        }
                        navigate(-1);
                      }}
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col className="ps-14">
                    <Form.Item>
                      <Button
                        type="primary"
                        loading={saveButtonLoading}
                        onClick={() => {
                          save(false);
                        }}
                      >
                        {id ? "Update" : "Save"}
                      </Button>
                    </Form.Item>
                  </Col>
                  {/* <Col className="ps-14">
                <Form.Item>
                  <Button
                    // loading={saveNewLoading}
                    onClick={() => {
                      save(true);
                    }}
                    type="primary"
                  >
                    Save & New
                  </Button>
                </Form.Item>
              </Col> */}
                </Row>
              </Col>
            </Row>
          </Form>
        </Card>
      </Spin>
      {openModel.type === "addItem" && (
        <AddItems
          open={openModel?.param.open}
          onCancel={() => setOpenModel({ type: "", param: "" })}
          id={itemId}
          setTrigger={setloadItemAPI}
          setNewItem={setNewItem}
        />
      )}
      {openModel.type === "addLedger" && (
        <AddLedger
          openDrawer={openModel?.param.open}
          id={editParty.id}
          setOpenDrawer={() => setOpenModel({ type: "", param: "" })}
          setTrigger={() => setTrigger(!trigger)}
          setAddLedgerResponse={setAddLedgerResponse}
        />
      )}
      {previewImage.path && (
        <DocumentPreview
          open={true}
          onCancel={() =>
            setPreviewImage({
              path: "",
              isBaseConvert: false,
              // uploadType: "",
            })
          }
          documentDetails={previewImage}
        />
      )}
      {openModel.type === "termsAndConditionsModal" && (
        <TermsAndConditionModal
          open={openModel.param.open}
          onCancel={() => setOpenModel({ type: "", param: { open: false } })}
          setTermsAndConditionField={setTermsAndConditionField}
          termsAndConditionField={termsAndConditionField}
          voucherType={Voucher_Type?.SalesVoucher}
        />
      )}
    </div>
  );
};

export default AddQuotation;
