import React from "react";
import {
  Layout,
  Avatar,
  Popover,
  Typography,
  Menu,
  theme,
  MenuProps,
  ConfigProvider,
  Input,
  Drawer,
  Breadcrumb,
  Space,
} from "antd";
import companylogo1 from "../Images/caplogo.png";
import {
  PercentageOutlined,
  SearchOutlined,
  MenuOutlined,
  MenuFoldOutlined,
  SettingOutlined,
  UserOutlined,
  BellOutlined,
} from "@ant-design/icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useUser } from "../../Context/UserContext";
import companylogo from "../Images/CapsitechAccounts.svg";
import { HiOutlineUserGroup } from "react-icons/hi";
import "./Dashboardlayout.css";
import { RxCross2 } from "react-icons/rx";
import searchlog from "../Images/search1.svg";
import { useTheme } from "../Context/ThemeContext";
import { ContentUser } from "../../Common/Admin/AdminComponent";
import settingslogo from "../../images/settingslogo.png";
import avtaruser from "../../images/avtaruser.png";
import allclients from "../../images/allclient.png";
import AssetImg from "../../images/AssetImg.svg";
import Search from "../Images/search.svg";
import { useClient } from "../Context/ClientContext";

import { useHotkeys, HotkeysProvider } from "react-hotkeys-hook";
const { Header, Sider, Content } = Layout;

type MenuItem = Required<MenuProps>["items"][number];

const MainClientTable = (props: any) => {
  const { isloggedIn, setIsLoggedIn } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const { isAuthenticated } = useUser();
  const [collapsed, setCollapsed] = React.useState(false);
  const [searchClicked, setSearchClicked] = React.useState(false);
  const [width, setWidth] = React.useState<any>(window.innerWidth);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  // const [open, setOpen] = React.useState<boolean>(false);
  const themeData: any = useTheme();
  const { defaultAlgorithm, darkAlgorithm } = theme;
  console.log("themeData", themeData, themeData.themeData?.silderSize);

  React.useEffect(() => {
    if (!isAuthenticated()) navigate("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  React.useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  const selectedMenuItem = (key: any) => {
    setOpenDrawer(false);
    navigate(key);
  };

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: "group"
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      type,
    } as MenuItem;
  }

  const items: MenuItem[] = [
    // getItem("Dashboard", "", <HomeOutlined />),
    getItem(
      <Link to={"/accounts"}>Clients</Link>,
      "/accounts",
      <img
        src={allclients}
        alt="all-clients"
        height={"20px"}
        width={"20px"}
        style={{ marginRight: "12px" }}
      />
    ),
    // getItem(
    //   <Link to={"/accounts/generalsettings"}>General Settings</Link>,
    //   "/accounts/generalsettings",
    //   <img
    //     alt="settingslogo"
    //     src={settingslogo}
    //     style={{ height: "20px", width: "20px", marginRight: "8px" }}
    //   />
    // ),
    getItem(
      <Link to={"/accounts/gst"}>GST</Link>,
      "/accounts/gst",
      <img
        alt="AssetImg"
        src={AssetImg}
        style={{ height: "20px", width: "20px", marginRight: "12px" }}
      />
    ),
    getItem(
      <Link to={"/accounts/users"}>Users</Link>,
      "/accounts/users",
      <img
        alt="AssetImg"
        src={AssetImg}
        style={{ height: "20px", width: "20px", marginRight: "12px" }}
      />
    ),
  ];
  console.log(width, "width--===");
  return (
    <>
      {/* <ConfigProvider theme={{ token: { colorPrimary: "#3d4998" } }}> */}
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: themeData?.themeData?.themeColor ?? "#395cd2",
            fontSize: themeData?.themeData?.fontSize ?? 14,
            borderRadius: themeData?.themeData?.borderRadius ?? 6,
          },
          algorithm:
            themeData?.themeData?.darkMode === true
              ? darkAlgorithm
              : defaultAlgorithm,
        }}
        componentSize={themeData?.themeData?.componentSize ?? "middle"}
      >
        <HotkeysProvider>
          <Layout>
            {/* ------- Sider & Drawer ------- */}
            {/* (themeData?.themeData?.silderSize ?? true) ? */}
            {width > 1024 && themeData?.themeData?.silderSize ? (
              <Sider
                trigger={null}
                collapsible
                collapsed={collapsed}
                breakpoint="lg"
                // onCollapse={(x) => {
                //   setCollapsed(x);
                // }}
                style={{
                  background: colorBgContainer,
                  position: "fixed",
                  boxShadow: "0px 15px 4px 0px #E4E8F0",
                  height: "100vh",
                  borderRight: "1px solid #E4E8F0",
                }}
                className="sidertoggle"
              >
                <div>
                  <div style={{ position: "fixed", top: 0, zIndex: 1 }}>
                    <img
                      src={collapsed ? companylogo1 : companylogo}
                      width={collapsed ? 50 : 140}
                      height={50}
                      onClick={() => setCollapsed(!collapsed)}
                      style={{
                        margin: "4px 0px 0px 16px",
                        transitionDuration: "0.18s",
                        cursor: "pointer",
                      }}
                      alt="companylogo1"
                    />
                  </div>
                  <Menu
                    mode="inline"
                    defaultSelectedKeys={[location.pathname]}
                    selectedKeys={[location.pathname]}
                    items={items}
                    onClick={(e: any) => selectedMenuItem(e.key)}
                    style={{
                      top: "60px",
                      position: "relative",
                      overflowY: "auto",
                      height: `calc(100vh - 30px)`,
                      border: "none",
                      // boxShadow: "2px 1px 5px rgb(81 100 120 / 8%)",
                    }}
                  />
                </div>
              </Sider>
            ) : (
              <Drawer
                className="drawerToggle"
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
                closeIcon={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={companylogo}
                      width={120}
                      height={50}
                      style={{
                        margin: "0px 0px 0px 10px",
                      }}
                      alt="companylogo1"
                    />
                    <div>
                      <MenuOutlined
                        style={{
                          fontSize: "20px",
                          paddingLeft: "20px",
                        }}
                      />
                    </div>
                  </div>
                }
                placement="left"
                style={{ width: "200px" }}
              >
                <Menu
                  mode="inline"
                  defaultSelectedKeys={[location.pathname]}
                  selectedKeys={[location.pathname]}
                  items={items}
                  onClick={(e: any) => selectedMenuItem(e.key)}
                  style={{
                    // width: "200px",
                    // position: "relative",
                    // overflowY: "auto",

                    // top: "60px",
                    position: "relative",
                    overflowY: "auto",
                    height: `calc(100vh - 60px)`,
                    fontFamily: "'Open Sans','sans-serif'",
                    fontSize: "14px",
                    borderRight: "none",
                    boxShadow: "2px 1px 5px rgb(81 100 120 / 8%)",
                  }}
                />
              </Drawer>
            )}
            <Layout
              style={{
                marginLeft:
                  width <= 1024 || themeData?.themeData?.silderSize !== true
                    ? "0px"
                    : collapsed !== true
                    ? "200px"
                    : "80px",
                transition: "0.2s",
              }}
            >
              {/* ------- Header ------- */}
              <Header
                style={{
                  padding: 0,
                  // backgroundColor: themeData?.themeData?.themeColor ?? "#3d4998",
                  height: "40px",
                  backgroundColor: "#f7f8fa",

                  borderBottom: "1px solid #E4E8F0",
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ marginTop: "-8px" }}>
                    <Space>
                      {!collapsed == true ? (
                        <MenuOutlined
                          style={{
                            fontSize: "20px",
                            color: "#8B99A7",
                            paddingLeft: "20px",
                          }}
                          onClick={() => {
                            setCollapsed(!collapsed);
                            setOpenDrawer(true);
                          }}
                        />
                      ) : (
                        <MenuFoldOutlined
                          onClick={() => {
                            setOpenDrawer(true);
                            setCollapsed(false);
                          }}
                          style={{
                            fontSize: "20px",
                            color: "#395cd2",
                            paddingLeft: "10px",
                          }}
                        />
                      )}
                      {/* <Typography.Title level={5} style={{ color: "white" }}>
                      {" "}
                      Capsitech Accounts
                    </Typography.Title> */}
                    </Space>
                  </div>
                  <div>
                    <div
                      style={{
                        marginRight: "2px",
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "6px",
                      }}
                    >
                      <>
                        <div>
                          <Input
                            style={{
                              display: "flex",
                              marginTop: "5px",
                              cursor: "pointer",
                              width: "165px",
                              height: "28px",
                              // backgroundColor: "#f5f8ff",
                              border: "none",
                            }}
                            suffix={
                              <>
                                <img
                                  src={Search}
                                  alt="search"
                                  style={{ opacity: "50%" }}
                                />
                              </>
                            }
                          ></Input>
                        </div>
                        <BellOutlined
                          style={{ marginBottom: "22px", color: "#8B99A7" }}
                        />
                      </>
                      {/* ) : (
                      <div>
                        <div>
                          <img
                            src={searchlog}
                            alt="searchlog"
                            style={{
                              marginBottom: "5px",
                              height: "20px",
                              cursor: "pointer",
                            }}
                            onClick={() => setSearchClicked(true)}
                          ></img>
                        </div>
                      </div>
                    )} */}
                      <div>
                        <Popover
                          content={
                            <ContentUser
                              isloggedIn={isloggedIn}
                              setIsLoggedIn={setIsLoggedIn}
                            />
                          }
                          placement="bottomLeft"
                          trigger="click"
                          // open={open}
                          // onOpenChange={(newOpen) => setOpen(newOpen)}
                        >
                          {/* <Avatar
                          className="avatar-user"
                          icon={<UserOutlined />}
                          style={{
                            cursor: "pointer",
                            marginBottom: "26px",
                            marginRight: "10px",
                          }}
                        /> */}
                          <img
                            src={avtaruser}
                            alt="avtaruser"
                            style={{
                              cursor: "pointer",
                              marginBottom: "6px",
                              marginRight: "10px",
                            }}
                          />
                        </Popover>
                      </div>
                    </div>
                  </div>
                </div>
              </Header>

              {/* <Header
              style={{
                // color: "#172B4D",
                backgroundColor: themeData?.themeData?.darkMode ? "" : "white",
                width: "100%",
                padding: "8px 0px 4px 16px",
                borderBottom: "2px solid whitesmoke",
                fontWeight: 600,
                fontSize: "18px",
                color: "rgb(11, 48, 142)",
                // position: "fixed",
                // zIndex: 999,
              }} 
              // separator=">"
            >
              Bookkeeping
            </Header> */}

              {/* ------- Account Content ------- */}
              <Content
                // className="chartdivstyle"
                style={{
                  height: `calc(100vh - 40px)`,
                  overflowY: "auto",
                  backgroundColor: themeData?.themeData?.darkMode
                    ? ""
                    : "#f7f8fa",
                }}

                // style={{
                //   background: colorBgContainer,
                // }}
              >
                {props.element}
              </Content>
            </Layout>
          </Layout>
        </HotkeysProvider>
      </ConfigProvider>
    </>
  );
};

export default MainClientTable;
