// import Modal from "antd/es/modal/Modal";
// import React from "react";

// const MainSettingModal = ({ onDismiss, type }: any) => {
//     console.log("type", type);

//     return (
//         <div>
//             <Modal
//                 title={
//                     type === "ledger"
//                         ? "Ledger"
//                         : type === "group"
//                             ? "Group"
//                             : type === "item"
//                                 ? "Items"
//                                 : ""
//                 }
//                 open={true}
//                 onCancel={() => onDismiss(false)}
//             >
//                 <h1>
//                     {type === "ledger"
//                         ? "This is a ledger"
//                         : type === "group"
//                             ? "this is Group"
//                             : type === "item"
//                                 ? "This is a Item"
//                                 : ""}
//                 </h1>
//             </Modal>
//         </div>
//     );
// };

// export default MainSettingModal;'

import React from 'react'

const MainSettingModal = ({ onDismiss, type }: any) => {
  return <div></div>;
};

export default MainSettingModal


