import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Input,
  InputNumber,
  Popover,
  Row,
  Select,
  Skeleton,
  Space,
  Table,
} from "antd";
import { ColumnsType } from "antd/es/table";
import React, { useEffect } from "react";
import { useClient } from "../../Context/ClientContext";
import { dayjs } from "../../../Utilities/dayjs";
import {
  DownloadOutlined,
  ReloadOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import ReportsService, {
  AgeingFilterType,
  AgeingReportTypes,
  IAgeingListParams,
  IAgeingResponse,
} from "../../Services/ReportsServiceNew";
import { Utils } from "../../../Utilities/Utils";
import { IIdName } from "../../../Types/CommonTypes";
import { useTheme } from "../../Context/ThemeContext";
import { useLocation } from "react-router-dom";
import { getSettingsAmount } from "./TrialBalance";
import { ReportSettings } from "../../Common/CAPComponents";
import ReportsServiceNew from "../../Services/ReportsServiceNew";
import InvoiceExportService from "../../Services/InvoiceExportDetails";

const Ageing = ({ SelType }: any) => {
  console.log("Props: ", SelType);

  const location = useLocation();
  let splitpath = location.pathname.split("/");
  let agedOf = splitpath[splitpath.length - 1];
  console.log("agedOf", agedOf);

  const { themeData } = useTheme();
  const { currentClient, companySettings } = useClient();
  const [isCurrentDate, setCurrentDate] = React.useState(false);
  const [items, setItems] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [shouldRefresh, setShouldRefresh] = React.useState(false);
  const [exportAgeingData, setExportAgeingData] = React.useState<any[]>([]);
  const [exportType, setExportType] = React.useState<number>(-1);
  const [settings, setSettings] = React.useState<
    ITrialBalanceSettings | undefined
  >();
  const [totalPercentages, setTotalPercentages] = React.useState({
    totalDueAmt: "0.00%",
    rangeTotals: [{ id: undefined, name: "0.00%" }],
  });
  const [listParams, setListParams] = React.useState<IAgeingListParams>({
    toDate: dayjs().format("DD/MM/YYYY"),
    type:
      SelType === 1 ? AgeingReportTypes.Creditors : AgeingReportTypes.Debtors,
    useDueDate: true,
    filterType: AgeingFilterType.Days,
    numberOfSlabs: 7,
    periods: 15,
    sortCol: undefined,
    sortDir: undefined,
  });
  const [totalValues, setTotalValues] = React.useState<{
    totalPrepayment?: string;
    totalCreditLimit?: string;
    totalAvailableCredit?: string;
    totalDueAmt?: string;
    rangeTotals: { id?: number; name?: number }[];
  }>({
    totalPrepayment: undefined,
    totalCreditLimit: undefined,
    totalAvailableCredit: undefined,
    totalDueAmt: undefined,
    rangeTotals: [{ id: undefined, name: undefined }],
  });

  const setListParamsAndRefresh = (params: IAgeingListParams) => {
    setListParams((newParams: IAgeingListParams) => {
      newParams = { ...params };
      return newParams;
    });
    setShouldRefresh(!shouldRefresh);
  };

  const handleInputChange = (e: any) => {
    let value = Utils.getNumber(e.target.value);
    if (value < 1) {
      value = 1;
    }
    setListParamsAndRefresh({
      ...listParams,
      numberOfSlabs: value,
    });
  };

  console.log("param", listParams);

  useEffect(() => {
    setSettings(companySettings?.setting?.tbSettings);
  }, [companySettings, shouldRefresh]);

  useEffect(() => {
    setListParamsAndRefresh({
      toDate: dayjs().format("DD/MM/YYYY"),
      type:
        SelType === 1 ? AgeingReportTypes.Creditors : AgeingReportTypes.Debtors,
      useDueDate: true,
      filterType: AgeingFilterType?.Days,
      numberOfSlabs: 7,
      periods: 15,
      sortCol: undefined,
      sortDir: undefined,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (currentClient?.id && (exportType === -1 || exportType)) {
      getAgeingReportList(currentClient?.id!, listParams);
      console.log("Get Data: ", totalValues);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listParams, shouldRefresh, exportType]);

  const getAgeingReportList = async (
    clientId: string,
    listParams: IAgeingListParams
  ) => {
    if (exportType < 1) {
      setLoading(true);
    }
    await ReportsServiceNew.getAgeingReport(clientId, listParams)
      .then((res) => {
        if (res) {
          let data = res?.result?.items;
          setTotalValues({
            totalPrepayment: getSettingsAmount(
              false,
              res?.result?.totalPrepayments,
              settings,
              true,
              false,
              0,
              0
            ),
            totalCreditLimit: getSettingsAmount(
              false,
              res?.result?.totalCreditLimit,
              settings,
              true,
              false,
              0,
              0
            ),
            totalAvailableCredit: getSettingsAmount(
              false,
              res?.result?.totalAvailableCredit,
              settings,
              true,
              false,
              0,
              0
            ),
            totalDueAmt: getSettingsAmount(
              false,
              res?.result?.totalDueAmt,
              settings,
              true,
              false,
              0,
              0
            ),
            rangeTotals: res?.result?.rangeTotals,
          });
          setTotalPercentages({
            totalDueAmt:
              res?.result?.totalDueAmt && res?.result?.totalDueAmt > 0
                ? "100.00%"
                : "0.00%",
            rangeTotals: res?.result?.rangeTotals?.map((itm: any) => ({
              ...itm,
              name: isNaN((itm?.name * 100) / res?.result?.totalDueAmt)
                ? "0.00%"
                : `${((itm?.name * 100) / res?.result?.totalDueAmt).toFixed(
                    2
                  )}%`,
            })),
          });
          if (exportType >= 1) {
            setExportAgeingData(data);
            if (exportType === 1) {
              exportCsv(data);
            } else if (exportType === 2) {
              exportPDF(data);
            } else if (exportType === 3) {
              exportExcel(data);
            }
          } else {
            setItems(data);
          }
        }
        setLoading(false);
      })
      .catch((ex) => {
        console.log(ex);
        setLoading(false);
      });
  };

  const columnName = (
    slab: number,
    nextSlab: number,
    period: number,
    type: AgeingFilterType
  ) => {
    let colName = "";
    switch (type) {
      case AgeingFilterType.Days:
        colName =
          slab === 1
            ? `${slab}-${listParams?.periods} Days`
            : slab === listParams?.numberOfSlabs + 1
            ? `Older than ${nextSlab - 1} Days`
            : `${nextSlab}-${nextSlab + listParams?.periods - 1} Days`;
        break;
      case AgeingFilterType.Month:
        colName =
          slab === 1
            ? `${slab} Month`
            : slab === listParams?.numberOfSlabs + 1
            ? `Older than ${nextSlab - period} Months`
            : `${nextSlab} Months`;
        break;
      case AgeingFilterType.Weeks:
        colName =
          slab === 1
            ? `${slab} Week`
            : slab === listParams?.numberOfSlabs + 1
            ? `Older than ${nextSlab - period} Weeks`
            : `${nextSlab} Weeks`;
        break;
      default:
    }
    return colName;
  };

  const columns: ColumnsType<IAgeingResponse> = [
    {
      title: agedOf === "debtors" ? "Customer" : "Supplier",
      width: 190,
      dataIndex: "customer",
      key: "name",
      fixed: "left",
      render: (_, row: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {row?.party?.name}
        </Skeleton>
      ),
    },
    {
      title: "Prepayments",
      width: 106,
      dataIndex: "prepayments",
      key: "prepayments",
      fixed: "left",
      align: "right",
      render: (x) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
            style: { display: "flex", justifyContent: "end" },
          }}
          title={false}
        >
          {getSettingsAmount(false, x, settings, true, false, 0, 0)}
        </Skeleton>
      ),
    },
    {
      title: "Total Due",
      width: 100,
      key: "operation",
      dataIndex: "totalDue",
      align: "right",
      fixed: "right",
      render: (x) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
            style: { display: "flex", justifyContent: "end" },
          }}
          title={false}
        >
          {getSettingsAmount(false, x, settings, true, false, 0, 0)}
        </Skeleton>
      ),
    },
    // {
    //   title: "Credit Limit",
    //   dataIndex: "address",
    //   fixed: "right",
    //   key: "9",
    //   render: (x) => <span>{getSettingsAmount(false, x, settings, true, false, 0, 0)}</span>,
    // },
    // {
    //   title: "Available Credit",
    //   width: 125,
    //   dataIndex: "availableCredit",
    //   align: "right",
    //   fixed: "right",
    //   key: "10",
    //   render: (x) => <span>{getSettingsAmount(false, x, settings, true, false, 0, 0)}</span>,
    // },
  ];

  for (
    let slab = 1, start = 2, nextSlab = 1;
    slab <= listParams?.numberOfSlabs + 1;
    slab++, start++, nextSlab += listParams?.periods
  ) {
    columns.splice(start, 0, {
      key: `${slab - 1 + listParams?.periods}`,
      width: slab === listParams.numberOfSlabs + 1 ? 153 : 105,
      align: "right",
      dataIndex: columnName(
        slab,
        nextSlab,
        listParams?.periods,
        listParams?.filterType
      ),
      title: columnName(
        slab,
        nextSlab,
        listParams?.periods,
        listParams?.filterType
      ),
      render: (_, row: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
            style: { display: "flex", justifyContent: "end" },
          }}
          title={false}
        >
          {getSettingsAmount(
            false,
            row?.ranges[slab - 1]?.amount,
            settings,
            true,
            false,
            0,
            0
          )}
        </Skeleton>
      ),
    });
  }

  const handleFilterChange = (value: any) => {
    if (value) {
      setListParamsAndRefresh({
        ...listParams,
        filterType: value,
      });
    }
  };
  const oneMoreFooter = (columns: any[]) => {
    return columns.map((col: string) => {
      if (col === (agedOf === "debtors" ? "Customer" : "Supplier")) {
        return "Percentage of Total";
      } else if (col === "Total Due") {
        return (
          totalPercentages?.rangeTotals
            ?.map((itm: any) => itm?.name)
            .join(", ") || ""
        );
      }
      return undefined;
    });
  };
  const generateFooterRow = (colum: any[]) => {
    return colum?.map((col) => {
      if (col === "Customer") {
        return "Total";
      } else if (col === "Prepayments") {
        return `${Utils.getFormattedNumber(totalValues?.totalPrepayment)}`;
      } else {
        return ""; // Empty string for other columns
      }
    });
  };
  let path = "Ageing Debtors";
  // const columnsA = [
  //   agedOf === "debtors" ? "Customer" : "Supplier",
  //   "Prepayments",
  //   "Total Due",
  // ];

  const columnsA = columns.map((x) => {
    return { content: x?.title, styles: x?.align };
  });

  const fieldMapping = {
    Customer: (item: any) => item?.party?.name,
    Supplier: (item: any) => item?.party?.name,
    Prepayments: (item: any) => item?.prepayments,
    ...Object.fromEntries(
      columns.map((column: any, columnIndex: number) => [
        column?.title,
        (item: any) =>
          getSettingsAmount(
            false,
            item?.ranges?.[columnIndex]?.amount,
            settings,
            true,
            false,
            0,
            0
          ),
      ])
    ),
    "Total Due": (item: any) => item?.totalDue,
  };

  const exportCsv = (exportData: any[]) => {
    InvoiceExportService.generateCSV(
      exportData,
      columnsA,
      fieldMapping,
      generateFooterRow,
      oneMoreFooter,
      "",
      path,
      currentClient?.businessName
    );
    setExportType(0);
  };

  const exportPDF = (exportData: any[]) => {
    InvoiceExportService.generatePDF(
      exportData,
      currentClient?.businessName,
      columnsA,
      fieldMapping,
      generateFooterRow,
      "",
      path,
      oneMoreFooter
    );
    setExportType(0);
  };

  const exportExcel = async (exportData: any[]) => {
    await InvoiceExportService.generateExcel(
      exportData,
      currentClient?.businessName,
      columnsA,
      fieldMapping,
      generateFooterRow,
      "",
      path,
      oneMoreFooter
    );
    setExportType(0);
  };

  const content = (
    <Space direction="vertical">
      <div>
        <Button
          style={{ width: "80px" }}
          size="small"
          title="CSV"
          onClick={() => {
            if (exportAgeingData.length > 0) {
              exportCsv(exportAgeingData);
            } else {
              setExportType(1);
              // setApiCallExports(true);
            }
          }}
          icon={<DownloadOutlined />}
        >
          CSV
        </Button>
      </div>

      <div>
        <Button
          style={{ width: "80px" }}
          size="small"
          title="PDF"
          onClick={() => {
            if (exportAgeingData.length > 0) {
              exportPDF(exportAgeingData);
            } else {
              setExportType(2);
              // setApiCallExports(true);
            }
          }}
          icon={<DownloadOutlined />}
        >
          PDF
        </Button>
      </div>
      <div>
        <Button
          style={{ width: "80px" }}
          size="small"
          title="XLSL"
          onClick={() => {
            if (exportAgeingData.length > 0) {
              exportExcel(exportAgeingData);
            } else {
              setExportType(3);
              // setApiCallExports(true);
            }
          }}
          icon={<DownloadOutlined />}
        >
          XLSL
        </Button>
      </div>
    </Space>
  );

  return (
    <div>
      {/* <Spin spinning={loading} indicator={<LoadingOutlined />}> */}
      <Card
        // className="ca-card-body"
        style={{
          boxShadow: "0px 0px 10px 0px #96A1C84D",
          height: "90vh",
          padding: "0px",
        }}
      >
        <Row>
          <Col lg={12}>
            <div className="themeColor fs-20 fw-600">
              {currentClient?.businessName}
            </div>
            <p style={{ color: "rgb(120, 120, 120)" }}>{`${
              agedOf === "creditors" ? "Creditors" : "Debtors"
            } Ageing Report as of ${dayjs().format("DD MMM YYYY")}`}</p>
          </Col>
          <Col lg={12} style={{ paddingTop: "5px" }}>
            <Row justify={"end"} gutter={[8, 0]}>
              <Col>
                <DatePicker
                  format="DD/MM/YYYY"
                  defaultValue={dayjs()}
                  onChange={(e) => {
                    setListParams({
                      ...listParams,
                      toDate: dayjs(e).format("DD/MM/YYYY"),
                    });
                    setExportAgeingData([]);
                    setExportType(-1);
                  }}
                />
              </Col>
              <Col>
                {/* <Col style={{ marginRight: "10px" }}>
                  {items?.length === 0 ? (
                    <>
                      <Button
                        type="default"
                        icon={<DownloadOutlined />}
                        disabled
                      >
                        Export
                      </Button>
                    </>
                  ) : (
                    <>
                      <Popover
                        content={content}
                        placement="rightTop"
                        trigger="hover"
                      >
                        <Button
                          type="default"
                          icon={<DownloadOutlined />}
                          // onClick={() => exportCsv()}
                        >
                          Export
                        </Button>
                      </Popover>
                    </>
                  )}
                </Col> */}
              </Col>
              <Col>
                <Button
                  onClick={() => setIsModalOpen(true)}
                  icon={<SettingOutlined />}
                ></Button>
              </Col>
              <Col>
                <Button
                  icon={<ReloadOutlined />}
                  onClick={() => setShouldRefresh(!shouldRefresh)}
                ></Button>
              </Col>
            </Row>
          </Col>
          <Divider style={{ margin: "10px 0px " }} />
        </Row>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Space size={12}>
            <Row gutter={10}>
              <Col>
                <span style={{ marginRight: "5px" }}>Ageing</span>
                <Input
                  type="number"
                  // disabled={loading ? true : false}
                  min={1}
                  style={{ width: "70px" }}
                  value={listParams?.numberOfSlabs?.toString()}
                  onChange={handleInputChange}
                />
              </Col>
              <Col>
                <span style={{ marginRight: "5px" }}>Period of</span>
                <Input
                  // disabled={loading ? true : false}
                  style={{ width: "40px" }}
                  value={listParams?.periods?.toString()}
                  // onChange={(e: any) =>
                  //   setListParams({
                  //     ...listParams,
                  //     periods: Utils.getNumber(e?.target?.value),
                  //   })
                  // }
                  onChange={(e: any) => {
                    setListParamsAndRefresh({
                      ...listParams,
                      periods: Utils.getNumber(e?.target?.value),
                    });
                    setExportAgeingData([]);
                    setExportType(-1);
                  }}
                />
              </Col>
              <Col>
                <Select
                  style={{ width: "81px" }}
                  options={[
                    { value: AgeingFilterType.Month, label: "Month" },
                    { value: AgeingFilterType.Weeks, label: "Week" },
                    { value: AgeingFilterType.Days, label: "Days" },
                  ]}
                  defaultValue={AgeingFilterType.Days}
                  onChange={(val: AgeingFilterType) => {
                    setListParamsAndRefresh({
                      ...listParams,
                      filterType: val,
                    });
                    setExportAgeingData([]);
                    setExportType(-1);
                  }}
                />
              </Col>
              <Col>
                <span style={{ marginRight: "5px" }}>Ageing by</span>
                <Select
                  style={{ width: "119px" }}
                  defaultValue="dueDate"
                  options={[
                    { value: "dueDate", label: "Due Date" },
                    { value: "invoiceDate", label: "Invoice Date" },
                  ]}
                  onChange={(value) => {
                    if (value) {
                      if (value === "dueDate")
                        setListParamsAndRefresh({
                          ...listParams,
                          useDueDate: true,
                        });
                      else if (value === "invoiceDate")
                        setListParamsAndRefresh({
                          ...listParams,
                          useDueDate: false,
                        });
                    }
                    setExportAgeingData([]);
                    setExportType(-1);
                  }}
                />
              </Col>
              <Col>
                <span style={{ marginRight: "5px" }}>Ageing method</span>
                <Select
                  style={{ width: "122px" }}
                  defaultValue="reportDate"
                  // selectedValue={isCurrentDate ? "currentDate" : "reportDate"}
                  value={isCurrentDate ? "currentDate" : "reportDate"}
                  options={[
                    { value: "currentDate", label: "Current Date" },
                    { value: "reportDate", label: "Report Date" },
                  ]}
                  onChange={(value) => {
                    if (value) {
                      if (value === "currentDate") {
                        setCurrentDate(true);
                        setListParamsAndRefresh({
                          ...listParams,
                          toDate: dayjs().format("DD/MM/YYYY"),
                        });
                      } else setCurrentDate(false);
                    }
                    setExportAgeingData([]);
                    setExportType(-1);
                  }}
                />
              </Col>
            </Row>
          </Space>
        </div>
        <Table
          // className="Tabel-style"
          style={{ paddingTop: "20px" }}
          pagination={false}
          columns={columns}
          dataSource={items}
          scroll={
            items?.length > 15
              ? { x: 1500, y: window.innerHeight - 300 }
              : undefined
          }
          className={`Tabel-style table-${
            themeData?.componentSize ?? "middle"
          }`}
          summary={() => {
            return (
              <Table.Summary fixed="bottom">
                {!loading && (
                  <>
                    <Table.Summary.Row
                      style={{
                        fontWeight: 600,
                        color: "#3d4998",
                        backgroundColor: "#EFF5FF",
                        textAlign: "right",
                      }}
                    >
                      <Table.Summary.Cell
                        index={0}
                        className="textStart bg-ThemeLight"
                      >
                        Total
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={1} className="bg-ThemeLight">
                        {totalValues?.totalPrepayment}
                      </Table.Summary.Cell>
                      {totalValues?.rangeTotals?.map((itm: any, i: number) => {
                        return (
                          <Table.Summary.Cell index={i + 2}>
                            {getSettingsAmount(
                              false,
                              itm?.name,
                              settings,
                              true,
                              false,
                              0,
                              0
                            )}
                          </Table.Summary.Cell>
                        );
                      })}
                      <Table.Summary.Cell
                        index={
                          totalValues?.rangeTotals?.length > 0
                            ? totalValues?.rangeTotals?.length + 2
                            : 2
                        }
                        className="bg-ThemeLight"
                      >
                        {totalValues?.totalDueAmt}
                      </Table.Summary.Cell>
                      {/* <Table.Summary.Cell
                        index={
                          totalValues?.rangeTotals?.length > 0
                            ? totalValues?.rangeTotals?.length + 3
                            : 3
                        }
                        className="bg-ThemeLight"
                      >
                        {
                          totalValues?.totalAvailableCredit
                        }
                      </Table.Summary.Cell> */}
                    </Table.Summary.Row>

                    <Table.Summary.Row
                      style={{
                        fontWeight: 600,
                        color: "#3d4998",
                        backgroundColor: "#EFF5FF",
                        textAlign: "right",
                      }}
                    >
                      <Table.Summary.Cell
                        index={0}
                        className="textStart bg-ThemeLight"
                      >
                        Percentage of Total
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={1} className="bg-ThemeLight">
                        -
                      </Table.Summary.Cell>
                      {totalPercentages?.rangeTotals?.map(
                        (itm: any, i: number) => {
                          return (
                            <Table.Summary.Cell index={i + 2}>
                              {itm?.name}
                            </Table.Summary.Cell>
                          );
                        }
                      )}
                      <Table.Summary.Cell
                        index={totalPercentages?.rangeTotals?.length + 2}
                        className="bg-ThemeLight"
                      >
                        {totalPercentages?.totalDueAmt}
                      </Table.Summary.Cell>
                      {/* <Table.Summary.Cell
                        index={totalPercentages?.rangeTotals?.length + 3}
                        className="bg-ThemeLight"
                      >
                        -
                      </Table.Summary.Cell> */}
                    </Table.Summary.Row>
                  </>
                )}
              </Table.Summary>
            );
          }}
        />
        {/* </Spin> */}
        {/* customize setting modal */}
        {isModalOpen && (
          <ReportSettings
            isOpen={isModalOpen}
            setOpen={setIsModalOpen}
            type="Ageing"
            value={settings}
            refresh={shouldRefresh}
            setRefresh={setShouldRefresh}
          />
        )}
      </Card>
    </div>
  );
};

export default Ageing;

export interface ITrialBalanceSettings {
  hideDecimals: boolean;
  roundToMillion: boolean;
  roundToThousand: boolean;
  showZeros: boolean;
  showNegative: boolean;
  showAccounts: IIdName<number>;
  showCustomCols: boolean;
  showAccBasis: boolean;
  showDatePrepared: boolean;
  showTimePrepared: boolean;
  enableNoteReferencing: boolean;
  notes: string;
  detailed: boolean;
  monthly: boolean;
}
