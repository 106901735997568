import { Table } from "antd";
import Card from "antd/es/card/Card";
import { ColumnsType } from "antd/es/table";
import React from "react";

const Users = () => {
  const columns: ColumnsType<any> = [
    {
      title: "S No.",
      dataIndex: "sno",
      key: "sno",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Number",
      dataIndex: "number",
      key: "number",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
  ];

  return (
    <>
      <div
        style={{
          padding: "0px 0px 3px 0px",
        }}
      >
        <Card
          size="small"
          className="TableCard"
          style={{
            justifyContent: "center",
            margin: "20px 20px",
            boxShadow: "0px 0px 10px 0px #96A1C84D",
            height: "90vh",
          }}
        >
          <div style={{ marginTop: "20px" }}>
            <Table columns={columns} size="small" />
          </div>
        </Card>
      </div>
    </>
  );
};

export default Users;
