import {
  ArrowLeftOutlined,
  CalculatorOutlined,
  CloseOutlined,
  EditOutlined,
  EditTwoTone,
  FilterOutlined,
  PlusCircleTwoTone,
  PlusOutlined,
  SettingFilled,
  SettingTwoTone,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Col,
  Divider,
  Drawer,
  Input,
  message,
  notification,
  Popover,
  Row,
  Select,
  Space,
  Tag,
  Timeline,
} from "antd";
import React from "react";
import ClientDataProvider, { useClient } from "../Context/ClientContext";
import { Link, matchPath, useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "../Context/ThemeContext";
import { RiHomeLine } from "react-icons/ri";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { ImHistory } from "react-icons/im";
import LogService, { LogTypes } from "../Services/LogService";
import { useUser } from "../../Context/UserContext";
import { dayjs } from "../../Utilities/dayjs";
import LogPannel from "./LogPannel";
import calculate from "../../images/calculate.svg";
import slash from "../../images/slash.svg";
import { LocalChunkSize } from "papaparse";
import ClientService from "../Services/ClientService";
import MainSettingModal from "./MainSettingModal";
import CommonSettingButton from "./CommonSettingButton/CommonButton";
import { useHotkeys } from "react-hotkeys-hook";
interface IBreadCrumbProps {
  breadCrumbItems: any;
}
export function MainBreadCrumbs({ breadCrumbItems }: IBreadCrumbProps) {
  const { currentClient } = useClient();
  const { isAuthenticated, getUser } = useUser();
  const [searchInput, setSearchInput] = React.useState(false);
  const [clientName, setClientName] = React.useState<any>([]);
  const [searchClient, setSearchClient] = React.useState<string>("");
  const [newClientId, setNewClientId] = React.useState<string>("");
  const [isSelected, setIsSelected] = React.useState<boolean>(false);
  const [width, setWidth] = React.useState<any>(window.innerWidth);
  const [logData, setLogData] = React.useState<any>([]);
  const loginUser: any = { ...getUser() };
  const location = useLocation();
  const match = matchPath(`/accounts/clients/:clientId/*`, location.pathname);
  const navigate = useNavigate();
  const themeData: any = useTheme();
  const [getSearch, setGetSearch] = React.useState<string>();
  const [isPopoverVisible, setIsPopoverVisible] = React?.useState(false);
  const [openSettingModal, setOpenSettingModal] = React.useState<any>({
    type: "",
    params: {},
  });

  const handleVisibleChange = (visible: any) => {
    setIsPopoverVisible(visible);
  };
  const [type, setType] = React.useState<any>();

  React.useEffect(() => {
    // if (searchClient) {
    //   ClientService.searchClientName(searchClient)
    //     .then((res: any) => {
    //       if (res?.result) {
    //         setClientName(res);
    //       } else {
    //         message.error(res?.message);
    //       }
    //     })
    //     .catch((err: any) => console.error(err));
    // }
    // if (searchClient) {
    ClientService.getClientList(0, 15, "", "", searchClient).then(
      (res: any) => {
        if (res != null || res?.items?.length > 0) {
          setClientName(res?.items);
        } else {
          message.error(res?.message);
        }
      }
    );
    // }
  }, [searchClient]);

  let InvPath = "";
  let ExpPath = "";

  React.useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  const [inputValue, setInputValue] = React.useState("");
  const [openLogDrawer, setOpenLogDrawer] = React.useState(false);

  useHotkeys("shift+f1", (event) => {
    event.preventDefault();
    // navigate("/accounts");
    setSearchInput(() => true);
  });

  //----- Api Call in 500 ms -----
  React.useEffect(() => {
    let timeoutId: any;
    if (inputValue) {
      setInputValue(inputValue);
      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setSearchClient(inputValue);
        console.log("Delayed input value:", inputValue);
      }, 500);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [inputValue]);

  // getClientList;

  // const getLogs = (storevar: any) => {
  //   LogService.getUserLog(
  //     storevar,
  //     loginUser?.id,
  //     currentClient?.id!,
  //     0,
  //     15,
  //     getSearch
  //   ).then((res: any) => {
  //     console.log(res, "log response......>>>>>");
  //     if (res !== null || res?.items?.length > 0) {
  //       setLogData(
  //         res?.items?.map((d: any, i: number) => ({
  //           // serialNo: i + 1 + listParams.start,
  //           key: i,
  //           company: d?.company?.name,
  //           date: dayjs(d?.createdBy?.date).format("DD MMM YYYY , hh:mm A"),
  //           description: d?.description,
  //           user: d?.createdBy?.userName,
  //           action: d?.action,
  //         }))
  //       );
  //       // setLoading(false);
  //       // setTotalRecords(res?.totalRecords);
  //     } else {
  //       // messageApi.error(res?.message)
  //     }
  //   });
  // };

  const showLogDrawer = () => {
    setOpenLogDrawer(true);
  };

  const CloseLogDrawer = () => {
    setOpenLogDrawer(false);
  };

  return (
    <>
      <div
        style={{
          top: 40,
          // height: "32px",
          display: "flex",
          // borderBottom: "1px solid #E4E8F0",
          // justifyContent: "space-between",
          borderTop: "1px solid #E4E8F0",
          borderBottom: "1px solid #E4E8F0",
          boxShadow: "0px 4px 15px 0px #96A1C81C",
          backgroundColor: themeData?.themeData?.darkMode ? "" : "white",
        }}
      >
        <div
          style={{
            padding: "4px 10px",
            borderRight: "1px dashed #ced9e2",
          }}
        >
          <Button
            size="small"
            icon={<ArrowLeftOutlined />}
            type="primary"
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
        </div>
        <div
          style={{
            padding: "4.5px 0px 0px 22px",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          {/* <div style={{ paddingBottom: "4px", marginRight: "5px" }}>
            <Tag
              // color="#F7F8FA"
              style={{
                color: "#424652", // Text color
                cursor: "pointer",
                border: "none",
              }}
              icon={<ArrowLeftOutlined />}
              onClick={() => navigate(-1)}
            >
              Back
            </Tag>
          </div> */}
          {/* <Divider
            type="vertical"
            style={{ height: "23px", borderColor: "#ced9e2" }}
          /> */}
          <div
            style={{
              display: "flex",
            }}
          >
            <div style={{ marginTop: "2px", color: "#8B99A7" }}>
              {/* <RiHomeLine size={15} /> */}
              {/* <img src={calculate} />
               */}
              <CalculatorOutlined style={{ fontSize: "18px" }} />
            </div>
            <div style={{ width: "100%" }}>
              <Row justify={"space-between"}>
                <Col>
                  <Breadcrumb
                    style={{ margin: "0px 5px", color: "rgb(61, 73, 152)" }}
                    separator={
                      // <IoIosArrowForward
                      //   style={{ marginTop: "4px", color: "#424652" }}
                      // />
                      <img alt="slash" src={slash} />
                    }
                  >
                    <Breadcrumb.Item key="bookkeeping">
                      <Link
                        to="/accounts"
                        style={{ fontSize: "13px", color: "#424652" }}
                      >
                        Bookkeeping
                      </Link>
                    </Breadcrumb.Item>
                    {searchInput ? (
                      <Space>
                        <Select
                          size="small"
                          showSearch
                          placeholder="Search for business"
                          // showArrow={false}
                          filterOption={false}
                          onSearch={(value) => setInputValue(value)}
                          notFoundContent={
                            clientName?.length === 0 && searchClient === "" ? (
                              ""
                            ) : (
                              <p style={{ textAlign: "center" }}>
                                No result Found
                              </p>
                            )
                          }
                          onSelect={(clientId) => {
                            setIsSelected(true);
                            setNewClientId(clientId);
                            setSearchInput(false);
                            navigate(`/accounts/clients/${clientId}/dashboard`);
                            if (
                              JSON.parse(localStorage.getItem("LockPeriod")!)
                            ) {
                              localStorage.removeItem("LockPeriod");
                            }
                          }}
                          style={{ width: 240 }}
                          options={clientName?.map((value: any) => ({
                            value: value?.id,
                            label: value?.bName,
                          }))}
                        />
                        <CloseOutlined
                          style={{ paddingRight: 15, color: "#395cd2" }}
                          onClick={() => setSearchInput(false)}
                        />
                      </Space>
                    ) : (
                      <div
                        key="currentClient"
                        style={{
                          paddingRight: 10,
                          display: "flex",
                          justifyContent: "space-between",
                          color: "#395cd2",
                        }}
                        onClick={() => setSearchInput(true)}
                      >
                        <div
                          style={{
                            fontWeight: 600,
                            fontSize: "13px",
                            marginTop: "2px",
                          }}
                        >
                          {currentClient?.businessName}
                        </div>
                        <div style={{ marginLeft: "7px", marginTop: "2px" }}>
                          {" "}
                          {/* <IoIosArrowDown /> */}
                          <img alt="slash" src={slash} />
                        </div>
                      </div>
                    )}
                    {breadCrumbItems?.map(
                      (x: any, index: number) =>
                        x.item && (
                          <Breadcrumb.Item key={index}>
                            <Link
                              style={{
                                color:
                                  index === breadCrumbItems?.length - 1
                                    ? "#424652"
                                    : "#424652",
                                fontWeight:
                                  index === breadCrumbItems?.length - 1
                                    ? 600
                                    : undefined,
                                fontSize: "13px",
                              }}
                              to={x?.path}
                            >
                              {x?.item}
                            </Link>
                          </Breadcrumb.Item>
                        )
                    )}
                  </Breadcrumb>
                </Col>
                <LogPannel />
              </Row>
            </div>
          </div>
          <div style={{ paddingRight: "18px" }}>
            <Popover
              content={<CommonSettingButton />}
              trigger="click"
              placement="leftBottom"
              visible={isPopoverVisible}
              onVisibleChange={handleVisibleChange}
            >
              <SettingTwoTone style={{ fontSize: "18px" }} />
            </Popover>
          </div>
        </div>
      </div>

      {isSelected && <ClientDataProvider clientId={newClientId} />}
      {openSettingModal.type === "settingModal" && (
        <MainSettingModal
          type={type}
          onDismiss={(confirmed: boolean) => {
            setOpenSettingModal({ type: "", params: {} });
          }}
        />
      )}
    </>
  );
}
