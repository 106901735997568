import React, { useState } from "react";
import {
  Button,
  Table,
  Row,
  Col,
  Input,
  DatePicker,
  Select,
  Space,
  Card,
  Skeleton,
  Tooltip,
  Tag,
  Popover,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import {
  DeleteTwoTone,
  DownloadOutlined,
  EditOutlined,
  PlusOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router";
import { useClient } from "../../Context/ClientContext";
import { useTheme } from "../../Context/ThemeContext";
import { dayjs } from "../../../Utilities/dayjs";
import ViewPaymentIn from "./ViewPaymentIn";
import { Utils } from "../../../Utilities/Utils";
import ViewSalesInvoice from "./ViewSalesInvoice";
import { ConfirmationModal } from "../../Common/ConfirmationModal";
import { notificationContext } from "../../Common/PageRoute";
import search from "../../Images/search.svg";
import VoucherServices, {
  VoucherFilterStatus,
  Voucher_Type,
} from "../../Services/VoucherServices";
import { useLocation } from "react-router-dom";
import InvoiceExportService from "../../Services/InvoiceExportDetails";

import jsPDF from "jspdf";
import "jspdf-autotable";
import ExcelJS from "exceljs";
import DraftService from "../../Services/DraftService";
import DownloadService, { ViewType } from "../../Services/DownloadService";
import { FiDownload } from "react-icons/fi";
import ViewPreviewModel from "./ViewPreviewModel";
interface DataType {
  key: string;
  id: string;
  // number: { name: string; id: string };
  number: string;
  name: number;
  date: string;
  account: string;
  receivedAmount: number;
  partyNewAmount: number;
  invoices: any[];
  supplierName: string;
  voucherStatus: number;
}

const PaymentIn: React.FC<{ partyId?: string }> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { themeData } = useTheme();
  const { RangePicker } = DatePicker;
  const { currentClient, companySettings } = useClient();
  const { openNotification } = React.useContext(notificationContext);
  const [open, setIsOpen] = React.useState<boolean>(false);
  const [open1, setIsOpen1] = React.useState<string>("");
  const [confirmation, setConfirmation] = React.useState<boolean>(false);
  const [refreshAtDelete, setRefreshAtDelete] = React.useState<boolean>(false);
  const [paymentId, setPaymentId] = React.useState<string>("");
  const [salesInvoiceId, setSalesInvoiceId] = React.useState<string>("");
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
  const [openPopArray, setOpenPopArray] = React.useState<boolean[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [receiptList, setReceiptList] = React.useState<any>([]);
  const [totalRecords, setTotalRecords] = React.useState<number>(0);
  const [totalAmount, setTotalAmount] = React.useState<number>(0);
  const [totalRecAmount, setTotalRecAmount] = React.useState<number>(0);
  const [searchCustomer, setSearchCustomer] = React.useState<string>("");
  const [page, setPage] = React.useState(1);
  const [width, setWidth] = React.useState<any>(window.innerWidth);
  const [pageSize, setPageSize] = React.useState<number>(15);
  const [filterDate, setFilterDate] = React.useState<any>(
    JSON.parse(localStorage.getItem("LockPeriod")!)
      ? {
          from: JSON.parse(localStorage.getItem("LockPeriod")!).from,
          to: JSON.parse(localStorage.getItem("LockPeriod")!).to,
        }
      : {
          from: dayjs(companySettings?.period?.from).format("DD/MM/YYYY"),
          to: dayjs(companySettings?.period?.to).format("DD/MM/YYYY"),
        }
  );
  const [paymentInStatus, setPaymentInStatus] =
    React.useState<VoucherFilterStatus>(VoucherFilterStatus.Active);
  const [openSalesInvoice, setOpenSalesInvoice] =
    React.useState<boolean>(false);
  const [openInvoiceNoPopArray, setOpenInvoiceNoPopArray] = React.useState<
    boolean[]
  >([]);
  const [filter, setfilter] = React.useState<any>({
    sortCol: "",
    sortDir: "",
  });
  const [exportType, setExportType] = React.useState<number>(-1);
  const [exportReceiptData, setExportReceiptData] = React.useState([]);
  const [deletePaymentIn, setDeletePaymentIn] = React.useState<{
    id: string;
    isRev: boolean;
    number: string;
  }>({
    id: "",
    isRev: false,
    number: "",
  });
  const [loadingButtons, setLoadingButtons] = useState<any>({});
  const downloadAndView = async (
    cliendId: string,
    voucherId: string,
    type: ViewType
    // invId: any
  ) => {
    if (cliendId && voucherId) {
      setLoadingButtons((prev: any) => ({ ...prev, [voucherId]: true }));
      await DownloadService.downloadPdf(
        cliendId,
        voucherId,
        type,
        splitPathInVchType
      )
        .then((res) => {
          console.log("res", res);
          if (res) {
            console.log("download sucess");
            setLoadingButtons((prev: any) => ({ ...prev, [voucherId]: false }));
          }
        })
        .catch((ex) => {
          openNotification("error", ex.message);
        });
    } else {
      openNotification("error", "Invalid Details");
      setLoadingButtons(false);
    }
  };
  React.useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const selectedRows = receiptList?.filter((item: any) =>
    selectedRowKeys.includes(item.key)
  );

  console.log("Selected Row Keys:", selectedRowKeys);
  console.log("Selected Row Data:", selectedRows);

  let splitPathInVchType: Voucher_Type;
  if (location.pathname.split("/")[5] === "receipts") {
    splitPathInVchType = Voucher_Type.Receipt;
  } else {
    splitPathInVchType = Voucher_Type.Payment;
  }

  // --------- Table Columns  ---------
  const columns: ColumnsType<DataType> = [
    {
      title: "Voucher No.",
      dataIndex: "number",
      key: "Number",
      sorter: true,
      width: width <= 1024 ? "13%" : "9%",
      render: (_, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.voucherStatus !== 1 ? (
            <>
              <span
                style={{ color: "#1677FF", cursor: "pointer" }}
                onClick={() => {
                  // setIsOpen(true);
                  setIsOpen1("viewPreview");
                  setPaymentId(record?.id);
                }}
              >
                {record?.number}
              </span>
            </>
          ) : (
            <>
              <span style={{ color: "red" }}>{record?.number}</span>
            </>
          )}
        </Skeleton>
      ),
    },
    // {
    //   title: "Invoices",
    //   dataIndex: "invoices",
    //   width: width <= 1440 ? "15%" : "10%",
    //   render: (_: any, record: any, index: number) => (
    //     <>
    //       <Row justify={"start"} align={"middle"} className="p-0">
    //         <Skeleton
    //           active
    //           loading={loading}
    //           paragraph={{ rows: 1 }}
    //           title={false}
    //         >
    //           <span
    //             style={{ color: "#1677FF", cursor: "pointer" }}
    //             onClick={() => {
    //               setOpenSalesInvoice(true);
    //               setSalesInvoiceId(record?.invoices[0]?.invoice?.id);
    //             }}
    //           >
    //             {record?.invoices?.length > 0 &&
    //               record?.invoices[0]?.invoice?.name}
    //           </span>
    //         </Skeleton>
    //         {record?.invoices?.length > 1 && (
    //           <Popover
    //             placement="rightTop"
    //             open={openInvoiceNoPopArray[index]}
    //             // arrow={{ pointAtCenter: true }}
    //             onOpenChange={(newOpen) =>
    //               invoicePopoverOnchange(index, newOpen)
    //             }
    //             // onOpenChange={(x: boolean) => console.log(x)}
    //             trigger="click"
    //             content={() => (
    //               <>
    //                 <h3 className="my-1">Invoices</h3>
    //                 <div
    //                   style={{
    //                     maxHeight: 300,
    //                     maxWidth: 200,
    //                     overflow: "auto",
    //                   }}
    //                 >
    //                   {record?.invoices?.map((v: any, index: any) => (
    //                     <>
    //                       {index > 0 && (
    //                         <div key={index}>
    //                           <Divider className="m-0" key={index} />
    //                           <div
    //                             style={{ cursor: "pointer", color: "#1677FF" }}
    //                             className="p-1"
    //                             key={index + 1}
    //                             onClick={() => {
    //                               setOpenSalesInvoice(true);
    //                               setSalesInvoiceId(v?.invoice?.id);
    //                               invoicePopoverOnchange(index, false);
    //                             }}
    //                           >
    //                             {v?.invoice?.name}
    //                           </div>
    //                         </div>
    //                       )}
    //                     </>
    //                   ))}
    //                 </div>
    //               </>
    //             )}
    //           >
    //             <div
    //               style={{
    //                 // color: "#1677FF",
    //                 cursor: "pointer",
    //                 paddingLeft: 10,
    //               }}
    //             >{`${record?.invoices?.length - 1} more`}</div>
    //           </Popover>
    //         )}
    //       </Row>
    //     </>
    //   ),
    // },
    {
      title: "Date",
      dataIndex: "date",
      sorter: true,
      key: "date",
      width: "10%",
      render: (_, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.date}
        </Skeleton>
      ),
    },
    {
      title: "Bank / Cash Account",
      dataIndex: "account",
      width: `${props.partyId ? "23%" : "16%"}`,
      render: (_, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.account}
        </Skeleton>
      ),
    },
    {
      title: "Amount",
      dataIndex: "partyNewAmount",
      key: "invAmount",
      sorter: true,
      width: "10%",
      align: "right",
      render: (_, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
            style: { display: "flex", justifyContent: "end" },
          }}
          title={false}
        >
          {record?.voucherStatus !== 1 ? (
            <>₹ {Utils.getFormattedNumber(record?.partyNewAmount)}</>
          ) : (
            <>
              <span style={{ textDecoration: "line-through" }}>
                ₹ {Utils.getFormattedNumber(record?.partyNewAmount)}
              </span>
            </>
          )}
        </Skeleton>
      ),
    },
    {
      title: "Adjusted Amt.",
      dataIndex: "receivedAmount",
      width: "10%",
      align: "right",
      render: (_, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
            style: { display: "flex", justifyContent: "end" },
          }}
          title={false}
        >
          {record?.voucherStatus !== 1 ? (
            <> ₹ {Utils.getFormattedNumber(record?.receivedAmount)}</>
          ) : (
            <>
              <span style={{ textDecoration: "line-through" }}>
                ₹ {Utils.getFormattedNumber(record?.receivedAmount)}
              </span>
            </>
          )}
        </Skeleton>
      ),
    },
    {
      title: "Pending Amt.",
      dataIndex: "",
      width: "10%",
      align: "right",
      render: (_, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
            style: { display: "flex", justifyContent: "end" },
          }}
          title={false}
        >
          ₹{" "}
          {record?.voucherStatus !== 1 ? (
            <>
              {Utils.getFormattedNumber(
                record?.partyNewAmount - record?.receivedAmount
              )}
            </>
          ) : (
            <>
              <span style={{ textDecoration: "line-through" }}>
                {Utils.getFormattedNumber(
                  record?.partyNewAmount - record?.receivedAmount
                )}
              </span>
            </>
          )}
        </Skeleton>
      ),
    },
    {
      title: "Action",
      dataIndex: "",
      key: "action",
      // width: "5%",
      align: "center",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          <div>
            {record?.voucherStatus !== 1 ? (
              <>
                <Row gutter={10} justify={"center"}>
                  <Col>
                    <Button
                      size="small"
                      icon={<FiDownload />}
                      style={{ border: "none", color: "#395cd2" }}
                      loading={!!loadingButtons[record.id]}
                      onClick={() =>
                        downloadAndView(
                          currentClient?.id!,
                          record?.id,
                          ViewType.Pdf
                          // record.invNumber?.id
                        )
                      }
                    />
                  </Col>
                  <Col>
                    <Tooltip title="Edit" placement="top" color="#444653">
                      {paymentInStatus === VoucherFilterStatus?.Draft ? (
                        <>
                          <EditOutlined
                            // className="ca-edit-btn"
                            style={{
                              color: "#444653",
                            }}
                            onClick={() => {
                              // console.log(record.id);
                              splitPathInVchType === Voucher_Type.Receipt
                                ? navigate(
                                    `/accounts/clients/${currentClient?.id}/sales/editReceiptDraft/${record?.id}`
                                  )
                                : navigate(
                                    `/accounts/clients/${currentClient?.id}/purchase/editPaymentOutDraft/${record?.id}`
                                  );
                              // setOpenModel({
                              //   open: true,
                              //   id: record?.id,
                              // });
                            }}
                          />
                        </>
                      ) : (
                        <>
                          <EditOutlined
                            // className="ca-edit-btn"
                            style={{
                              color: "#444653",
                            }}
                            onClick={() => {
                              // console.log(record.id);
                              splitPathInVchType === Voucher_Type.Receipt
                                ? navigate(
                                    `/accounts/clients/${currentClient?.id}/sales/editReceipt/${record?.id}`
                                  )
                                : navigate(
                                    `/accounts/clients/${currentClient?.id}/purchase/editPaymentOut/${record?.id}`
                                  );
                              // setOpenModel({
                              //   open: true,
                              //   id: record?.id,
                              // });
                            }}
                          />
                        </>
                      )}
                    </Tooltip>
                  </Col>
                  <Col>
                    <Tooltip title="Delete" color="red">
                      {record?.voucherStatus !== 1 && (
                        <>
                          <DeleteTwoTone
                            className="ca-delete-btn"
                            twoToneColor="#ff879d"
                            onClick={() => {
                              setConfirmation(true);
                              setPaymentId(record?.id);
                              setDeletePaymentIn({
                                ...deletePaymentIn,
                                number: record?.number,
                              });
                              // handleOpenChange(index, false);
                            }}
                          />
                        </>
                      )}
                    </Tooltip>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Tooltip title="Revive Voucher">
                  <SyncOutlined
                    style={{ color: "green" }}
                    onClick={() => {
                      // console.log(record.id);
                      splitPathInVchType === Voucher_Type.Receipt
                        ? navigate(
                            `/accounts/clients/${currentClient?.id}/sales/editReceipt/${record?.id}`
                          )
                        : navigate(
                            `/accounts/clients/${currentClient?.id}/purchase/editPaymentOut/${record?.id}`
                          );
                      // setOpenModel({
                      //   open: true,
                      //   id: record?.id,
                      // });
                    }}
                  />
                </Tooltip>
              </>
            )}
          </div>
        </Skeleton>
      ),
    },
  ];

  if (!props?.partyId) {
    columns.splice(1, 0, {
      title: "Account Name",
      dataIndex: "name",
      width: width <= 1024 ? "15%" : "25%",
      render: (_, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.name}
        </Skeleton>
      ),
    });
  }

  const handleOpenChange = (columnIndex: number, newOpen: boolean) => {
    const updatedOpenPopArray = [...openPopArray];
    updatedOpenPopArray[columnIndex] = newOpen;
    setOpenPopArray(updatedOpenPopArray);
  };

  const invoicePopoverOnchange = (columnIndex: number, newOpen: boolean) => {
    const updatedOpenPopArray = [...openInvoiceNoPopArray];
    updatedOpenPopArray[columnIndex] = newOpen;
    setOpenInvoiceNoPopArray(updatedOpenPopArray);
  };

  // --------- Get TableData API ---------

  React.useEffect(() => {
    // setLoading(true);
    if (currentClient?.id && (exportType === -1 || exportType)) {
      // setLoading(true);
      getList(
        currentClient?.id,
        splitPathInVchType,
        exportType >= 1 ? 0 : (page - 1) * pageSize,
        exportType >= 1 ? 0 : pageSize,
        filterDate.from,
        filterDate.to,
        filter.sortCol,
        filter.sortDir,
        props?.partyId,
        undefined,
        searchCustomer,
        paymentInStatus
      );
    } else if (currentClient?.id === null) {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    page,
    pageSize,
    filter,
    // filter,
    // filterDate,
    filterDate,
    paymentInStatus,
    currentClient?.id,
    searchCustomer,
    refreshAtDelete,
    exportType,
    // props?.partyId,
  ]);
  console.log("Export Type=>>", splitPathInVchType);

  const getList = async (
    clientId: string,
    Voucher_Type: Voucher_Type,
    start: number,
    length: number,
    fromDate?: string,
    toDate?: string,
    sortCol?: string,
    sortDir?: string,
    partyId?: string,
    npReconciled?: any,
    search?: string,
    status?: VoucherFilterStatus
  ) => {
    try {
      if (exportType < 1) {
        setLoading(true);
      }
      const res =
        paymentInStatus === VoucherFilterStatus.Draft
          ? await DraftService.getVoucherDraftList(
              clientId,
              Voucher_Type,
              start,
              length,
              fromDate,
              toDate,
              sortCol,
              sortDir,
              "",
              search
            )
          : await VoucherServices.getVoucharList(
              clientId,
              Voucher_Type,
              start,
              length,
              fromDate,
              toDate,
              sortCol,
              sortDir,
              partyId,
              npReconciled,
              search,
              status
            );
      console.log("result", res);
      if (res.result?.voucherItems?.length > 0) {
        let receiptData = res?.result?.voucherItems?.map(
          (v: any, index: number) => {
            return {
              key: index,
              id: v?.vId,
              number: v?.number,
              name: v?.pLedger[0]?.ledgerName,
              date: dayjs(v?.date).format("DD/MM/YYYY"),
              account: v?.eLedger[0]?.ledgerName,
              receivedAmount: v?.receivedAmount,
              partyNewAmount: v?.invAmount,
              invoices: v?.item,
              voucherStatus: v?.voucherStatus,
            };
          }
        );
        if (exportType >= 1) {
          setExportReceiptData(receiptData);
          if (exportType === 1) {
            exportCsv(receiptData);
          } else if (exportType === 2) {
            exportPDF(receiptData);
          } else if (exportType === 3) {
            exportExcel(receiptData);
          }
        } else {
          setTotalRecords(res.result?.totalRecords);
          setTotalAmount(res?.result?.totalAmount);
          setTotalRecAmount(res?.result?.totalReceivedAmount);
          setReceiptList(receiptData);
        }
      } else {
        setReceiptList([]);
        if (res?.message) {
          openNotification("error", res?.message);
        }
      }
      setLoading(false);
    } catch (ex: any) {
      console.error("error..", ex.message);
      openNotification("error", ex.message);
      setLoading(false);
      setReceiptList([]);
    }
  };

  console.log("receiptList", receiptList);

  // delete payment api
  React.useEffect(() => {
    console.log("deleteCreditNote.id", deletePaymentIn?.id);
    if (deletePaymentIn?.id) {
      const DeleteCreditNote = async () => {
        setLoading(true);
        const res =
          paymentInStatus === VoucherFilterStatus.Draft
            ? await DraftService.deleteDraft(
                currentClient?.id!,
                deletePaymentIn.id
              )
            : await VoucherServices.deleteVoucher(
                currentClient?.id!,
                deletePaymentIn.id
              );
        setLoading(false);
        if (res?.result) {
          setRefreshAtDelete(!refreshAtDelete);
          {
            paymentInStatus === VoucherFilterStatus.Draft
              ? openNotification(
                  "success",
                  `Draft entry deleted successfully ${deletePaymentIn.number}`
                )
              : openNotification(
                  "success",
                  `Entry deleted successfully ${deletePaymentIn.number}`
                );
          }
        }
      };
      DeleteCreditNote();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletePaymentIn?.id]);

  // ----- Table Ascending & Desending Order -----
  const tableSort = (pagination?: any, sort?: any, filter?: any) => {
    setfilter({
      sortCol: filter.column !== undefined ? filter.columnKey : "Date",
      sortDir:
        filter.column !== undefined
          ? filter.order === "descend"
            ? "desc"
            : "asc"
          : "desc",
    });
  };

  const getQuarterPreset: any = (startMonth: number) => {
    const fromDate = dayjs(companySettings?.period?.from);
    return [
      fromDate.month(fromDate.month() + startMonth).startOf("month"),
      fromDate.month(fromDate.month() + startMonth + 2).endOf("month"),
    ];
  };
  const thisYear = () => {
    const fromDate = dayjs(companySettings?.period?.from);
    return [
      fromDate.month(fromDate.month()).startOf("month"),
      fromDate.month(fromDate.month() + 11).endOf("month"),
    ];
  };
  const lastYear = () => {
    const fromDate = dayjs(companySettings?.period?.from);
    return [
      fromDate.month(fromDate.month() - 12).startOf("month"),
      fromDate.month(fromDate.month() - 1).endOf("month"),
    ];
  };

  const getLast15Days = () => {
    const fromDate = dayjs(companySettings?.period?.from);
    return [fromDate.subtract(15, "day").startOf("day"), fromDate.endOf("day")];
  };
  const getLast7Days = () => {
    const fromDate = dayjs(companySettings?.period?.from);
    return [fromDate.subtract(7, "day").startOf("day"), fromDate.endOf("day")];
  };

  const getThisMonth = () => {
    const fromDate = dayjs(companySettings?.period?.from);
    return [fromDate.startOf("month"), fromDate.endOf("month")];
  };

  const getLastMonth = () => {
    const fromDate = dayjs(companySettings?.period?.from);
    return [
      fromDate.subtract(1, "month").startOf("month"),
      fromDate.subtract(1, "month").endOf("month"),
    ];
  };

  // document.addEventListener("keydown", (e) => {
  //   e.preventDefault();
  //   if (e.altKey && e.key.toLowerCase() === "o") {
  //     navigate(
  //       `/accounts/clients/${currentClient?.id}/${
  //         splitPathInVchType === Voucher_Type.Receipt
  //           ? "sales/addPaymentIn"
  //           : "purchase/addPaymentOut"
  //       }`
  //     );
  //     // console.log("event", e);
  //   }
  // });
  //----- Short-Cut for Payment In  -----

  // const exporCsv = () => {
  //   VoucherServices.exportCsv(
  //     currentClient?.id,
  //     splitPathInVchType === Voucher_Type.Receipt
  //       ? Voucher_Type.Receipt
  //       : Voucher_Type.Payment,
  //     (page - 1) * pageSize,
  //     pageSize,
  //     filterDate.from,
  //     filterDate.to,
  //     filter.sortCol,
  //     filter.sortDir,
  //     undefined,
  //     undefined,
  //     searchCustomer
  //     // invoiceStatus
  //   )
  //     .then((res) => {
  //       if (res) {
  //         console.log("download sucess");
  //       }
  //     })
  //     .catch((ex: any) => {
  //       openNotification("error", ex.message);
  //     });
  // };
  let path =
    splitPathInVchType === Voucher_Type.Receipt
      ? "Receipt List"
      : "Payment List";
  const generateFooterRow = (columns: string[]) => {
    return columns.map((col) => {
      if (col === "Amount") {
        return `${
          selectedRowKeys.length > 0
            ? Utils.getFormattedNumber(
                selectedRows.reduce(
                  (acc: any, data: any) => acc + (data?.partyNewAmount || 0),
                  0
                )
              )
            : Utils.getFormattedNumber(totalAmount)
        }`;
      } else if (col === "Adjust Amount") {
        return `${
          selectedRowKeys.length > 0
            ? Utils.getFormattedNumber(
                selectedRows.reduce(
                  (acc: any, data: any) => acc + (data?.receivedAmount || 0),
                  0
                )
              )
            : Utils.getFormattedNumber(totalRecAmount)
        }`;
      } else if (col === "Pending Amount") {
        return `${
          selectedRowKeys.length > 0
            ? Utils.getFormattedNumber(
                selectedRows.reduce(
                  (acc: any, data: any) =>
                    acc + (data?.receivedAmount - data?.receivedAmount || 0),
                  0
                )
              )
            : Utils.getFormattedNumber(totalAmount - totalRecAmount)
        }`;
      } else if (col === "Voucher No") {
        return "Total";
      } else {
        return ""; // Empty string for other columns
      }
    });
  };

  const columnsA = [
    "Voucher No",
    "Account Name",
    "Date",
    "Bank/Cash Account",
    "Amount",
    "Adjust Amount",
    "Pending Amount",
  ];

  const fieldMapping = {
    "Voucher No": (item: any) => item?.number,
    "Account Name": (item: any) => item?.name,
    Date: (item: any) => item?.date,
    "Bank/Cash Account": (item: any) => item?.account,
    Amount: (item: any) => Utils.getFormattedNumber(item?.partyNewAmount),
    "Adjust Amount": (item: any) =>
      Utils.getFormattedNumber(item?.receivedAmount),
    "Pending Amount": (item: any) =>
      Utils.getFormattedNumber(item?.partyNewAmount - item?.receivedAmount),
  };

  const exportCsv = (invExportData: any[]) => {
    const exportData =
      selectedRowKeys.length > 0 ? selectedRows : invExportData;
    InvoiceExportService.generateCSV(
      exportData,
      columnsA,
      fieldMapping,
      generateFooterRow,
      "",
      filterDate,
      path,
      currentClient?.businessName
    );
    setExportType(0);
  };

  const exportPDF = (invExportData: any[]) => {
    const exportData =
      selectedRowKeys.length > 0 ? selectedRows : invExportData;
    InvoiceExportService.generatePDF(
      exportData,
      currentClient?.businessName,
      columnsA,
      fieldMapping,
      generateFooterRow,
      filterDate,
      path
    );
    setExportType(0);
  };

  const exportExcel = async (invExportData: any[]) => {
    const exportData =
      selectedRowKeys.length > 0 ? selectedRows : invExportData;
    await InvoiceExportService.generateExcel(
      exportData,
      currentClient?.businessName,
      columnsA,
      fieldMapping,
      generateFooterRow,
      filterDate,
      path
    );
    setExportType(0);
  };

  const content = (
    <Space direction="vertical">
      <div>
        <Button
          style={{ width: "80px" }}
          size="small"
          title="CSV"
          onClick={() => {
            if (exportReceiptData.length > 0) {
              exportCsv(exportReceiptData);
            } else {
              setExportType(1);
              // setApiCallExports(true);
            }
          }}
          icon={<DownloadOutlined />}
        >
          CSV
        </Button>
      </div>

      <div>
        <Button
          style={{ width: "80px" }}
          size="small"
          title="PDF"
          onClick={() => {
            if (exportReceiptData.length > 0) {
              exportPDF(exportReceiptData);
            } else {
              setExportType(2);
              // setApiCallExports(true);
            }
          }}
          icon={<DownloadOutlined />}
        >
          PDF
        </Button>
      </div>
      <div>
        <Button
          style={{ width: "80px" }}
          size="small"
          title="XLSL"
          onClick={() => {
            if (exportReceiptData.length > 0) {
              exportExcel(exportReceiptData);
            } else {
              setExportType(3);
              // setApiCallExports(true);
            }
          }}
          icon={<DownloadOutlined />}
        >
          XLSL
        </Button>
      </div>
    </Space>
  );

  return (
    <Card
      size="small"
      className="TableCard"
      style={{
        marginTop: 35,
        // padding: "48px 0px 0px 0px",
        boxShadow: "0px 0px 10px 0px #96A1C84D",
      }}
    >
      <Row
        justify="space-between"
        style={{
          // marginTop: 10,
          padding: "0px 0px 3px 0px",
        }}
      >
        <Col>
          <Space>
            {/* <Tooltip
              title={
                splitPathInVchType === Voucher_Type.Receipt ? (
                  <>
                    Add Receipt
                    <br />
                    <Tag>
                      <kbd>Shift + F7</kbd>
                    </Tag>
                  </>
                ) : (
                  <>
                    Add Payment
                    <br />
                    <Tag>
                      <kbd>Shift + F11</kbd>
                    </Tag>
                  </>
                )
              }
            > */}
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                props?.partyId
                  ? navigate(
                      `/accounts/clients/${currentClient?.id}/sales/paymentIn/${props?.partyId}`
                    )
                  : navigate(
                      `/accounts/clients/${currentClient?.id}/${
                        splitPathInVchType === Voucher_Type.Receipt
                          ? "sales/addReceipt"
                          : "purchase/addPaymentOut"
                      }`
                    );
              }}
            >
              {splitPathInVchType === Voucher_Type.Receipt
                ? "Receipt"
                : "Payment"}
            </Button>
            {/* </Tooltip> */}
            {receiptList?.length === 0 ? (
              <>
                <Button type="default" icon={<DownloadOutlined />} disabled>
                  Export
                </Button>
              </>
            ) : (
              <>
                <Popover content={content} placement="rightTop" trigger="hover">
                  <Button
                    type="default"
                    icon={<DownloadOutlined />}
                    // onClick={() => exportCsv()}
                  >
                    Export
                  </Button>
                </Popover>
              </>
            )}
          </Space>
        </Col>
        <Col
          style={{
            marginTop: width <= 768 ? 15 : "",
          }}
        >
          {/* gutter={width <= 768 ? 62 : 8} */}
          <Row gutter={width <= 768 ? 62 : 8}>
            <Col>
              <Input
                style={{ width: "200px", border: "1px solid #f1f3ff" }}
                placeholder="Search Ledger"
                allowClear
                onChange={(e: any) => {
                  setPage(1);
                  setSearchCustomer(e.target.value);
                  setExportReceiptData([]);
                  setExportType(-1);
                }}
                suffix={searchCustomer ? "" : <img alt="search" src={search} />}
              />
            </Col>
            <Col>
              <RangePicker
                defaultValue={[
                  dayjs(filterDate?.from, "DD/MM/YYYY"),
                  dayjs(filterDate?.to, "DD/MM/YYYY"),
                ]}
                format="DD/MM/YYYY"
                style={{
                  width:
                    width <= 464 ? "230px" : width == 375 ? "200px" : "250px",
                  border: "1px solid #f1f3ff",
                }}
                onChange={(e: any) => {
                  console.log(e);
                  if (e === null) {
                    setFilterDate({});
                  } else {
                    setFilterDate({
                      ...filterDate,
                      from: dayjs(e[0]).format("DD/MM/YYYY"),
                      to: dayjs(e[1]).format("DD/MM/YYYY"),
                    });
                    localStorage.setItem(
                      "LockPeriod",
                      JSON.stringify({
                        ...filterDate,
                        from: dayjs(e[0]).format("DD/MM/YYYY"),
                        to: dayjs(e[1]).format("DD/MM/YYYY"),
                      })
                    );
                  }
                  openNotification("success", `Period locked.`);
                  setExportReceiptData([]);
                  setExportType(-1);
                }}
                presets={[
                  {
                    label: "Today",
                    value: [dayjs().add(0, "d"), dayjs()],
                  },
                  {
                    label: "Last 7 Days",
                    value: getLast7Days(),
                  },
                  {
                    label: "Last 15 Days",
                    value: getLast15Days(),
                  },
                  {
                    label: "This Month",
                    value: getThisMonth(),
                  },
                  {
                    label: "Last Month",
                    value: getLastMonth(),
                  },
                  {
                    label: "Quarter 1",
                    value: getQuarterPreset(0),
                  },
                  {
                    label: "Quarter 2",
                    value: getQuarterPreset(3),
                  },
                  {
                    label: "Quarter 3",
                    value: getQuarterPreset(6),
                  },
                  {
                    label: "Quarter 4",
                    value: getQuarterPreset(9),
                  },
                  {
                    label: "This Year",
                    value: thisYear(),
                  },
                  {
                    label: "Last Year",
                    value: lastYear(),
                  },
                ]}
              />
            </Col>
            <Col>
              <Select
                // clearIcon={true}
                defaultValue={VoucherFilterStatus.Active}
                style={{ width: 120 }}
                allowClear
                options={[
                  { value: VoucherFilterStatus.All, label: "All" },
                  { value: VoucherFilterStatus.Active, label: "Active" },
                  { value: VoucherFilterStatus.Deleted, label: "Deleted" },
                  { value: VoucherFilterStatus.Draft, label: "Draft" },
                ]}
                onSelect={(val: VoucherFilterStatus) => {
                  setPage(1);
                  setPaymentInStatus(val);
                  setExportReceiptData([]);
                  setExportType(-1);
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Table
        className={`Tabel-style table-${themeData?.componentSize ?? "middle"}`}
        loading={false}
        locale={{
          triggerDesc: "",
          triggerAsc: "",
          cancelSort: "",
        }}
        scroll={
          pageSize > 15 ? { x: 1500, y: window.innerHeight - 300 } : undefined
        }
        rowSelection={rowSelection}
        columns={columns}
        dataSource={
          loading
            ? (Array.from({ length: pageSize }, (_, index) => ({
                key: `loading-${index}`,
              })) as any)
            : receiptList
        }
        onChange={tableSort}
        summary={() => {
          return (
            <Table.Summary fixed>
              <Table.Summary.Row
                style={{ backgroundColor: "#f5f8ff", fontWeight: "bold" }}
              >
                <Table.Summary.Cell
                  index={1}
                  className="fw600"
                ></Table.Summary.Cell>
                <Table.Summary.Cell
                  index={2}
                  className="fw600"
                ></Table.Summary.Cell>
                <Table.Summary.Cell index={3} className="fw600">
                  Total
                </Table.Summary.Cell>
                <Table.Summary.Cell
                  index={4}
                  className="fw600"
                ></Table.Summary.Cell>
                <Table.Summary.Cell index={5} className="fw600">
                  {"   "}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={6} className="fw600" align="right">
                  ₹ {Utils.getFormattedNumber(totalAmount)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={7} className="fw600" align="right">
                  ₹ {Utils.getFormattedNumber(totalRecAmount)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={7} className="fw600" align="right">
                  ₹ {Utils.getFormattedNumber(totalAmount - totalRecAmount)}
                </Table.Summary.Cell>
                <Table.Summary.Cell
                  index={9}
                  className="fw600"
                ></Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          );
        }}
        // onRow={(record, rowIndex) => ({
        //   onMouseEnter: () => {
        //     // Set the visibility of the popover for the current row to true
        //     const updatedStates = [...visibleStates];
        //     updatedStates[rowIndex] = true;
        //     setVisibleStates(updatedStates);
        //   },
        //   onMouseLeave: () => {
        //     // Set the visibility of the popover for the current row to false
        //     const updatedStates = [...visibleStates];
        //     updatedStates[rowIndex] = false;
        //     setVisibleStates(updatedStates);
        //   },
        // })}
        pagination={{
          total: totalRecords,
          current: page,
          pageSize: pageSize,
          showSizeChanger: true,
          onChange: (page: number, pageSize: number) => {
            setPage(page);
            setPageSize(pageSize);
          },
          pageSizeOptions: ["10", "15", "25", "50", "100"],
          showTotal: (totalRecords, page) =>
            `${page[0]}-${page[1]} of ${totalRecords} items`,
        }}
      />
      {confirmation && (
        <ConfirmationModal
          open={confirmation}
          onNo={() => setConfirmation(false)}
          onYes={() => {
            setDeletePaymentIn({
              ...deletePaymentIn,
              id: paymentId,
              isRev:
                paymentInStatus === VoucherFilterStatus.Deleted ? true : false,
            });
            setConfirmation(false);
          }}
          text={`Are you sure you want to delete "${deletePaymentIn.number}" ?`}
        />
      )}
      {open && (
        <ViewPaymentIn
          open={open}
          onCancel={() => setIsOpen(false)}
          paymentId={paymentId}
        />
      )}
      {open1 === "viewPreview" && (
        <ViewPreviewModel
          open={open1 === "viewPreview"}
          onCancel={() => setIsOpen1("")}
          voucherId={paymentId}
          invVoucharType={splitPathInVchType}
          partyId={currentClient?.id}
        />
      )}
      {openSalesInvoice && (
        <ViewSalesInvoice
          open={openSalesInvoice}
          onCancel={() => setOpenSalesInvoice(false)}
          invoiceId={salesInvoiceId}
        />
      )}
    </Card>
  );
};
export default PaymentIn;
