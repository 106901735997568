import React, { useContext, useEffect, useState } from "react";
import "./Assets.css";
import {
  DeleteTwoTone,
  EditOutlined,
  PlusOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import {
  Row,
  Button,
  Input,
  Table,
  Tooltip,
  Col,
  Card,
  Select,
  Space,
  Skeleton,
  TableColumnsType,
} from "antd";
import AssetModel, { PmtMethod } from "./AssetModel";
import { ColumnsType } from "antd/es/table";
import FixedAssetsService, {
  Asset_Type,
  AssetStatus,
} from "../../Services/FixedAssetsServiceNew";
import { useClient } from "../../Context/ClientContext";
import { useTheme } from "../../Context/ThemeContext";
import search from "../../Images/search.svg";
import { Utils } from "../../../Utilities/Utils";
import ViewAssetModel from "./ViewAssetModel";
import { ConfirmationModal } from "../../Common/ConfirmationModal";
import { notificationContext } from "../../Common/PageRoute";
import AssetJournalModal from "./AssetJournalModal";
import { dayjs } from "../../../Utilities/dayjs";
import { useKeyboardShortcuts } from "../../Common/KeyboardShortcuts ";
import { useNavigate } from "react-router-dom";

interface IDataType {
  key: string;
  id: string;
  sno: number;
  number: {
    no: string;
    id: string;
  };
  name: string;
  date: string;
  price: string;
  isDepreciable: string;
  status: AssetStatus;
  invoiceDetail: any;
  // journals: any[];
  // jrNumber: any[];
}
// interface ExpandedDataType {
//   key: React.Key;
//   date: string;
//   gst: any;
//   invNumber: string;
//   partiallyPaidAmount: number;
//   price: number;
//   purPartyLedger: string;
//   purPayDate: string;
//   purPayMethod: PmtMethod;
//   purPayRef: string;
//   purPaymentLedger: string;
//   qty: number;
//   taxCredit: boolean;
//   voucherDate: string;
//   childAssetDetail: ChildAssetDetail[];
// }

// interface ChildAssetDetail {
//   accumulatedDepreciation: number;
//   childAssetName: string;
//   childAassetNumber: string;
//   depAmount: number;
//   depreciationLastDate: string;
//   depreciationStartDate: string;
//   description: string;
//   disposalDate: string;
//   disposalPartyLedger: string;
//   disposalPaymentLedger: string;
//   disposalPaymentRef: string;
//   disposalValue: number;
//   identityCode: string;
//   isDisposed: boolean;
//   lifeCycleDuration: number;
//   location: string;
//   model: string;
//   residualValue: number;
//   warrantyExpDate: string;
//   wdValue: number;
//   wdvDate: string;
//   writeOffValue: number;
// }

const FixedAssets = () => {
  const { openNotification } = useContext(notificationContext);
  const { currentClient } = useClient();
  const navigate = useNavigate();
  const { themeData } = useTheme();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isConfirmation, setIsConfirmation] = useState<boolean>(false);
  const [successRollBack, setSeccesRollBack] = React.useState<boolean>(false);
  const [totalRecords, setTotalRecords] = React.useState<number>(0);
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState<number>(15);
  const [fixedAssetList, setFixedAssetList] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [trigger, setTrigger] = React.useState<boolean>(false);
  const [editAsset, setEditAsset] = React.useState<any>(false);
  const [searchAsset, setSearchAsset] = React.useState("");
  const [isViewModelOpen, setIsViewModelOpen] = useState<boolean>(false);
  const [assetId, setAssetId] = React.useState<string>();
  const [confirmation, setConfirmation] = React.useState<boolean>(false);
  const [type, setType] = React?.useState<boolean>(Boolean);
  const [changeStatus, setChangeStatus] = React.useState<number>(
    AssetStatus.Active
  );
  const [deleteOrRevive, setDeleteOrRevive] = React.useState<any>({
    id: "",
    isRev: null,
  });
  const [journalModel, setJournalModel] = React.useState<{
    open: boolean;
    data: any;
  }>({ open: false, data: {} });
  const [refreshAtDelete, setRefreshAtDelete] = React.useState<boolean>(false);

  // const showModal = () => {
  //   setEditAsset({ id: null });
  //   setIsModalOpen(true);
  // };

  // const modelCancel = () => {
  //   setIsModalOpen(false);
  //   setIsConfirmation(false);
  //   // form.resetFields();
  // };

  const getList = async (
    clientId: string,
    start: number,
    length: number,
    sortCol: any,
    sortDir: any,
    search: string,
    type: Asset_Type,
    status: AssetStatus
  ) => {
    await FixedAssetsService.getChildAssetList(
      clientId,
      start,
      length,
      "",
      "",
      sortCol,
      sortDir,
      search,
      type,
      status
    )
      .then((res) => {
        if (res != null || res?.items?.length > 0) {
          setFixedAssetList(
            res?.items?.map((e: any, index: number) => {
              console.log("AssetValue: ", e);

              return {
                key: e?.assetKey,
                sno: index + 1,
                id: e?.id,
                // number: { no: e?.number, id: e?.id },
                number: e?.assetNumber,
                name: e?.assetName,
                date: e?.invoiceDate,
                status: e?.assetStatus,
                price: e?.assetPrice,
                isDepreciable: e?.isDepreciable === true ? "Yes" : "No",
              };
            })
          );
          setTotalRecords(res?.totalRecords);
        } else if (res.items.length === 0) {
          setFixedAssetList([]);
          setTotalRecords(res?.totalRecords);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
    setLoading(false);
  };

  console.log("fixedAssetList: ", fixedAssetList);

  //for getlist
  useEffect(() => {
    setLoading(true);

    getList(
      currentClient?.id!,
      (page - 1) * pageSize,
      pageSize,
      undefined,
      undefined,
      searchAsset,
      Asset_Type?.All,
      changeStatus
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentClient?.id,
    trigger,
    page,
    pageSize,
    searchAsset,
    changeStatus,
    refreshAtDelete,
    successRollBack,
  ]);

  // // ----- Short-Cut for Open Model -----F
  // useKeyboardShortcuts("N", ["shiftKey"], () => {
  //   setIsModalOpen(true);
  // });

  const columns: ColumnsType<IDataType> = [
    {
      title: "S.No",
      dataIndex: "sno",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.sno}
        </Skeleton>
      ),
    },
    {
      title: "Asset Reference",
      dataIndex: "number",
      render: (_, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          <span
            style={{ cursor: "pointer", color: "#1677FF" }}
            onClick={() => {
              setIsViewModelOpen(true);
              setAssetId(record.id);
              setType(false);
            }}
          >
            {record?.number}
          </span>
        </Skeleton>
      ),
    },
    {
      title: "Asset Name",
      dataIndex: "name",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.name}
        </Skeleton>
      ),
    },
    {
      title: "Purchase Date",
      dataIndex: "date",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {dayjs(record?.date).format("DD/MM/YYYY")}
        </Skeleton>
      ),
    },
    {
      title: "Total Value",
      dataIndex: "price",
      align: "right",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
            style: { display: "flex", justifyContent: "right" },
          }}
          title={false}
        >
          {Utils.getFormattedNumber(record?.price)}
        </Skeleton>
      ),
    },
    {
      title: "Depreciable Asset",
      dataIndex: "isDepreciable",
      align: "center",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
            style: { display: "flex", justifyContent: "center" },
          }}
          title={false}
        >
          {record?.isDepreciable}
        </Skeleton>
      ),
    },
    {
      title: "Actions",
      dataIndex: "id",
      align: "center",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
            style: { display: "flex", justifyContent: "center" },
          }}
          title={false}
        >
          <>
            {record.status !== AssetStatus.Disposed ? (
              <Tooltip title="Edit" color="#444653" placement="top">
                <EditOutlined
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setIsModalOpen(true);
                    // setEditAsset(record);
                    navigate(
                      `/accounts/clients/${currentClient?.id}/asset/addFixedAsset`,
                      {
                        state: {
                          data: record,
                        },
                      }
                    );
                  }}
                />
              </Tooltip>
            ) : (
              ""
            )}
          </>
        </Skeleton>
      ),
    },
  ];

  return (
    <>
      <Card
        size="small"
        className="TableCard"
        style={{
          // width: "97%",
          // marginLeft: "16px",
          marginTop: 35,
          // padding: "48px 0px 0px 0px",
          boxShadow: "0px 0px 10px 0px #96A1C84D",
        }}
      >
        <Row
          justify={"space-between"}
          style={{
            // paddingTop: props?.partyId ? "10px" : "0px"
            // marginTop: 10,
            padding: "0px 0px 3px 0px",
          }}
        >
          <Col>
            <Row>
              <Col>
                <Button
                  type="primary"
                  onClick={() =>
                    navigate(
                      `/accounts/clients/${currentClient?.id}/asset/addFixedAsset`
                    )
                  }
                >
                  <PlusOutlined />
                  Fixed Assets
                </Button>
              </Col>
            </Row>
          </Col>

          <Col>
            <Row>
              <Col>
                <Input
                  style={{ width: "200px" }}
                  placeholder="Search Asset"
                  allowClear
                  onChange={(e: any) => {
                    setPage(1);
                    setSearchAsset(e.target.value);
                    //console.log(e.target.value, "search");
                  }}
                  suffix={
                    loading || searchAsset ? (
                      ""
                    ) : (
                      <img alt="search" src={search} />
                    )
                  }
                />
              </Col>
              <Space size={5}>
                <span style={{ marginLeft: "10px" }}>Filter</span>
                <Col style={{ justifyContent: "end" }}>
                  <Select
                    defaultValue={AssetStatus.Active}
                    style={{ width: 120 }}
                    options={[
                      { value: AssetStatus.All, label: "ALL" },
                      { value: AssetStatus.Active, label: "Active" },
                      { value: AssetStatus.Disposed, label: "Disposed" },
                    ]}
                    onChange={(e: any) => {
                      setChangeStatus(e);
                      console.log(e, "changestatusassets");
                    }}
                  />
                </Col>
              </Space>
            </Row>
          </Col>
        </Row>
        <Table
          className={`Tabel-style table-${
            themeData?.componentSize ?? "middle"
          }`}
          columns={columns}
          dataSource={
            loading
              ? (Array.from({ length: pageSize }, (_, index) => ({
                  key: `loading-${index}`,
                })) as IDataType[])
              : fixedAssetList
          }
          // expandable={{
          //   expandedRowRender: test,
          //   // expandedRowRender: (_: any, record: any) => {
          //   //   console.log("Sel Record: ", record);
          //   //   return expandedRowRender(record?.invoiceDetail);
          //   // },
          //   defaultExpandAllRows: true,
          // }}
          pagination={{
            total: totalRecords,
            current: page,
            pageSize: pageSize,
            showSizeChanger: true,
            onChange: (page: number, pageSize: number) => {
              setPage(page);
              setPageSize(pageSize);
            },
            pageSizeOptions: ["10", "15", "25", "50", "100"],
          }}
          scroll={
            pageSize > 15 ? { x: 1500, y: window.innerHeight - 300 } : undefined
          }
        />
        {confirmation && (
          <ConfirmationModal
            open={confirmation}
            onNo={() => setConfirmation(false)}
            onYes={() => {
              setDeleteOrRevive({
                id: assetId,
                isRev: changeStatus === AssetStatus.Disposed ? true : false,
              });
              //console.log("yogesh");
              setConfirmation(false);
            }}
            text={
              changeStatus === AssetStatus.Disposed
                ? `Are you sure you want to revive ?`
                : `Are you sure you want to Delete this row ?`
            }
          />
        )}
        {/* {isModalOpen && (
          <AssetModel
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            isConfirmation={isConfirmation}
            setIsConfirmation={setIsConfirmation}
            modelCancel={modelCancel}
            setTrigger={setTrigger}
            editAsset={editAsset}
          />
        )} */}
        {isViewModelOpen && (
          <ViewAssetModel
            isViewModelOpen={isViewModelOpen}
            onCancel={() => setIsViewModelOpen(false)}
            assetId={assetId}
            type={type}
          />
        )}
      </Card>
      {journalModel.open && (
        <AssetJournalModal
          modal={journalModel}
          setSuccess={setSeccesRollBack}
          success={successRollBack}
          setModal={setJournalModel}
        />
      )}
    </>
  );
};
export default FixedAssets;
