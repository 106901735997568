import React, { ReactNode } from "react";
import { useClient } from "../Context/ClientContext";
import { useUser } from "../../Context/UserContext";
import LogService, { LogTypes } from "../Services/LogService";
import { matchPath, useLocation } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Divider,
  Drawer,
  Input,
  Pagination,
  Row,
  Select,
  Space,
  Spin,
  Typography,
} from "antd";
import { dayjs } from "../../Utilities/dayjs";
import {
  CiCircleFilled,
  FilterOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { ImHistory } from "react-icons/im";
import logsicon from "../../images/logsicon.svg";
import { AiOutlineFileSync } from "react-icons/ai";
import Search from "antd/es/transfer/search";
import { number } from "echarts";
const LogPannel = () => {
  const [logData, setLogData] = React.useState<any>([]);
  const [openLogDrawer, setOpenLogDrawer] = React.useState(false);
  const [getSearch, setGetSearch] = React.useState<string>();
  const { currentClient } = useClient();
  const location = useLocation();
  const { getUser } = useUser();
  const [searchLog, setSearchLog] = React.useState<any>();
  const [selectLogType, setSelectLogType] = React.useState<any>(LogTypes?.All);
  const loginUser: any = { ...getUser() };
  const [pageSize, setPageSize] = React.useState<number>(10);
  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [totalRecords, setTotalRecords] = React.useState<any>();
  const match = matchPath(`/accounts/clients/:clientId/*`, location.pathname);
  let InvPath = "";
  let InvPathMerge = "";
  const splittedpath = location.pathname.split("/");
  InvPath = splittedpath[splittedpath.length - 1];
  InvPathMerge =
    splittedpath[splittedpath.length - 2] +
    splittedpath[splittedpath.length - 1];
  const { Text } = Typography;

  React.useEffect(() => {
    console.log("InvPath = ", InvPath);
    console.log("InvPathMerge=", InvPathMerge);
    let storevar: any;
    if (loginUser?.id && match?.params?.clientId) {
      console.log(InvPath, "this is invoice path");

      getLogs();
      console.log("storevar = ", storevar);
    }
  }, [
    loginUser?.id,
    match?.params?.clientId,
    InvPath,
    searchLog,
    openLogDrawer,
    selectLogType,
    (page - 1) * pageSize,
    pageSize,
  ]);

  const getLogs = () => {
    setLoading(true);
    if (openLogDrawer) {
      LogService.getUserLog(
        currentClient?.id!,
        loginUser?.id,
        selectLogType,
        "2024/04/01",
        "2025/03/31",
        searchLog,
        (page - 1) * pageSize,
        pageSize
      ).then((res: any) => {
        console.log(res, "log response......>>>>>");
        if (res !== null || res?.items?.length > 0) {
          setTotalRecords(res?.totalRecords);
          setLogData(
            res?.items?.map((d: any, i: number) => ({
              // serialNo: i + 1 + listParams.start,
              key: i,
              // logsList: d?.result
              company: d?.company?.name,
              date: dayjs(d?.createdBy?.date).format("DD MMM YYYY , hh:mm A"),
              description: d?.description,
              from: d?.from,
              to: d?.to,
              user: d?.createdBy?.userName,
              action: d?.action,
            }))
          );
          // setLoading(false);
          // setTotalRecords(res?.totalRecords);
          setLoading(false);
        } else {
          // messageApi.error(res?.message)
          setLoading(false);
        }
      });
    }
  };

  const showLogDrawer = () => {
    setOpenLogDrawer(true);
  };
  const CloseLogDrawer = () => {
    setOpenLogDrawer(false);
  };
  console.log("val", selectLogType);
  console.log("tott", totalRecords);

  return (
    <>
      <span
        style={{
          display: "flex",
          justifyContent: "end",
          cursor: "pointer",
        }}
      >
        {/* <Button size="small" onClick={showLogDrawer}>
                    <Space size={2}>
                        <ImHistory
                            style={{
                                paddingTop: "2px",
                                color: "rgb(66 70 82 / 76%)",
                            }}
                            size={20}
                        />{" "}
                        <span style={{ color: "#444653" }}>Logs</span>
                    </Space>
                </Button> */}
        <Space size={2} style={{ color: "#44546F" }} onClick={showLogDrawer}>
          {/* <img onClick={showLogDrawer} src={logsicon}/> */}
          <AiOutlineFileSync size={18} style={{ color: "#858C9A" }} />
          <span style={{ fontSize: "13px", color: "#253148" }}> Logs</span>
        </Space>
      </span>

      <Drawer
        title="Logs"
        placement={"right"}
        // closable={false}
        onClose={CloseLogDrawer}
        open={openLogDrawer}
      >
        <div style={{ position: "-webkit-sticky", overflowY: "hidden" }}>
          <div>
            <span>Search...</span>
            <Search
              placeholder="Search here..."
              onChange={(e: any) => setSearchLog(e.target.value)}
            />
          </div>
          <div style={{ marginTop: "10px" }}>
            <span style={{ marginBottom: "5px" }}>Select Log Type</span>
            <Select
              // value={LogTypes?.All}
              defaultValue={LogTypes.All}
              style={{ width: "100%" }}
              onChange={(val: any) => setSelectLogType(val)}
              options={[
                {
                  value: LogTypes?.All,
                  label: "All Log Types",
                },
                {
                  value: LogTypes?.Vouchers,
                  label: "Vouchers",
                },
                {
                  value: LogTypes?.Master,
                  label: "Master",
                },
                {
                  value: LogTypes?.Users,
                  label: "Users",
                },
                {
                  value: LogTypes?.Settings,
                  label: "Settings",
                },
                {
                  value: LogTypes?.Security,
                  label: "Security",
                },
                {
                  value: LogTypes?.Reports,
                  label: "Reports",
                },
                {
                  value: LogTypes?.Email,
                  label: "Email",
                },
              ]}
            />
          </div>
        </div>
        <Divider />
        <div
          style={{
            height: "78%",
            overflowY: "auto",
          }}
        >
          <Spin spinning={loading} indicator={<LoadingOutlined />}>
            {logData?.map((item: any, index: number) => {
              return (
                <>
                  <div>
                    <Row>
                      <Col span={1} style={{ marginTop: "10px" }}>
                        <span>{index + 1 + (page - 1) * pageSize}.</span>
                      </Col>
                      <Col span={23}>
                        <Card
                          className="cusomcard3"
                          style={{ margin: "10px", borderColor: "#eff3fe" }}
                        >
                          <p
                            style={{
                              fontSize: "11px",
                              textAlign: "end",
                              color: "#444653",
                            }}
                          >
                            {item?.date}
                          </p>
                          <p
                            style={{
                              fontSize: "11px",
                              textAlign: "end",
                              color: "#444653",
                            }}
                          >
                            {item?.company}
                          </p>
                          <Text
                            style={{
                              fontSize: "13px",
                              fontWeight: "500",
                              color: "rgba(0, 0, 0, 0.88)",
                            }}
                          >
                            {item?.description}
                          </Text>
                          <p style={{ fontSize: "13px", color: "#444653" }}>
                            {" "}
                            ~ {item?.user}
                          </p>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                </>
              );
            })}
          </Spin>
        </div>
        <Divider style={{ margin: "0px" }} />
        <div
          style={{
            paddingTop: "7px",
            boxShadow: "0 0px 4px rgba(0, 0, 0, 0.1)",
            // paddingt: "5px",
          }}
        >
          {/* <Pagination
            defaultCurrent={totalRecords}
            total={totalRecords}
            onChange={(page: number, pageSize: number) => {
              setPage(page);
              setPageSize(pageSize);
            }}
          /> */}
          <Pagination
            showSizeChanger
            simple
            defaultCurrent={totalRecords}
            total={totalRecords}
            onChange={(page: number, pageSize: number) => {
              setPage(page);
              setPageSize(pageSize);
            }}
          />
        </div>
      </Drawer>
    </>
  );
};

export default LogPannel;
