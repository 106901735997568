import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "../../Context/ThemeContext";
import VoucherServices, {
  Party_Tax,
  ReconcileTypes,
  Voucher_Type,
  VoucherFilterStatus,
} from "../../Services/VoucherServices";
import React, { useContext } from "react";
import { useClient } from "../../Context/ClientContext";
import { dayjs } from "../../../Utilities/dayjs";
import { VoucherMode } from "../../Services/SalesService";
import {
  Button,
  Col,
  DatePicker,
  Popover,
  Row,
  Select,
  Skeleton,
  Space,
  Table,
  TableColumnsType,
  Tooltip,
} from "antd";
import { VoucherDetailsData, voucherType } from "./VoucherDetailsByLedger";
import { Utils } from "../../../Utilities/Utils";
import {
  DeleteTwoTone,
  DownloadOutlined,
  EditOutlined,
  ReloadOutlined,
  SyncOutlined,
  ToTopOutlined,
} from "@ant-design/icons";
import ViewPaymentIn from "../Sales/ViewPaymentIn";
import AddLedger from "../Master/Ledger/AddLedger";
import ViewPreviewModel from "../Sales/ViewPreviewModel";
import ViewCreditNote from "../Sales/ViewCreditNote";
import ViewContra from "../Banking/ViewContra";
import ViewJournal from "../Journals/ViewJournal";
import ImportBankTransactionModal from "../Banking/Banks/ImportBankTransactionModal";
import { ConfirmationModal } from "../../Common/ConfirmationModal";
import { viewVoucher } from "../../Services/ViewVoucher";
import { SearchLedgerByGroupsTypes } from "../../Services/LedgerService";
import { notificationContext } from "../../Common/PageRoute";
import InvoiceExportService from "../../Services/InvoiceExportDetails";
import AdjustBankBalanceModel from "../Banking/Banks/AdjustBankBalanceModel";

const VoucherDetailsByLedgerView: React.FC<{
  ledgerId: any;
  refresh: any;
  partyOrTax: any;
  setActiveKey: any;
  setMoreTabs: any;
  moreTabs: any;
  filterToDate?: any;
  filterFromDate?: any;
  voucherType?: Voucher_Type;
  firstLedgerId?: string;
  // setFilterDate?: any;
}> = (props) => {
  console.log("111", props);
  const { themeData } = useTheme();
  const navigate = useNavigate();
  const { openNotification } = useContext(notificationContext);
  const { currentClient, companySettings } = useClient();
  const [loading, setLoading] = React.useState<boolean>(false);
  // const [voucherDetailsData, setVoucherDetailsData] = React.useState([]);
  const [ledgerDetailsData, setLedgerDetailsData] = React.useState<any>([]);
  const [exportBankAllData, setExportBankAllData] = React.useState<any[]>([]);
  const [isExportDisabled, setIsExportDisabled] =
    React.useState<boolean>(false);
  const [totalRecords, setTotalRecords] = React.useState<number>();
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState<number>(50);
  const [searchCustomer, setSearchCustomer] = React.useState<string>("");
  const [width, setWidth] = React.useState<any>(window.innerWidth);
  const [id, setId] = React?.useState<any>();
  const [trigger, setTrigger] = React?.useState(false);
  const [invoiceModel, setInvoiceModel] = React.useState<string>("");
  const [filter, setfilter] = React.useState<any>({
    sortCol: "",
    sortDir: "",
  });
  const [type, setType] = React?.useState<any>();
  const [paymentId, setPaymentId] = React.useState<string>("");
  const [open, setIsOpen] = React.useState<boolean>(false);
  const [journalModal, setJournalModal] = React.useState<boolean>(false);
  const [contraModalOpen, setContraModalOpen] = React.useState<boolean>(false);
  const [bankTransactionModal, setBankTransactionModal] = React?.useState<{
    type: string;
    param: any;
  }>({ type: "", param: {} });
  // const [triggerBankTransactions, setTriggerBankTransactions] =
  //   React.useState<boolean>(false);
  const [dueAmount, setDueAmount] = React.useState<number>(0);
  // const [bankTrigger, setBankTrigger] = React.useState<boolean>(false);
  const [creditModalOpen, setCreditModalOpen] = React.useState<boolean>(false);
  const [voucher, setVoucher] = React.useState<Voucher_Type>(
    props.voucherType ?? Voucher_Type.All
  );
  const [exportType, setExportType] = React.useState<number>(-1);
  const [openModel, setOpenModel] = React.useState<{
    type: string;
    param: any;
  }>({
    type: "",
    param: {},
  });
  const [bank, setBank] = React.useState<{
    id: string;
    name: string;
    partyOrTax: Party_Tax;
  }>({
    id: "",
    name: "",
    partyOrTax: Party_Tax.other,
  });
  const [invoiceStatus, setInvoiceStatus] = React.useState<VoucherFilterStatus>(
    VoucherFilterStatus.Active
  );
  const [confirmation, setConfirmation] = React.useState<boolean>(false);
  const [deleteVoucher, setDeleteVoucher] = React.useState<{
    id: string;
    isRev: boolean;
    number: string;
  }>({
    id: "",
    isRev: false,
    number: "",
  });
  const [refresh, setRefresh] = React.useState<boolean>(false);
  const [refreshButton, setRefreshButton] = React.useState<boolean>(false);

  const { RangePicker } = DatePicker;
  const location = useLocation();
  const [filterDate, setFilterDate] = React.useState<any>({
    from:
      props.filterFromDate ??
      dayjs(companySettings?.period?.from).format("DD/MM/YYYY"),
    to:
      props.filterToDate ??
      dayjs(companySettings?.period?.to).format("DD/MM/YYYY"),
  });

  const oneMoreFooter = (colum: any) => {
    return colum?.map((col: any) => {
      if (col === "Ref No.") {
        return "Balance";
      } else if (col === "Credit") {
        return `${
          tmpTotal === 0
            ? Utils.getFormattedNumber(0)
            : tmpTotal > 0
            ? Utils.getFormattedNumber(tmpTotal) + " Dr"
            : Utils.getFormattedNumber(Math.abs(tmpTotal)) + " Cr"
        }`;
      }
    });
  };

  const generateFooterRow = (columns: string[]) => {
    const totalDebit = ledgerDetailsData
      ?.filter((rcc: any) => rcc?.voucherStatus !== 1)
      .reduce(
        (acc: number, rcc: any) => acc + (rcc?.amount > 0 ? rcc?.amount : 0),
        0
      );
    const totalCredit = ledgerDetailsData
      ?.filter((rcc: any) => rcc?.voucherStatus !== 1)
      ?.filter((rcc: any) => rcc?.voucherStatus !== 1)
      .reduce(
        (acc: any, rcc: any) => acc + (rcc?.amount < 0 ? rcc?.amount : 0),
        0
      );

    return columns.map((col) => {
      if (col === "Debit") {
        return `${Utils.getFormattedNumber(Math.abs(totalDebit))}`;
      } else if (col === "Credit") {
        return `${Utils.getFormattedNumber(Math.abs(totalCredit))}`;
      } else if (col === "Ref No.") {
        return "Total Amount";
      } else {
        return "";
      }
    });
  };

  const columnsA = [
    "Date",
    "Account Name",
    "Ref No.",
    "Voucher",
    "Debit",
    "Credit",
  ];
  let path =
    location.pathname.split("/").pop() === "bankAccounts"
      ? "Bank Account List"
      : location.pathname.split("/").pop() === "cashInHand"
      ? "Cash In Hand List"
      : "";

  console.log("path", location.pathname.split("/").pop());

  const fieldMapping = {
    Date: (item: any) => item?.date,
    "Account Name": (item: any) => item?.ledgerName,
    "Ref No.": (item: any) => item?.number,
    Voucher: (item: any) => voucherType[item?.voucherType],
    Debit: (item: any) =>
      Utils.getFormattedNumber(item?.amount > 0 ? item?.amount : 0),
    Credit: (item: any) =>
      Utils.getFormattedNumber(Math.abs(item?.amount < 0 ? item?.amount : 0)),
  };

  const exportCsv = (exportData: any[]) => {
    InvoiceExportService.generateCSV(
      exportData,
      columnsA,
      fieldMapping,
      generateFooterRow,
      oneMoreFooter,
      filterDate,
      path,
      currentClient?.businessName
    );
    setExportType(0);
  };
  console.log("BankData", ledgerDetailsData);

  const exportPDF = (exportData: any[]) => {
    InvoiceExportService.generatePDF(
      exportData,
      currentClient?.businessName,
      columnsA,
      fieldMapping,
      generateFooterRow,
      filterDate,
      path,
      oneMoreFooter
    );
    setExportType(0);
  };

  const exportExcel = async (exportData: any[]) => {
    await InvoiceExportService.generateExcel(
      exportData,
      currentClient?.businessName,
      columnsA,
      fieldMapping,
      generateFooterRow,
      filterDate,
      path,
      oneMoreFooter
    );
    setExportType(0);
  };

  const content = (
    <Space direction="vertical">
      <div>
        <Button
          style={{ width: "80px" }}
          size="small"
          title="CSV"
          onClick={() => {
            if (exportBankAllData.length > 0) {
              exportCsv(exportBankAllData);
            } else {
              setExportType(1);
              // setApiCallExports(true);
            }
          }}
          icon={<DownloadOutlined />}
        >
          CSV
        </Button>
      </div>

      <div>
        <Button
          style={{ width: "80px" }}
          size="small"
          title="PDF"
          onClick={() => {
            if (exportBankAllData.length > 0) {
              exportPDF(exportBankAllData);
            } else {
              setExportType(2);
              // setApiCallExports(true);
            }
          }}
          icon={<DownloadOutlined />}
        >
          PDF
        </Button>
      </div>
      <div>
        <Button
          style={{ width: "80px" }}
          size="small"
          title="XLSL"
          onClick={() => {
            if (exportBankAllData.length > 0) {
              exportExcel(exportBankAllData);
            } else {
              setExportType(3);
              // setApiCallExports(true);
            }
          }}
          icon={<DownloadOutlined />}
        >
          XLSL
        </Button>
      </div>
    </Space>
  );

  const openRowStyle = (record: any, index: number | undefined) => {
    // Return an object with the desired CSS style for the row
    return {
      background: record.mode === VoucherMode.Opening ? "#f2f5f3" : "none",
    };
  };

  console.log("status", invoiceStatus);

  const columns: TableColumnsType<VoucherDetailsData> = [
    {
      title: "Date",
      key: "date",
      dataIndex: "date",
      sorter: true,
      ellipsis: true,
      width: width <= 1440 ? "10%" : "9%",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
          }}
          title={false}
        >
          {record?.date}
        </Skeleton>
      ),
    },
    {
      title: "Account Name",
      // key: "ledgerName",
      key: "pLedger",
      sorter: true,
      dataIndex: "ledgerName",
      width: "25%",
      align: "left",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          <div
            className="hoverLinkColor cursorPointer"
            onClick={() => openNewTab(record)}
          >
            {record?.ledgerName}
          </div>
        </Skeleton>
      ),
    },
    {
      title: "Ref.No",
      key: "number",
      sorter: true,
      dataIndex: "number",
      ellipsis: true,
      width: width <= 1440 ? "9%" : "8%",
      align: "left",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.voucherStatus === 1 ? (
            <>
              <span
                style={{ color: "red" }}
                // style={{
                //   color: "#395cd2",
                //   cursor: "pointer",
                // }}
                // onClick={() =>
                //   viewVoucher(
                //     navigate,
                //     currentClient,
                //     record.id,
                //     record.voucherType,
                //     setId,
                //     setIsOpen,
                //     setPaymentId,
                //     setType,
                //     setInvoiceModel,
                //     setCreditModalOpen,
                //     setJournalModal,
                //     setContraModalOpen
                //   )
                // }
              >
                {record.mode === VoucherMode.Opening ||
                record.mode === VoucherMode.Closing
                  ? ""
                  : record?.number}
              </span>
            </>
          ) : (
            <>
              <span
                style={{
                  color: "#395cd2",
                  cursor: "pointer",
                }}
                onClick={() =>
                  viewVoucher(
                    navigate,
                    currentClient,
                    record.id,
                    record.voucherType,
                    setId,
                    setIsOpen,
                    setPaymentId,
                    setType,
                    setInvoiceModel,
                    setCreditModalOpen,
                    setJournalModal,
                    setContraModalOpen
                  )
                }
              >
                {record.mode === VoucherMode.Opening ||
                record.mode === VoucherMode.Closing
                  ? ""
                  : record?.number}
              </span>
            </>
          )}
        </Skeleton>
      ),
    },
    {
      title: "Voucher",
      key: "voucherType",
      dataIndex: "voucherType",
      ellipsis: true,
      width: width <= 1440 ? "10%" : "9%",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
          }}
          title={false}
        >
          {record.mode === VoucherMode.Opening ||
          record.mode === VoucherMode.Closing
            ? ""
            : voucherType[record?.voucherType]}
        </Skeleton>
      ),
    },
    {
      title: "Debit ( ₹ )",
      key: "amount",
      dataIndex: "amount",
      ellipsis: true,
      width: width <= 1440 ? "9%" : "8%",
      align: "right",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
            style: { display: "flex", justifyContent: "end" },
          }}
          title={false}
        >
          {record.voucherStatus !== 1 ? (
            <>
              {Utils.getFormattedNumber(
                record?.amount > 0 ? record?.amount : 0
              )}
            </>
          ) : (
            <>
              <span style={{ textDecoration: "line-through" }}>
                {Utils.getFormattedNumber(
                  record?.amount > 0 ? record?.amount : 0
                )}
              </span>
            </>
          )}
        </Skeleton>
      ),
    },
    {
      title: "Credit ( ₹ )",
      key: "amount",
      dataIndex: "amount",
      ellipsis: true,
      width: width <= 1440 ? "9%" : "8%",
      align: "right",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
            style: { display: "flex", justifyContent: "end" },
          }}
          title={false}
        >
          {record.voucherStatus !== 1 ? (
            <>
              {Utils.getFormattedNumber(
                Math.abs(record?.amount < 0 ? record?.amount : 0)
              )}
            </>
          ) : (
            <>
              <span style={{ textDecoration: "line-through" }}>
                {Utils.getFormattedNumber(
                  Math.abs(record?.amount < 0 ? record?.amount : 0)
                )}
              </span>
            </>
          )}
        </Skeleton>
      ),
    },
    {
      title: "Action",
      key: "id",
      dataIndex: "icon2",
      width: "5%",
      align: "center",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record.mode === VoucherMode.Opening ||
          record.mode === VoucherMode.Closing ? (
            ""
          ) : record?.voucherStatus !== 1 ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Tooltip title="Edit" color="#444653" placement="top">
                <EditOutlined
                  twoToneColor="#444653"
                  style={{ marginRight: "10px" }}
                  onClick={() => {
                    invoiceStatus === VoucherFilterStatus.Draft
                      ? record?.voucherType === Voucher_Type.Receipt
                        ? navigate(
                            `/accounts/clients/${currentClient?.id}/sales/editReceiptDraft/${record?.id}`
                          )
                        : record?.voucherType === Voucher_Type.Payment
                        ? navigate(
                            `/accounts/clients/${currentClient?.id}/purchase/editPaymentOutDraft/${record.id}`
                          )
                        : record?.voucherType === Voucher_Type.SalesVoucher
                        ? navigate(
                            `/accounts/clients/${currentClient?.id}/sales/editInvoiceDraft/${record?.id}`,
                            {
                              state: { copy: false },
                            }
                          )
                        : record?.voucherType === Voucher_Type?.PurchaseVoucher
                        ? navigate(
                            `/accounts/clients/${currentClient?.id}/purchase/editBillDraft/${record?.id}`,
                            {
                              state: { copy: false },
                            }
                          )
                        : record?.voucherType === Voucher_Type.CreditNote
                        ? navigate(
                            `/accounts/clients/${currentClient?.id}/sales/editCreditNoteDraft/${record?.id}`
                          )
                        : record?.voucherType === Voucher_Type.DebitNote
                        ? navigate(
                            `/accounts/clients/${currentClient?.id}/sales/editCreditNoteDraft/${record?.id}`
                          )
                        : record?.voucherType === Voucher_Type.Journal
                        ? navigate(
                            `/accounts/clients/${currentClient?.id}/journals/${record?.id}`
                          )
                        : record?.voucherType === Voucher_Type.Quotation
                        ? navigate(
                            `/accounts/clients/${currentClient?.id}/sales/editQuotationDraft/${record?.id}`
                          )
                        : record?.voucherType === Voucher_Type.SalesOrder
                        ? navigate(
                            `/accounts/clients/${currentClient?.id}/sales/editSalesOrderDraft/${record?.id}`
                          )
                        : record?.voucherType === Voucher_Type.PurchaseOrder
                        ? navigate(
                            `/accounts/clients/${currentClient?.id}/purchase/editPurchaseOrderDraft/${record?.id}`
                          )
                        : setId(record?.id)
                      : record?.voucherType === Voucher_Type.Receipt
                      ? navigate(
                          `/accounts/clients/${currentClient?.id}/sales/editReceipt/${record?.id}`
                        )
                      : record?.voucherType === Voucher_Type.Payment
                      ? navigate(
                          `/accounts/clients/${currentClient?.id}/purchase/editPaymentOut/${record.id}`
                        )
                      : record?.voucherType === Voucher_Type.SalesVoucher
                      ? navigate(
                          `/accounts/clients/${currentClient?.id}/sales/editInvoice/${record?.id}`,
                          {
                            state: { copy: false },
                          }
                        )
                      : record?.voucherType === Voucher_Type?.PurchaseVoucher
                      ? navigate(
                          `/accounts/clients/${currentClient?.id}/sales/editInvoice/${record?.id}`,
                          {
                            state: { copy: false },
                          }
                        )
                      : record?.voucherType === Voucher_Type.CreditNote
                      ? navigate(
                          `/accounts/clients/${currentClient?.id}/sales/editCreditNote/${record?.id}`
                        )
                      : record?.voucherType === Voucher_Type.DebitNote
                      ? navigate(
                          `/accounts/clients/${currentClient?.id}/sales/editCreditNote/${record?.id}`
                        )
                      : record?.voucherType === Voucher_Type.Journal
                      ? navigate(
                          `/accounts/clients/${currentClient?.id}/journals/${record?.id}`
                        )
                      : setId(record?.id);
                    setType(SearchLedgerByGroupsTypes?.All);

                    record?.voucherType === Voucher_Type.Contra &&
                      setBank({
                        id:
                          record?.amount > 0
                            ? record?.ledgerId
                            : props.ledgerId,
                        name: record?.amount > 0 ? record?.ledgerName : "",
                        partyOrTax: props.partyOrTax,
                      });

                    if (invoiceStatus === VoucherFilterStatus.Draft) {
                      setOpenModel({
                        type: "adjustBankBalance",
                        param: {
                          id: "",
                          type: VoucherFilterStatus.Draft,
                        },
                      });
                    } else {
                      setOpenModel({
                        type: "adjustBankBalance",
                        param: {
                          id: "",
                          type: "",
                        },
                      });
                    }

                    // Navigate
                    // setId(record?.id);
                    // setType(SearchLedgerByGroupsTypes?.All);
                    // setOpenModel({
                    //   type: "adjustBankBalance",
                    //   param: {
                    //     id: "",
                    //     type: "",
                    //   },
                    // });
                  }}
                />
              </Tooltip>
              {invoiceStatus !== VoucherFilterStatus.Deleted && (
                <Tooltip title="Delete" color="red" placement="top">
                  <DeleteTwoTone
                    className="ca-delete-btn cursor"
                    twoToneColor="#ff879d"
                    onClick={() => {
                      setConfirmation(true);
                      setPaymentId(record?.id);
                      setDeleteVoucher({
                        ...deleteVoucher,
                        number: record?.number,
                      });
                    }}
                  />
                </Tooltip>
              )}
            </div>
          ) : (
            <>
              {/* <Tag color="red">Deleted</Tag> */}
              <Tooltip title="Revive Voucher">
                <SyncOutlined
                  style={{ color: "green" }}
                  onClick={() => {
                    record?.voucherType === Voucher_Type.Receipt
                      ? navigate(
                          `/accounts/clients/${currentClient?.id}/sales/editReceipt/${record?.id}`
                        )
                      : record?.voucherType === Voucher_Type.Payment
                      ? navigate(
                          `/accounts/clients/${currentClient?.id}/purchase/editPaymentOut/${record.id}`
                        )
                      : record?.voucherType === Voucher_Type.SalesVoucher
                      ? navigate(
                          `/accounts/clients/${currentClient?.id}/sales/editInvoice/${record?.id}`,
                          {
                            state: { copy: false },
                          }
                        )
                      : record?.voucherType === Voucher_Type?.PurchaseVoucher
                      ? navigate(
                          `/accounts/clients/${currentClient?.id}/sales/editInvoice/${record?.id}`,
                          {
                            state: { copy: false },
                          }
                        )
                      : record?.voucherType === Voucher_Type.CreditNote
                      ? navigate(
                          `/accounts/clients/${currentClient?.id}/sales/editCreditNote/${record?.id}`
                        )
                      : record?.voucherType === Voucher_Type.DebitNote
                      ? navigate(
                          `/accounts/clients/${currentClient?.id}/sales/editCreditNote/${record?.id}`
                        )
                      : record?.voucherType === Voucher_Type.Journal
                      ? navigate(
                          `/accounts/clients/${currentClient?.id}/journals/${record?.id}`
                        )
                      : setId(record?.id);
                    setType(SearchLedgerByGroupsTypes?.All);
                    setOpenModel({
                      type: "adjustBankBalance",
                      param: {
                        id: "",
                        type: "",
                      },
                    });
                    // Navigate
                    // setId(record?.id);
                    // setType(SearchLedgerByGroupsTypes?.All);
                    // setOpenModel({
                    //   type: "adjustBankBalance",
                    //   param: {
                    //     id: "",
                    //     type: "",
                    //   },
                    // });
                  }}
                />
              </Tooltip>
            </>
          )}
        </Skeleton>
      ),
    },
  ];

  React.useEffect(() => {
    if (currentClient?.id && props?.ledgerId) {
      // setLedgerDetailsData([]);
      getList(
        currentClient?.id,
        voucher,
        (page - 1) * pageSize,
        pageSize,
        filterDate.from,
        filterDate.to,
        filter.sortCol,
        filter.sortDir,
        props.ledgerId,
        ReconcileTypes.Both,
        searchCustomer,
        invoiceStatus
        // props?.filterDate
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentClient?.id,
    page,
    pageSize,
    filter.sortCol,
    filter.sortDir,
    props.filterFromDate,
    props.filterToDate,
    props.ledgerId,
    searchCustomer,
    refresh,
    props.refresh,
    invoiceStatus,
    props.voucherType,
    refreshButton,
    filterDate,
    voucher,
    // props?.filterDate,
  ]);

  const getList = async (
    clientId: string,
    Voucher_Type: Voucher_Type,
    start: number,
    length: number,
    from?: string,
    to?: string,
    sortCol?: string,
    sortDir?: string,
    partyId?: string,
    IsReconcile?: ReconcileTypes,
    search?: string,
    status?: VoucherFilterStatus
  ) => {
    if (exportType < 1) {
      setLoading(true);
    }
    await VoucherServices.getVoucharList(
      clientId,
      Voucher_Type,
      start,
      0,
      from,
      to,
      sortCol,
      sortDir,
      partyId,
      IsReconcile,
      search,
      status
    )
      .then((res: any) => {
        if (res.result?.voucherItems?.length > 0) {
          let ledgerDetails = res?.result?.voucherItems
            ?.map((el: any, index: number) => {
              var arr: any = [];
              //  console.log("This is my data", el);
              switch (el.voucherType) {
                case 10:
                  if (
                    el?.eLedger?.find(
                      (item: any) => item.id === props?.ledgerId
                    )
                  ) {
                    var tmpLedList = el?.eLedger?.filter(
                      (itm: any) => itm.id === props?.ledgerId
                    );
                    arr.push({
                      ...tmpLedList[0],
                      amount: tmpLedList[0].amount,
                      ledgerName:
                        el.mode === VoucherMode.Normal
                          ? el?.pLedger[0]?.ledgerName
                          : el.mode === VoucherMode.Opening
                          ? "Opening Balance"
                          : "Closing Balance",
                      voucherType: el?.voucherType,
                      id: el?.vId,
                      ledgerId: el?.pLedger[0]?.id,
                      number: el?.number,
                      mode: el?.mode,
                      voucherStatus: el?.voucherStatus,
                      date: dayjs(el?.date).format("DD/MM/YYYY"),
                    });
                  } else {
                    tmpLedList = el?.pLedger?.filter(
                      (itm: any) => itm.id === props?.ledgerId
                    );
                    arr.push({
                      ...tmpLedList[0],
                      amount: tmpLedList[0].amount,
                      ledgerName:
                        el.mode === VoucherMode.Normal
                          ? el?.eLedger[0]?.ledgerName
                          : el.mode === VoucherMode.Opening
                          ? "Opening Balance"
                          : "Closing Balance",
                      ledgerId: el?.eLedger[0]?.id,
                      voucherType: el?.voucherType,
                      id: el?.vId,
                      number: el?.number,
                      mode: el?.mode,
                      voucherStatus: el?.voucherStatus,

                      date: dayjs(el?.date).format("DD/MM/YYYY"),
                    });
                  }
                  break;
                case 1:
                case 2:
                case 3:
                case 4:
                  if (
                    el?.pLedger?.find((itm: any) => itm?.id === props?.ledgerId)
                  ) {
                    arr.push({
                      ...el?.eLedger[0],
                      ledgerName:
                        el.mode === VoucherMode.Normal
                          ? el?.eLedger[0]?.ledgerName
                          : el.mode === VoucherMode.Opening
                          ? "Opening Balance"
                          : "Closing Balance",
                      ledgerId: el?.eLedger[0]?.id,
                      amount: el?.pLedger[0].amount, //-itm.amount,
                      voucherType: el?.voucherType,
                      id: el?.vId,
                      number: el?.number,
                      mode: el?.mode,
                      voucherStatus: el?.voucherStatus,

                      date: dayjs(el?.date).format("DD/MM/YYYY"),
                    });
                  } else {
                    if (
                      el?.dLedger?.find(
                        (item: any) => item.id === props?.ledgerId
                      )
                    ) {
                      tmpLedList = el?.dLedger?.filter(
                        (itm: any) => itm.id === props?.ledgerId
                      );

                      arr.push({
                        ...tmpLedList[0],
                        amount: tmpLedList[0].amount,
                        ledgerName:
                          el.mode === VoucherMode.Normal
                            ? el?.pLedger[0]?.ledgerName
                            : el.mode === VoucherMode.Opening
                            ? "Opening Balance"
                            : "Closing Balance",
                        ledgerId: el?.pLedger[0]?.id,
                        voucherType: el?.voucherType,
                        id: el?.vId,
                        number: el?.number,
                        mode: el?.mode,
                        voucherStatus: el?.voucherStatus,
                        date: dayjs(el?.date).format("DD/MM/YYYY"),
                      });
                    }
                    if (
                      el?.eLedger?.find(
                        (item: any) => item.id === props?.ledgerId
                      )
                    ) {
                      tmpLedList = el?.eLedger?.filter(
                        (itm: any) => itm.id === props?.ledgerId
                      );
                      arr.push({
                        ...tmpLedList[0],
                        amount: tmpLedList[0].amount,
                        ledgerName:
                          el.mode === VoucherMode.Normal
                            ? el?.pLedger[0]?.ledgerName
                            : el.mode === VoucherMode.Opening
                            ? "Opening Balance"
                            : "Closing Balance",
                        ledgerId: el?.pLedger[0]?.id,

                        voucherType: el?.voucherType,
                        id: el?.vId,
                        number: el?.number,
                        mode: el?.mode,
                        date: dayjs(el?.date).format("DD/MM/YYYY"),
                        voucherStatus: el?.voucherStatus,
                      });
                    }
                    if (
                      el?.sLedger?.find(
                        (item: any) => item.id === props?.ledgerId
                      )
                    ) {
                      tmpLedList = el?.sLedger?.filter(
                        (itm: any) => itm.id === props?.ledgerId
                      );
                      arr.push({
                        ...tmpLedList[0],
                        amount: tmpLedList[0].amount,
                        ledgerName:
                          el.mode === VoucherMode.Normal
                            ? el?.pLedger[0]?.ledgerName
                            : el.mode === VoucherMode.Opening
                            ? "Opening Balance"
                            : "Closing Balance",
                        ledgerId: el?.pLedger[0]?.id,
                        voucherType: el?.voucherType,
                        id: el?.vId,
                        number: el?.number,
                        mode: el?.mode,
                        date: dayjs(el?.date).format("DD/MM/YYYY"),
                        voucherStatus: el?.voucherStatus,
                      });
                    }
                  }
                  break;
                case 5:
                case 6:
                  if (
                    el?.eLedger?.find((itm: any) => itm?.id === props?.ledgerId)
                  ) {
                    arr.push({
                      ...el?.pLedger[0],
                      ledgerName:
                        el.mode === VoucherMode.Normal
                          ? el?.pLedger[0]?.ledgerName
                          : el.mode === VoucherMode.Opening
                          ? "Opening Balance"
                          : "Closing Balance",
                      ledgerId: el?.pLedger[0]?.id,
                      amount: el?.eLedger[0].amount, //-itm.amount,
                      voucherType: el?.voucherType,

                      id: el?.vId,
                      number: el?.number,
                      mode: el?.mode,
                      date: dayjs(el?.date).format("DD/MM/YYYY"),
                      voucherStatus: el?.voucherStatus,
                    });
                  } else {
                    if (
                      el?.dLedger?.find(
                        (item: any) => item.id === props?.ledgerId
                      )
                    ) {
                      tmpLedList = el?.dLedger?.filter(
                        (itm: any) => itm.id === props?.ledgerId
                      );

                      arr.push({
                        ...tmpLedList[0],
                        amount: tmpLedList[0].amount,
                        ledgerName:
                          el.mode === VoucherMode.Normal
                            ? el?.eLedger[0]?.ledgerName
                            : el.mode === VoucherMode.Opening
                            ? "Opening Balance"
                            : "Closing Balance",
                        ledgerId: el?.eLedger[0]?.id,
                        voucherType: el?.voucherType,
                        id: el?.vId,
                        number: el?.number,
                        mode: el?.mode,
                        date: dayjs(el?.date).format("DD/MM/YYYY"),
                        voucherStatus: el?.voucherStatus,
                      });
                    }
                    if (
                      el?.pLedger?.find(
                        (item: any) => item.id === props?.ledgerId
                      )
                    ) {
                      tmpLedList = el?.pLedger?.filter(
                        (itm: any) => itm.id === props?.ledgerId
                      );
                      arr.push({
                        ...tmpLedList[0],
                        amount: tmpLedList[0].amount,
                        ledgerName:
                          el.mode === VoucherMode.Normal
                            ? el?.eLedger[0]?.ledgerName
                            : el.mode === VoucherMode.Opening
                            ? "Opening Balance"
                            : "Closing Balance",
                        ledgerId: el?.eLedger[0]?.id,
                        voucherType: el?.voucherType,
                        id: el?.vId,
                        number: el?.number,
                        mode: el?.mode,
                        date: dayjs(el?.date).format("DD/MM/YYYY"),
                        voucherStatus: el?.voucherStatus,
                      });
                    }
                    if (
                      el?.sLedger?.find(
                        (item: any) => item.id === props?.ledgerId
                      )
                    ) {
                      tmpLedList = el?.sLedger?.filter(
                        (itm: any) => itm.id === props?.ledgerId
                      );
                      arr.push({
                        ...tmpLedList[0],
                        amount: tmpLedList[0].amount,
                        ledgerName:
                          el.mode === VoucherMode.Normal
                            ? el?.eLedger[0]?.ledgerName
                            : el.mode === VoucherMode.Opening
                            ? "Opening Balance"
                            : "Closing Balance",
                        ledgerId: el?.eLedger[0]?.id,
                        voucherType: el?.voucherType,
                        id: el?.vId,
                        number: el?.number,
                        mode: el?.mode,
                        date: dayjs(el?.date).format("DD/MM/YYYY"),
                        voucherStatus: el?.voucherStatus,
                      });
                    }
                  }
                  break;
                case 7:
                  if (
                    el?.eLedger?.find((itm: any) => itm?.id === props?.ledgerId)
                  ) {
                    arr.push({
                      ...el?.pLedger[0],
                      amount: el?.eLedger[0].amount, //-itm.amount,
                      ledgerName:
                        el.mode === VoucherMode.Normal
                          ? el?.pLedger[0]?.ledgerName
                          : el.mode === VoucherMode.Opening
                          ? "Opening Balance"
                          : "Closing Balance",
                      ledgerId: el?.pLedger[0]?.id,
                      voucherType: el?.voucherType,
                      id: el?.vId,
                      number: el?.number,
                      mode: el?.mode,
                      date: dayjs(el?.date).format("DD/MM/YYYY"),
                      voucherStatus: el?.voucherStatus,
                    });
                  } else {
                    if (
                      el?.dLedger?.find(
                        (item: any) => item.id === props?.ledgerId
                      )
                    ) {
                      tmpLedList = el?.dLedger?.filter(
                        (itm: any) => itm.id === props?.ledgerId
                      );

                      arr.push({
                        ...tmpLedList[0],
                        amount: tmpLedList[0].amount,
                        ledgerName:
                          el.mode === VoucherMode.Normal
                            ? el?.eLedger[0]?.ledgerName
                            : el.mode === VoucherMode.Opening
                            ? "Opening Balance"
                            : "Closing Balance",
                        ledgerId: el?.eLedger[0]?.id,
                        voucherType: el?.voucherType,
                        id: el?.vId,
                        number: el?.number,
                        mode: el?.mode,
                        date: dayjs(el?.date).format("DD/MM/YYYY"),
                        voucherStatus: el?.voucherStatus,
                      });
                    }
                    if (
                      el?.pLedger?.find(
                        (item: any) => item.id === props?.ledgerId
                      )
                    ) {
                      tmpLedList = el?.pLedger?.filter(
                        (itm: any) => itm.id === props?.ledgerId
                      );
                      arr.push({
                        ...tmpLedList[0],
                        amount: tmpLedList[0].amount,
                        ledgerName:
                          el.mode === VoucherMode.Normal
                            ? el?.eLedger[0]?.ledgerName
                            : el.mode === VoucherMode.Opening
                            ? "Opening Balance"
                            : "Closing Balance",
                        ledgerId: el?.eLedger[0]?.id,
                        voucherType: el?.voucherType,
                        id: el?.vId,
                        number: el?.number,
                        mode: el?.mode,
                        date: dayjs(el?.date).format("DD/MM/YYYY"),
                        voucherStatus: el?.voucherStatus,
                      });
                    }
                    if (
                      el?.sLedger?.find(
                        (item: any) => item.id === props?.ledgerId
                      )
                    ) {
                      tmpLedList = el?.sLedger?.filter(
                        (itm: any) => itm.id === props?.ledgerId
                      );
                      arr.push({
                        ...tmpLedList[0],
                        amount: tmpLedList[0].amount,
                        ledgerName:
                          el.mode === VoucherMode.Normal
                            ? el?.eLedger[0]?.ledgerName
                            : el.mode === VoucherMode.Opening
                            ? "Opening Balance"
                            : "Closing Balance",
                        ledgerId: el?.eLedger[0]?.id,
                        voucherType: el?.voucherType,
                        id: el?.vId,
                        number: el?.number,
                        mode: el?.mode,
                        date: dayjs(el?.date).format("DD/MM/YYYY"),
                        voucherStatus: el?.voucherStatus,
                      });
                    }
                  }
                  break;
              }
              let openiningEntryIndex = arr.findIndex(
                (v: any) => v.mode === VoucherMode.Opening
              );
              if (openiningEntryIndex !== -1) {
                const obj = arr.splice(openiningEntryIndex, 1)[0];
                arr.unshift(obj);
              }
              return arr;
            })
            .flat();
          setExportBankAllData(ledgerDetails);
          setLedgerDetailsData(ledgerDetails);

          // setDueAmount(res?.result?.totalDueAmount);
          //setNetAmount(        );
          //setNetAmount(res?.result.totalAmount);
          // setTotalRecords(res?.totalRecords);
          setLoading(false);
          // setVoucherDetailsData(res);
          // console.log("due", res?.result?.items);
          setIsExportDisabled(false);
        } else {
          setIsExportDisabled(true);
          setLoading(false);
          setLedgerDetailsData([]);
        }
      })
      .catch((ex: any) => {
        console?.error("error..", ex.message);
        setLoading(false);
        setLedgerDetailsData([]);
      });
  };

  // deleteVoucher api
  React.useEffect(() => {
    if (deleteVoucher?.id) {
      const DeleteCreditNote = async () => {
        setLoading(true);
        await VoucherServices.deleteVoucher(
          currentClient?.id!,
          deleteVoucher.id
        ).then((res) => {
          setLoading(false);
          if (res?.result) {
            setRefresh(!refresh);
            openNotification(
              "success",
              `Entry deleted successfully ${deleteVoucher.number}`
            );
          }
        });
      };
      DeleteCreditNote();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteVoucher?.id]);

  var tmpTotal: number = ledgerDetailsData
    ?.filter((rcc: any) => rcc?.voucherStatus !== 1)
    .reduce((acc: number, rcc: any) => acc + rcc?.amount, 0);

  const tableSort = (pagination?: any, sort?: any, filter?: any) => {
    setfilter({
      sortCol: filter.column !== undefined ? filter.columnKey : "AutoNumber",
      sortDir:
        filter.column !== undefined
          ? filter.order === "descend"
            ? "desc"
            : "asc"
          : "desc",
    });
  };

  const openNewTab = (record: any) => {
    const newActiveKey = record?.ledgerId;
    console.log(
      "record",
      record,
      props.moreTabs.find((x: any) => x.key === newActiveKey)
    );
    if (
      props.moreTabs.find((x: any) => x.key === newActiveKey) ||
      props.firstLedgerId === newActiveKey
    ) {
      props.setActiveKey(newActiveKey);
    } else {
      props.setMoreTabs([
        ...props.moreTabs,
        {
          label: `${record?.ledgerName}`,
          children: "New Tab Pane",
          key: newActiveKey,
        },
      ]);
      props.setActiveKey(newActiveKey);
      // setLedgerId(record?.id);
    }
  };

  return (
    <>
      <>
        {/* {props.ledgerId !== props.firstLedgerId && ( */}
        <Row
          justify="space-between"
          style={{
            // paddingTop: props?.partyId ? "10px" : "0px"
            // marginTop: 10,
            padding: "4px 0px 0px",
          }}
        >
          <Col>
            <Space size={width <= 768 ? 440 : 5}>
              {/* <Col span={24}> */}
              <RangePicker
                format="DD-MM-YYYY"
                style={{ width: "250px" }}
                defaultValue={
                  props?.filterFromDate && props?.filterToDate
                    ? [
                        dayjs(props.filterFromDate, "DD/MM/YYYY"),
                        dayjs(props.filterToDate, "DD/MM/YYYY"),
                      ]
                    : [
                        dayjs(filterDate?.from, "DD/MM/YYYY"),
                        dayjs(filterDate?.to, "DD/MM/YYYY"),
                      ]
                }
                onChange={(e: any) => {
                  if (e === null) {
                    setFilterDate({});
                  } else {
                    setFilterDate({
                      ...filterDate,
                      from: dayjs(e[0]).format("DD/MM/YYYY"),
                      to: dayjs(e[1]).format("DD/MM/YYYY"),
                    });
                    localStorage.setItem(
                      "LockPeriod",
                      JSON.stringify({
                        ...filterDate,
                        from: dayjs(e[0]).format("DD/MM/YYYY"),
                        to: dayjs(e[1]).format("DD/MM/YYYY"),
                      })
                    );
                  }
                  openNotification("success", `Period locked.`);

                  // props.setFilterDate({
                  //   from: dayjs(e[0]).format("DD/MM/YYYY"),
                  //   to: dayjs(e[1]).format("DD/MM/YYYY"),
                  // });
                  // SetPageInv(1);
                }}
                presets={[
                  {
                    label: "Today",
                    value: [dayjs().add(0, "d"), dayjs()],
                  },
                  {
                    label: "Last 7 Days",
                    value: [dayjs().add(-7, "d"), dayjs()],
                  },
                  {
                    label: "Last 15 Days",
                    value: [dayjs().add(-15, "d"), dayjs()],
                  },
                  {
                    label: "This Month",
                    value: [dayjs().startOf("month"), dayjs()],
                  },
                  {
                    label: "Last Month",
                    value: [dayjs().date(0).startOf("month"), dayjs().date(0)],
                  },
                  {
                    label: "Quarter 1",
                    value: [
                      dayjs().month(3).startOf("month"),
                      dayjs().month(5).endOf("month"),
                    ],
                  },
                  {
                    label: "Quarter 2",
                    value: [
                      dayjs().month(6).startOf("month"),
                      dayjs().month(8).endOf("month"),
                    ],
                  },
                  {
                    label: "Quarter 3",
                    value: [
                      dayjs().month(9).startOf("month"),
                      dayjs().month(11).endOf("month"),
                    ],
                  },
                  {
                    label: "Quarter 4",
                    value: [
                      dayjs().month(0).startOf("month"),
                      dayjs().month(2).endOf("month"),
                    ],
                  },
                  {
                    label: "This Year",
                    //           dayjs().month(0).startOf("month"),
                    // dayjs(),
                    value: [dayjs().month(0).startOf("month"), dayjs()],
                  },
                  {
                    label: "Last Year",
                    value: [
                      dayjs().month(-12).startOf("month"),
                      dayjs().month(-1).endOf("month"),
                    ],
                  },
                ]}
              />
              {/* </Col> */}

              {/* <Col span={24}> */}
              <Select
                clearIcon={true}
                value={voucher}
                style={{ width: "150px" }}
                allowClear
                options={[
                  { value: Voucher_Type.All, label: "All Vouchers" },
                  {
                    value: Voucher_Type.SalesVoucher,
                    label: "Sales Invoice",
                  },
                  { value: Voucher_Type.CreditNote, label: "Credit Note" },
                  {
                    value: Voucher_Type.PurchaseVoucher,
                    label: "Purchase Invoice",
                  },
                  { value: Voucher_Type.DebitNote, label: "Debit Note" },
                  { value: Voucher_Type.Receipt, label: "Receipt" },
                  { value: Voucher_Type.Payment, label: "Payment" },
                  { value: Voucher_Type.Journal, label: "Journal" },
                  { value: Voucher_Type.Contra, label: "Contra" },
                ]}
                onChange={(val: Voucher_Type) => {
                  setPage(1);
                  setVoucher(val);
                }}
              />
              {/* </Col> */}
            </Space>
          </Col>

          <Col
            style={{
              marginTop: width <= 768 ? 15 : "",
            }}
          >
            <Row gutter={width <= 768 ? 60 : 8}>
              <Col>
                <Space size={width <= 768 ? 440 : 5}>
                  <Button
                    icon={<ReloadOutlined />}
                    // disabled={loading}
                    onClick={() => setRefreshButton(!refreshButton)}
                  />
                  {location?.pathname ===
                    `/accounts/clients/${currentClient?.id}/banking/bankAccounts` && (
                    <>
                      <Button
                        icon={<ToTopOutlined />}
                        type="primary"
                        onClick={() => {
                          navigate(
                            `/accounts/clients/${currentClient?.id!}/banking/bankTransactionsHistory`,
                            {
                              state: {
                                ledgerId: props?.ledgerId,
                                partyOrTax: props?.partyOrTax,
                              },
                            }
                          );

                          // setBankTransactionModal({
                          //   type: "BankTransaction",
                          //   param: {
                          //     open: true,
                          //   },
                          // });
                        }}
                      >
                        Import Transactions
                      </Button>
                    </>
                  )}
                  <Popover
                    content={content}
                    placement="rightTop"
                    trigger="hover"
                  >
                    <Button
                      type="default"
                      icon={<DownloadOutlined />}
                      disabled={isExportDisabled}
                    >
                      Export
                    </Button>
                  </Popover>
                  <Select
                    clearIcon={true}
                    value={invoiceStatus}
                    style={{ width: 120 }}
                    allowClear
                    options={[
                      { value: VoucherFilterStatus.All, label: "All" },
                      { value: VoucherFilterStatus.Active, label: "Active" },
                      {
                        value: VoucherFilterStatus.Deleted,
                        label: "Deleted",
                      },
                    ]}
                    onChange={(val: VoucherFilterStatus) => {
                      //  setPage(1);
                      setInvoiceStatus(val);
                    }}
                  />
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* )} */}
        {/* <Row gutter={10} justify="space-between" style={{ marginTop: "10px" }}>
        <Col
          span={12}
          style={{
            marginTop: width <= 768 ? 15 : "",
          }}
        >
          <Row></Row>
        </Col>
      </Row> */}

        <Table
          className={`Tabel-style table-${
            themeData?.componentSize ?? "middle"
          }`}
          size="small"
          columns={columns}
          onRow={(record, index) => ({
            style: openRowStyle(record, index),
          })}
          locale={{
            triggerDesc: "",
            triggerAsc: "",
            cancelSort: "",
          }}
          dataSource={
            loading
              ? (Array.from({ length: 15 }, (_, index) => ({
                  key: `loading-${index}`,
                })) as VoucherDetailsData[])
              : ledgerDetailsData
          }
          pagination={false}
          scroll={{ x: 950, y: window.innerHeight - 280 }}
          onChange={tableSort}
          summary={() => {
            return (
              <Table.Summary fixed>
                <Table.Summary.Row
                  style={{ backgroundColor: "#f5f8ff", fontWeight: "bold" }}
                >
                  <Table.Summary.Cell
                    index={1}
                    className="fw600"
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={2}
                    className="fw600"
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell index={3} className="fw600">
                    Total Amount
                  </Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={4}
                    className="fw600"
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell index={5} align="right">
                    ₹{" "}
                    {Utils.getFormattedNumber(
                      Math?.abs(
                        ledgerDetailsData
                          ?.filter((rcc: any) => rcc?.voucherStatus !== 1)
                          .reduce(
                            (acc: any, rcc: any) =>
                              acc + (rcc?.amount > 0 ? rcc?.amount : 0),
                            0
                          )
                      )
                    )}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={5} className="fw600" align="right">
                    ₹{" "}
                    {Utils.getFormattedNumber(
                      Math?.abs(
                        ledgerDetailsData
                          ?.filter((rcc: any) => rcc?.voucherStatus !== 1)
                          .reduce(
                            (acc: any, rcc: any) =>
                              acc + (rcc?.amount < 0 ? rcc?.amount : 0),
                            0
                          )
                      )
                    )}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={7}></Table.Summary.Cell>
                </Table.Summary.Row>
                <Table.Summary.Row
                  style={{ backgroundColor: "#f5f8ff", fontWeight: "bold" }}
                >
                  <Table.Summary.Cell index={1}></Table.Summary.Cell>
                  <Table.Summary.Cell index={2}></Table.Summary.Cell>
                  <Table.Summary.Cell index={3}>Balance</Table.Summary.Cell>
                  <Table.Summary.Cell index={4}></Table.Summary.Cell>
                  <Table.Summary.Cell index={5}></Table.Summary.Cell>
                  <Table.Summary.Cell index={6} align="right">
                    ₹{" "}
                    {tmpTotal === 0
                      ? Utils.getFormattedNumber(0)
                      : tmpTotal > 0
                      ? Utils.getFormattedNumber(tmpTotal) + " Dr"
                      : Utils.getFormattedNumber(Math.abs(tmpTotal)) + " Cr"}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={7}></Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
      </>

      {open && (
        <ViewPaymentIn
          open={open}
          onCancel={() => setIsOpen(false)}
          paymentId={paymentId}
          type={type}
        />
      )}

      {openModel.type === "addLedger" && (
        <AddLedger
          openDrawer={openModel?.param.open}
          id={props?.ledgerId}
          setOpenDrawer={() => setOpenModel({ type: "", param: "" })}
          setTrigger={() => setTrigger(!trigger)}
          // setRefresh={setRefresh}
        />
      )}

      {invoiceModel === "viewSalesInvoice" && (
        <ViewPreviewModel
          open={invoiceModel === "viewSalesInvoice"}
          invVoucharType={type}
          onCancel={() => setInvoiceModel("")}
          voucherId={paymentId}
        />
      )}

      {creditModalOpen && (
        <ViewCreditNote
          open={creditModalOpen}
          onCancel={() => setCreditModalOpen(false)}
          creditNoteId={paymentId}
          voucharType={type}
        />
      )}

      {journalModal && (
        <ViewJournal
          open={journalModal}
          onCancel={() => setJournalModal(false)}
          journalId={paymentId}
          type={type}
        />
      )}

      {contraModalOpen && (
        <ViewContra
          open={contraModalOpen}
          onCancel={() => setContraModalOpen(false)}
          voucherId={paymentId}
          type={type}
        />
      )}

      {bankTransactionModal.type === "BankTransaction" && (
        <ImportBankTransactionModal
          ledgerId={props?.ledgerId}
          open={true}
          onCancel={() => setBankTransactionModal({ type: "", param: "" })}
          partyOrTax={props?.partyOrTax}
        />
      )}

      {confirmation && (
        <ConfirmationModal
          open={confirmation}
          onNo={() => setConfirmation(false)}
          onYes={() => {
            setDeleteVoucher({
              ...deleteVoucher,
              id: paymentId,
              isRev:
                invoiceStatus === VoucherFilterStatus.Deleted ? true : false,
            });
            setConfirmation(false);
          }}
          text={
            invoiceStatus === VoucherFilterStatus.Deleted
              ? `Are you sure you want to revive "${deleteVoucher.number}" ?`
              : `Are you sure you want to deleteVoucher "${deleteVoucher.number}" ?`
          }
        />
      )}

      {openModel.type === "adjustBankBalance" && (
        <AdjustBankBalanceModel
          open={true}
          onCancel={() => setOpenModel({ type: "", param: "" })}
          // bankAccountTrigger={() => setBankTrigger(!bankTrigger)}
          // triggerBankTransactions={() =>
          //   setTriggerBankTransactions(!triggerBankTransactions)
          // }
          type={openModel.param?.type}
          allBankAccount={[]}
          bank={bank}
          id={id}
          ledgerName={props?.ledgerId}
          setRefresh={setRefresh}
          disable={openModel?.param?.disable}
          // item={{ id: bank.id, name: bankAccountDetails?.name }}
        />
      )}
    </>
  );
};
export default VoucherDetailsByLedgerView;
